import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Plan } from "./Plan";
import { getPlans } from "api";

export const Planes = () => {
  const { t } = useTranslation(["organization"]);

  const [planes, setPlanes] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const { data } = await getPlans();
        setPlanes(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchPlans();
  }, []);

  const onClick = (plan) => {
    if (plan.name === "Small") {
      console.log(plan.name);
    }
    if (plan.name === "Free") {
      console.log(plan.name);
    }
    if (plan.name === "Plus") {
      console.log(plan.name);
    }
    if (plan.name === "Enterprise") {
      console.log(plan.name);
    }
    if (plan.name === "Trial") {
      console.log(plan.name);
    }
  };

  return (
    <>
      <Grid container spacing={8}>
        {planes.map((plan, index) => (
          <Grid key={index} item={true} xs={12} md={4}>
            <Plan
              plan={plan}
              onClick={onClick}
              label={t("organization:Adquirir")}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
