import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicTable, UIButton } from "components";
import {
  Box,
  Paper,
  Typography,
  TableCell,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { ModalAsset } from "../../Employees/Profiles/Assets/ModalAsset";
import { getAssets } from "api";

export const PhysicalAssets = () => {
  const [rows, setRows] = useState([]);
  const [alignment, setAlignment] = useState("Activos");
  const [openModal, setOpenModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const { data } = await getAssets();
        const updateData = data.map((asset) => ({
          id: asset._id,
          name: asset.name,
          brand: asset.brand,
          use: asset.serialNumber,
          employee: asset.employeeFullName,
          impact: asset.impact,
        }));
        setRows(updateData);
        setResponseData(data);
      } catch (err) {
        console.error("Error getting assets:", err);
      }
    };

    fetchAssets();
  }, []);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    if (newAlignment === "Activos") {
      const updateData = responseData.map((asset) => ({
        id: asset._id,
        name: asset.name,
        brand: asset.brand,
        use: asset.serialNumber,
        employee: asset?.employeeFullName ?? "",
        impact: asset.impact,
      }));
      setRows(updateData);
    } else if (newAlignment === "Asignados") {
      const updateData = responseData
        .filter((asset) => asset?.employee)
        .map((asset) => ({
          id: asset._id,
          name: asset.name,
          brand: asset.brand,
          use: asset.serialNumber,
          employee: asset.employeeFullName,
          impact: asset.impact,
        }));
      setRows(updateData);
    } else if (newAlignment === "No Asignados") {
      const updateData = responseData
        .filter((asset) => !asset?.employee)
        .map((asset) => ({
          id: asset._id,
          name: asset.name,
          brand: asset.brand,
          use: asset.serialNumber,
          impact: asset.impact,
        }));
      setRows(updateData);
    }
  };

  const handleClick = (row) => {
    const assetSelected = responseData.find((asset) => asset._id === row.id);
    console.log("Asset Selected: ", assetSelected);
  };

  const handleClickAssign = (row) => {};

  return (
    <>
      <Paper>
        <Box p={4}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" mb={2}>
              {t("Activos")}
            </Typography>
            <UIButton
              label={"Crear activo"}
              onClick={() => setOpenModal(true)}
            />
          </Box>

          <br />
          <Box
            sx={{
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton value="Activos">Activos</ToggleButton>
              <ToggleButton value="Asignados">Asignados</ToggleButton>
              <ToggleButton value="No Asignados">No Asignados</ToggleButton>
              <ToggleButton value="Baja Temporal">Baja Temporal</ToggleButton>
              <ToggleButton value="Baja">Baja</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <br />
          <br />
          {(alignment === "Activos" || alignment === "Asignados") && (
            <BasicTable rows={rows} handleClick={handleClick}>
              <TableCell>Nombre del activo</TableCell>
              <TableCell>Marca</TableCell>
              <TableCell>Serial</TableCell>
              <TableCell>Empleado</TableCell>
              <TableCell>Impacto del activo</TableCell>
            </BasicTable>
          )}
          {alignment === "No Asignados" && (
            <BasicTable rows={rows} handleClick={handleClickAssign}>
              <TableCell>Nombre del activo</TableCell>
              <TableCell>Marca</TableCell>
              <TableCell>Serial</TableCell>
              <TableCell>Impacto del activo</TableCell>
            </BasicTable>
          )}
        </Box>
      </Paper>
      <ModalAsset
        open={openModal}
        setOpen={setOpenModal}
        title={"Crear un activo"}
      />
    </>
  );
};
