import { cerezaClient } from "../adapters";

const EMPLOYEE = "/v1/emps/employee";
const EMPLOYEE_LIST_SELECTING = "/v1/emps/employee/list";
const FORMER_EMPLOYEES = "/v1/emps/former-employee";
const CHANGE_EMAIL = "/v1/auth/password/update";
const CHANGE_PASSWORD = "/v1/auth/password/confirm/update";
const COWORKER = "/v1/emps/meet-a-coworker";

export function getCoworker() {
  return cerezaClient.get(`${COWORKER}`);
}

export function getEmployee(param, value) {
  if (param === "userId") {
    return cerezaClient.get(`${EMPLOYEE}?userId=${value}`);
  } else if (param === "id") {
    return cerezaClient.get(`${EMPLOYEE}?id=${value}`);
  }
}

export function importEmployees(data, tenantId) {
  return cerezaClient.post(`${EMPLOYEE}/import/${tenantId}`, data);
}

export function saveEmployee(data, tenantId) {
  return cerezaClient.post(`${EMPLOYEE}/${tenantId}`, data);
}

export function updateEmployee(data, employeeId) {
  return cerezaClient.put(`${EMPLOYEE}/${employeeId}`, data);
}

export function enableOrDisableEmployee(employeeId, isActive) {
  return cerezaClient.patch(
    `${EMPLOYEE}/enable/disable/${employeeId}?isActive=${isActive}`
  );
}

export function getEmployeeRecordHistory(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/record-history/${employeeId}`);
}

export function getReferences(employeeId, model) {
  return cerezaClient.get(`${EMPLOYEE}/references/${employeeId}/${model}`);
}

export function saveReferences(data, employeeId, model) {
  return cerezaClient.post(
    `${EMPLOYEE}/references/${employeeId}/${model}`,
    data
  );
}

export function updateReferences(data, employeeId, model, id) {
  return cerezaClient.put(
    `${EMPLOYEE}/references/${employeeId}/${model}/${id}`,
    data
  );
}

export function deleteReferences(employeeId, model, id) {
  return cerezaClient.delete(
    `${EMPLOYEE}/references/${employeeId}/${model}/${id}`
  );
}

export function getContactDetails(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/contact-detail/${employeeId}`);
}

export function saveContactDetails(data) {
  return cerezaClient.post(`${EMPLOYEE}/contact-detail`, data);
}

export function updateContactDetails(data, id) {
  return cerezaClient.put(`${EMPLOYEE}/contact-detail/${id}`, data);
}

export function selectingEmployeeList() {
  return cerezaClient.get(`${EMPLOYEE_LIST_SELECTING}`);
}

export function formerEmployeesList() {
  return cerezaClient.get(`${FORMER_EMPLOYEES}`);
}

export function getEmploymentInfo(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/employment-info/${employeeId}`);
}

export function saveEmploymentInfo(data) {
  return cerezaClient.post(`${EMPLOYEE}/employment-info`, data);
}

export function updateEmploymentInfo(data, id) {
  return cerezaClient.put(`${EMPLOYEE}/employment-info/${id}`, data);
}

export function getEmploymentHistory(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/employment-history/${employeeId}`);
}

export function saveEmploymentHistory(data) {
  return cerezaClient.post(`${EMPLOYEE}/employment-history`, data);
}

export function updateEmploymentHistory(data, id) {
  return cerezaClient.put(`${EMPLOYEE}/employment-history/${id}`, data);
}

export function deleteEmploymentHistory(id) {
  return cerezaClient.delete(`${EMPLOYEE}/employment-history/${id}`);
}

export function getDocuments(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/documents/${employeeId}`);
}

export function saveDocuments(data) {
  return cerezaClient.post(`${EMPLOYEE}/documents`, data);
}

export function updateDocuments(data, id) {
  return cerezaClient.put(`${EMPLOYEE}/documents/${id}`, data);
}

export function getSalaryBase(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/salary-base/${employeeId}`);
}

export function saveSalaryBase(data) {
  return cerezaClient.post(`${EMPLOYEE}/salary-base`, data);
}

export function updateSalaryBase(data, id) {
  return cerezaClient.put(`${EMPLOYEE}/salary-base/${id}`, data);
}

export function getBenefits() {
  return cerezaClient.get(`${EMPLOYEE}/benefits`);
}

export function getBenefitsPackages() {
  return cerezaClient.get(`${EMPLOYEE}/benefit/shortcuts`);
}

export function getEmployeeBenefits(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/employee-benefits/${employeeId}`);
}

export function saveEmployeeBenefits(data) {
  return cerezaClient.post(`${EMPLOYEE}/employee-benefits`, data);
}

export function updateEmployeeBenefits(data, id) {
  return cerezaClient.put(`${EMPLOYEE}/employee-benefits/${id}`, data);
}

export function updatePassword(userId) {
  return cerezaClient.get(`${EMPLOYEE}/update-password/${userId}`);
}

export function enableOrDisableUser(employeeId, isActive) {
  return cerezaClient.patch(
    `${EMPLOYEE}/enable/disable/${employeeId}?isActive=${isActive}`,
    {}
  );
}

export function updateUserPreferences(userId, data) {
  return cerezaClient.put(`/v1/emps/preferences/${userId}`, data);
}

export function getFacialRecognition(employeeId) {
  return cerezaClient.get(`${EMPLOYEE}/facial-recognition/${employeeId}`);
}

export function saveFacialRecognition(data) {
  return cerezaClient.post(`${EMPLOYEE}/facial-recognition`, data);
}

export function patchFacialRecognition(data) {
  return cerezaClient.patch(`${EMPLOYEE}/facial-recognition/${data._id}`, data);
}

export function postChangeEmail(body) {
  return cerezaClient.post(CHANGE_EMAIL, body);
}

export function postChangePassword(body) {
  return cerezaClient.post(CHANGE_PASSWORD, body);
}

export function patchEmployeeNumber(employeeId, newNumber) {
  return cerezaClient.patch(
    `${EMPLOYEE}/${employeeId}/change-number/${newNumber}`,
    {}
  );
}
