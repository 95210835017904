import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Paper, Switch, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { UIJoditEditor, UIButton } from "components";
import { MenuNav } from "./MenuNav";
import { UIPaper, UISelectCheckmarks, Toast, UISelect } from "components";
import { selectingEmployeeList } from "api/employee";
import {
  getVacancy,
  patchVacancy,
  getVacancyOthersSttings,
  patchVacancyOthersSttings,
} from "api";
import { managerErrors } from "utils";
import { useAccess } from "hooks";

function generateNumberArray(n) {
  const result = [];

  for (let i = 1; i <= n; i++) {
    result.push({ value: i, label: i.toString() });
  }

  return result;
}

export const RecruitmentSettings = () => {
  const { t } = useTranslation(["recruitment"]);

  const { HasPermissions, access, HaveAcessToEdit } = useAccess();
  const { canChange } = HasPermissions(
    "/cereza-organization/recruitment/recruitment-settings/TerminosYcondicionesPoliticasYFooter"
  );

  const ToEdit = HaveAcessToEdit(
    "/cereza-organization/recruitment/recruitment-settings/OtrosAjustes",
    ["change_signAuthsSettings", "view_signAuthsSettings"]
  );

  const accessToTerminosYcondicionesPoliticasYFooter = access(
    "/cereza-organization/recruitment/recruitment-settings/TerminosYcondicionesPoliticasYFooter"
  );

  const accessToOtrosAjustes = access(
    "/cereza-organization/recruitment/recruitment-settings/OtrosAjustes"
  );

  const showSection = () => {
    if (accessToTerminosYcondicionesPoliticasYFooter) {
      return "Terms & Conditions";
    } else if (accessToOtrosAjustes) {
      return "Others";
    }
  };

  const [ubication, setUbication] = useState(showSection());
  const [employees, setEmployees] = useState([]);
  const [firstSettings, setFirstSettings] = useState(null);
  const [checked, setChecked] = useState(null);
  const [render, setRender] = useState(false);
  const [signs, setSigns] = useState(null);

  const handleChange = async (event) => {
    if (canChange) {
      let values = { ...firstSettings };
      values.showSocials = event.target.checked;
      try {
        setChecked(event.target.checked);
        await patchVacancy(values);
        Toast.fire({
          icon: "success",
          title: t("AcciónExitosa"),
        });
        setRender(!render);
      } catch (error) {
        managerErrors(err?.response?.data?.message);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const lista = async () => {
      try {
        const { data } = await selectingEmployeeList();
        const selector = data
          .filter((obj) => obj.isActive === true)
          .map(({ fullName, _id }) => ({
            _id: _id,
            fullName: fullName,
          }));
        setEmployees(selector);
      } catch (error) {
        console.log(error);
      }
    };
    lista();

    const settingsHTML = async () => {
      try {
        const { data } = await getVacancy();
        setFirstSettings(data);
        setChecked(data?.showSocials);
      } catch (error) {
        console.log(error);
      }
    };
    settingsHTML();

    const othersSttings = async () => {
      try {
        const { data } = await getVacancyOthersSttings();
        setSigns(data);
      } catch (error) {
        console.log(error);
      }
    };
    othersSttings();
  }, [render]);

  const onSubmit = async (values) => {
    if (canChange) {
      try {
        await patchVacancy(values);
        Toast.fire({
          icon: "success",
          title: t("AcciónExitosa"),
        });
        setRender(!render);
      } catch (error) {
        managerErrors(err?.response?.data?.message);
        console.log(error);
      }
    }
  };

  const onSubmitOthers = async (values) => {
    if (ToEdit) {
      try {
        await patchVacancyOthersSttings(values);
        Toast.fire({
          icon: "success",
          title: t("AcciónExitosa"),
        });
        setRender(!render);
      } catch (error) {
        managerErrors(err?.response?.data?.message);
        console.log(error);
      }
    }
  };

  return (
    <>
      <MenuNav ubication={ubication} setUbication={setUbication} />
      <br />
      <UIPaper>
        <Box p={4}>
          <Formik
            initialValues={firstSettings}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                {ubication === "Terms & Conditions" && (
                  <>
                    <Typography variant="h5">
                      {t("recruitment:TerminosCondiciones")}
                    </Typography>
                    <br />
                    <UIJoditEditor
                      label={t("recruitment:htmlContent")}
                      name={"termsAndConditions"}
                      disabled={formik.isSubmitting}
                    />
                  </>
                )}
                {ubication === "Privacy Policy" && (
                  <>
                    <Typography variant="h5">
                      {t("recruitment:PoliticasPrivacidad")}
                    </Typography>
                    <br />
                    <UIJoditEditor
                      label={t("recruitment:htmlContent")}
                      name={"privacyPolicy"}
                      disabled={formik.isSubmitting}
                    />
                  </>
                )}
                {canChange &&
                  (ubication === "Terms & Conditions" ||
                    ubication === "Privacy Policy") && (
                    <>
                      <br />
                      <Box
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box display="flex" gap="1em">
                          <UIButton
                            type="submit"
                            label={
                              formik?.values?._id
                                ? t("general:Actualizar")
                                : t("general:Guardar")
                            }
                            loading={formik.isSubmitting}
                          />
                        </Box>
                      </Box>
                    </>
                  )}
              </Form>
            )}
          </Formik>

          {ubication === "Others" && (
            <>
              <Typography variant="h5">
                {t("recruitment:OtrosAjustes")}
              </Typography>
              <br />
              {accessToTerminosYcondicionesPoliticasYFooter && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    disabled={!canChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
                    {t("recruitment:ViewSocialNetworks")}
                  </Typography>
                </Box>
              )}
              {accessToOtrosAjustes && signs?.signAuthSettings && (
                <>
                  <hr />
                  <Formik
                    initialValues={signs?.signAuthSettings}
                    onSubmit={onSubmitOthers}
                    enableReinitialize
                  >
                    {(formik) => (
                      <Form id="d" autoComplete="off">
                        <Paper>
                          <Box p={4}>
                            <Grid container spacing={4}>
                              <Grid item={true} xs={12} md={4}>
                                <UISelect
                                  formik={formik}
                                  label={t("recruitment:NivelAutorizaciones")}
                                  name="hierarchyDepth"
                                  options={generateNumberArray(
                                    formik?.values?.hierarchyDepth
                                  )}
                                  disabled={formik.isSubmitting}
                                />
                              </Grid>
                              <Grid item={true} xs={12} md={8}>
                                <UISelectCheckmarks
                                  options={employees}
                                  formik={formik}
                                  label={t("recruitment:UltimaPalabra")}
                                  name={"decisionMakingBody"}
                                  fieldRender={"fullName"}
                                  disabled={!ToEdit || formik.isSubmitting}
                                />
                              </Grid>
                            </Grid>
                            <br />
                            {ToEdit && (
                              <Box
                                mt={2}
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box display="flex" gap="1em">
                                  <UIButton
                                    type="submit"
                                    label={
                                      formik?.values?._id
                                        ? t("general:Actualizar")
                                        : t("general:Guardar")
                                    }
                                    loading={formik.isSubmitting}
                                  />
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Paper>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </>
          )}
        </Box>
      </UIPaper>
    </>
  );
};
