import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, TableCell } from "@mui/material";
import { BasicTable, FilePreview, UISelect } from "components";
import { generalSelect, setLanguages } from "@redux/slices/general";
import { profileSelect } from "@redux/slices/profile";
import { getLanguages, getReferences } from "api";
import { getLabel, LEVELS } from "utils";
import { translateSelectors } from "helpers";

export const FormLanguage = (
  { formik, newData, canAdd, canChange, user = null },
) => {
  const { t } = useTranslation(["employee"]["options"]);

  const dispatcher = useDispatch();
  const { languages } = useSelector(generalSelect);
  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const [src, setSrc] = useState();
  const [srcPath, setSrcPath] = useState("");
  const [rows, setRows] = useState([]);
  const [responseData, setResponseData] = useState({});
  const disabled = (!canAdd && formik.values?._id === undefined) ||
    (!canChange && formik.values?._id !== undefined) ||
    formik.isSubmitting;

  useEffect(() => {
    const fetchDataLanguages = async () => {
      if (languages?.length === 0 || !languages || languages === undefined) {
        try {
          const { data } = await getLanguages(true);
          dispatcher(setLanguages(data));
        } catch (err) {
          console.error("Error getting languages:", err);
        }
      }
    };

    const fetchData = async () => {
      if (languages?.length > 0) {
        try {
          const { data } = await getReferences(
            employeeSelected._id,
            "languages",
          );
          const cleanData = data.map((item) => ({
            id: item._id,
            language: getLabel(languages, item.language),
            fluencyLevel: item.fluencyLevel,
          }));
          setRows(cleanData);
          setResponseData(data);
        } catch (err) {
          console.error("Error getting languages:", err);
        }
      }
    };

    fetchDataLanguages();
    fetchData();
  }, [employeeSelected, newData, languages]);

  const handleClick = (row) => {
    const languageSelected = {
      ...responseData.find((obj) => obj._id === row.id),
    };
    delete languageSelected.createdAt;
    delete languageSelected.updatedAt;
    setSrcPath(languageSelected.filePath);
    delete languageSelected.filePath;
    formik.setValues(languageSelected);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
        <Grid item={true} xs={12} md={3}>
          <Grid
            container
            flexDirection={{ xs: "column", md: "column" }}
            spacing={2}
          >
            <Grid item={true}>
              <UISelect
                formik={formik}
                label={t("employee:Language")}
                name="language"
                options={languages}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true}>
              <UISelect
                formik={formik}
                label={t("employee:Levelofcompetency")}
                name="fluencyLevel"
                options={translateSelectors(t, LEVELS)}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <FilePreview
            formik={formik}
            name={"file"}
            src={src}
            setSrc={setSrc}
            srcPath={srcPath}
            lblBtnUpdate={t("employee:UploadFile")}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("employee:Idioma")}</TableCell>
            <TableCell>{t("employee:Niveldefluidez")}</TableCell>
          </BasicTable>
        </Grid>
      </Grid>
    </>
  );
};
