import { Box, Tab, Tabs } from "@mui/material";
import { useAccess } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MenuNav = ({ ubication }) => {
  const { t } = useTranslation(["sidenav"]);
  const navigate = useNavigate();
  const { access } = useAccess();

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        placeItems: "center",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Tabs
        value={ubication}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        style={{ maxWidth: "100%" }}
      >
        {access("/cereza-organization/settings/configurations") && (
          <Tab
            value="Ajustes"
            label={t("sidenav:Ajustes")}
            onClick={() => {
              navigate("/cereza-organization/settings/configurations");
            }}
          />
        )}
        {access("/cereza-organization/settings/templates") && (
          <Tab
            value="Templates"
            label={t("sidenav:Templates")}
            onClick={() => {
              navigate("/cereza-organization/settings/templates");
            }}
          />
        )}
        {access("/cereza-organization/settings/import-export") && (
          <Tab
            value="ImportExport"
            label={t("sidenav:ImportExport")}
            onClick={() => {
              navigate("/cereza-organization/settings/import-export");
            }}
          />
        )}
        {access("/cereza-organization/settings/authorization-flows") && (
          <Tab
            value="Autorizaciones"
            label={t("sidenav:Flujos")}
            onClick={() => {
              navigate("/cereza-organization/settings/authorization-flows");
            }}
          />
        )}
        {access("/cereza-organization/settings/security") && (
          <Tab
            value="Security"
            label={t("sidenav:Security")}
            onClick={() => {
              navigate("/cereza-organization/settings/security");
            }}
          />
        )}
      </Tabs>
    </Box>
  );
};
