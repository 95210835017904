import { Grid, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Toast, UIButton, UIPasswordField } from "components";
import { managerErrors } from "utils/functions";
import { changePassword } from "api";

export const PasswordForm = ({ setpassword, setOpen, iValues }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string()
      .min(12, t("general:validation1"))
      .max(20, t("general:validation2"))
      .matches(/[a-zA-Z]/, t("general:validation3"))
      .matches(/\d/, t("general:validation4"))
      .matches(/[$@$!%*?&#]/, t("general:validation5"))
      .notOneOf(["123", "abc"], t("general:validation6"))
      .matches(/[a-z]/, t("general:validation7"))
      .matches(/[A-Z]/, t("general:8"))
      .matches(/\S/, t("general:9"))
      .required(t("general:validation10")),

    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("general:validation11"))
      .required(t("general:validation12")),
  });

  const handleSubmit = async (values) => {
    try {
      await changePassword(iValues._id, values);
      setOpen(false);
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error time check:", err);
    }
  };

  return (
    <>
      <Box sx={{ display: "grid", placeItems: "center" }}>
        <Formik
          initialValues={{ password: "", password2: "" }}
          validationSchema={passwordValidationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="timeCheck" autoComplete="off">
              <Grid
                container
                spacing={2}
                sx={{
                  width: "300px",
                  "@media (min-width: 600px)": {
                    width: "400px",
                  },
                }}
              >
                <Grid item={true} xs={12}>
                  <UIPasswordField
                    formik={formik}
                    label={t("shiftAttendance:NewPassword")}
                    name={"password"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <UIPasswordField
                    formik={formik}
                    label={t("shiftAttendance:ConfirmPassword")}
                    name={"password2"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <UIButton
                    type="submit"
                    label={t("shiftAttendance:changePassword")}
                    loading={formik.isSubmitting}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
      <Box mt={2} width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" gap="1em">
          <UIButton
            onClick={() => {
              setpassword(false);
            }}
          >
            <ArrowBackIcon />
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
