import { cerezaClient } from "../adapters";

const EMAILS = "v1/glob/emails";
const TEMPLATE_TAGS = "v1/glob/templates/tags";
const SETTINGS_EMAIL = "v1/glob/settings/email";
const EMAIL_TEMPLATE = "v1/glob/templates";

export function getEmails() {
  return cerezaClient.get(`${EMAILS}`);
}

export function getTemplateTags() {
  return cerezaClient.get(`${TEMPLATE_TAGS}`);
}

export function postTemplateTag(data) {
  return cerezaClient.post(`${TEMPLATE_TAGS}`, data);
}

export function putTemplateTag(data) {
  return cerezaClient.put(`${TEMPLATE_TAGS}/${data._id}`, data);
}

export function deleteTemplateTag(id) {
  return cerezaClient.delete(`${TEMPLATE_TAGS}/${id}`);
}

export function getSettingsEmail(id) {
  if (id) {
    return cerezaClient.get(`${SETTINGS_EMAIL}/${id}`);
  } else {
    return cerezaClient.get(`${SETTINGS_EMAIL}`);
  }
}

export function patchSettingsEmail(id, data) {
  return cerezaClient.patch(`${SETTINGS_EMAIL}/${id}`, data);
}

export function getEmailTemplate() {
  return cerezaClient.get(`${EMAIL_TEMPLATE}`);
}

export function postEmailTemplate(data, tenantId) {
  return cerezaClient.post(`${EMAIL_TEMPLATE}/${tenantId}`, data);
}
export function patchEmailTemplate(data) {
  return cerezaClient.patch(`${EMAIL_TEMPLATE}/${data._id}`, data);
}

export function deleteEmailTemplate(id) {
  return cerezaClient.delete(`${EMAIL_TEMPLATE}/${id}`);
}
