import { Alert, Grid, Stack } from "@mui/material";
import {
  UIButton,
  UIJoditEditor,
  UITextFast,
  UISelectFast,
  UISelectMultipleFast,
  UISwitchFast,
} from "components";
import { useTranslation } from "react-i18next";
import { FormAuthorizationGeneric } from "./FormAuthorizationGeneric";
import { FieldArray } from "formik";
import { memo } from "react";

export const FormDismissals = ({
  formik,
  countries,
  employees,
  jobPositions,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation();
  const disabled =
    (!canAdd && !formik.values._id) ||
    (!canChange && formik.values._id) ||
    formik.isSubmitting;
  const fieldArrayName = "alerts";

  const TYPE_REQUEST = [
    { value: "TERMINATION", label: t("options:TERMINATION") },
    { value: "KICKED_OUT", label: t("options:KICKED_OUT") },
    { value: "QUITTING", label: t("options:QUITTING") },
  ];

  return (
    <>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={6}>
          <UITextFast
            label={t("organization:NameRule")}
            name="title"
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("organization:Country")}
            name="country"
            options={countries}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelectFast
            label={t("settings:TypeRequest")}
            name="type"
            options={TYPE_REQUEST}
            required={true}
            onChange={(e, newValue) => {
              if (newValue.value === "QUITTING") {
                formik.setFieldValue("requiresSupervisorComments", true);
              } else {
                formik.setFieldValue("requiresSupervisorComments", false);
              }

              formik.setFieldValue("type", newValue.value);
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      {formik.values.type !== "KICKED_OUT" && (
        <FormAuthorizationGeneric
          formik={formik}
          employees={employees}
          disabled={disabled}
        />
      )}
      {formik.values.type === "KICKED_OUT" && (
        <Alert severity="info">{t("settings:MsgInmediateDismissal")}</Alert>
      )}
      <Grid container={true} mt={2}>
        <Grid item={true}>
          <UISwitchFast
            label={t("settings:requiresSupervisorComments")}
            name={"requiresSupervisorComments"}
            disabled={disabled || formik.values.type === "QUITTING"}
          />
        </Grid>
      </Grid>
      <FieldArray name={fieldArrayName}>
        {({ push, remove }) => (
          <>
            <Stack
              direction="row"
              spacing={2}
              mb={2}
              sx={{
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <UIButton
                label={t("settings:AddAlert")}
                loading={formik.isSubmitting}
                fullWidth={false}
                onClick={() => {
                  push({
                    message: "",
                    subTitle: "",
                    employees: [],
                    jobPositions: [],
                  });
                }}
              />
            </Stack>
            {formik.values.alerts.length > 0 ? (
              formik.values.alerts.map((alert, index) => (
                <AlertForm
                  key={index}
                  index={index}
                  t={t}
                  fieldArrayName={fieldArrayName}
                  disabled={disabled}
                  employees={employees}
                  jobPositions={jobPositions}
                  formik={formik}
                  remove={remove}
                />
              ))
            ) : (
              <Alert severity="warning">{t("settings:RequeridOneAlert")}</Alert>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
};

const AlertForm = memo(
  ({
    index,
    t,
    fieldArrayName,
    disabled,
    employees,
    jobPositions,
    formik,
    remove,
  }) => {
    return (
      <>
        <Stack direction="column" spacing={2} mb={2} mt={2}>
          <UITextFast
            label={t("settings:Subtitle")}
            name={`${fieldArrayName}.${index}.subTitle`}
            disabled={disabled}
          />
          <UISelectMultipleFast
            label={t("settings:Employees")}
            name={`${fieldArrayName}.${index}.employees`}
            options={employees}
            disabled={disabled}
          />
          <UISelectMultipleFast
            label={t("settings:JobPostions")}
            name={`${fieldArrayName}.${index}.jobPositions`}
            options={jobPositions}
            disabled={disabled}
          />
          <UIJoditEditor
            name={`${fieldArrayName}.${index}.message`}
            label={t("settings:Message")}
            disabled={disabled}
          />
        </Stack>
        <UIButton
          label={t("settings:RemoveAlert")}
          loading={formik.isSubmitting}
          fullWidth={false}
          onClick={() => remove(index)}
        />
      </>
    );
  },
);
