import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { TIME_CHECK_TYPES } from "utils/constants";
import {
  Toast,
  UIButton,
  UIPasswordField,
  UIRichText,
  UISelect,
  UISwitch,
  UITextField,
} from "components";
import {
  getLocations,
  createTimeCheck,
  updateTimeCheck,
  deleteTimeCheck,
} from "api";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLocations, generalSelect } from "@redux/slices/general";
import { managerErrors } from "utils/functions";
import { useTranslation } from "react-i18next";
import { translateSelectors } from "helpers";
import * as Yup from "yup";

export const CreationForm = ({ iValues, setpassword, setOpen, setNewData }) => {
  const dispatcher = useDispatch();
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const { locations } = useSelector(generalSelect);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(12, t("general:validation1"))
      .max(20, t("general:validation2"))
      .matches(/[a-zA-Z]/, t("general:validation3"))
      .matches(/\d/, t("general:validation4"))
      .matches(/[$@$!%*?&#]/, t("general:validation5"))
      .notOneOf(["123", "abc"], t("general:validation6"))
      .matches(/[a-z]/, t("general:validation7"))
      .matches(/[A-Z]/, t("general:validation8"))
      .matches(/\S/, t("general:validation9"))
      .required(t("general:validation10")),

    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], t("general:validation11"))
      .required(t("general:validation12")),

    name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z0-9_-]+$/, t("general:validation13"))
      .required(t("general:validation14")),
  });

  useEffect(() => {
    const fetchLocations = async () => {
      if (locations?.length === 0 || !locations || locations === undefined) {
        try {
          const { data } = await getLocations("selectors");
          dispatcher(setLocations(data));
        } catch (err) {
          console.error("Error getting locations:", err);
        }
      }
    };

    fetchLocations();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    delete values.updatedAt;
    delete values.createdAt;
    delete values.password2;
    try {
      if (values?._id) {
        delete values.password;
        await updateTimeCheck(values._id, values);
        setOpen(false);
        resetForm();
        setNewData((prev) => !prev);
        Toast.fire({
          icon: "success",
          title: t("general:AcciónExitosa"),
        });
      } else {
        await createTimeCheck(values);
        setOpen(false);
        resetForm();
        setNewData((prev) => !prev);
        Toast.fire({
          icon: "success",
          title: t("general:AcciónExitosa"),
        });
      }
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error creating time check:", err);
    }
  };

  const deleteClock = async () => {
    try {
      if (iValues?._id) {
        await deleteTimeCheck(iValues?._id);
        setOpen(false);
        setNewData((prev) => !prev);
        Toast.fire({
          icon: "success",
          title: t("general:AcciónExitosa"),
        });
      }
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error creating time check:", err);
    }
  };

  return (
    <>
      <Formik
        initialValues={
          iValues === undefined
            ? {
                name: "",
                type: "WEB",
                isActive: true,
                description: "",
                location: "",
                photo: true,
                changePIN: true,
                msg: "",
                sesionExpires: 0,
                password: "",
                password2: "",
              }
            : iValues
        }
        validationSchema={iValues?._id ? null : validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form id="timeCheck" autoComplete="off">
            <Grid container spacing={2}>
              <Grid item={true} xs={12} md={4}>
                <UISwitch
                  formik={formik}
                  label={t("shiftAttendance:Foto")}
                  name={"photo"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <UISwitch
                  formik={formik}
                  label={t("shiftAttendance:CambiarPIN")}
                  name={"changePIN"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <UISwitch
                  formik={formik}
                  label={t("shiftAttendance:Activo")}
                  name={"isActive"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <UITextField
                  formik={formik}
                  label={t("shiftAttendance:name")}
                  name={"name"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <UISelect
                  formik={formik}
                  label={t("shiftAttendance:tipo")}
                  name={"type"}
                  options={translateSelectors(t, TIME_CHECK_TYPES)}
                />
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <UISelect
                  formik={formik}
                  label={t("shiftAttendance:Ubicacion")}
                  name={"location"}
                  options={locations}
                  disabled={formik.isSubmitting}
                  isrequired={true}
                />
              </Grid>
              {!iValues?._id && (
                <>
                  <Grid item={true} xs={12} md={4}>
                    <UIPasswordField
                      formik={formik}
                      label={t("shiftAttendance:Password")}
                      name={"password"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>

                  <Grid item={true} xs={12} md={4}>
                    <UIPasswordField
                      formik={formik}
                      label={t("shiftAttendance:ConfirmPassword")}
                      name={"password2"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                </>
              )}
              <Grid item={true} xs={12} md={4}>
                <UITextField
                  type="number"
                  formik={formik}
                  label={t("shiftAttendance:sesionExpires")}
                  name={"sesionExpires"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={iValues?._id ? 8 : 12}>
                <UITextField
                  formik={formik}
                  label={t("shiftAttendance:descripcion")}
                  name={"description"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={12} md={12}>
                <UIRichText
                  formik={formik}
                  label={t("shiftAttendance:Mensaje")}
                  name={"msg"}
                  disabled={formik.isSubmitting}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item={true} xs={4} md={3}>
                <UIButton
                  type="submit"
                  label={t("general:Guardar")}
                  loading={formik.isSubmitting}
                />
              </Grid>
              <Grid item={true} xs={4} md={3}>
                <UIButton
                  onClick={() => {
                    setOpen(false);
                  }}
                  label={t("general:Cancel")}
                  loading={formik.isSubmitting}
                />
              </Grid>
              {iValues?._id && (
                <Grid item={true} xs={6} md={3}>
                  <UIButton
                    onClick={() => setpassword(true)}
                    label={t("shiftAttendance:changePassword")}
                    loading={formik.isSubmitting}
                  />
                </Grid>
              )}
              {iValues?._id && (
                <Grid item={true} xs={6} md={3}>
                  <UIButton
                    onClick={deleteClock}
                    label={t("shiftAttendance:deleteClock")}
                    loading={formik.isSubmitting}
                  />
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
