import { Box, Grid, Menu, MenuItem } from "@mui/material";
import {
  Toast,
  UIButton,
  UIJoditEditor,
  UISelect,
  UISwitch,
  UITextField,
} from "../../../components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

export const FormTemplate = ({ formik, emails, customTags, canEdit }) => {
  const { t } = useTranslation(["settings"]["general"]);
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    if (formik.values.email) {
      const optionsEmail = emails.find(
        (e) => e.value === formik.values.email
      ).context;
      setVariables(optionsEmail);
    }
  }, [formik.values.email]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (textCopy) => {
    Toast.fire({
      icon: "success",
      title: t("settings:VariableCopyToClipboard"),
    });
    navigator.clipboard.writeText(textCopy);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={5}>
          <UITextField
            name={"title"}
            required={true}
            formik={formik}
            label={t("settings:Title")}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid item={true} xs={12} md={5}>
          <UISelect
            name={"email"}
            label={t("settings:Email")}
            formik={formik}
            options={emails}
            isrequired={true}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid item={true} xs={12} md={2}>
          <UISwitch
            name={"isActive"}
            label={t("settings:Active")}
            formik={formik}
            disabled={formik.isSubmitting || !canEdit}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={8}>
          <UITextField
            name={"subject"}
            required={true}
            formik={formik}
            label={t("settings:Subject")}
            disabled={formik.isSubmitting}
          />
        </Grid>
        <Grid item={true} xs={12} md={2}>
          <UIButton
            label={t("settings:AddVariable")}
            id="variables-button"
            aria-controls={open ? "variables-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ height: "100%" }}
            disabled={!canEdit}
          />
          <Menu
            id="variables-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "variables-button",
            }}
          >
            {variables.map((v, index) => (
              <MenuItem key={index} onClick={() => handleClose(v.variable)}>
                {v.label}
              </MenuItem>
            ))}
            {customTags.map((c, index) => (
              <MenuItem
                key={index}
                onClick={() => handleClose(`$$${c.title}$$`)}
              >
                {c.title}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      </Grid>
      <Box>
        <UIJoditEditor
          name={"html"}
          label={"HTML"}
          disabled={formik.isSubmitting}
        />
      </Box>
    </Box>
  );
};
