import { Grid } from "@mui/material";
import { UISwitch, UITextField } from "../../../components";
import { useTranslation } from "react-i18next";

export const FormPolicy = ({ formik }) => {
  const { t } = useTranslation(["general"]["shiftAttendance"]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={4}>
        <UITextField
          name={"name"}
          required={true}
          formik={formik}
          label={t("shiftAttendance:Name")}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <UITextField
          name={"description"}
          required={true}
          formik={formik}
          label={t("shiftAttendance:Description")}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={2}>
        <UISwitch
          formik={formik}
          label={t("shiftAttendance:Active")}
          name={"isActive"}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
