import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ProfileTabs, Toast, UIButton, UISwitchFast } from "components";
import { FormPersonalInformation } from "./FormPersonalInformation";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { profileSelect, setEmployeeSelected } from "@redux/slices/profile";
import { updateEmployee, getFiles } from "api";
import { managerErrors } from "utils";
import { useAccess } from "hooks";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { pathEmployee } from "helpers";
import { ChangeEmployeeNumberModal } from "./ChangeEmployeeNumberModal";
import { QrCard } from "../../../../../components/QrCard";
import {
  parameterizationSelect,
  setLogo,
} from "@redux/slices/parameterization";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { isFormerEmployee, getProfileReturnUri } from "helpers";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { NewRequestModal } from "modules/Requests/Petitions/PetitionsModals";
dayjs.extend(utc);

const createInitialValues = (values) => {
  if (values || values !== undefined) {
    const iValues = { ...values };
    delete iValues.createdAt;
    delete iValues.updatedAt;
    return iValues;
  } else {
    return {
      names: "",
      surnames: "",
      email: "",
      gender: "",
      dateOfBirth: dayjs(),
      bloodType: "",
      nationality: "",
      maritalStatus: "",
      hobbies: [],
      socialSecurityNumber: "",
      taxIdentificationNumber: "",
      photo: "",
      number: "",
    };
  }
};

export const PersonalInformation = ({
  application = "organization",
  user = null,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const dispatch = useDispatch();
  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [initialValues, setInitialValues] = useState(
    createInitialValues(employeeSelected),
  );
  const { HasPermissions, accessCodeName } = useAccess();

  const { canAdd, canChange, canEmployeeKickedOut } = isFormerEmployee(
    HasPermissions(
      pathEmployee(application, "/profile/collaborator/personal-information"),
      application,
      ["employee_kicked_out"],
    ),
    employeeSelected,
  );
  const returnUri = getProfileReturnUri(employeeSelected);

  const { tenant } = useSelector(tenantSettingsSelect);
  const { logo } = useSelector(parameterizationSelect);
  const cardRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openNewRequest, setOpenNewRequest] = useState(false);

  useEffect(() => {
    setInitialValues(createInitialValues(employeeSelected));
  }, [employeeSelected]);

  useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant._id, tenant.companyLogo);
        dispatch(setLogo(data.content));
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (logo === null && tenant?._id && tenant?.companyLogo) {
      fetchSource();
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      const updatedValues = { ...values };
      updatedValues.names = updatedValues.names.trim();
      updatedValues.surnames = updatedValues.surnames.trim();
      const { data } = await updateEmployee(
        updatedValues,
        employeeSelected._id,
      );
      dispatch(setEmployeeSelected(data));
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  const handleDownload = () => {
    const qrCard = cardRef.current;
    if (qrCard) {
      html2canvas(qrCard, { backgroundColor: "#fff", scale: 2 })
        .then((canvas) => {
          const dataUrl = canvas.toDataURL("image/png");
          const document = new jsPDF({
            orientation: "portrait",
            unit: "pt",
            format: [175, 244],
          });

          document.addImage(dataUrl, "PNG", 0, 0, 175, 244);
          document.save("Credencial.pdf");
        })
        .catch((error) => {
          console.log("Error al generar el canvas", error);
        });
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="collaborator" application={application} />
      <br />
      <Paper>
        <Box p={4}>
          <Typography variant="h5">
            {t("employee:InformaciónPersonal")}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <Stack
                  mb={2}
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UISwitchFast
                      label={t("employee:isActive")}
                      name="isActive"
                      disabled={formik.isSubmitting || !canChange}
                    />
                  )}
                  <UISwitchFast
                    label={t("employee:MostrarEnElDirectorio")}
                    name="showInDirectory"
                    disabled={formik.isSubmitting || !canChange}
                  />
                </Stack>
                <FormPersonalInformation
                  formik={formik}
                  canAdd={canAdd}
                  canChange={canChange}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UIButton
                      variant="contained"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() => navigate(returnUri)}
                      disabled={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {((canAdd && !formik?.values?._id) ||
                    (canChange && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting || !canChange}
                      fullWidth={false}
                    />
                  )}
                  {formik?.values?._id &&
                    accessCodeName("change_employee_number") &&
                    application === "organization" && (
                      <UIButton
                        variant="contained"
                        label={t("employee:CambiarNumeroEmpleado")}
                        onClick={() => setOpen(true)}
                        disabled={formik.isSubmitting || !canChange}
                        fullWidth={false}
                      />
                    )}
                  {application === "organization" && canEmployeeKickedOut && (
                    <UIButton
                      label={t("request:ImmediateDismissal")}
                      disabled={formik.isSubmitting || !canChange}
                      fullWidth={false}
                      onClick={() => {
                        setOpenNewRequest(true);
                      }}
                    />
                  )}
                  {application === "organization" && (
                    <>
                      <UIButton
                        variant="contained"
                        label={t("employee:DownloadQr")}
                        startIcon={<QrCodeScannerIcon />}
                        onClick={handleDownload}
                        disabled={formik.isSubmitting || !canChange}
                        fullWidth={false}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          top: "-10000px",
                          left: "-10000px",
                        }}
                      >
                        <Box ref={cardRef}>
                          <QrCard
                            fullName={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
                            employee={employeeSelected?.number}
                            avatarUrl={logo}
                          />
                        </Box>
                      </Box>
                    </>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
        <NewRequestModal
          open={openNewRequest}
          setOpen={setOpenNewRequest}
          application={application}
          typeDocument="kicked_out"
          saveInRedux={false}
          employee={employeeSelected._id}
        />
      </Paper>
      {initialValues?._id &&
        accessCodeName("change_employee_number") &&
        application === "organization" && (
          <ChangeEmployeeNumberModal
            open={open}
            setOpen={setOpen}
            initialValues={initialValues}
          />
        )}
    </>
  );
};
