import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Toast, UIButton, UIPaper } from "components";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { FormHolidays } from "./FormHolidays";
import { FormHolidayDetails } from "./FormHolidaysDetails";
import { useSelector } from "react-redux";
import { setHolidays, timeOffSelect } from "@redux/slices/time-off";
import { useDispatch } from "react-redux";
import { deleteHolidays, postHolidays, putHolidays } from "api";
import { generalSelect } from "@redux/slices/general";
import { useState } from "react";
import { useCountries } from "hooks";
import Swal from "sweetalert2";
import isEqual from "lodash/isEqual";
import dayjs from "dayjs";
import { Loop as LoopIcon } from "@mui/icons-material";

export const Holidays = ({ setUpdateWizard = null }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const { holidays } = useSelector(timeOffSelect);
  const { countries, countriesCompleteData } = useSelector(generalSelect);
  useCountries();
  const dispatch = useDispatch();
  const [downloadHolidays, setDownloadHolidays] = useState(false);
  const [holidaysToReplace, setHolidaysToReplace] = useState([]);
  const [openHolidaysToReplace, setOpenHolidaysToReplace] = useState(false);

  const onSubmit = async (values) => {
    let message = "";
    try {
      if (values._id) {
        const { data } = await putHolidays(values._id, values);
        dispatch(setHolidays(data));
        message = t("shiftAttendance:HolidaysSuccessfullyUpdate");
      } else {
        const { data } = await postHolidays(values);
        dispatch(setHolidays(data));
        message = t("shiftAttendance:HolidaysSuccessfullyCreated");
      }

      if (setUpdateWizard) {
        setUpdateWizard(Math.random());
      }

      Toast.fire({
        icon: "success",
        title: message,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const getPublicHolidays = async (country_id, formik) => {
    const country = countriesCompleteData.find((item) => {
      return item._id === country_id;
    });
    let updateHolidays = JSON.parse(JSON.stringify(formik.values));
    let year = new Date().getFullYear();
    let years = year + 2;
    let existHolidays = false;
    let replaceHolidays = [];

    for (let i = year; i <= years; i++) {
      const holidaysPublic = await fetch(
        `https://date.nager.at/api/v3/PublicHolidays/${i}/${country.alpha2}`,
      )
        .then((response) => response.json())
        .then((data) => data)
        .catch((err) => console.log(err));

      if (holidaysPublic) {
        existHolidays = true;

        holidaysPublic.forEach((holiday) => {
          const found = holidays.holidays.find((e) => e.date === holiday.date);
          let holidayAdd = {
            date: holiday.date,
            localName: holiday.localName,
            name: holiday.name,
            isWorkday: false,
          };

          if (!found) {
            updateHolidays.holidays.push(holidayAdd);
          } else if (!isEqual(holidayAdd, found)) {
            replaceHolidays.push({ new: holidayAdd, actual: found });
          }
        });
      }
    }

    setHolidaysToReplace(replaceHolidays);
    if (replaceHolidays.length > 0) {
      setOpenHolidaysToReplace(true);
    }

    dispatch(setHolidays(updateHolidays));
    setDownloadHolidays(false);

    if (!existHolidays) {
      Toast.fire({
        icon: "error",
        title: t("shiftAttendance:NotExistsHolidays"),
      });
    }
  };

  const replaceHoliday = (index, formik) => {
    let holiday = holidaysToReplace[index].new;
    let updateHolidays = JSON.parse(JSON.stringify(formik.values));
    let indexUpdate = updateHolidays.holidays.findIndex(
      (e) => e.date === holiday.date,
    );

    updateHolidays.holidays[indexUpdate] = holiday;
    dispatch(setHolidays(updateHolidays));
    holidaysToReplace.splice(index, 1);
    setHolidaysToReplace(holidaysToReplace);

    if (holidaysToReplace.length === 0) {
      setOpenHolidaysToReplace(false);
    }
  };

  const deleteItem = async (holidays) => {
    try {
      await deleteHolidays(holidays._id);
      dispatch(
        setHolidays({
          name: "",
          description: "",
          country: "",
          isVisible: true,
          holidays: [],
        }),
      );
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <UIPaper>
      <Box p={2}>
        <Typography variant="h5" mb={2}>
          {t("shiftAttendance:ListOfHolidays")}
        </Typography>
        <Formik
          initialValues={holidays}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form id={"d"} autoComplete={"off"}>
              <FormHolidays
                formik={formik}
                countries={countries}
                getPublicHolidays={getPublicHolidays}
                downloadHolidays={downloadHolidays}
                setDownloadHolidays={setDownloadHolidays}
              />
              <FormHolidayDetails formik={formik} nameFiledArray={"holidays"} />
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={formik?.values?._id
                      ? t("general:Actualizar")
                      : t("general:Guardar")}
                    loading={formik.isSubmitting}
                    disabled={formik?.values?.holidays.length === 0}
                  />
                  {formik?.values?._id && (
                    <UIButton
                      label={t("general:Eliminar")}
                      onClick={() => {
                        Swal.fire({
                          title: t("general:Are"),
                          text: t("general:Youwon"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: t("general:deleteit"),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteItem(formik.values);
                            Swal.fire({
                              title: t("general:Deleted"),
                              text: t("general:YourFileHasBeenDeleted"),
                              icon: "success",
                            });
                          }
                        });
                      }}
                      disabled={formik.isSubmitting}
                    />
                  )}
                </Box>
              </Box>
              <Dialog
                open={openHolidaysToReplace}
                onClose={() => setOpenHolidaysToReplace(false)}
                maxWidth={"md"}
                fullWidth={true}
              >
                <DialogContent>
                  <Typography>
                    {t("shiftAttendance:replaceMessagesHolidays")}
                  </Typography>
                  {holidaysToReplace.length > 0 && (
                    <List>
                      {holidaysToReplace.map((e, index) => (
                        <ListItem
                          key={index}
                          secondaryAction={
                            <IconButton
                              onClick={() => replaceHoliday(index, formik)}
                            >
                              <LoopIcon />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={
                              <>
                                {[
                                  dayjs(e.new.date).format("MM/DD/YYYY"),
                                  e.new.localName,
                                  e.new.name,
                                  e.new.isWorkday
                                    ? t("general:Yes")
                                    : t("general:Not"),
                                ].join(", ")}
                              </>
                            }
                            secondary={
                              <>
                                {[
                                  dayjs(e.actual.date).format("MM/DD/YYYY"),
                                  e.actual.localName,
                                  e.actual.name,
                                  e.actual.isWorkday
                                    ? t("general:Yes")
                                    : t("general:Not"),
                                ].join(", ")}
                              </>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  <Box
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box display="flex" gap="1em">
                      <UIButton
                        type="button"
                        label={t("general:Cancel")}
                        onClick={() => setOpenHolidaysToReplace(false)}
                      />
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </Form>
          )}
        </Formik>
      </Box>
    </UIPaper>
  );
};
