import { useTranslation } from "react-i18next";
import {
  UIButton,
  UIPhoneNumber,
  UISelect,
  UISwitch,
  UITextField,
} from "components";
import { Box, Grid, Typography } from "@mui/material";
import { FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { RELATIONSHIP_OPTIONS } from "utils";
import { Fragment } from "react";
import { translateSelectors } from "helpers";

export const Contacts = ({
  formik,
  nameFileArray,
  values,
  canAdd,
  canChange,
  canDelete,
}) => {
  const { t } = useTranslation(["employee"]["options"]);
  const disabled = (!canAdd && !canChange) || formik.isSubmitting;

  return (
    <>
      <Box>
        <FieldArray name={nameFileArray}>
          {({ push, remove }) => (
            <Box>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={11}>
                  <Typography variant="h5">
                    {t("employee:ContactInformation")}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12} md={1}>
                  {canAdd && (
                    <UIButton
                      onClick={() =>
                        push({
                          names: "",
                          surnames: "",
                          emergencyPhoneNumber: "",
                          relationship: "",
                          email: "",
                          isEmergencyContact: true,
                        })
                      }
                      disabled={disabled}
                    >
                      <AddCircleOutlineIcon />
                    </UIButton>
                  )}
                </Grid>
              </Grid>
              <br />
              {values?.map((value, index) => (
                <Fragment key={index}>
                  <Grid container spacing={2} key={index}>
                    <Grid item={true} xs={12} md={11}>
                      <Grid container spacing={2}>
                        <Grid item={true} xs={12} md={4}>
                          <UITextField
                            formik={formik}
                            label={t("employee:Nombres")}
                            name={"names"}
                            nameFileArray={nameFileArray}
                            index={index}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <UITextField
                            formik={formik}
                            label={t("employee:Apellidos")}
                            name={"surnames"}
                            nameFileArray={nameFileArray}
                            index={index}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <UIPhoneNumber
                            formik={formik}
                            label={t("employee:Númerodeteléfono")}
                            name={"emergencyPhoneNumber"}
                            nameFileArray={nameFileArray}
                            index={index}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <UITextField
                            formik={formik}
                            label={t("employee:Email")}
                            name={"email"}
                            type={"email"}
                            nameFileArray={nameFileArray}
                            index={index}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <UISelect
                            formik={formik}
                            label={t("employee:Parentesco")}
                            name={"relationship"}
                            nameFileArray={nameFileArray}
                            index={index}
                            options={translateSelectors(
                              t,
                              RELATIONSHIP_OPTIONS
                            )}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <UISwitch
                            formik={formik}
                            label={t("employee:Escontactodeemergencia")}
                            name={"isEmergencyContact"}
                            nameFileArray={nameFileArray}
                            index={index}
                            disabled={disabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} md={1}>
                      {canDelete && (
                        <UIButton
                          onClick={() => remove(index)}
                          disabled={disabled}
                        >
                          <DeleteIcon />
                        </UIButton>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                </Fragment>
              ))}
            </Box>
          )}
        </FieldArray>
      </Box>
    </>
  );
};
