import { Grid } from "@mui/material";
import { UIJoditEditor, UISelectFast, UITextFast } from "components";
import { useTranslation } from "react-i18next";
import { JobDescriptionDisabled } from "./JobDescriptionDisabled";

export const FormJobDescription = ({
  formik,
  canAdd,
  canChange,
  currencies,
}) => {
  const { t } = useTranslation(["employee"]["general"]);
  const disabled = JobDescriptionDisabled({ canAdd, canChange, formik });

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={3}>
        <UITextFast
          type="number"
          label={t("recruitment:MinimumSalary")}
          name="description.salaryRange.minSalary"
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UITextFast
          type="number"
          label={t("recruitment:MaximumSalary")}
          name="description.salaryRange.maxSalary"
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UISelectFast
          label={t("employee:Divisa")}
          name={"description.salaryRange.currency"}
          options={currencies}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={12}>
        <UIJoditEditor
          label={t("employee:Goals")}
          name="description.objective"
          formik={formik}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={12}>
        <UIJoditEditor
          label={t("employee:Functions")}
          name="description.functions"
          formik={formik}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={12}>
        <UIJoditEditor
          label={t("employee:Responsibilities")}
          name="description.responsibilities"
          formik={formik}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
