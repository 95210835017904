import { Typography } from "@mui/material";
import { ErrorMessage, Field, useField } from "formik";
import JoditEditor from "jodit-react";
import { useRef } from "react";

export const UIJoditEditor = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);
  const editorRef = useRef(null);

  return (
    <Field {...field} {...rest}>
      {({ field, form: { setFieldValue } }) => (
        <>
          <Typography variant="caption" color={"primary"}>
            {props.label}
          </Typography>
          <JoditEditor
            ref={editorRef}
            value={field.value}
            config={{
              addNewLine: false,
              disabled: props.disabled,
              cleanHTML: {
                denyTags: {
                  script: false,
                },
              },
            }}
            onBlur={(newContent) =>
              setFieldValue(
                field.name,
                newContent === "<p><br></p>" ? "" : newContent,
              )
            }
          />
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <Typography color="error" variant="caption">
                {msg}
              </Typography>
            )}
          />
        </>
      )}
    </Field>
  );
};
