import { Typography } from "@mui/material";
import { UIModal, UITextField, UIButton, Toast } from "components";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { patchEmployeeNumber } from "api";
import { setEmployeeSelected } from "@redux/slices/profile";

export const ChangeEmployeeNumberModal = ({ open, setOpen, initialValues }) => {
  const { t } = useTranslation(["employee"]["general"]);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    try {
      const { data } = await patchEmployeeNumber(
        initialValues?._id,
        values.number
      );
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
      setOpen(false);
      dispatch(setEmployeeSelected(data));
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: error?.response?.data?.message,
      });
    }
  };

  return (
    <>
      <UIModal open={open} setOpen={setOpen}>
        <Typography variant="h6" fontWeight="medium">
          {t("employee:CambiarNumeroEmpleado")}
        </Typography>
        <br />
        <Formik
          initialValues={{ number: initialValues?.number }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <UITextField
                formik={formik}
                label={t("employee:employeeNumber")}
                name={"number"}
                isrequired={true}
                type="number"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              <br />
              <br />
              <UIButton
                type="submit"
                label={
                  formik?.values?._id
                    ? t("general:Actualizar")
                    : t("general:Guardar")
                }
                loading={formik.isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </UIModal>
    </>
  );
};
