import React, { useState } from "react";
import { UIBootstrapDialog, UIButton } from "components";
import ReactCrop from "react-image-crop";
import {
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export const ImageCrop = ({ srcRef, setSrc, open, setOpen, formik, name }) => {
  const { t } = useTranslation(["settings"]["general"]);

  const handleCloseImageCrop = () => {
    setCrop(null);
    setOpen(false);
  };

  const [crop, setCrop] = useState(null);

  const onCropChange = (c) => {
    setCrop(c);
  };

  const onCropComplete = (c) => {
    setCrop(c);
    makeClientCropt();
  };

  const makeClientCropt = () => {
    let image = new Image();
    image.src = srcRef;
    if (image && crop?.width && crop?.height) {
      const croppedImageUrl = getCroppedImg(image, crop);

      setSrc(croppedImageUrl);
      formik.setFieldValue(name, croppedImageUrl);
      handleCloseImageCrop();
    } else {
      setSrc(srcRef);
      formik.setFieldValue(name, srcRef);
      handleCloseImageCrop();
    }
  };

  const getCroppedImg = (image, crop) => {
    const image_area_crop = document.getElementById("image-area-crop");
    const canvas = document.createElement("canvas");
    const scaleX = image_area_crop.naturalWidth / image_area_crop.width;
    const scaleY = image_area_crop.naturalHeight / image_area_crop.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return canvas.toDataURL();
  };

  return (
    <UIBootstrapDialog open={open} onClose={handleCloseImageCrop} maxWidth={"fit-content"} fullWidth={false}>
      <Box p={2} maxWidth={"fit-content"}>

        <ReactCrop
          src={srcRef}
          crop={crop}
          ruleOfThirds
          onChange={onCropChange}
          maxWidth={"fit-content"}
        // onComplete={onCropComplete}
        >
          <img
            id="image-area-crop"
            src={srcRef}
            style={{
              width: "500px",
              maxWidth: "500px",
            }}
          />
        </ReactCrop>
        <Box mt={2} display="flex" width={"fit-content"} maxWidth={"55%"} gap="1em">
          <UIButton
            onClick={makeClientCropt}
            label={t("settings:Accept")}
          />
        </Box>
      </Box>
    </UIBootstrapDialog>
  );
}