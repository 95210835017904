import { IconButton, Modal, Box } from "@mui/material";
import { modalStyle } from "helpers";
import CloseIcon from "@mui/icons-material/Close";

export const UIModal = ({
  children,
  open,
  setOpen,
  width = "auto",
  height = "auto",
}) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={{ width: width, height: height }} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </Modal>
  );
};
