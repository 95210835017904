import { useEffect, useState } from "react";
import { MenuRequests } from "../MenuRequests";
import { BasicTable, Toast } from "components";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TableCell,
} from "@mui/material";
import { Filtro } from "../components/Filtro";
import {
  getContractEnd,
  getJobPositionsRequest,
  getJustification,
  getScheduledPermissions,
  getStaffRequest,
} from "api";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { setStaffRequest } from "@redux/slices/recruitment";
import { requestsSelect, setRequestUpdated } from "@redux/slices/requests";
import { useRequests } from "hooks";
import { setJobDescription } from "@redux/slices/jobDescription";
import _ from "lodash";

import dayjs from "dayjs";
import { NewRequestModal, RequestModal } from "./PetitionsModals";
import { MenuRequest } from "./MenuRequest";

export const Petitions = ({ application = "organization" }) => {
  const { t } = useTranslation(["request", "recruitment", "general"]);
  const dispatch = useDispatch();

  const { user } = useSelector(authUserSelect);
  const { employees, vacancyReasons } = useRequests();

  const [dataFilter, setDataFilter] = useState(null);
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState(null);
  const [typeDocument, setTypeDocument] = useState(null);
  const [openNewRequest, setOpenNewRequest] = useState(false);

  const [dataRequests, setDataRequests] = useState([]);
  const [finish, setFinish] = useState(false);
  const [staff, setStaff] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [justifications, setJustifications] = useState([]);
  const [jobPositions, setJobPositions] = useState([]);
  const [contractEnd, setContractEnd] = useState([]);
  const [update, setUpdate] = useState(null);
  const [supervises, setSupervies] = useState([]);

  const { requestUpdated } = useSelector(requestsSelect);

  const [typesOfRequests, setTypesOfRequests] = useState({
    staff: {
      label: t("request:Staff"),
      checked: false,
    },
    vacation: {
      label: t("request:Vacations"),
      checked: false,
    },
    permissions: {
      label: t("request:Permissions"),
      checked: false,
    },
    justifications: {
      label: t("request:Justifications"),
      checked: false,
    },
    jobPosition: {
      label: t("request:JobsPositions"),
      checked: false,
    },
    quitting: {
      label: t("request:Quitting"),
      checked: false,
    },
    termination: {
      label: t("request:Dismissal"),
      checked: false,
    },
  });

  const mapRequests = ({ requests, data, employees, type }) => {
    const newRequests = data.map((i) => ({
      id: i._id,
      folio: i.folio,
      applicant: employees[i.applicant].fullName,
      createdAt: i.createdAt,
      status: t(`recruitment:${i.status}`),
      type: t(`recruitment:${type}`),
    }));

    requests.all = _.unionBy(newRequests, requests.all, "id");

    requests.types = data.reduce(
      (previous, current) => ((previous[current._id] = type), previous),
      requests.types,
    );

    return requests;
  };

  const orderRequests = ({ requests }) => {
    requests.all.sort((a, b) =>
      dayjs(a.createdAt).isBefore(dayjs(b.createdAt)),
    );
  };

  useEffect(() => {
    try {
      if (requestUpdated && requestUpdated.action) {
        setFinish(false);

        let requests = _.cloneDeep(dataRequests);
        let newTypesOfRequest = _.cloneDeep(typesOfRequests);
        const { action, type, data } = requestUpdated;
        const dataArray = [data];
        const typesSelected = _.find(newTypesOfRequest, "checked");

        if (action === "add" || action === "update") {
          if (typesSelected) {
            newTypesOfRequest[type].checked = true;
          }

          requests = mapRequests({
            data: dataArray,
            type: type,
            employees,
            requests,
          });
          orderRequests({ requests });

          switch (type) {
            case "vacation":
            case "permissions":
              setPermissions(_.unionBy(dataArray, permissions, "_id"));
              break;

            case "jobPosition":
              setJobPositions(_.unionBy(dataArray, jobPositions, "_id"));
              break;

            case "staff":
              setStaff(_.unionBy(dataArray, staff, "_id"));
              break;

            case "quitting":
            case "termination":
              setContractEnd(_.unionBy(dataArray, contractEnd, "_id"));
              break;
          }
        } else if (action === "delete") {
          requests.all = requests.all.filter((item) => {
            return item.id !== requestUpdated.data._id;
          });
        }

        setTypesOfRequests(newTypesOfRequest);
        setDataRequests(requests);
        setFinish(true);
        dispatch(setRequestUpdated(null));
      }
    } catch (err) {
      console.log(err);
      dispatch(setFinish(true));
      dispatch(setRequestUpdated(null));
    }
  }, [requestUpdated]);

  const getSupervises = (employees, supervisor, supervises) => {
    const supervisesNextLevel = _.filter(
      employees,
      (e) => e.supervisor === supervisor.fullName,
    );

    if (supervisesNextLevel.length) {
      supervises = _.reduce(
        supervisesNextLevel,
        (result, s, n) => {
          return getSupervises(employees, s, result);
        },
        supervises,
      );
    }

    return [...supervises, ...supervisesNextLevel];
  };

  useEffect(() => {
    setFinish(false);

    const getData = async () => {
      let requests = { all: [], types: {} };
      let messageError = "";

      const supervisor = employees[user._id];
      const supervisesFilter = getSupervises(employees, supervisor, []);
      setSupervies(supervisesFilter);

      try {
        const { data: staffData } = await getStaffRequest();

        requests = mapRequests({
          type: "staff",
          data: staffData,
          employees: employees,
          requests: requests,
        });
        setStaff(staffData);
      } catch (e) {
        messageError = t("request:ErrorStaffRequests");
      }

      try {
        const { data: scheduledPermissions } = await getScheduledPermissions(
          user._id,
        );

        const vacationsData = scheduledPermissions.filter(
          (obj) => obj.type === "VACATION",
        );

        requests = mapRequests({
          type: "vacation",
          data: vacationsData,
          employees: employees,
          requests: requests,
        });

        const permissionsData = scheduledPermissions.filter(
          (obj) => obj.type !== "VACATION",
        );

        requests = mapRequests({
          type: "permissions",
          data: permissionsData,
          employees: employees,
          requests: requests,
        });

        setPermissions(scheduledPermissions);
      } catch (e) {
        messageError = t("request:ErrorPermissions");
      }

      try {
        const { data: justificationsData } = await getJustification(user._id);

        requests = mapRequests({
          type: "justifications",
          data: justificationsData,
          employees: employees,
          requests: requests,
        });
        setJustifications(justificationsData);
      } catch (e) {
        messageError = t("request:ErrorJustificacions");
      }

      try {
        const { data: jobPositionsData } = await getJobPositionsRequest(
          user._id,
        );

        requests = mapRequests({
          type: "jobPosition",
          data: jobPositionsData,
          employees: employees,
          requests: requests,
        });
        setJobPositions(jobPositionsData);
      } catch (e) {
        messageError = t("request:ErrorJobPosition");
      }

      try {
        const { data: contractEndData } = await getContractEnd(
          "PENDING",
          "REVIEW_REQUIRED",
        );

        requests = mapRequests({
          type: "quitting",
          data: _.filter(contractEndData, { type: "QUITTING" }),
          employees: employees,
          requests: requests,
        });

        requests = mapRequests({
          type: "termination",
          data: _.filter(contractEndData, { type: "TERMINATION" }),
          employees: employees,
          requests: requests,
        });

        setContractEnd(contractEndData);
      } catch (e) {
        messageError = t("request:ErrorContractEnd");
      }

      orderRequests({ requests });
      setDataRequests(requests);
      setFinish(true);

      if (messageError) {
        Toast.fire({
          icon: "error",
          title: messageError,
        });
      }
    };

    if (employees && vacancyReasons) {
      getData();
    }
  }, [employees, vacancyReasons, update]);

  useEffect(() => {
    if (finish) {
      let requests = _.cloneDeep(dataRequests);

      const requestsChecked = _.reduce(
        typesOfRequests,
        function (result, value, key) {
          if (value.checked) {
            result.push(key);
          }

          return result;
        },
        [],
      );

      if (requestsChecked.length) {
        requests.all = _.filter(requests.all, function (request) {
          return requestsChecked.includes(request.type);
        });
      }

      requests.all = requests.all.map(({ type, ...rest }) => ({
        ...rest,
        type: t(`request:t${type}`),
      }));
      setDataFilter(requests);
    }
  }, [finish, dataRequests, typesOfRequests]);

  const handleClick = async (row) => {
    const type = dataRequests.types[row.id];
    let data = null;
    setRequest(data);

    switch (type) {
      case "staff":
        data = staff.find((i) => i._id === row.id);
        await dispatch(setStaffRequest(data));
        break;

      case "vacation":
        data = permissions.find((i) => i._id === row.id);
        break;

      case "permissions":
        data = permissions.find((i) => i._id === row.id);
        break;

      case "justifications":
        data = justifications.find((i) => i._id === row.id);
        break;

      case "jobPosition":
        data = jobPositions.find((i) => i._id === row.id);
        dispatch(setJobDescription(data));
        break;

      case "quitting":
      case "termination":
        data = contractEnd.find((i) => i._id === row.id);
        break;
    }

    setRequest(data);
    setTypeDocument(type);
    setOpen(true);
  };

  return (
    <Paper>
      <MenuRequests ubication={"Request"} application={application} />
      <Box p={2}>
        {finish ? (
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={9}>
              {dataFilter ? (
                <BasicTable
                  PerPage={25}
                  rows={dataFilter.all}
                  handleClick={handleClick}
                  customStyle={"StatusRequest"}
                >
                  <TableCell>{t("recruitment:Folio")}</TableCell>
                  <TableCell>{t("recruitment:Applicant")}</TableCell>
                  <TableCell>{t("request:CreationDateAndTime")}</TableCell>
                  <TableCell>{t("recruitment:status")}</TableCell>
                  <TableCell>{t("request:TypeOfRequest")}</TableCell>
                </BasicTable>
              ) : (
                <Alert severity="info">{t("request:NotRequestPending")}</Alert>
              )}
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Filtro
                typesOfRequests={typesOfRequests}
                setTypesOfRequests={setTypesOfRequests}
                requests={dataRequests}
              >
                <MenuRequest
                  setOpen={setOpenNewRequest}
                  application={application}
                  setUpdate={setUpdate}
                  setTypeDocument={setTypeDocument}
                  supervises={supervises}
                />
              </Filtro>
            </Grid>
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
          </Stack>
        )}
      </Box>
      <RequestModal
        open={open}
        setOpen={setOpen}
        request={request}
        typeDocument={typeDocument}
        application={application}
        employees={employees}
      />
      <NewRequestModal
        open={openNewRequest}
        setOpen={setOpenNewRequest}
        application={application}
        typeDocument={typeDocument}
      />
    </Paper>
  );
};
