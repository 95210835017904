import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  Switch,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSettingsEmail, patchSettingsEmail } from "api";
import { Toast } from "components";
import { useAccess, useEmails } from "hooks";
import Swal from "sweetalert2";

export const MailSettingsSend = () => {
  const { t } = useTranslation(["settings"]["general"]);

  const [rows, setRows] = useState([]);
  const [checked, setChecked] = useState([]);

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);

  const emails = useEmails();

  const { HaveAcessToEdit } = useAccess();
  const canChange = HaveAcessToEdit(
    "/cereza-organization/settings/configurations/EnvíoCorreo",
    ["change_emailSettings"]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getSettingsEmail();

        setChecked(
          data
            .filter((item) => {
              return item.sendEmail;
            })
            .map((item) => item._id)
        );

        setRows(data);
        setNoOfPages(Math.ceil(data.length / itemsPerPage));
      } catch (error) {
        console.log("Error get data: ", error);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getSettingsEmail();

        setChecked(
          data
            .filter((item) => {
              return item.sendEmail;
            })
            .map((item) => item._id)
        );

        setRows(data);
        setNoOfPages(Math.ceil(data.length / itemsPerPage));
      } catch (error) {
        console.log("Error get data: ", error);
      }
    };

    getData();
  }, []);

  const handleToggle = async (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    try {
      await patchSettingsEmail(value, { sendEmail: currentIndex === -1 });

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
      Toast.fire({
        icon: "success",
        title: t("settings:SendEmailSuccessfullyUpdated"),
      });
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title: err.message,
      });
    }
  };

  const pageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} md={3}></Grid>
      <Grid item={true} xs={12} md={6} component={Paper}>
        <List component={Box}>
          {emails &&
            rows
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((row) => (
                <Fragment key={row._id}>
                  {row.isUpdateable ? (
                    <ListItemButton
                      role={undefined}
                      onClick={(event) => {
                        if (canChange) {
                          handleToggle(row._id);
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: t("general:NoAccess"),
                          });
                        }
                      }}
                    >
                      <ListItemText
                        primary={t(`settings:${emails[row?.email]?.title}`)}
                        secondary={
                          t("settings:ForModule") +
                          t(`settings:${emails[row?.email]?.module}`)
                        }
                      />
                      <Switch
                        checked={checked.indexOf(row._id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        disabled={!canChange}
                      />
                    </ListItemButton>
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={t(`settings:${emails[row.email]?.title}`)}
                        secondary={
                          t("settings:ForModule") +
                          t(`settings:${emails[row.email]?.module}`)
                        }
                      />
                    </ListItem>
                  )}
                  <Divider />
                </Fragment>
              ))}
        </List>
        <Stack alignItems="end" mb={1}>
          <Pagination
            count={noOfPages}
            page={page}
            defaultPage={1}
            onChange={pageChange}
            size="large"
            showFirstButton
            showLastButton
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
