import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";

export const UIPaper = ({ children, props }) => {
  const [height, setHeight] = useState(130);

  useEffect(() => {
    let h = 0;
    const header = document.getElementById("cereza-app-bar");
    h = h + header?.offsetHeight;

    const profile_tabs = document.getElementById("cereza-profile-tabs");
    const general_tabs = document.getElementById("cereza-general-tabs");

    if (profile_tabs) {
      h = h + profile_tabs?.offsetHeight;
    }

    if (general_tabs) {
      h = h + general_tabs?.offsetHeight;
    }

    setHeight(height + h);
  }, []);

  return (
    <Paper sx={{ minHeight: `calc(100vh - ${height}px)` }} {...props}>
      {children}
    </Paper>
  );
};
