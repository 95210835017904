import Map from ".";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { UIButton, UIModal } from "..";

export const ModalMap = ({
  open,
  setOpen,
  setAddress,
  address,
  handleClick,
}) => {
  const { t } = useTranslation(["employee"]["general"]);

  return (
    <>
      <UIModal open={open} setOpen={setOpen}>
        <Typography variant="h4" fontWeight="medium">
          {t("employee:Map")}
        </Typography>
        <Typography variant="h6" fontWeight="regular">
          {t("employee:drag")}
        </Typography>
        <Map setAddress={setAddress} />
        <br />
        <Box mt={2} width="100%" display="flex" justifyContent="space-between">
          <Box display="flex" gap="1em">
            <UIButton
              type="submit"
              label={t("general:Guardar")}
              disabled={address === null ? true : false}
              onClick={() => {
                setOpen(false);
                handleClick();
              }}
            />
            <UIButton
              label={t("general:Cancel")}
              onClick={() => {
                setOpen(false);
              }}
            />
          </Box>
        </Box>
      </UIModal>
    </>
  );
};

ModalMap.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setAddress: PropTypes.func,
  address: PropTypes.object,
  handleClick: PropTypes.func,
};
