import { Grid } from "@mui/material";
import {
  UITextFast,
  UISelectFast,
  UISwitchFast,
  UISelectMultipleFast,
} from "components";
import { useTranslation } from "react-i18next";
import { FormAuthorizationGeneric } from "./FormAuthorizationGeneric";

export const FormVacation = ({
  formik,
  countries,
  holidays,
  employees,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation();
  const disabled =
    (!canAdd && !formik.values._id) ||
    (!canChange && formik.values._id) ||
    formik.isSubmitting;

  const disabledAllowVacationPeriods = (e) => {
    if (e.target.checked) {
      formik.setFieldValue("extraRules.allowVacationPeriods", false);
      formik.setFieldValue("extraRules.numberPeriodsInYear", "");
      formik.setFieldValue("extraRules.numberConsecutiveDays", "");
    }
  };

  return (
    <>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={6}>
          <UITextFast
            label={t("organization:NameRule")}
            name="title"
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:Country")}
            name="country"
            options={countries}
            required={true}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <FormAuthorizationGeneric
        formik={formik}
        employees={employees}
        disabled={disabled}
      />
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AllowVacationAcrual")}
            name={"extraRules.allowVacationAccrual"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue(
                  "extraRules.maximumAccrualPeriodsDays",
                  "",
                );
              }

              disabledAllowVacationPeriods(e);

              formik.setFieldValue(
                "extraRules.allowVacationAccrual",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            type="number"
            label={t("organization:MaximumPeriods")}
            name="extraRules.maximumAccrualPeriodsDays"
            helperText={t("organization:MaximumPeriodsHelp")}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationAccrual}
            disabled={
              disabled || !formik.values.extraRules.allowVacationAccrual
            }
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={8}>
          <UISwitchFast
            label={t("organization:AllowExceedingBalance")}
            name={"extraRules.allowExceedingBalance"}
            onChange={(e) => {
              disabledAllowVacationPeriods(e);

              formik.setFieldValue(
                "extraRules.allowExceedingBalance",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AllowVacationPeriods")}
            name={"extraRules.allowVacationPeriods"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue("extraRules.numberPeriodsInYear", "");
                formik.setFieldValue("extraRules.numberConsecutiveDays", "");
              }
              formik.setFieldValue(
                "extraRules.allowVacationPeriods",
                e.target.checked,
              );
            }}
            disabled={
              disabled ||
              formik.values.extraRules.allowVacationAccrual ||
              formik.values.extraRules.allowExceedingBalance
            }
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            type="number"
            label={t("organization:PeriodsPerYear")}
            name="extraRules.numberPeriodsInYear"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationPeriods}
            disabled={
              disabled || !formik.values.extraRules.allowVacationPeriods
            }
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextFast
            type="number"
            label={t("organization:NumberConsecutiveDays")}
            name="extraRules.numberConsecutiveDays"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationPeriods}
            disabled={
              disabled || !formik.values.extraRules.allowVacationPeriods
            }
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AccountForNonWorkingDays")}
            name={"extraRules.accountForNonWorkingDays"}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AccountForHolidays")}
            name={"extraRules.accountForHolidays"}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <UISelectMultipleFast
            label={t("organization:HolidayCalendars")}
            name="extraRules.holidayCalendars"
            multiple={true}
            options={holidays}
            disabled={disabled}
            required={formik.values.extraRules.accountForHolidays}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2}>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:AllowVacationBeforeFirstYear")}
            name={"extraRules.allowVacationBeforeTheEndYear"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue(
                  "extraRules.allowVacationStartingFromWhichMonth",
                  "",
                );
              }
              formik.setFieldValue(
                "extraRules.allowVacationBeforeTheEndYear",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UITextFast
            type="number"
            label={t("organization:VacationFromMonth")}
            helperText={t("Mes")}
            name={"extraRules.allowVacationStartingFromWhichMonth"}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
            required={formik.values.extraRules.allowVacationBeforeTheEndYear}
            disabled={
              disabled ||
              !formik.values.extraRules.allowVacationBeforeTheEndYear
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
