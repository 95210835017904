import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

export const Paneles = ({ paneles }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return paneles.map((panel) => (panel.hasAccess && (
    <Accordion
      key={panel.id}
      expanded={expanded === panel.id}
      onChange={handleExpanded(panel.id)}
      elevation={1}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" alignItems="center" gap={1}>
          {panel.icon}
          <Typography>
            {panel.title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {panel.content}
      </AccordionDetails>
    </Accordion>
  )));
};
