import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Button, Menu, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "utils/Language";

export const WizardActions = ({ children }) => {
  const { t } = useTranslation(["wizard"]["general"]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" justifyContent="flex-end" spacing={2}>
      {children}
      <Button
        variant="contained"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {t("wizard:Options")}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Language />
      </Menu>
    </Stack>
  );
};
