import { Box, Skeleton, CircularProgress } from "@mui/material";

export const Spinner = ({ width = 100, height = 46 }) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: `${width}%`,
        height: `${height}vh`,
      }}
    >
      <CircularProgress
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        color="secondary"
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={"100%"}
        height={"100%"}
      />
    </Box>
  );
};
