import { useTranslation } from "react-i18next";
import { UIButton, UISelectFast, UITextFast } from "components";
import { Box, Grid, Typography } from "@mui/material";
import { FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { JobDescriptionDisabled, JobDescriptionEnabledButtons } from "./JobDescriptionDisabled";

export const FormJobEducation = ({
  formik,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["recruitment"]["options"]);
  const fieldArrayName = "profile.education";

  const Levels = [
    { value: "PRIMARY", label: t("recruitment:PRIMARY") },
    { value: "SECONDARY", label: t("recruitment:SECONDARY") },
    { value: "HIGH_SCHOOL", label: t("recruitment:HIGH_SCHOOL") },
    { value: "TECHNICAL", label: t("recruitment:TECHNICAL") },
    { value: "UNIVERSITY", label: t("recruitment:UNIVERSITY") },
    { value: "POSTGRADUATE", label: t("recruitment:POSTGRADUATE") },
    { value: "MASTERS_DEGREE", label: t("recruitment:MASTER'S_DEGREE") },
    { value: "DOCTORATE", label: t("recruitment:DOCTORATE") },
  ];

  const Status = [
    { value: "INTERNSHIP", label: t("recruitment:INTERNSHIP") },
    { value: "DEGREE", label: t("recruitment:DEGREE") },
    { value: "SOME_COLLAGE", label: t("recruitment:SOME_COLLAGE") },
  ];

  const disabled = JobDescriptionDisabled({ canAdd, canChange, formik });
  const buttons = JobDescriptionEnabledButtons({ canAdd, canChange, formik });

  return (
    <Box mt={2}>
      <FieldArray name={fieldArrayName}>
        {({ push, remove }) => (
          <Box>
            <Grid container spacing={2} mb={2}>
              <Grid item={true} xs={12} md={11}>
                <Typography variant="h5">
                  {t("recruitment:Education")}
                </Typography>
              </Grid>
              {buttons && (
                <Grid item={true} xs={12} md={1}>
                  <UIButton
                    onClick={() =>
                      push({
                        education: "",
                        level: "",
                        status: "",
                      })}
                    disabled={formik.isSubmitting}
                  >
                    <AddCircleOutlineIcon />
                  </UIButton>
                </Grid>
              )}
            </Grid>
            {formik.values?.profile.education.map((school, index) => (
              <Grid container spacing={2} mb={2} key={index}>
                <Grid item={true} xs={12} md={5}>
                  <UITextFast
                    label={t("recruitment:Education")}
                    name={`${fieldArrayName}.${index}.education`}
                    disabled={disabled}
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3}>
                  <UISelectFast
                    label={t("recruitment:Level")}
                    name={`${fieldArrayName}.${index}.level`}
                    options={Levels}
                    disabled={disabled}
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3}>
                  <UISelectFast
                    label={t("recruitment:Status")}
                    name={`${fieldArrayName}.${index}.status`}
                    options={Status}
                    disabled={disabled}
                    required={true}
                  />
                </Grid>
                {buttons && (
                  <Grid item={true} xs={12} md={1}>
                    <UIButton
                      onClick={() =>
                        remove(index)}
                      disabled={disabled}
                    >
                      <DeleteIcon />
                    </UIButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};
