import { useState, useEffect } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import {
  UISelect,
  UITextField,
  UIMultipleFileInput,
  UIButton,
  Toast,
} from "components";
import { postJustification, getFiles, putJustification } from "api";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { useSelector } from "react-redux";

import * as Yup from "yup";

export const JustifyForm = ({
  dailyCheck = "",
  justificationRequest = null,
  readOnly = false,
  setOpen = () => {},
}) => {
  const { t } = useTranslation(["shiftAttendance", "general"]);

  const { tenant } = useSelector(tenantSettingsSelect);

  const [photos, setPhotos] = useState([]);

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(t("shiftAttendance:Jusificacion1")),

    subType: Yup.string().when("type", {
      is: "DISABILITY",
      then: (schema) => schema.required(t("shiftAttendance:Justificacion2")),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),

    comment: Yup.string().when("type", {
      is: (type) => ["OTHER", "PERSONAL"].includes(type),
      then: (schema) => schema.required(t("shiftAttendance:Justificacion3")),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),

    files: Yup.array()
      .of(Yup.string().required(t("shiftAttendance:Justificación5")))
      .when("type", {
        is: (type) => ["DISABILITY", "MEDICAL", "MOURNING"].includes(type),
        then: (schema) => schema.required(t("shiftAttendance:Justificación5")),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
  });

  const onSubmit = async (values) => {
    if (values?.type !== "DISABILITY") {
      delete values?.subType;
    }

    const formattedValues = {
      ...values,
      files: photos,
    };

    try {
      if (values?._id) {
        delete formattedValues?.createdAt;
        delete formattedValues?.folio;
        delete formattedValues?.status;
        delete formattedValues?.signStatus;
        delete formattedValues?.applicant;
        delete formattedValues?._id;

        await putJustification(values?._id, formattedValues);
      } else {
        await postJustification(formattedValues);
      }

      setOpen(false);

      Toast.fire({
        icon: "success",
        title: t("shiftAttendance:SolicitudeDeJustificaciónCreada"),
      });
    } catch (err) {
      console.log(err);
      Toast.fire({
        icon: "error",
        title: t("shiftAttendance:NoFuePosibleCrearUnaJustificacion"),
      });
    }
  };

  async function fetchImagesInBase64(tenantId, fileNames) {
    if (!Array.isArray(fileNames)) {
      throw new Error("El argumento fileNames debe ser un array.");
    }

    if (fileNames.length === 0) {
      return [];
    }

    const requests = fileNames.map((fileName) => {
      return getFiles(tenantId, fileName)
        .then((response) => {
          return response.data.content;
        })
        .catch((error) => {
          console.error(`Error al obtener la imagen para ${fileName}:`, error);
          return null;
        });
    });

    const images = await Promise.all(requests);

    return images.filter((image) => image !== null);
  }

  useEffect(() => {
    if (justificationRequest?.files.length > 0) {
      fetchImagesInBase64(tenant._id, justificationRequest?.files)
        .then((images) => {
          setPhotos(images);
        })
        .catch((error) => {
          console.error("Error al obtener las imágenes:", error);
        });
    }
  }, []);

  return (
    <>
      <Typography variant="h5">
        {t("shiftAttendance:FormularioDeJustificacion")}
      </Typography>
      <br />

      <Formik
        initialValues={
          justificationRequest
            ? justificationRequest
            : {
                dailyCheck: dailyCheck,
                type: "",
                subType: "",
                comment: "",
                files: [],
              }
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {(formik) => (
          <Form>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={4}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.errors.type && formik.touched.type)}
                >
                  <UISelect
                    formik={formik}
                    label={t("shiftAttendance:TipoDeJustificación")}
                    name={"type"}
                    disabled={formik.isSubmitting || readOnly}
                    options={[
                      {
                        value: "DISABILITY",
                        label: t("shiftAttendance:DISABILITY"),
                      },
                      {
                        value: "MOURNING",
                        label: t("shiftAttendance:MOURNING"),
                      },
                      {
                        value: "MEDICAL",
                        label: t("shiftAttendance:MEDICAL"),
                      },
                      {
                        value: "PERSONAL",
                        label: t("shiftAttendance:PERSONAL"),
                      },
                      {
                        value: "OTHER",
                        label: t("shiftAttendance:OTHER"),
                      },
                    ]}
                  />
                  <FormHelperText>
                    <ErrorMessage name="type" />
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                {formik?.values?.type === "DISABILITY" && (
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.errors.subType && formik.touched.subType
                    )}
                  >
                    <UISelect
                      formik={formik}
                      label={t("shiftAttendance:TipoDeDISABILITY")}
                      name={"subType"}
                      disabled={formik.isSubmitting || readOnly}
                      options={[
                        {
                          value: "ILLNESS",
                          label: t("shiftAttendance:ILLNESS"),
                        },
                        {
                          value: "ACCIDENT",
                          label: t("shiftAttendance:ACCIDENT"),
                        },
                        {
                          value: "MATERNITY",
                          label: t("shiftAttendance:MATERNITY"),
                        },
                        {
                          value: "PATERNITY",
                          label: t("shiftAttendance:PATERNITY"),
                        },
                        {
                          value: "TEMPORARY",
                          label: t("shiftAttendance:TEMPORARY"),
                        },
                        {
                          value: "PERMANENT",
                          label: t("shiftAttendance:PERMANENT"),
                        },
                      ]}
                    />
                    <FormHelperText>
                      <ErrorMessage name="subType" />
                    </FormHelperText>
                  </FormControl>
                )}
              </Grid>
              <Grid item={true} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.errors.comment && formik.touched.comment
                  )}
                >
                  <UITextField
                    label={t("shiftAttendance:Comentario")}
                    isrequired={false}
                    name={"comment"}
                    formik={formik}
                    disabled={formik.isSubmitting || readOnly}
                    error={Boolean(
                      formik.errors.comment && formik.touched.comment
                    )}
                    helperText={
                      formik.errors.comment && formik.touched.comment
                        ? formik.errors.comment
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item={true} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.errors.files && formik.touched.files)}
                >
                  <UIMultipleFileInput
                    formik={formik}
                    photos={photos}
                    setPhotos={setPhotos}
                    label={t("shiftAttendance:CargarArchivos")}
                    disabled={formik.isSubmitting || readOnly}
                  />
                  <FormHelperText>
                    <ErrorMessage name="files" />
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {!readOnly && (
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                </Box>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
