import { timeClockClient } from "../adapters";

const GENERAL_REPORTS = "/v1/attendance/general/report";
const ATTENDANCE_EMPLOYEE_ID = "/v1/attendance/";

export function getGeneralReports(dateFrom, dateTo) {
  return timeClockClient.get(
    `${GENERAL_REPORTS}?dateFrom=${dateFrom}&dateTo=${dateTo}`
  );
}

export function getAttendance(id, dateFrom, dateTo) {
  return timeClockClient.get(
    `${ATTENDANCE_EMPLOYEE_ID}${id}/report?dateFrom=${dateFrom}&dateTo=${dateTo}`
  );
}
