function removeWhitespaceFromArray(array) {
  return array.map((item) => ({
    ...item,
    label: item.label.replace(/\s+/g, ""),
  }));
}

export const translateSelectors = (t, options) => {
  options = removeWhitespaceFromArray(options);
  const translateSelector = options.map(({ value, label }) => {
    label = t(`options:${label}`);
    return {
      value: value,
      label: label,
    };
  });

  return translateSelector;
};
