import { useState } from "react";
import { Grid, TableCell, Box, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BasicTable, SearchBar, UIBootstrapDialog } from "components";
import { parameterizationSelect } from "../../../../../redux/slices/parameterization";
import { useSelector } from "react-redux";

export const MembersGroup = ({
  groupSelected,
  miembrosGrupo,
  openMiembrosGrupo,
  setOpenMiembrosGrupo,
}) => {
  const { t } = useTranslation(["settings"]["general"]);
  const [filteredData, setFilteredData] = useState([]);

  const { mainColor } = useSelector(parameterizationSelect);
  const title = t("settings:EmpleadosRelacionados") + " " + (groupSelected?.name || " ");
  return (
    <UIBootstrapDialog open={openMiembrosGrupo} setOpen={setOpenMiembrosGrupo}
      title={title}>
      <Box p={2}>
        {
          miembrosGrupo !== null
            ?
            <Grid container spacing={3}>
              <Grid item={true} xs={12}>
                <SearchBar
                  data={miembrosGrupo}
                  searchKeys={["fullName"]}
                  setFilteredData={setFilteredData}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <BasicTable
                  rows={filteredData.length === 0 ? miembrosGrupo : filteredData}
                  handleClick={() => { }}
                  PerPage={10}
                >
                  <TableCell>{t("employee:fullName")}</TableCell>
                  <TableCell>{t("employee:department")}</TableCell>
                  <TableCell>{t("employee:position")}</TableCell>
                </BasicTable>
              </Grid>
            </Grid>
            :
            <Box
              sx={{
                display: "grid",
                placeItems: "center",
                height: "70vh",
              }}
            >
              <CircularProgress />
            </Box>
        }
      </Box>
    </UIBootstrapDialog>
  );
};
