import { Autocomplete, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, useField } from "formik";
import { useTranslation } from "react-i18next";

export const UISelectFast = (props) => {
  const { t } = useTranslation(["general"]);
  const { name, options, ...rest } = props;
  const [field] = useField(name);
  const newOptions = [{ value: "", label: t("general:Select") }, ...options];

  let option_selected = field.value
    ? newOptions.find((option) => {
        return option.value === field.value;
      })
    : null;

  return (
    <Field {...field} {...rest}>
      {({ field, form: { setFieldValue } }) => (
        <>
          <Autocomplete
            disableClearable={true}
            value={option_selected}
            getOptionLabel={(o) => o.label}
            fullWidth
            isOptionEqualToValue={(o, v) => o.value === v.value}
            onChange={(event, newValue) => setFieldValue(name, newValue.value)}
            renderInput={(params) => <TextField {...params} {...rest} />}
            {...rest}
            options={newOptions}
          />
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <Typography color="error" variant="caption">
                {msg}
              </Typography>
            )}
          />
        </>
      )}
    </Field>
  );
};
