import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "formik";

export const CustomTextField = ({ field, form: { touched, errors }, ...props }) => (
  <>
    <TextField {...field} {...props} />
    {touched[field.name] &&
      errors[field.name] && <div>{errors[field.name]}</div>}
  </>
);

export const FormCheckIn = ({ formik }) => {
  const { t } = useTranslation(["general"]["shiftAttendance"]);
  const [preEntryTime, setPreEntryTime] = useState(
    formik.values.checkIn.preEntryTime,
  );
  const [workingTimeBeforeSchedule, setWorkingTimeBeforeSchedule] = useState(
    formik.values.checkIn.workingTimeBeforeSchedule,
  );
  const [arrivesAfterTheStartingTime, setArrivesAfterTheStartingTime] =
    useState(
      formik.values.checkIn.arrivesAfterTheStartingTime,
    );
  const [overtimeRequired, setOvertimeRequired] = useState(
    formik?.values?.checkIn.arrivesAfterTheStartingTime ===
      "DELAY_TOLERANCE_TIME",
  );

  return (
    <Stack spacing={2}>
      <FormControl>
        <FormLabel id={"preEntryTime-label"}>
          {t("shiftAttendance:preEntryTime")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="preEntryTime-label"
          name="checkIn.preEntryTime"
          value={preEntryTime}
          onChange={(event) => {
            setPreEntryTime(event.target.value);
            formik.setFieldValue("checkIn.preEntryTime", event.target.value);
          }}
        >
          <FormControlLabel
            value="KEEP_ACTUAL_ENTRY_TIME"
            control={<Radio />}
            label={t("shiftAttendance:KeepActualEntryTime")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="ASSIGN_ENTRY_TIME_DEFINED_IN_SCHEDULE"
            control={<Radio />}
            label={t("shiftAttendance:AssignEntryTimeDefinedInSchedule")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id={"workingTimeBeforeSchedule-label"}>
          {t("shiftAttendance:workingTimeBeforeSchedule")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="workingTimeBeforeSchedule-label"
          name="checkIn.workingTimeBeforeSchedule"
          value={workingTimeBeforeSchedule}
          onChange={(event) => {
            setWorkingTimeBeforeSchedule(event.target.value);
            formik.setFieldValue(
              "checkIn.workingTimeBeforeSchedule",
              event.target.value,
            );
          }}
        >
          <FormControlLabel
            value="TIME_IN_FAVOR"
            control={<Radio />}
            label={t("shiftAttendance:TimeInFavor")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="OVERTIME"
            control={<Radio />}
            label={t("shiftAttendance:Overtime")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id={"arrivesAfterTheStartingTime-label"}>
          {t("shiftAttendance:arrivesAfterTheStartingTime")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="arrivesAfterTheStartingTime-label"
          name="checkIn.arrivesAfterTheStartingTime"
          value={arrivesAfterTheStartingTime}
          onChange={(event) => {
            setArrivesAfterTheStartingTime(event.target.value);
            formik.setFieldValue(
              "checkIn.arrivesAfterTheStartingTime",
              event.target.value,
            );

            if (event.target.value !== "DELAY_TOLERANCE_TIME") {
              setOvertimeRequired(false);
              formik.setFieldValue("checkIn.toleranceTime", 0);
            } else {
              setOvertimeRequired(true);
            }
          }}
        >
          <FormControlLabel
            value="DELAY_TOLERANCE_TIME"
            control={<Radio />}
            label={t("shiftAttendance:DelayToleranceTime")}
            disabled={formik.isSubmitting}
          />
          {overtimeRequired && (
            <Field
              name={"checkIn.toleranceTime"}
              label={t("shiftAttendance:toleranceTime")}
              component={CustomTextField}
              type={"number"}
              required={overtimeRequired}
              disabled={formik.isSubmitting}
            />
          )}
          <FormControlLabel
            value="ASSIGN_ENTRY_TIME_DEFINED_IN_SCHEDULE"
            control={<Radio />}
            label={t("shiftAttendance:AssignEntryTimeDefinedInSchedule")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name={"checkIn.autoRegister"}
              disabled={formik.isSubmitting}
              checked={formik.values.checkIn.autoRegister}
              onChange={() =>
                formik.setFieldValue(
                  "checkIn.autoRegister",
                  !formik.values.checkIn.autoRegister,
                )}
            />
          }
          label={t("shiftAttendance:AutoRegisterCheckIn")}
        />
      </FormGroup>
    </Stack>
  );
};
