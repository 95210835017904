import { Paper, Button, Stack, Typography } from "@mui/material";
import { NewRequestModal } from "modules/Requests/Petitions/PetitionsModals";
import { useEffect, useState } from "react";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { useTranslation } from "react-i18next";
import {
  getAutorizationFlowHelper,
  getBalanceDaysVacations,
  getEmploymentInfo,
  getEmployeeInTimeClock,
  getFlowVacation,
} from "api";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";

export const VacationWidget = ({ application = "organization" }) => {
  const { t } = useTranslation();
  const { user } = useSelector(authUserSelect);

  const [openNewRequest, setOpenNewRequest] = useState(false);
  const [canRequestVacation, setCanRequestVacation] = useState(false);
  const [flowVacation, setFlowVacation] = useState(null);
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: autorizationFlow } = await getAutorizationFlowHelper(
          user._id,
        );
        const { data: employmentInfo } = await getEmploymentInfo(user._id);
        const { data: timeClock } = await getEmployeeInTimeClock(user._id);
        const { data: dataVacationFlowSettings } = await getFlowVacation();
        const { data: balance } = await getBalanceDaysVacations(user._id);

        const flowVacation = dataVacationFlowSettings.find(
          (v) => employmentInfo.vacationFlowSetting === v._id,
        );

        setTotalDays(balance.balance);
        setFlowVacation(flowVacation);
        setCanRequestVacation(
          autorizationFlow.vacations && timeClock.policy && timeClock.schedule,
        );
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    canRequestVacation && (
      <>
        <Paper
          component={Stack}
          direction="column"
          elevation={3}
          sx={{ width: "100%", height: "100%" }}
          p={2}
          spacing={2}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 1, md: 1 }}
            justifyContent="center"
            alignItems="flex-start"
          >
            <BeachAccessIcon fontSize="large" sx={{ fontSize: 40 }} />
            <Stack spacing={0} mt={2}>
              <Typography>
                {t("request:VacationDaysAllowed")}
                <Typography component="span" color="primary">
                  {totalDays}
                </Typography>
              </Typography>
            </Stack>
            <Stack spacing={0} mt={2}>
              {flowVacation &&
                flowVacation.extraRules.allowExceedingBalance && (
                  <Typography variant="subtitle2">
                    {t("request:HelpAllowExceedingBalance")}
                  </Typography>
                )}
              {flowVacation && flowVacation.extraRules.allowVacationAccrual && (
                <Typography variant="subtitle2">
                  {t("request:HelpAllowVacationAcrual", {
                    maximumAccrualPeriodsDays:
                      flowVacation.extraRules.maximumAccrualPeriodsDays,
                  })}
                </Typography>
              )}
              {flowVacation &&
                flowVacation.extraRules.accountForNonWorkingDays && (
                  <Typography variant="subtitle2">
                    {t("request:HelpAccountForNonWorkingDays")}
                  </Typography>
                )}
              {flowVacation && flowVacation.extraRules.accountForHolidays && (
                <Typography variant="subtitle2">
                  {t("request:HelpAccountForHolidays")}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Button
            variant="contained"
            fullWidth={true}
            onClick={() => setOpenNewRequest(true)}
          >
            {t("request:Solicituddevacaciones")}
          </Button>
          <NewRequestModal
            open={openNewRequest}
            setOpen={setOpenNewRequest}
            application={application}
            typeDocument="vacation"
            saveInRedux={false}
          />
        </Paper>
      </>
    )
  );
};
