import React from 'react';
import { Card, CardContent, Avatar, Typography, Box, createTheme, ThemeProvider } from '@mui/material';
import avatar from "assets/illustrations/avatar.jpeg";
import { QRCodeSVG } from 'qrcode.react';

export const QrCard = ({ fullName, employee, avatarUrl }) => {

  const qrData = `fullname: ${fullName}, employee: ${employee}`

  return (
    <Card sx={{ width: 300, height: 410, textAlign: 'center', padding: 0.5, borderRadius: 0, backgroundColor: "#fff", borderColor: "#fff" }}>
      <CardContent style={{ backgroundColor: "#fff" }}>
        <img style={{ width: '80px', height: '80px'}} src={avatarUrl || avatar} />
        <Typography variant="h6" gutterBottom sx={{ whiteSpace: 'normal', marginTop: fullName.length < 22 && 4 }}>
          {fullName}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ whiteSpace: 'normal', marginBottom: fullName.length < 22 && 4 }}>
          {employee}
        </Typography>
        <Box mt={2}>
          <QRCodeSVG value={qrData} style={{ width: "120px", height: "120px"}}/>
        </Box>
      </CardContent>
    </Card>
  );
};
