import { useEffect, useState } from "react";
import { Button, CircularProgress, Stack, TableCell } from "@mui/material";
import { BasicTable } from "components/Table/BasicTable";
import { PersonAddAlt as PersonAddAltIcon } from "@mui/icons-material";
import { getEmployee, selectingEmployeeList } from "api/employee";
import { useTranslation } from "react-i18next";
import { useCountries } from "hooks";
import { ModalEmployeeWizard } from "./ModalEmployeeWizard";
import { WizardActions } from "./WizardActions";

export const EmployeesWizard = ({ setUpdateWizard }) => {
  useCountries();

  const [open, setOpen] = useState(false);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [updateRows, setUpdateRows] = useState(null);
  const [values, setValues] = useState(null);

  const { t } = useTranslation(["employee"]);

  const onClick = async (row) => {
    try {
      const { data } = await getEmployee("id", row.id);
      setValues(data);
      setOpen(true);
    } catch (err) {
      console.error("Error get employee:", err);
    }
  };

  useEffect(() => {
    const lista = async () => {
      try {
        const { data } = await selectingEmployeeList();
        const rows = data.map((item) => ({
          id: item._id,
          thumbnail: item.thumbnail,
          fullName: item.fullName,
          email: item.email,
          dateOfBirth: item.dateOfBirth,
        }));

        setDataEmployees(rows);
      } catch (error) {
        console.error(error);
      }
    };

    lista();
  }, [updateRows]);

  return (
    <>
      {dataEmployees.length ? (
        <>
          <WizardActions>
            <Button
              variant="contained"
              onClick={() => {
                setValues({
                  names: "",
                  surnames: "",
                  email: "",
                  gender: "",
                  dateOfBirth: "",
                  bloodType: "",
                  nationality: "",
                  maritalStatus: "",
                  hobbies: [],
                  socialSecurityNumber: "",
                  taxIdentificationNumber: "",
                  photo: "",
                });
                setOpen(true);
              }}
            >
              <PersonAddAltIcon />
            </Button>
          </WizardActions>
          <BasicTable rows={dataEmployees} handleClick={onClick} hover={false}>
            <TableCell></TableCell>
            <TableCell>{t("employee:fullName")}</TableCell>
            <TableCell>{t("employee:Email")}</TableCell>
            <TableCell>{t("employee:FechaDeNacimiento")}</TableCell>
          </BasicTable>
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress />
        </Stack>
      )}
      <ModalEmployeeWizard
        open={open}
        setOpen={setOpen}
        values={values}
        canAdd={true}
        canChange={true}
        setUpdateWizard={setUpdateWizard}
        setUpdateRows={setUpdateRows}
      />
    </>
  );
};
