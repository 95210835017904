import { Grid } from "@mui/material";
import { Storage } from "./Storage";
import { Plan } from "./Plan";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "../.././../../redux/slices/tenant";
import { useTranslation } from "react-i18next";
import { MenuNav } from "./MenuNav";

export const PageCurrentPlan = () => {
  const { t } = useTranslation(["organization"]);

  const { currentPlan } = useSelector(tenantSettingsSelect);

  return (
    <>
      <MenuNav ubication={"plan"} />
      <br />
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={6}>
          <Plan plan={currentPlan} label={t("organization:Planes")} />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <Storage />
        </Grid>
      </Grid>
    </>
  );
};
