import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, TableCell } from "@mui/material";
import { BasicTable, UIDatePicker, UIRichText, UITextField } from "components";
import { profileSelect } from "@redux/slices/profile";
import { getEmploymentHistory } from "api";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const FormEmploymentHistory = ({
  formik,
  newData,
  canAdd,
  canChange,
  user = null,
}) => {
  const { t } = useTranslation(["employee"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const [rows, setRows] = useState([]);
  const [responseData, setResponseData] = useState({});
  const disabled = (!canAdd && formik.values?._id === undefined) ||
    (!canChange && formik.values?._id !== undefined) ||
    formik.isSubmitting;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getEmploymentHistory(employeeSelected._id);
        const cleanData = data.map((item) => ({
          id: item._id,
          type: item.companyName,
          number: item.position,
        }));
        setRows(cleanData);
        setResponseData(data);
      } catch (err) {
        console.error("Error getting employment history:", err);
      }
    };

    fetchData();
  }, [employeeSelected, newData]);

  const handleClick = (row) => {
    const historySelected = responseData.find((obj) => obj._id === row.id);
    delete historySelected.createdAt;
    delete historySelected.updatedAt;

    const updatedHistory = {
      ...historySelected,
      startDate: dayjs.utc(historySelected.startDate),
      endDate: dayjs.utc(historySelected.endDate),
    };

    formik.setValues(updatedHistory);
  };

  return (
    <Grid mt={2} container spacing={2}>
      <Grid item={true} xs={12} md={3}>
        <UITextField
          formik={formik}
          label={t("employee:Nombredelacompañía")}
          name={"companyName"}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UIDatePicker
          formik={formik}
          label={t("employee:Fechadeinicio")}
          name={"startDate"}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UIDatePicker
          formik={formik}
          label={t("employee:Fechadetermino")}
          name={"endDate"}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UITextField
          formik={formik}
          label={"Posición"}
          name={"position"}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={12}>
        <UIRichText
          formik={formik}
          label={t("employee:Responsabilidades")}
          name={"responsibilities"}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <BasicTable rows={rows} handleClick={handleClick}>
          <TableCell>{t("employee:Nombredelaempresa")}</TableCell>
          <TableCell>{t("employee:Posición")}</TableCell>
        </BasicTable>
      </Grid>
    </Grid>
  );
};
