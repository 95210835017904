import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  holidays: "",
  jobDescription: {},
};

const jobDescriptionSlice = createSlice({
  name: "jobDescription",
  initialState,
  reducers: {
    setJobDescription: (state, action) => {
      state.jobDescription = action.payload;
    },
  },
});

export const { setJobDescription } = jobDescriptionSlice.actions;

export const jobDescriptionSelect = (state) => state.jobDescription;

export default jobDescriptionSlice.reducer;
