import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";

export const UISelect = ({
  formik,
  label = "",
  name = "",
  options = [],
  nameFileArray = "",
  index = null,
  isMulti = false,
  isrequired = false,
  readOnly = false,
  disabled = false,
}) => {
  let value_selected = nameFileArray === ""
    ? formik.values[name]
    : formik.values[nameFileArray][index][name];
  let option_selected = (value_selected === false || !value_selected)
    ? null
    : options.find((option) => {
      return option.value === value_selected;
    });
  const [inputValue, setInputValue] = useState(null);

  return (
    <Box>
      <FormControl fullWidth>
        <Autocomplete
          id={`demo-simple-select-${name}`}
          value={option_selected}
          options={options}
          disableClearable={true}
          renderInput={(params) => (
            <TextField {...params} label={label} required={isrequired} />
          )}
          isOptionEqualToValue={(o, v) => (o.value === v.value)}
          getOptionLabel={(o) => o?.label}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            let index_name = index !== null
              ? `${nameFileArray}.${index}.${name}`
              : name;
            formik.setFieldValue(index_name, newValue.value);
          }}
          readOnly={readOnly}
          disabled={disabled}
          sx={{
            required: true,
          }}
        />
      </FormControl>
    </Box>
  );
};

UISelect.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameFileArray: PropTypes.string,
  index: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isrequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
