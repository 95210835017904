import { useField, useFormikContext } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { TextField } from "@mui/material";
import { convertUTCToLocal } from "helpers";

import dayjs from "dayjs";

const today = dayjs().startOf("day");

const shouldDisableDate = (date) => {
  return date.isBefore(today, "day");
};

const shouldDisableDateAndToday = (date) => {
  return date.isBefore(today, "day") || date.isSame(today, "day");
};

const shouldDisableFutureDates = (date) => {
  return date.isAfter(today, "day") || date.isSame(today, "day");
};

const shouldDisable = (customDisabled) => {
  if (customDisabled === "Before") {
    return shouldDisableDate;
  }

  if (customDisabled === "After") {
    return shouldDisableFutureDates;
  }

  if (customDisabled === "BeforeAndToday") {
    return shouldDisableDateAndToday;
  }

  return null;
};

export const UIDateTimePicker = ({
  name,
  label,
  customDisabled = "",
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (value) => {
    setFieldValue(name, value);
  };

  const configDateTimePicker = {
    ...field,
    ...otherProps,
    onChange: handleChange,
    value: (field.value && convertUTCToLocal(field.value)) || null,
    renderInput: (params) => <TextField {...params} />,
    shouldDisableDate: shouldDisable(customDisabled),
    format: "YYYY/MM/DD HH:mm",
  };

  if (meta && meta.touched && meta.error) {
    configDateTimePicker.error = true;
    configDateTimePicker.helperText = meta.error;
  }

  return (
    <DateTimePicker
      label={label}
      sx={{ width: "100% !important" }}
      viewRenderers={{
        hours: renderTimeViewClock,
        minutes: renderTimeViewClock,
        seconds: renderTimeViewClock,
      }}
      {...configDateTimePicker}
    />
  );
};
