import { useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import { UITextField, TagInput, FilePreview } from "components";

export const FormDocument = ({ formik, srcPath }) => {
  const { t } = useTranslation(["employee"]);

  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState(null);

  return (
    <Grid container spacing={1} flexDirection={{ xs: "column", md: "row" }}>
      <Grid item="true" md={6}>
        <Grid
          container
          spacing={2}
          flexDirection={{ xs: "column", md: "column" }}
        >
          <Grid item="true">
            <UITextField
              formik={formik}
              label={t("employee:Nameofdocument")}
              name={"name"}
              disabled={formik.isSubmitting}
            />
          </Grid>
          <Grid item="true">
            <TagInput
              formik={formik}
              label={t("employee:Labels")}
              name={"labels"}
              disabled={formik.isSubmitting}
            />
          </Grid>
          <Grid item="true">
            <UITextField
              formik={formik}
              label={t("employee:Descriptionofdocument")}
              name={"description"}
              disabled={formik.isSubmitting}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item="true" md={6}>
        <FilePreview
          formik={formik}
          name={"filePath"}
          src={src}
          setSrc={setSrc}
          open={open}
          setOpen={setOpen}
          srcPath={srcPath}
          lblBtnUpdate={t("employee:UploadFile")}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
