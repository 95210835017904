import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Field } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "./FormCheckIn";

export const FormLunchTime = ({ formik }) => {
  const { t } = useTranslation(["general"]["shiftAttendance"]);
  const [notUseLuchTime, setNotUseLuchTime] = useState(
    formik.values.lunchTime.notUseLuchTime,
  );

  return (
    <Stack spacing={2}>
      <FormControl>
        <FormLabel id={"notUseLuchTime-label"}>
          {t("shiftAttendance:notUseLuchTime")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="notUseLuchTime-label"
          name="lunchTime.notUseLuchTime"
          value={notUseLuchTime}
          onChange={(event) => {
            setNotUseLuchTime(event.target.value);
            formik.setFieldValue(
              "lunchTime.notUseLuchTime",
              event.target.value,
            );
          }}
        >
          <FormControlLabel
            value="TIME_IN_FAVOR"
            control={<Radio />}
            label={t("shiftAttendance:TimeInFavor")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="OVERTIME"
            control={<Radio />}
            label={t("shiftAttendance:Overtime")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="AUTO_RECORD"
            control={<Radio />}
            label={t("shiftAttendance:AutoRecord")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:AllowToScrollMealTime")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <Field
        name={"lunchTime.toleranceTime"}
        label={t("shiftAttendance:LunchToleranceTime")}
        component={CustomTextField}
        type={"number"}
        required={true}
        disabled={formik.isSubmitting}
        helperText={t("shiftAttendance:LunchToleranceTimeHelpText")}
      />
    </Stack>
  );
};
