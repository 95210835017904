import { Grid } from "@mui/material";
import {
  UISelect,
  UITextField,
  UIDatePicker,
  TagInput,
  FilePreview,
} from "components";
import { useState } from "react";
import {
  BLOOD_TYPE_OPTIONS,
  CIVIL_STATUS_OPTIONS,
  GENDER_OPTIONS,
} from "utils";
import { useSelector } from "react-redux";
import { generalSelect } from "@redux/slices/general";
import { useTranslation } from "react-i18next";
import { useCountries } from "hooks";
import { translateSelectors } from "helpers";

export const FormPersonalInformation = ({ formik, canAdd, canChange }) => {
  const { t } = useTranslation(["employee"]["options"]);

  useCountries();
  const { countries } = useSelector(generalSelect);
  const [src, setSrc] = useState(null);
  const disabled = (!canAdd && !canChange) || formik.isSubmitting;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item={true} xs={12} md={4}>
          <FilePreview
            src={src}
            setSrc={setSrc}
            lblBtnUpdate={t("employee:CargarFotoDePerfil")}
            isAvatar={true}
            formik={formik}
            name={"photo"}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={4}>
              <UITextField
                formik={formik}
                label={t("employee:Nombres")}
                name={"names"}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UITextField
                formik={formik}
                label={t("employee:Apellidos")}
                name={"surnames"}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UITextField
                formik={formik}
                label={t("employee:Email")}
                name={"email"}
                type={"email"}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelect
                formik={formik}
                label={t("employee:EstadoCivil")}
                name={"maritalStatus"}
                options={translateSelectors(t, CIVIL_STATUS_OPTIONS)}
                disabled={disabled}
                isrequired={true}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UIDatePicker
                formik={formik}
                label={t("employee:FechaDeNacimiento")}
                name={"dateOfBirth"}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UITextField
                formik={formik}
                label={t("employee:NúmeroDeSeguridadSocial")}
                name={"socialSecurityNumber"}
                disabled={disabled}
                isrequired={false}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelect
                formik={formik}
                label={t("employee:Genero")}
                name={"gender"}
                options={translateSelectors(t, GENDER_OPTIONS)}
                disabled={disabled}
                isrequired={true}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelect
                formik={formik}
                label={t("employee:TipoDeSangre")}
                name={"bloodType"}
                options={BLOOD_TYPE_OPTIONS}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelect
                formik={formik}
                label={t("employee:PaísDeOrigen")}
                name={"nationality"}
                options={countries}
                disabled={disabled}
                isrequired={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={2}>
              <UITextField
                formik={formik}
                label={t("employee:employeeNumber")}
                name={"number"}
                disabled={true}
              />
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <UITextField
                formik={formik}
                label={t("employee:IDImpuestos")}
                name={"taxIdentificationNumber"}
                disabled={disabled}
                isrequired={false}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <TagInput
                formik={formik}
                label={t("employee:Pasatiempos")}
                name={"hobbies"}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
