import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
} from "@mui/material";
import { Field } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomTextField } from "./FormCheckIn";

export const FormCheckOut = ({ formik }) => {
  const { t } = useTranslation(["general"]["shiftAttendance"]);
  const [afterCheckOutTime, setAfterCheckOutTime] = useState(
    formik.values.checkOut.afterCheckOutTime,
  );
  const [timeWorkedAfterHours, setTimeWorkedAfterHours] = useState(
    formik.values.checkOut.timeWorkedAfterHours,
  );
  const [leavesBeforeCheckOutTime, setleavesBeforeCheckOutTime] = useState(
    formik.values.checkOut.leavesBeforeCheckOutTime,
  );
  const [overtimeRequired, setOvertimeRequired] = useState(
    formik?.values?.checkOut.leavesBeforeCheckOutTime ===
      "EARLY_DEPARTURE",
  );

  return (
    <Stack spacing={2}>
      <FormControl>
        <FormLabel id={"afterCheckOutTime-label"}>
          {t("shiftAttendance:afterCheckOutTime")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="afterCheckOutTime-label"
          name="checkOut.afterCheckOutTime"
          value={afterCheckOutTime}
          onChange={(event) => {
            setAfterCheckOutTime(event.target.value);
            formik.setFieldValue(
              "checkOut.afterCheckOutTime",
              event.target.value,
            );
          }}
        >
          <FormControlLabel
            value="KEEP_THE_ACTUAL_DEPARTURE_TIME"
            control={<Radio />}
            label={t("shiftAttendance:KeepTheActualDepartureTime")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="ASSIGN_ENTRY_TIME_DEFINED_IN_SCHEDULE"
            control={<Radio />}
            label={t("shiftAttendance:AssignEntryTimeDefinedInSchedule")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id={"timeWorkedAfterHours-label"}>
          {t("shiftAttendance:timeWorkedAfterHours")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="timeWorkedAfterHours-label"
          name="checkOut.timeWorkedAfterHours"
          value={timeWorkedAfterHours}
          onChange={(event) => {
            setTimeWorkedAfterHours(event.target.value);
            formik.setFieldValue(
              "checkOut.timeWorkedAfterHours",
              event.target.value,
            );
          }}
        >
          <FormControlLabel
            value="TIME_IN_FAVOR"
            control={<Radio />}
            label={t("shiftAttendance:TimeInFavor")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="OVERTIME"
            control={<Radio />}
            label={t("shiftAttendance:Overtime")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id={"leavesBeforeCheckOutTime-label"}>
          {t("shiftAttendance:leavesBeforeCheckOutTime")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="leavesBeforeCheckOutTime-label"
          name="checkOut.leavesBeforeCheckOutTime"
          value={leavesBeforeCheckOutTime}
          onChange={(event) => {
            setleavesBeforeCheckOutTime(event.target.value);
            formik.setFieldValue(
              "checkOut.leavesBeforeCheckOutTime",
              event.target.value,
            );

            if (event.target.value !== "EARLY_DEPARTURE") {
              setOvertimeRequired(false);
              formik.setFieldValue("checkOut.toleranceTime", 0);
            } else {
              setOvertimeRequired(true);
            }
          }}
        >
          <FormControlLabel
            value="EARLY_DEPARTURE"
            control={<Radio />}
            label={t("shiftAttendance:EarlyDeparture")}
            disabled={formik.isSubmitting}
          />
          {overtimeRequired && (
            <Field
              name={"checkOut.toleranceTime"}
              label={t("shiftAttendance:EarlyDepartureToleranceTime")}
              component={CustomTextField}
              type={"number"}
              required={true}
              disabled={formik.isSubmitting}
            />
          )}
          <FormControlLabel
            value="ASSIGN_ENTRY_TIME_DEFINED_IN_SCHEDULE"
            control={<Radio />}
            label={t("shiftAttendance:AssignEntryTimeDefinedInSchedule")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name={"checkOut.autoRegister"}
              disabled={formik.isSubmitting}
              checked={formik.values.checkOut.autoRegister}
              onChange={() =>
                formik.setFieldValue(
                  "checkOut.autoRegister",
                  !formik.values.checkOut.autoRegister,
                )}
            />
          }
          label={t("shiftAttendance:AutoRegisterCheckOut")}
        />
      </FormGroup>
    </Stack>
  );
};
