import { getLocations } from "api";
import { useDispatch } from "react-redux";
import { generalSelect, setLocations } from "../redux/slices/general";
import { useSelector } from "react-redux";

export function useLocations() {
  const dispatcher = useDispatch();
  const { locations } = useSelector(generalSelect);

  const refreshLocationsSeleectors = async (refresh = false) => {
    if (
      locations?.length === 0 ||
      !locations ||
      locations === undefined ||
      refresh
    ) {
      try {
        const { data } = await getLocations("selectors");
        dispatcher(setLocations(data));
      } catch (err) {
        console.error("Error getting locations:", err);
      }
    }
  };

  return { refreshLocationsSeleectors };
}
