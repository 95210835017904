export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "100vw",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",

  "@media (max-width: 600px)": {
    width: "100vw !important",
    height: "100vh !important",
    maxHeight: "100vh !important",
    transform: "translate(0, 0)",
    top: 0,
    left: 0,
  },
};
