import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { setCurrentGeolocation } from "@redux/slices/ui";
import edificios from "assets/illustrations/edificios.jpeg";
import dayjs from "dayjs";

export const Weather = () => {
  const dispatch = useDispatch();

  const [weatherData, setWeatherData] = useState(null);
  const [iconUrl, setIconUrl] = useState(null);

  useEffect(() => {
    const successHandler = (position) => {
      const { latitude, longitude } = position.coords;
      dispatch(setCurrentGeolocation({ lat: latitude, lng: longitude }));

      const fetchData = async () => {
        const appid = process.env.REACT_APP_OPEN_WEATHER_MAP_KEY;
        try {
          const baseUrl = "https://api.openweathermap.org/data/2.5/weather";
          const apiUrl = `${baseUrl}?lat=${latitude}&lon=${longitude}&appid=${appid}`;
          const response = await fetch(apiUrl);

          const data = await response.json();
          setWeatherData(data);

          const iconCode = data?.weather[0]?.icon;
          setIconUrl(`https://openweathermap.org/img/wn/${iconCode}@2x.png`);
        } catch (error) {
          console.error("Hubo un problema con la solicitud:", error);
        }
      };

      fetchData();
    };

    const errorHandler = (error) => {
      console.error("Error getting geolocation:", error);
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    } else {
      console.error("Geolocation is not supported by this browser");
    }
  }, []);

  return (
    <Paper elevation={3} sx={{ width: "100%", height: "100%" }}>
      <Grid container sx={{ width: "100%", height: "100%" }}>
        <Grid item xs={4}>
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${edificios})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Grid>
        <Grid item xs={8}>
          {weatherData !== null ? (
            <Box p={2} pb={0}>
              <Typography variant="h6" gutterBottom>
                {weatherData?.name} - {weatherData?.sys?.country}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {dayjs().format("DD MMM YYYY")}
              </Typography>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: "center",
                  marginBottom: "0px",
                }}
              >
                <img src={iconUrl} alt="Ícono del clima" />
                <b>{Math.round(weatherData?.main?.temp - 273.15)} °C</b>
              </Typography>
            </Box>
          ) : (
            <Box
              p={2}
              sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
