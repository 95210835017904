import { useEffect, useState } from "react";
import { selectingEmployeeList, getVacancyReasons, getJobPositions } from "api";
import { setVacancyReasons } from "@redux/slices/recruitment";
import { useDispatch } from "react-redux";

export const useRequests = () => {
  const dispatch = useDispatch();

  const [employees, setEmployees] = useState(null);
  const [vacancyReasons, setListVacancyReasons] = useState(null);
  const [jobsProfile, setJobsProfile] = useState(null);

  useEffect(() => {
    const listEmployees = async () => {
      try {
        const { data } = await selectingEmployeeList();
        setEmployees(data.reduce((res, i) => ((res[i._id] = i), res), {}));
      } catch (e) {
        console.log("Error get employees: ", e);
      }
    };

    listEmployees();
  }, []);

  useEffect(() => {
    const listVacancyReasons = async () => {
      try {
        const { data } = await getVacancyReasons(null);
        setListVacancyReasons(
          data.reduce((res, i) => ((res[i.value] = i.label), res), {}),
        );
        dispatch(setVacancyReasons(data));
      } catch (e) {
        console.log("error get vacancies reseasons: ", e);
      }
    };

    listVacancyReasons();
  }, []);

  useEffect(() => {
    const listJobsProfile = async () => {
      try {
        const { data } = await getJobPositions(null);
        setJobsProfile(
          data.reduce((res, i) => ((res[i._id] = i.title), res), {}),
        );
      } catch (e) {
        console.log("Error get jobs profile: ", e);
      }
    };
    listJobsProfile();
  }, []);

  return { employees, vacancyReasons, jobsProfile };
};
