import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generalSelect } from "@redux/slices/general";
import { FieldArray } from "formik";
import { UIButton, UISelectFast } from "components";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LEVELS } from "utils/constants";
import { translateSelectors } from "helpers";
import {
  JobDescriptionDisabled,
  JobDescriptionEnabledButtons,
} from "./JobDescriptionDisabled";

export const FormJobLanguages = ({
  formik,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["recruitment"]["options"]);
  const { languages } = useSelector(generalSelect);
  const fieldArrayName = "profile.languages";

  const disabled = JobDescriptionDisabled({ canAdd, canChange, formik });
  const buttons = JobDescriptionEnabledButtons({ canAdd, canChange, formik });

  return (
    <Box mt={2}>
      <FieldArray name={fieldArrayName}>
        {({ push, remove }) => (
          <Box>
            <Grid container spacing={2} mb={2}>
              <Grid item={true} xs={12} md={11}>
                <Typography variant="h5">
                  {t("recruitment:Languages")}
                </Typography>
              </Grid>
              {buttons && (
                <Grid item={true} xs={12} md={1}>
                  <UIButton
                    onClick={() =>
                      push({
                        language: "",
                        fluencyLevel: "",
                      })}
                    disabled={formik.isSubmitting}
                  >
                    <AddCircleOutlineIcon />
                  </UIButton>
                </Grid>
              )}
            </Grid>
            {formik.values?.profile.languages.map((language, index) => (
              <Grid container={true} spacing={2} mb={2} key={index}>
                <Grid item={true} xs={12} md={4}>
                  <UISelectFast
                    label={t("recruitment:Language")}
                    name={`${fieldArrayName}.${index}.language`}
                    options={translateSelectors(t, languages)}
                    disabled={disabled}
                    required={true}
                  />
                </Grid>
                <Grid item={true} xs={12} md={4}>
                  <UISelectFast
                    label={t("recruitment:Level")}
                    name={`${fieldArrayName}.${index}.fluencyLevel`}
                    options={translateSelectors(t, LEVELS)}
                    disabled={disabled}
                    required={true}
                  />
                </Grid>
                {buttons && (
                  <Grid item={true} xs={12} md={1}>
                    <UIButton
                      onClick={() => remove(index)}
                      disabled={formik.isSubmitting}
                    >
                      <DeleteIcon />
                    </UIButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Box>
        )}
      </FieldArray>
    </Box>
  );
};
