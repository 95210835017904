import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UISelectFast, UISwitchFast, UITagFast, UITextFast } from "components";
import { FastField } from "formik";
import { JobDescriptionDisabled } from "./JobDescriptionDisabled";

export const FormJobProfile = ({
  formik,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["employee"]["general"]);
  const haveExperience = formik.values?.profile.experience.haveExperience;

  const disabled = JobDescriptionDisabled({ canAdd, canChange, formik });

  const genders = [
    { value: "MALE", label: t("recruitment:MALE") },
    { value: "FEMALE", label: t("recruitment:FEMALE") },
    { value: "INDISCRIMINATE", label: t("recruitment:INDISCRIMINATE") },
  ];

  const maritalStatus = [
    { value: "SINGLE", label: t("recruitment:SINGLE") },
    { value: "MARRIED", label: t("recruitment:MARRIED") },
    { value: "DIVORCED", label: t("recruitment:DIVORCED") },
    { value: "WIDOWED", label: t("recruitment:WIDOWED") },
    { value: "INDISCRIMINATE", label: t("recruitment:INDISCRIMINATE") },
  ];

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={3}>
        <UISelectFast
          label={t("recruitment:Gender")}
          name="profile.gender"
          disabled={disabled}
          required={true}
          options={genders}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UISelectFast
          label={t("recruitment:MaritalStatus")}
          name="profile.maritalStatus"
          disabled={disabled}
          required={true}
          options={maritalStatus}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UITextFast
          type="number"
          label={t("recruitment:MinAge")}
          name="profile.minAge"
          disabled={disabled}
          required={true}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UITextFast
          type="number"
          label={t("recruitment:MaxAge")}
          name="profile.maxAge"
          disabled={disabled}
          required={true}
        />
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UISwitchFast
          name={"profile.experience.haveExperience"}
          label={t("recruitment:HaveExperience")}
          disabled={disabled}
          onChange={(e) => {
            formik.setFieldValue(e.target.name, e.target.checked);

            if (!e.target.checked) {
              formik.setFieldValue(
                "profile.experience.howManyYearsOfExperience",
                0,
              );
            }
          }}
        />
      </Grid>
      {haveExperience && (
        <Grid item={true} xs={12} md={2}>
          <UITextFast
            name={"profile.experience.howManyYearsOfExperience"}
            label={t("recruitment:HowManyYearsOfExperience")}
            disabled={disabled}
            type="number"
            required={haveExperience}
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
      )}
      <Grid item={true} xs={12} md={12}>
        <FastField
          label={t("recruitment:TrainingIn")}
          name="profile.trainingIn"
          disabled={disabled}
          component={UITagFast}
        />
      </Grid>
      <Grid item={true} xs={12} md={12}>
        <FastField
          label={t("recruitment:SpecializedKnowledge")}
          name="profile.specializedKnowledge"
          disabled={disabled}
          component={UITagFast}
        />
      </Grid>
    </Grid>
  );
};
