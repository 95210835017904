import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Activar los plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Función para convertir UTC a la hora local
export function convertUTCToLocal(utcDateString) {
  // Analizar la fecha UTC
  const utcDate = dayjs.utc(utcDateString);

  // Convertir a la hora local del navegador
  const localDate = utcDate.local();

  return localDate;
}
