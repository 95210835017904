import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";

import "./styles.css";

export const ShowForm = ({ selectedManual }) => {
  return (
    <>
      <Box className="form-group-map">
        <TextField
          type="text"
          variant="outlined"
          label={"Address"}
          placeholder={"Cl. 6b Este #11-35"}
          value={selectedManual !== null ? selectedManual.address : " "}
          InputProps={{ readOnly: true }}
        />
        <TextField
          type="text"
          variant="outlined"
          label={"latitude"}
          placeholder={"4.808161464039251"}
          value={selectedManual !== null ? selectedManual.lat : " "}
          InputProps={{ readOnly: true }}
        />
        <TextField
          type="text"
          variant="outlined"
          label={"longitude"}
          placeholder={"-75.67394984643555"}
          value={selectedManual !== null ? selectedManual.lng : " "}
          InputProps={{ readOnly: true }}
        />
      </Box>
    </>
  );
};

ShowForm.propTypes = {
  selectedManual: PropTypes.object,
};
