import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Wizard from "./app/modules/Wizard";
import { createTheme, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { authUserSelect } from "@redux/slices/authUser";

const App = () => {
  const { mainColor, mode } = useSelector(parameterizationSelect);
  const { tenant } = useSelector(tenantSettingsSelect);
  const { keycloakProfile } = useSelector(
    authUserSelect,
  );

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mainColor,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {keycloakProfile?.is_superuser && !tenant?.wizard?.completed
          ? <Route path="/wizard" element={<Wizard />} />
          : (
            <Route
              path="*"
              element={<Navigate to="/cereza-organization/home/indicators" />}
            />
          )}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
