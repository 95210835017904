import { useTranslation } from "react-i18next";
import { UIButton, UITextField, UISelect, SocialNetworksBtn } from "components";
import { Box, Grid, Typography } from "@mui/material";
import { FieldArray } from "formik";
import { SOCIAL_NETWORKS } from "utils";
import { Fragment } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";

export const SocialNetworks = ({
  formik,
  nameFileArray,
  values,
  md = 7,
  canAdd,
  canChange,
  canDelete,
}) => {
  const { t } = useTranslation(["employee"]);
  const disabled = (!canAdd && !canChange) || formik.isSubmitting;

  return (
    <>
      <Box>
        <FieldArray name={nameFileArray}>
          {({ push, remove }) => (
            <Box>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={11}>
                  <Typography variant="h5">
                    {t("employee:RedesSociales")}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12} md={1}>
                  {canAdd && (
                    <UIButton
                      onClick={() =>
                        push({
                          socialNetwork: "",
                          url: "",
                        })
                      }
                      disabled={disabled}
                    >
                      <AddCircleOutlineIcon />
                    </UIButton>
                  )}
                </Grid>
              </Grid>
              <br />
              {values?.map((value, index) => (
                <Fragment key={index}>
                  <Grid container spacing={2} key={index}>
                    <Grid item={true} xs={12} md={11}>
                      <Grid container spacing={2}>
                        <Grid item={true} xs={12} md={1}>
                          <SocialNetworksBtn
                            red={
                              formik.values[nameFileArray][index][
                                "socialNetwork"
                              ]
                            }
                            url={formik.values[nameFileArray][index]["url"]}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <UISelect
                            formik={formik}
                            label={t("employee:RedSocial")}
                            name={"socialNetwork"}
                            nameFileArray={nameFileArray}
                            index={index}
                            options={SOCIAL_NETWORKS}
                            disabled={disabled}
                          />
                        </Grid>
                        <Grid item={true} xs={12} md={md}>
                          <UITextField
                            formik={formik}
                            label={t("employee:URL")}
                            name={"url"}
                            nameFileArray={nameFileArray}
                            index={index}
                            disabled={disabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item={true} xs={12} md={1}>
                      {canDelete && (
                        <UIButton
                          onClick={() => remove(index)}
                          disabled={disabled}
                          sx={{
                            height: "100%",
                          }}
                        >
                          <DeleteIcon />
                        </UIButton>
                      )}
                    </Grid>
                  </Grid>
                  <br />
                </Fragment>
              ))}
            </Box>
          )}
        </FieldArray>
      </Box>
    </>
  );
};
