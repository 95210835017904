import { ProfileTabs, TitleBox, UIButton, UIPaper } from "components";
import { useAccess } from "hooks";
import { Watches } from "./Watches";
import { profileSelect } from "@redux/slices/profile";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import { FacialRecognition } from "./FacialRecognition";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { pathEmployee, isFormerEmployee, getProfileReturnUri } from "helpers";

export const TimeClock = ({ application = "organization", user = null }) => {
  const navigate = useNavigate();
  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const { HasPermissions } = useAccess();
  const { canAccess: canAccessClocks, canChange: canChangeClocks } =
    isFormerEmployee(HasPermissions(
      pathEmployee(application, "/profile/time-clocks"),
      application,
    ),
      employeeSelected
    );

  const {
    canAccess: canAccessApparences,
    canAdd: canAddApparences,
    canChange: canChangeApparences,
    canDelete: canDeleteApparences,
  } =
    isFormerEmployee(HasPermissions(
      pathEmployee(application, "/profile/facial-recognition"),
      application,
    ),
      employeeSelected
    );
  const returnUri = getProfileReturnUri(employeeSelected);

  return (
    <>
      <ProfileTabs tab_initial="time-clock" />
      <UIPaper>
        <Box pt={2} pb={2} m={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
            isRed={!!employeeSelected?.formerEmployee}
          />
          {canAccessClocks && (
            <>
              <Watches
                employeeSelected={employeeSelected._id}
                canChange={canChangeClocks}
              />
            </>
          )}
          {canAccessApparences && (
            <FacialRecognition
              canAdd={canAddApparences}
              canChange={canChangeApparences}
              canDelete={canDeleteApparences}
              employeeSelected={employeeSelected}
            />
          )}
          {application === "organization" && (
            <Stack
              mt={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <UIButton
                variant="contained"
                startIcon={<ChevronLeftIcon />}
                onClick={() =>
                  navigate(returnUri)
                }
                fullWidth={false}
              />
            </Stack>
          )}
        </Box>
      </UIPaper>
    </>
  );
};
