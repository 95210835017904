import { Grid } from "@mui/material";
import SelectingTable from "components/Table/SelectingTable";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UIButton } from "components";
import { setPolicy } from "@redux/slices/attendance-absence";
import { getPolicy } from "api";
import { setAllPolices } from "@redux/slices/attendance-absence";

export const Polices = () => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const dispatcher = useDispatch();

  const [rows, setRows] = useState([]);
  const [reponseData, setResponseData] = useState([]);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const headCells = [
    {
      id: "name",
      label: t("shiftAttendance:name"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "description",
      label: t("shiftAttendance:Description"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "isActive",
      label: t("shiftAttendance:Active"),
      numeric: false,
      disablePadding: true,
    },
  ];

  useEffect(() => {
    const list = async () => {
      try {
        const { data } = await getPolicy(null);
        dispatcher(setAllPolices(data));
        setResponseData(data);
        setRows(
          data.map((e) => ({
            id: e._id,
            name: e.name,
            description: e.description,
            isActive: e.isActive === true ? t("general:Si") : t("general:No"),
          })),
        );
      } catch (e) {
        console.log(e);
      }
    };

    list();
  }, []);

  const actionOnClick = async (row) => {
    try {
      let data = reponseData.find((e) => row.id === e._id);
      toPolicy(data);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const toPolicy = (policy) => {
    dispatch(setPolicy(policy));
    navigate("/cereza-organization/shift-attendance/policy");
  };

  return (
    <Grid container={true}>
      <Grid item={true} xs={12} md={12}>
        <SelectingTable
          headCells={headCells}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
          name={t("shiftAttendance:Polices")}
          actionOnClick={actionOnClick}
        >
          <Grid
            container={true}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item={true} xs={12} md={3}>
              <UIButton
                label={t("shiftAttendance:New")}
                onClick={() => {
                  toPolicy({
                    name: "",
                    description: "",
                    isActive: true,
                    attendance: {
                      notAttendOnAWorkday: "",
                      attendOnAWorkday: "",
                    },
                    checkIn: {
                      preEntryTime: "",
                      workingTimeBeforeSchedule: "",
                      arrivesAfterTheStartingTime: "",
                      toleranceTime: 0,
                      autoRegister: false,
                    },
                    lunchTime: {
                      notUseLuchTime: "",
                    },
                    checkOut: {
                      afterCheckOutTime: "",
                      timeWorkedAfterHours: "",
                      leavesBeforeCheckOutTime: "",
                      autoRegister: false,
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        </SelectingTable>
      </Grid>
    </Grid>
  );
};
