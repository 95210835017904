import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { UIButton } from "components";
import SelectingTable from "components/Table/SelectingTable";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHolidays } from "@redux/slices/time-off";
import { getHolidays, getCountries } from "api";
import { generalSelect, setCountriesCompleteData } from "@redux/slices/general";
import { useSelector } from "react-redux";
import { useCountries } from "hooks";
import { countryFlagLabel } from "helpers";

export const TimeOff = () => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const [rows, setRows] = useState([]);
  const [reponseData, setResponseData] = useState([]);
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const { countriesCompleteData } = useSelector(generalSelect);
  const dispatch = useDispatch();

  const headCells = [
    {
      id: "name",
      label: t("shiftAttendance:name"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "description",
      label: t("shiftAttendance:Description"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "country",
      label: t("shiftAttendance:Country"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "isVisible",
      label: t("shiftAttendance:ShowInCalendar"),
      numeric: false,
      disablePadding: true,
    },
  ];

  useCountries();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await getCountries(false);
        dispatch(setCountriesCompleteData(data));
      } catch (err) {
        console.error("Error getting countries:", err);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const list = async () => {
      try {
        const { data } = await getHolidays(null);
        setResponseData(data);
        setRows(
          data.map((e) => ({
            id: e._id,
            name: e.name,
            description: e.description,
            country: countryFlagLabel(
              countriesCompleteData.find((c) => c._id === e.country)
            ),
            isVisible: e.isVisible === true ? t("genera:Si") : t("general:No"),
          }))
        );
      } catch (e) {
        console.log(e);
      }
    };

    list();
  }, [countriesCompleteData]);

  const actionOnClick = async (row) => {
    try {
      let data = reponseData.find((e) => row.id === e._id);
      toHolidays(data);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const toHolidays = (holidays) => {
    dispatch(setHolidays(holidays));
    navigate(
      "/cereza-organization/shift-attendance/time-off-holidays/holidays"
    );
  };

  return (
    <Grid container={true}>
      <Grid item={"true"} xs={12} md={12}>
        <SelectingTable
          headCells={headCells}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
          name={t("shiftAttendance:Calendars")}
          actionOnClick={actionOnClick}
        >
          <Grid
            container={true}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item={"true"} xs={12} md={12}>
              <UIButton
                label={t("shiftAttendance:New")}
                onClick={() => {
                  toHolidays({
                    name: "",
                    description: "",
                    country: "",
                    isVisible: true,
                    holidays: [],
                  });
                }}
              />
            </Grid>
          </Grid>
        </SelectingTable>
      </Grid>
      <Grid item={"true"} xs={12} md={9}></Grid>
    </Grid>
  );
};
