import {
  Box,
  Chip,
  Grid,
  Tooltip,
  TableCell,
  TextField,
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Toast, UIButton, BasicTable, UIDatePicker } from "components";
import { Formik, Form } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getGeneralReports, getAttendance } from "api";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { ModalJustify } from "./ModalJustify";
import { generalSelect } from "@redux/slices/general";
import { policesSelect } from "@redux/slices/attendance-absence";
import { workSchedulesSelect } from "@redux/slices/work-schedules";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx-js-style";

import HeightIcon from "@mui/icons-material/Height";
import dayjs from "dayjs";
import _ from "lodash";

const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

export const AttendanceReports = ({ application = "organization" }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const { user } = useSelector(authUserSelect);

  const [alignment, setAlignment] = useState(
    application === "organization" ? "all" : "onlyMy",
  );
  const [reports, setReports] = useState([]);
  const [optionsSelector, setOptionsSelector] = useState([]);
  const [filter, setFilter] = useState([]);
  const [sorting, setSorting] = useState("ASC");
  const [sortType, setSortType] = useState("EMPLOYEE");
  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState({});

  const { allPolices } = useSelector(policesSelect);
  const { allWorkSchedules } = useSelector(workSchedulesSelect);
  const { countries } = useSelector(generalSelect);

  const handleChangeSort = (event) => {
    const direction =
      event !== sortType ? "ASC" : sorting === "ASC" ? "DESC" : "ASC";
    setSorting(direction);
    setSortType(event);
    const employees = value.map((item) => item?._id);
    setFilter(
      createDataFilter({ data: reports, orderBy: event, direction, employees }),
    );
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const dateTimeFormat = (date) => {
    return date ? dayjs(date).format("LLL") : date;
  };

  const sortData = ({
    filterData,
    orderBy = "EMPLOYEE",
    direction = "ASC",
  }) => {
    if (orderBy === "EMPLOYEE") {
      filterData = filterData.sort((a, b) =>
        direction === "DESC"
          ? a.fullName < b.fullName
          : a.fullName > b.fullName,
      );
    }

    if (orderBy === "DATE") {
      filterData = filterData.sort((a, b) => {
        return direction === "DESC"
          ? dayjs(a.date).isBefore(dayjs(b.date))
          : dayjs(b.date).isBefore(dayjs(a.date));
      });
    }

    return filterData;
  };

  const createDataFilter = ({
    data,
    employees = [],
    orderBy = "EMPLOYEE",
    direction = "ASC",
  }) => {
    let filterData = _.map(_.flatten(_.values(data)), (obj) => ({
      id: obj._id,
      thumbnail: obj.thumbnail,
      employee: obj.employee,
      fullName: obj.fullName,
      date: obj.date,
      checkIn: dateTimeFormat(obj.checkIn),
      realCheckIn: dateTimeFormat(obj.realCheckIn),
      differenceCheckIn: obj.differenceCheckIn,
      checkInDelay: obj.checkInDelay,
      checkOut: dateTimeFormat(obj.checkOut),
      realCheckOut: dateTimeFormat(obj.realCheckOut),
      differenceCheckOut: obj.differenceCheckOut,
      workedHours: obj.workedHours,
      timeIn: obj.timeIn,
      timeOut: obj.timeOut,
      assistance: t(`recruitment:${obj.assistance}`),
      checks: obj.checks,
      country: obj.country,
      lunchStart: dateTimeFormat(obj.lunchStart),
      lunchEnd: dateTimeFormat(obj.lunchEnd),
      lunchTime: obj.lunchTime,
      policy: obj.policy,
      schedule: obj.schedule,
      assistanceColor: obj.assistance,
    }));

    filterData = employees.length
      ? _.filter(filterData, (e) => employees.includes(e.employee))
      : filterData;

    filterData = sortData({ filterData, orderBy, direction });

    return filterData;
  };

  const handleSubmit = async ({ dateFrom, dateTo }) => {
    setOptionsSelector([]);
    setFilter([]);
    setReports([]);
    setValue([]);

    try {
      const { data } =
        alignment === "all"
          ? await getGeneralReports(dateFrom, dateTo)
          : await getAttendance(user?._id, dateFrom, dateTo);

      if (alignment === "all") {
        setOptionsSelector(
          _.orderBy(
            _.uniqBy(
              _.map(_.flatten(_.values(data)), (i) => ({
                _id: i.employee,
                name: i.fullName,
              })),
              "_id",
            ),
            "name",
          ),
        );
      } else {
        setOptionsSelector([]);
      }

      setReports(data);
      setFilter(
        createDataFilter({ data, orderBy: sortType, direction: sorting }),
      );
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("recruitment:ErrorAlIntentarLaAccion"),
      });
      console.log("error: ", error);
    }
  };

  const handleSubmitFilter = (values) => {
    const employees = values.map((item) => item?._id);
    setFilter(
      createDataFilter({
        data: reports,
        orderBy: sortType,
        direction: sorting,
        employees,
      }),
    );
  };

  const handleClickAssign = (row) => {
    if (
      row.assistance !== t(`recruitment:NON_WORKING_DAY`) ||
      row.checkIn ||
      row.checkOut ||
      row.lunchStart ||
      row.lunchEnd
    ) {
      setReport(row);
      setOpen(true);
    } else {
      Toast.fire({
        icon: "error",
        title: t("recruitment:NoInformationAvailable"),
      });
    }
  };

  const exportToExcel = ({ arrayOfArray, name }) => {
    let wscols = [];

    if (arrayOfArray.length) {
      wscols = arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0)),
        ),
      }));
    }

    const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArray);

    worksheet["!cols"] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, name);
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.ms-excel",
    });
    saveAs(blob, `${name} - ${dayjs().format("LLLL")}.xlsx`);
  };

  const exportTo = () => {
    const arrayOfArray = _.map(
      filter,
      ({
        fullName,
        date,
        checkIn,
        realCheckIn,
        differenceCheckIn,
        checkInDelay,
        checkOut,
        realCheckOut,
        differenceCheckOut,
        workedHours,
        assistance,
      }) => {
        return [
          fullName,
          date,
          checkIn,
          realCheckIn,
          differenceCheckIn,
          checkInDelay,
          checkOut,
          realCheckOut,
          differenceCheckOut,
          workedHours,
          assistance,
        ];
      },
    );
    const head = [
      t("shiftAttendance:Empleado"),
      t("shiftAttendance:Fecha"),
      t("shiftAttendance:Entrada"),
      t("shiftAttendance:EntradaReal"),
      t("shiftAttendance:DiferenciaEntrada(Min)"),
      t("shiftAttendance:Retardo"),
      t("shiftAttendance:HoraSalida"),
      t("shiftAttendance:SalidaReal"),
      t("shiftAttendance:DiferenciaSalida(Min)"),
      t("shiftAttendance:Laborado"),
      t("shiftAttendance:Asistencia"),
    ];
    arrayOfArray.unshift(head);

    exportToExcel({ arrayOfArray, name: t("sidenav:AsistenciaAusencia") });
  };

  return (
    <>
      <Box>
        <Formik
          initialValues={{
            dateFrom: yesterday,
            dateTo: yesterday,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid container spacing={2} mb={2}>
                <Grid item={true} xs={12} md={6} lg={4}>
                  {application === "organization" && (
                    <ToggleButtonGroup
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                      sx={{ height: "100%" }}
                    >
                      <ToggleButton value="all" disabled={formik.isSubmitting}>
                        {t("shiftAttendance:VerTodo")}
                      </ToggleButton>
                      <ToggleButton
                        value="onlyMy"
                        disabled={formik.isSubmitting}
                      >
                        {t("shiftAttendance:MisAsistencias")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Grid>
                <Grid item={true} xs={12} md={6} lg={2}>
                  <UIDatePicker
                    formik={formik}
                    label={t("shiftAttendance:DíaInicio")}
                    name="dateFrom"
                    customDisabled={"After"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6} lg={2}>
                  <UIDatePicker
                    formik={formik}
                    label={t("shiftAttendance:DíaFin")}
                    name="dateTo"
                    customDisabled={"After"}
                    props={{ minDate: dayjs(formik.values.dateFrom) }}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3} lg={2}>
                  <UIButton
                    type="submit"
                    label={t("general:Buscar")}
                    style={{ height: "56px" }}
                    loading={formik.isSubmitting}
                    disabled={dayjs(formik.values.dateTo).isBefore(
                      dayjs(formik.values.dateFrom),
                    )}
                  />
                </Grid>
                <Grid item={true} xs={12} md={3} lg={2}>
                  <UIButton
                    label={t("general:Exportar")}
                    style={{ height: "56px" }}
                    loading={formik.isSubmitting}
                    disabled={filter.length === 0}
                    onClick={exportTo}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        {alignment === "all" && (
          <Grid container spacing={2} mb={2}>
            <Grid item={true} xs={12} lg={8}>
              <Autocomplete
                options={optionsSelector}
                getOptionLabel={(option) => option.name}
                value={value}
                multiple={true}
                isOptionEqualToValue={(option, value) =>
                  option?._id === value?._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("shiftAttendance:FiltroEmpleados")}
                  />
                )}
                onChange={(event, newValue) => {
                  handleSubmitFilter(newValue);
                  setValue(newValue);
                }}
                renderTags={(selected, getTagProps) =>
                  selected.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={option._id}
                        label={option.name}
                        {...tagProps}
                      />
                    );
                  })
                }
              />
            </Grid>
          </Grid>
        )}

        <BasicTable
          rows={filter}
          handleClick={handleClickAssign}
          customStyle={"AttendanceReports"}
          PerPage={25}
          color={false}
          dense={true}
          dontShow={[
            "lunchStart",
            "lunchEnd",
            "lunchTime",
            "checks",
            "employee",
            "country",
            "policy",
            "schedule",
            "timeIn",
            "timeOut",
            "assistanceColor",
          ]}
        >
          <TableCell sx={{ padding: "6px" }}></TableCell>
          <TableCell>
            <Tooltip title={t("general:Ordenar")}>
              <Box
                sx={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  handleChangeSort("EMPLOYEE");
                }}
              >
                {t("shiftAttendance:Empleado")}
                <HeightIcon sx={{ opacity: "0.9" }} />
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            <Tooltip title={t("general:Ordenar")}>
              <Box
                sx={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  handleChangeSort("DATE");
                }}
              >
                {t("shiftAttendance:Fecha")}
                <HeightIcon sx={{ opacity: "0.9" }} />
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Entrada")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:EntradaReal")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:DiferenciaEntrada(Min)")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Retardo")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:HoraSalida")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:SalidaReal")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:DiferenciaSalida(Min)")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Laborado")}
          </TableCell>
          <TableCell sx={{ padding: "6px" }}>
            {t("shiftAttendance:Asistencia")}
          </TableCell>
        </BasicTable>
      </Box>
      <ModalJustify
        open={open}
        setOpen={setOpen}
        report={report}
        allPolices={allPolices}
        allWorkSchedules={allWorkSchedules}
        countries={countries}
      />
    </>
  );
};
