import { cerezaClient } from "../adapters";

const PLANS = "/v1/boff/plans";

export function getPlans() {
  return cerezaClient.get(PLANS);
}

export function getPlan(id) {
  return cerezaClient.get(`${PLANS}?id=${id}`);
}
