import { Box, Grid, TableCell } from "@mui/material";
import { BasicTable, Toast, UIButton } from "../../../components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";
import isEqual from "lodash/isEqual";
import { FormMailVariables } from "./FormMailVariables";
import {
  deleteTemplateTag,
  getTemplateTags,
  postTemplateTag,
  putTemplateTag,
} from "api";
import { useAccess } from "hooks";

export const MailVariables = () => {
  const { t } = useTranslation(["settings"]["general"]);

  const initialValues = {
    title: "",
    html: "",
  };

  const [rows, setRows] = useState([]);
  const [dataResponse, setDataResponse] = useState([]);
  const [updateData, setUpdateData] = useState(null);
  const [values, setValues] = useState(initialValues);

  const { HaveAcessToEdit, HaveAcessToDelete, HaveAcessToCreate } = useAccess();
  const canChange = HaveAcessToEdit(
    "/cereza-organization/settings/configurations/Tags",
    ["change_tags"]
  );
  const canDelete = HaveAcessToDelete(
    "/cereza-organization/settings/configurations/Tags",
    ["delete_tags"]
  );
  const canCreate = HaveAcessToCreate(
    "/cereza-organization/settings/configurations/Tags",
    ["add_tags"]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await getTemplateTags();

        setRows(
          data.map((item) => ({
            id: item._id,
            title: item.title,
          }))
        );
        setDataResponse(data);
      } catch (error) {
        console.error("Error get data ", error);
      }
    };

    getData();
  }, [updateData]);

  const onSubmit = async (formValues, { resetForm }) => {
    let message = t("settings:CustomTagSuccessfullyUpdated");

    try {
      if (formValues?._id) {
        await putTemplateTag(formValues);
      } else {
        await postTemplateTag(formValues);
        message = t("settings:CustomTagSuccessfullyCreated");
        resetForm();
      }

      setValues(initialValues);
      setUpdateData(Math.random());
      Toast.fire({
        icon: "success",
        title: message,
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("settings:ErrorSendingData"),
      });
      console.log(error);
    }
  };

  const onDelete = async () => {
    try {
      await deleteTemplateTag(values._id);
      setUpdateData(Math.random());
      setValues(initialValues);
    } catch (error) {
      Toast.fire({ icon: "error", title: `${error.reponse.data.message}` });
    }
  };

  const handleClick = (row) => {
    const itemSelected = dataResponse.find((item) => item._id === row.id);
    setValues(itemSelected);
  };

  return (
    <Box>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={5}>
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("settings:TagName")}</TableCell>
          </BasicTable>
        </Grid>
        <Grid item={true} xs={12} md={7}>
          <Formik initialValues={values} onSubmit={onSubmit} enableReinitialize>
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormMailVariables formik={formik} />
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap="1em">
                    {((canCreate && !formik?.values?._id) ||
                      (canChange && formik?.values?._id)) && (
                      <UIButton
                        type="submit"
                        label={
                          formik?.values?._id
                            ? t("general:Actualizar")
                            : t("general:Guardar")
                        }
                        loading={formik.isSubmitting}
                      />
                    )}
                    {!isEqual(formik.values, initialValues) && (
                      <>
                        {canDelete && values?._id && (
                          <UIButton
                            label={t("general:Eliminar")}
                            onClick={() => {
                              Swal.fire({
                                title: t("general:Are"),
                                text: t("general:Youwon"),
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: t("general:deleteit"),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  onDelete();
                                  Swal.fire({
                                    title: t("general:Deleted"),
                                    text: t("general:YourFileHasBeenDeleted"),
                                    icon: "success",
                                  });
                                }
                              });
                            }}
                            disabled={formik.isSubmitting}
                          />
                        )}
                        {canCreate && (
                          <UIButton
                            label={t("general:Nuevo")}
                            onClick={() => {
                              setValues(initialValues);
                              formik.setValues(initialValues);
                            }}
                            disabled={formik.isSubmitting}
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  );
};
