import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { OrganizationNavigation } from "modules/Settings/Security/CreateGroups/OrganizationNavigation";
import { EmployeeNavigation } from "modules/Settings/Security/CreateGroups/EmployeeNavigation";

const navigationExpand = (navigation) => {
  let result = [];

  navigation.forEach((node) => {
    result.push(node);

    if (node.hasOwnProperty("tree")) {
      result = [...result, ...navigationExpand(node.tree)];
    }
  });

  return result;
};

export const useAccess = () => {
  const { keycloakProfile, permissions } = useSelector(authUserSelect);

  const access = (...paths) => {
    return (
      keycloakProfile?.is_superuser ||
      paths.some((path) => permissions?.paths?.includes(path))
    );
  };

  const accessCodeName = (...codenames) => {
    return (
      keycloakProfile?.is_superuser ||
      codenames.some((codename) => permissions?.permissions?.includes(codename))
    );
  };

  function permissionsContieneCodenameList(CodenameList) {
    return CodenameList?.every((item) =>
      permissions?.permissions?.includes(item),
    );
  }

  function arraysNoCompartenElementos(elseCodenames) {
    return !permissions?.permissions?.some((item) =>
      elseCodenames.includes(item),
    );
  }

  const readOnlyAccess = (path, codenameList) => {
    //! codenameList es un array que contiene todos los codename relacionados al path ["view_", "change_", "add_", "delete_"]
    if (access(path)) {
      const viewCode = codenameList?.find((codename) =>
        codename.includes("view_"),
      );
      const elseCodenames = codenameList?.filter(
        (codename) => codename !== viewCode,
      );

      if (permissions?.permissions?.find((codename) => codename === viewCode)) {
        return arraysNoCompartenElementos(elseCodenames);
      }
    } else return false;
  };

  const HaveAcessToEdit = (path, codenameList) => {
    //! codenameList debe ser un array que solo contenga los codename ["view_" y "change_"] relacionados al path
    if (access(path)) {
      return permissionsContieneCodenameList(codenameList);
    }
  };

  const HaveAcessToCreate = (path, codenameList) => {
    //! codenameList debe ser un array que solo contenga los codename ["view_" y "add_"] relacionados al path
    if (access(path)) {
      return permissionsContieneCodenameList(codenameList);
    }
  };

  const HaveAcessToDelete = (path, codenameList) => {
    //! codenameList debe ser un array que solo contenga los codename ["view_" y "delete_"] relacionados al path
    if (access(path)) {
      return permissionsContieneCodenameList(codenameList);
    }
  };

  const HasPermissions = (
    path,
    application = "organization",
    aditionalPermissions = [],
  ) => {
    const sections =
      application === "organization"
        ? navigationExpand(OrganizationNavigation)
        : navigationExpand(EmployeeNavigation);
    const section = sections.find((s) => s.path === path);
    const getPermissions = ["access", "view", "add", "change", "delete"].concat(
      aditionalPermissions,
    );

    let hasPermissions = {};

    getPermissions.forEach((permission) => {
      if (permission === "access") {
        hasPermissions["canAccess"] = access(path);
      } else if (section?.hasOwnProperty("permissions")) {
        let permission_code = section.permissions.find((p) =>
          p.startsWith(`${permission}_`),
        );

        hasPermissions[
          "can" +
            permission
              .toLowerCase()
              .replace(/_/g, " ")
              .replace(/(?: |\b)(\w)/g, function (key) {
                return key.toUpperCase();
              })
              .replace(/\s/g, "")
        ] =
          keycloakProfile?.is_superuser ||
          permissions?.permissions.includes(permission_code) ||
          permissions?.permissions.includes(permission);
      }
    });

    return hasPermissions;
  };

  return {
    access,
    accessCodeName,
    readOnlyAccess,
    HaveAcessToEdit,
    HaveAcessToCreate,
    HaveAcessToDelete,
    HasPermissions,
  };
};
