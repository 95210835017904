import { useState, useEffect } from "react";
import { FormControl, Autocomplete, TextField, Box, Chip } from "@mui/material";

export const UISelectCheckmarks = ({
  options,
  label = "",
  formik,
  name = "",
  index = null,
  nameFileArray = "",
  fieldRender = "title",
  maxHeightValue = "auto",
  isrequired = false,
  ...props
}) => {
  const [selectedOptionsOrder, setSelectedOptionsOrder] = useState([]);

  const selectedIds =
    nameFileArray === ""
      ? formik.values[name] || []
      : formik.values[nameFileArray][index][name] || [];

  useEffect(() => {
    const sortedOptions = selectedIds
      .map((id) => options.find((option) => option._id === id))
      .filter((option) => option);

    setSelectedOptionsOrder(sortedOptions);
  }, [options, selectedIds]);

  const handleChange = (event, value) => {
    const selectedIds = value.map((option) => option?._id);

    nameFileArray === ""
      ? formik.setFieldValue(name, selectedIds)
      : formik.setFieldValue(`${nameFileArray}.${index}.${name}`, selectedIds);

    setSelectedOptionsOrder(value);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <Autocomplete
          multiple
          options={options}
          getOptionLabel={(option) => option[fieldRender]}
          value={selectedOptionsOrder}
          onChange={handleChange}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              error={formik.touched[name] && Boolean(formik.errors[name])}
              helperText={formik.touched[name] && formik.errors[name]}
            />
          )}
          renderTags={(selected, getTagProps) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                maxHeight: maxHeightValue,
              }}
            >
              {selected.map((option, index) => {
                const tagProps = getTagProps({ index });
                const { key, ...restTagProps } = tagProps;

                return (
                  <Chip
                    key={option?._id}
                    label={option[fieldRender]}
                    {...restTagProps}
                  />
                );
              })}
            </Box>
          )}
          {...props}
        />
      </FormControl>
    </div>
  );
};
