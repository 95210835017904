import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  policy: {},
  allPolices: [],
};

const policesSlice = createSlice({
  name: "polices",
  initialState,
  reducers: {
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
    setAllPolices: (state, action) => {
      state.allPolices = action.payload;
    },
  },
});

export const { setPolicy, setAllPolices } = policesSlice.actions;

export const policesSelect = (state) => state.polices;

export default policesSlice.reducer;
