import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Knob } from "primereact/knob";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useEffect, useState } from "react";
import { getStorage } from "api";

function calcularPorcentajeConsumido(totalGigas, consumo) {
  if (
    typeof totalGigas !== "number" ||
    typeof consumo !== "number" ||
    totalGigas <= 0 ||
    consumo < 0
  ) {
    return "Los argumentos deben ser números positivos.";
  }

  const porcentajeConsumido = (consumo / totalGigas) * 100;

  const porcentajeRedondeado = parseFloat(porcentajeConsumido.toFixed(2));

  return porcentajeRedondeado;
}

function getSizeWithUnit(obj) {
  const { bytes, mb, gb } = obj;

  if (mb < 1 && gb < 1) {
    return { size: `0.${bytes.toFixed(0)}`, unidadMedida: "MB" };
  } else if (gb < 1 && mb < 999 && mb > 1) {
    return { size: mb.toFixed(2), unidadMedida: "MB" };
  } else if (gb > 1) {
    return { size: gb.toFixed(2), unidadMedida: "GB" };
  } else {
    return { size: mb.toFixed(2), unidadMedida: "MB" };
  }
}

export const Storage = () => {
  const { t } = useTranslation(["organization"]);
  const { mainColor } = useSelector(parameterizationSelect);
  const { tenant, currentPlan } = useSelector(tenantSettingsSelect);

  const [value, setValue] = useState(0);
  const [Storage, setStorage] = useState(null);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const { data } = await getStorage(tenant._id);
        setStorage(getSizeWithUnit(data.size));
        setValue(
          calcularPorcentajeConsumido(currentPlan.gbSpace, data.size.gb)
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchSkills();
  }, []);

  return (
    <>
      <Paper>
        <Box p={4}>
          <Typography variant="h5">{t("organization:Storage")}</Typography>
          <br />
          <Typography variant="body2">
            {t("organization:StorageDescription")}
            {` (${currentPlan.gbSpace} GB)`}
          </Typography>
          <br />
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <Knob
              value={value}
              valueTemplate={"{value}%"}
              size={200}
              valueColor={mainColor}
              readOnly
            />
            <Box
              width="auto"
              display="grid"
              sx={{
                gridTemplateColumns: "auto 1fr",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Typography variant="overline" display="block" gutterBottom>
                {Storage?.size}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                {Storage?.unidadMedida}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
