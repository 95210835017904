import RocketWhite from "assets/illustrations/rocket-white.png";
import MimePdfIcon from "assets/illustrations/application-pdf.svg";
import NoImage from "assets/illustrations/no-image.png";
export const FileThumbnail = ({ src }) => {
  let srcType = typeof src;

  switch (true) {
    case srcType === "string" && src.length > 0:
      var data_type = src.split(";")[0];
      switch (data_type) {
        case "data:application/pdf":
          src = MimePdfIcon;
          break;
        case "data:image/jpeg":
        case "data:image/png":
            src = src;
            break;
        default:
          src = NoImage;
          break
      }
      break;
  
    default:
      src = RocketWhite;
      break;
  }

  return <img src={src} alt="preview" width="193" height="193" />;
};