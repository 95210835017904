import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Tab, Tabs } from "@mui/material";

export const MenuRequests = ({ ubication, application = "organization" }) => {
  const { t } = useTranslation(["request"]);
  const navigate = useNavigate();
  const path = application === "organization" ? "/cereza-organization" : "";

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        placeItems: "center",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Tabs
        value={ubication}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        style={{ maxWidth: "100%" }}
      >
        <Tab
          value="Request"
          label={t("request:MyRequests")}
          onClick={() => {
            navigate(`${path}/requests`);
          }}
        />
        <Tab
          value="Signature"
          label={t("request:Signature")}
          onClick={() => {
            navigate(`${path}/requests/signature`);
          }}
        />
        <Tab
          value="CreateRequests"
          label={t("request:RequestsHistory")}
          onClick={() => {
            navigate(`${path}/requests/history`);
          }}
        />
      </Tabs>
    </Box>
  );
};
