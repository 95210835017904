import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  AddLocationAlt as AddLocationAltIcon,
  Apartment as ApartmentIcon,
  AssignmentInd as AssignmentIndIcon,
  BeachAccess as BeachAccessIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Lan as LanIcon,
  PunchClock as PunchClockIcon,
} from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Locations } from "modules/Organization/Settings/Locations";
import { OrganizationSettings } from "modules/Organization/Settings";
import { Departments } from "modules/Organization/Departments";
import { WorkSchedule } from "modules/ShiftAttendance/WorkShiftSchedule/WorkSchedule";
import { Holidays } from "modules/ShiftAttendance/Time-off/Holidays";
import { CompensationAndBenefits } from "modules/Organization/CompensationAndBenefits";
import { useEffect, useState } from "react";
import {
  getCountries,
  getDepartments,
  getHolidays,
  getLocations,
  getSchedule,
  getTenantSettings,
  patchTenantSettings,
  selectingEmployeeList,
} from "api";
import { useDispatch } from "react-redux";
import { setWorkSchedule } from "@redux/slices/work-schedules";
import { setHolidays } from "@redux/slices/time-off";
import { useCountries } from "hooks";
import { EmployeesWizard } from "./EmployeesWizard";
import { useSelector } from "react-redux";
import { setTenantSettings, tenantSettingsSelect } from "@redux/slices/tenant";
import { Toast } from "components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { WizardActions } from "./WizardActions";
import { setCountriesCompleteData } from "@redux/slices/general";
import PropTypes from "prop-types";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 136deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark} )`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        `linear-gradient( 136deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark} )`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === "dark"
      ? theme.palette.grey[800]
      : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark"
    ? theme.palette.grey[700]
    : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      `linear-gradient( 136deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark} )`,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      `linear-gradient( 136deg, ${theme.palette.primary.light}, ${theme.palette.primary.main} )`,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <AddLocationAltIcon />,
    2: <ApartmentIcon />,
    3: <LanIcon />,
    4: <PunchClockIcon />,
    5: <BeachAccessIcon />,
    6: <CurrencyExchangeIcon />,
    7: <AssignmentIndIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export default function Wizard() {
  const { t } = useTranslation(["wizard"]["general"]);
  const { tenant } = useSelector(tenantSettingsSelect);

  let wizard = {};

  if (tenant.wizard?.completed === undefined) {
    wizard.completed = false;
  } else {
    wizard.completed = tenant.wizard.completed;
  }

  if (tenant.wizard?.steps === undefined) {
    wizard.steps = {
      locationsCompleted: false,
      generalDataCompleted: false,
      departmentsCompleted: false,
      scheduleCompleted: false,
      holidaysCompleted: false,
      employeesCompleted: false,
    };
  } else {
    wizard.steps = { ...tenant.wizard.steps };
  }

  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(null);
  const [updateWizard, setUpdateWizard] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useCountries();
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data } = await getCountries(false);
        dispatch(setCountriesCompleteData(data));
      } catch (err) {
        console.error("Error getting countries:", err);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const loadLocations = async () => {
      try {
        if (!wizard.steps.locationsCompleted) {
          const { data } = await getLocations();

          if (data.length) {
            wizard.steps.locationsCompleted = true;
          }
        }

        loadGeneralData();
      } catch (err) {
        console.log("Error get locations: ", err);
      }
    };

    const loadGeneralData = async () => {
      try {
        if (!wizard.steps.generalData) {
          const { data } = await getTenantSettings();

          if (data.hasOwnProperty("mainLocation") && data.mainLocation) {
            wizard.steps.generalDataCompleted = true;
          }
        }

        loadDepartments();
      } catch (err) {
        console.log("Error get general data: ", err);
      }
    };

    const loadDepartments = async () => {
      try {
        if (!wizard.steps.departmentsCompleted) {
          const { data } = await getDepartments(null);

          if (data.length) {
            wizard.steps.departmentsCompleted = true;
          }
        }

        loadSchedule();
      } catch (err) {
        console.log("error get deparments: ", err);
      }
    };

    const loadSchedule = async () => {
      try {
        const { data } = await getSchedule(null);

        if (data.length) {
          dispatch(setWorkSchedule(data[0]));
          wizard.steps.scheduleCompleted = true;
        } else {
          dispatch(
            setWorkSchedule({
              name: "",
              description: "",
              isActive: true,
              type: "",
              lunch: "",
              rotations: 0,
              weeks: [{}],
            }),
          );
        }

        loadHolidays();
      } catch (err) {
        console.log("Error get schedules: ", err);
      }
    };

    const loadHolidays = async () => {
      try {
        const { data } = await getHolidays(null);

        if (data.length) {
          dispatch(setHolidays(data[0]));
          wizard.steps.holidaysCompleted = true;
        } else {
          dispatch(
            setHolidays({
              name: "",
              description: "",
              country: "",
              isVisible: true,
              holidays: [],
            }),
          );
        }
        loadEmployees();
      } catch (err) {
        console.log("Error get holidays: ", err);
      }
    };

    const loadEmployees = async () => {
      try {
        if (!wizard.steps.employeesCompleted) {
          const { data } = await selectingEmployeeList();

          if (data.length) {
            wizard.steps.employeesCompleted = true;
          }
        }
        generateSteps();
      } catch (err) {
        console.log("Error get employees: ", err);
      }
    };

    const generateSteps = async () => {
      if (activeStep !== 1) {
        const { data } = await patchTenantSettings(tenant._id, {
          wizard: wizard,
        });

        let newDataTenant = { ...tenant, ...data };

        dispatch(setTenantSettings(newDataTenant));
      }

      setSteps([
        {
          label: t("organization:locations"),
          content: (
            <>
              <WizardActions />
              <Locations showTabs={false} setUpdateWizard={setUpdateWizard} />
            </>
          ),
          completed: wizard.steps.locationsCompleted,
        },
        {
          label: t("organization:organization"),
          content: (
            <>
              <WizardActions />
              <OrganizationSettings
                showTabs={false}
                setUpdateWizard={setUpdateWizard}
              />
            </>
          ),
          completed: wizard.steps.generalDataCompleted,
        },
        {
          label: t("organization:departments"),
          content: (
            <>
              <WizardActions />
              <Departments setUpdateWizard={setUpdateWizard} />
            </>
          ),
          completed: wizard.steps.departmentsCompleted,
        },
        {
          label: t("shiftAttendance:WorkSchedule"),
          content: (
            <>
              <WizardActions />
              <WorkSchedule setUpdateWizard={setUpdateWizard} />
            </>
          ),
          completed: wizard.steps.scheduleCompleted,
        },
        {
          label: t("shiftAttendance:Holidays"),
          content: (
            <>
              <WizardActions />
              <Holidays setUpdateWizard={setUpdateWizard} />
            </>
          ),
          completed: wizard.steps.holidaysCompleted,
        },
        {
          label: t("employee:CompensacionesyBeneficios"),
          content: (
            <>
              <WizardActions />
              <CompensationAndBenefits setUpdateWizard={setUpdateWizard} />
            </>
          ),
          completed: true,
        },
        {
          label: t("employee:Employees"),
          content: (
            <>
              <EmployeesWizard setUpdateWizard={setUpdateWizard} />
            </>
          ),
          completed: wizard.steps.employeesCompleted,
        },
      ]);
    };

    loadLocations();
  }, [updateWizard]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const completedWizard = async () => {
    wizard.completed = true;

    const { data } = await patchTenantSettings(tenant._id, {
      wizard: wizard,
    });

    let newDataTenant = { ...tenant, ...data };

    await dispatch(setTenantSettings(newDataTenant));

    navigate("/cereza-organization/home/indicators");

    Toast.fire({
      icon: "success",
      title: t("wizard:MessageComplete"),
    });
  };

  return (
    <Box p={4}>
      {steps
        ? (
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {steps[activeStep].content}
            <Stack
              mt={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  if (activeStep === steps.length - 1) {
                    completedWizard();
                  } else {
                    handleNext();
                  }
                }}
                disabled={!steps[activeStep].completed}
              >
                {activeStep === steps.length - 1
                  ? t("wizard:Finish")
                  : t("wizard:Continue")}
              </Button>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                {t("wizard:Back")}
              </Button>
            </Stack>
          </Stack>
        )
        : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h3" align="center">
              {t("wizard:loadingMessage")}
            </Typography>
            <CircularProgress />
          </Stack>
        )}
    </Box>
  );
}
