import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Toast, UIButton, UISelectCheckmarks } from "components";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getLanguages,
  saveLanguages,
  updateLanguages,
  getBaseLanguages,
} from "api";
import { managerErrors } from "utils/functions";
import { setLanguages } from "@redux/slices/general";

export const Languages = () => {
  const dispatcher = useDispatch();

  const [baseLanguages, setBaseLanguages] = useState([]);
  const [favoriteLanguages, setFavoriteLanguages] = useState({});

  const { t } = useTranslation(["organization"]);

  useEffect(() => {
    const fetchBaseLanguages = async () => {
      try {
        const { data } = await getBaseLanguages();
        setBaseLanguages(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchLanguages = async () => {
      try {
        const { data } = await getLanguages();
        setFavoriteLanguages(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBaseLanguages();
    fetchLanguages();
  }, []);

  const handleSubmit = async (values, { setValues }) => {
    delete values.createdAt;
    delete values.updatedAt;
    try {
      if (values?._id) {
        const { data } = await updateLanguages(values, values._id);
        setFavoriteLanguages(data);
        setValues(data);
      } else {
        const { data } = await saveLanguages(values);
        setFavoriteLanguages(data);
        setValues(data);
      }
      const updateData = values?.languages?.map((id) => {
        const aux = baseLanguages.find((language) => language._id === id);
        return {
          value: aux._id,
          label: aux.label,
        };
      });
      dispatcher(setLanguages(updateData));
      Toast.fire({
        icon: "success",
        title: "Favorite languages updated successfully",
      });
    } catch (error) {
      managerErrors(error?.response?.data?.message);
      console.error(error);
    }
  };

  return (
    <>
      <Box p={2}>
        <Typography variant="body2">
          {t("organization:LenguajesDescription")}
        </Typography>
        <br />
        {Object.keys(favoriteLanguages).length > 0 && (
          <Formik initialValues={favoriteLanguages} onSubmit={handleSubmit}>
            {(formik) => (
              <Form id="d" autoComplete="off">
                <UISelectCheckmarks
                  options={baseLanguages}
                  formik={formik}
                  label={t("organization:Lenguajes")}
                  name={"languages"}
                  disabled={formik.isSubmitting}
                  fieldRender={"label"}
                />
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap="1em">
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};
