import { Box, Tab, Tabs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAccess } from "hooks";

export const MenuNav = ({ ubication, setUbication }) => {
  const { t } = useTranslation(["recruitment"]);

  const { access } = useAccess();

  const handleChange = (event, newValue) => {
    setUbication(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Tabs
        value={ubication}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        style={{ maxWidth: "100%" }}
      >
        {access(
          "/cereza-organization/recruitment/recruitment-settings/TerminosYcondicionesPoliticasYFooter"
        ) && (
          <Tab
            value="Terms & Conditions"
            label={t("recruitment:TerminosCondiciones")}
          />
        )}
        {access(
          "/cereza-organization/recruitment/recruitment-settings/TerminosYcondicionesPoliticasYFooter"
        ) && (
          <Tab
            value="Privacy Policy"
            label={t("recruitment:PoliticasPrivacidad")}
          />
        )}
        {access(
          "/cereza-organization/recruitment/recruitment-settings/TerminosYcondicionesPoliticasYFooter",
          "/cereza-organization/recruitment/recruitment-settings/OtrosAjustes"
        ) && <Tab value="Others" label={t("recruitment:OtrosAjustes")} />}
      </Tabs>
    </Box>
  );
};
