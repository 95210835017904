import {
  Stack,
  MenuList,
  Popover,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useSelector } from "react-redux";
import { useActionsNotificatio } from "hooks";
import { convertUTCToLocal } from "helpers";

import notificacion from "../assets/illustrations/notificacion.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import InsertPageBreakIcon from "@mui/icons-material/InsertPageBreak";
import TaskIcon from "@mui/icons-material/Task";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

export const Notifications = ({
  open,
  anchorEl,
  handleCloseNotification,
  notifications,
  fetchNotifications,
}) => {
  const { t } = useTranslation(["general"]);
  const { mainColor, mode, darkBackgroundColor } = useSelector(parameterizationSelect);

  const { markAsRead, deleteNotification, redirectNotification } =
    useActionsNotificatio();

  const primeros = notifications?.slice(0, 5);

  const getIcon = (type) => {
    if (type === "DOCUMENT_TO_BE_SIGN") return <HistoryEduIcon />;
    if (type === "DOCUMENT_TO_BE_REVIEW") return <FindInPageIcon />;
    if (type === "DOCUMENT_REJECTED") return <InsertPageBreakIcon />;
    if (type === "DOCUMENT_APPROVED") return <TaskIcon />;
    if (type === "NoteAddIcon") return <NoteAddIcon />;
    if (type === "DOCUMENT_FIXED") return <DocumentScannerIcon />;
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={handleCloseNotification}
      sx={{
        mt: 2,
      }}
    >
      <Stack
        spacing={2}
        sx={{
          margin: "15px",
          minWidth: "150px",
        }}
      >
        {notifications?.length === 0 ? (
          <img
            style={{ width: "4rem", margin: "0 auto" }}
            src={notificacion}
            alt="notifications"
          />
        ) : (
          <>
            <MenuList sx={{ width: 320, maxWidth: "100%" }}>
              {primeros?.map((notification) => (
                <Stack
                  key={notification._id}
                  direction="row"
                  spacing={1}
                  className="notifying"
                  style={{
                    padding: "6px 1rem",
                    marginBottom: "6px",
                    backgroundColor: notification?.isOpen
                      ? ( mode === 'dark' ) 
                      ? `${darkBackgroundColor}50` 
                      :  '#f7f7fa'
                      : `${mainColor}10`,
                    cursor: "pointer",
                    justifyContent: "space-between",
                  }}
                  onClick={() => {
                    if (!notification?.isOpen) {
                      markAsRead(notification, fetchNotifications);
                    }
                    redirectNotification(notification?.type);
                  }}
                >
                  {getIcon(notification.type)}
                  <div
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    <Typography
                      variant="overline"
                      display="block"
                      gutterBottom
                      sx={{ lineHeight: 1.5 }}
                    >
                      {convertUTCToLocal(notification.sendAt).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </Typography>
                    <Typography
                      variant="overline"
                      display="block"
                      gutterBottom
                      sx={{ lineHeight: 1.2 }}
                    >
                      {notification.msg}
                    </Typography>
                  </div>
                  <div
                    style={{
                      height: "auto !important",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => {
                        deleteNotification(
                          notification,
                          fetchNotifications,
                          handleCloseNotification
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Stack>
              ))}
            </MenuList>
            <hr />
            <div
              style={{
                padding: "0px 2rem",
                marginBottom: "1rem",
              }}
            >
              <Link
                to="/cereza-organization/notifications/center"
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
                onClick={handleCloseNotification}
              >
                {t("general:MasNotificaciones")} <ChevronRightIcon />
              </Link>
            </div>
          </>
        )}
      </Stack>
    </Popover>
  );
};
