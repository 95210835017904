import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import UndoIcon from "@mui/icons-material/Undo";
import { Box, Paper, Typography, Avatar } from "@mui/material";
import { UIButton } from "components";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { getHolidaysWidget } from "api";
import { useNavigate } from "react-router-dom";

export const UpcomingHolidays = () => {
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();

  const { mode, darkBackgroundColor } = useSelector(parameterizationSelect);

  const [action, setAction] = useState("more");
  const [dates, setDates] = useState([]);
  const [howManyShow, setHowManyShow] = useState(5);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await getHolidaysWidget();
        setDates(data);
      } catch (error) {}
    };
    fetch();
  }, []);

  const renderHolidayList = (limitedIterations) => {
    const holidayItems = [];

    for (let i = 0; i < limitedIterations; i++) {
      const date = dates[i];

      holidayItems.push(
        <Fragment key={i}>
          <Box
            sx={{
              borderRadius: "5px",
              backgroundColor:
                mode === "light" ? "#f3f6f9" : darkBackgroundColor,
              display: "grid",
              gridTemplateColumns: "auto auto",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "1rem",
              margin: "0.5rem 1rem",
            }}
            p={1}
          >
            <Typography variant="subtitle2">
              <b>{date?.date} -</b> {date?.localName}
            </Typography>
            <Avatar
              alt={date?.nameOfHoliday}
              src={`https://flagcdn.com/${date?.codeCountry}.svg`}
            />
          </Box>
        </Fragment>
      );
    }

    return holidayItems;
  };

  return (
    <>
      <Paper elevation={3} sx={{ height: "100%" }}>
        <Box p={2} pb={0}>
          <Typography variant="subtitle1">
            {t("home:UpcomingHolidays")}
          </Typography>
        </Box>
        {dates.length > 0 ? (
          <>
            <Box
              sx={
                howManyShow > 5
                  ? {
                      height: "328px",
                      overflowY: "auto",
                    }
                  : {}
              }
            >
              {renderHolidayList(howManyShow)}
            </Box>

            <Box
              p={2}
              pt={0}
              sx={{
                display: "grid",
                gridTemplateColumns: action === "more" ? "1fr" : "auto 1fr",
                gap: "1rem",
              }}
            >
              {action === "all" && (
                <UIButton
                  onClick={() => {
                    if (action === "all") {
                      setAction(action === "all" && "more");
                      setHowManyShow(5);
                    }
                  }}
                >
                  <UndoIcon />
                </UIButton>
              )}

              <UIButton
                label={action === "more" ? t("home:all") : t("home:more")}
                onClick={() => {
                  if (action === "more") {
                    setAction(action === "more" && "all");
                    setHowManyShow(dates.length);
                  }
                  if (action === "all") {
                    navigate(
                      "/cereza-organization/shift-attendance/time-off-holidays"
                    );
                  }
                }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              p={2}
              sx={{
                height: "calc(100% - 44px)",
                display: "grid",
                alignItems: "end",
              }}
            >
              <UIButton
                label={t("home:RegistrarCalendarios")}
                onClick={() =>
                  navigate(
                    "/cereza-organization/shift-attendance/time-off-holidays"
                  )
                }
              />
            </Box>
          </>
        )}
      </Paper>
    </>
  );
};
