import { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ProfileTabs, TitleBox, Toast, UIButton } from "components";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { profileSelect } from "@redux/slices/profile";
import {
  deleteEmploymentHistory,
  saveEmploymentHistory,
  updateEmploymentHistory,
} from "api";
import { FormEmploymentHistory } from "./FormEmploymentHistory";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAccess } from "hooks";
import { pathEmployee, isFormerEmployee, getProfileReturnUri } from "helpers";

import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import dayjs from "dayjs";

export const EmploymentSummary = ({
  application = "organization",
  user = null,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [newData, setNewData] = useState(false);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = 
  isFormerEmployee(HasPermissions(
    pathEmployee(application, "/profile/employment-information/employment-summary"),
      application,
    ),
    employeeSelected
  );
  const returnUri = getProfileReturnUri(employeeSelected);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (values?._id) {
        const { data } = await updateEmploymentHistory(values, values._id);
        setNewData(!newData);
        resetForm();
      } else {
        const { data } = await saveEmploymentHistory(values);
        setNewData(!newData);
        resetForm();
      }
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: { err },
      });
      console.error("Error submitting form:", err);
    }
  };

  const deleteItem = async (formik) => {
    try {
      const { data } = await deleteEmploymentHistory(formik.values._id);
      setNewData(!newData);
      formik.setValues(formik.initialValues);
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <>
      <ProfileTabs
        tab_initial="employment-information"
        application={application}
      />
      <br />
      <Paper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
            isRed={!!employeeSelected?.formerEmployee}
          />
          <Typography variant="h5" mb={2}>
            {t("employee:Historialdeempleo")}
          </Typography>
          <Formik
            initialValues={{
              employee: employeeSelected._id,
              companyName: "",
              position: "",
              startDate: dayjs().format("YYYY-MM-DD"),
              endDate: dayjs().format("YYYY-MM-DD"),
              responsibilities: "",
            }}
            //   validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormEmploymentHistory
                  formik={formik}
                  newData={newData}
                  canAdd={canAdd}
                  canChange={canChange}
                  user={user}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UIButton
                      variant="contained"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() =>
                        navigate(returnUri)
                      }
                      disabled={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {((canAdd && !formik?.values?._id) ||
                    (canChange && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {!isEqual(formik?.values, formik?.initialValues) && (
                    <>
                      {canDelete && formik?.values?._id && (
                        <UIButton
                          label={t("general:Eliminar")}
                          onClick={() => {
                            Swal.fire({
                              title: t("general:Are"),
                              text: t("general:Youwon"),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: t("general:deleteit"),
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteItem(formik);
                                Swal.fire({
                                  title: t("general:Deleted"),
                                  text: t("general:YourFileHasBeenDeleted"),
                                  icon: "success",
                                });
                              }
                            });
                          }}
                          disabled={formik.isSubmitting}
                          fullWidth={false}
                        />
                      )}
                      {canAdd && (
                        <UIButton
                          label={t("general:Nuevo")}
                          onClick={() => formik.setValues(formik.initialValues)}
                          disabled={formik.isSubmitting}
                          fullWidth={false}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </>
  );
};
