import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  Search as SearchIcon,
  SentimentVeryDissatisfied as SentimentVeryDissatisfiedIcon,
} from "@mui/icons-material";
import { getSearchAll, getEmployee } from "api";
import { useState } from "react";
import { setEmployeeSelected } from "@redux/slices/profile";
import { generalSelect } from "@redux/slices/general";
import { PROFILE_MODULE } from "@/routes";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner, UIPaper } from "components";
import { useTranslation } from "react-i18next";
import reactStringReplace from "react-string-replace";
import { parameterizationSelect } from "@redux/slices/parameterization";

function SearchAdvancedTabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box pt={2}>
          <UIPaper>
            <Box p={3}>
              {children}
            </Box>
          </UIPaper>
        </Box>
      )}
    </div>
  );
}

SearchAdvancedTabPanel.propTypes = {
  chidren: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const SearchAdvanced = () => {
  const { t } = useTranslation(["organization"]["general"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const personal_information = PROFILE_MODULE.Collaborator.sections.find((
    section,
  ) => section.name === "PersonalInformation");
  const { textSearchDialog } = useSelector(generalSelect);
  const [textSearch, setTextSearch] = useState(textSearchDialog);

  const [value, setValue] = useState(0);
  const [searchAll, setSearchAll] = useState([]);
  const [searchEmployees, setSearchEmployees] = useState([]);
  const [searchCandidates, setSearchCandidates] = useState([]);
  const { mainColor } = useSelector(parameterizationSelect);
  const [searching, setSearching] = useState(false);

  const handleChange = (event, value) => {
    setValue(value);
  };

  let delayTimer;

  const search = (value) => {
    setTextSearch(value);

    const fetchDataSearch = async () => {
      const { data } = await getSearchAll(value);
      setSearchAll(data);
      setSearchEmployees(
        Object.entries(data.employees).map(([id, text]) => {
          return { id: id, text: text };
        }),
      );
      setSearchCandidates(
        Object.entries(data.candidates).map(([id, text]) => {
          return { id: id, text: text };
        }),
      );
      setSearching(false);
    };

    if (value) {
      fetchDataSearch();
    } else {
      setSearchAll([]);
      setSearchEmployees([]);
      setSearchCandidates([]);
      setSearching(false);
    }
  };

  const startSearch = (event) => {
    setSearching(true);

    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      search(event.target.value);
    }, 200);
  };

  const actionOnClick = async (id) => {
    try {
      const { data } = await getEmployee("id", id);
      dispatch(setEmployeeSelected(data));

      if (window.location.pathname == personal_information.route) {
        setTimeout(() => {
          window.location.href = personal_information.route;
        }, 200);
      } else {
        navigate(personal_information.route);
      }
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };

  useEffect(() => {
    if (textSearch) {
      setSearching(true);
      search(textSearch);
    }
  }, []);

  const ItemResult = ({ id, text }) => {
    text = reactStringReplace(
      text,
      textSearch,
      (match, i) => (
        <span
          key={i}
          style={{
            fontWeight: "bold",
            color: mainColor,
          }}
        >
          {match}
        </span>
      ),
    );

    return (
      <ListItemButton
        key={id}
        onClick={() => {
          actionOnClick(id);
        }}
      >
        <ListItemText primary={text} />
      </ListItemButton>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box id={"cereza-general-tabs"}>
        <Grid container spacing={2}>
          <Grid item="true" md={4} xs={12} mdOffset={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
              mb={2}
            >
              <SearchIcon
                src={{
                  mr: 1,
                  my: 0.5,
                }}
              />
              <TextField
                variant="standard"
                fullWidth={true}
                onChange={startSearch}
                defaultValue={textSearch}
              />
            </Box>
          </Grid>
        </Grid>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            label={t("employee:allResults")}
            {...allyProps(0)}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
          <Tab
            label={t("employee:Employees")}
            {...allyProps(1)}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
          <Tab
            label={t("employee:candidates")}
            {...allyProps(2)}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
          <Tab
            label={t("employee:vacancies")}
            {...allyProps(3)}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
          <Tab
            label={t("employee:Activos")}
            {...allyProps(4)}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        </Tabs>
      </Box>
      <SearchAdvancedTabPanel value={value} index={0}>
        {searching && <Spinner />}
        {!searching && (Object.entries(searchAll).map(([key, values]) => (
          <List
            subheader={
              <ListSubheader component="div">
                <Typography variant="overline">
                  {t(`employee:${key}`)}
                </Typography>
              </ListSubheader>
            }
            key={key}
          >
            {(Object.keys(values).length > 0)
              ? (Object.entries(values).map(([id, text]) => (
                <ItemResult key={id} id={id} text={text} />
              )))
              : (
                <ListItem>
                  <ListItemIcon>
                    <SentimentVeryDissatisfiedIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("employee:withoutResults")} />
                </ListItem>
              )}
          </List>
        )))}
      </SearchAdvancedTabPanel>
      <SearchAdvancedTabPanel value={value} index={1}>
        {searching && <Spinner />}
        {!searching && searchEmployees.length > 0 &&
          (
            <List>
              {searchEmployees.map((e, index) => (
                <ItemResult key={index} id={e.id} text={e.text} />
              ))}
            </List>
          )}
        {!searching && textSearch && searchEmployees.length === 0 && (
          <Typography variant="subtitle2">
            {t("employee:withoutResults")}
          </Typography>
        )}
      </SearchAdvancedTabPanel>
      <SearchAdvancedTabPanel value={value} index={2}>
        {searching && <Spinner />}
        {!searching && searchCandidates.length > 0 &&
          (
            <List>
              {searchCandidates.map((e, index) => (
                <ItemResult key={index} id={e.id} text={e.text} />
              ))}
            </List>
          )}
        {!searching && textSearch && searchCandidates.length === 0 && (
          <Typography variant="body2">
            {t("employee:withoutResults")}
          </Typography>
        )}
      </SearchAdvancedTabPanel>
      <SearchAdvancedTabPanel value={value} index={3}>
        Vacancies
      </SearchAdvancedTabPanel>
      <SearchAdvancedTabPanel value={value} index={4}>
        Assets
      </SearchAdvancedTabPanel>
    </Box>
  );
};
