import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { Field, useField } from "formik";

export const UISwitchFast = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <Field {...field} {...rest}>
      {({ field, form: { touched, errors, setFieldValue } }) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.checked)}
                  {...field}
                  {...rest}
                />
              }
              {...props}
            />
          </FormGroup>
          {touched[field.name] && errors[field.name] && (
            <div>{errors[field.name]}</div>
          )}
        </>
      )}
    </Field>
  );
};
