import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { FormDepartment } from "./FormDepartment";
import { Toast, UIButton } from "components";
import {
  deleteDepartment,
  saveDepartment,
  updateDepartment,
  updateFirstDepartment,
} from "api";
import Swal from "sweetalert2";
import { useDepartments } from "hooks";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalDepartment = ({
  open,
  setOpen,
  values,
  parents,
  managers,
  change,
  setChange,
  employees,
  deparments,
  setUpdateWizard = null,
}) => {
  const { t } = useTranslation(["organization"]["general"]);
  const haveEmployees = employees.find((e) => e.department === values?.name);
  const haveChildrens = deparments.find((d) => d.parent === values?._id);
  const canDeleteDepartment =
    !haveEmployees && !haveChildrens && values?.parent !== undefined;

  const { refreshDepartmentSeleectors } = useDepartments();

  const handleSubmit = async (values, { resetForm }) => {
    let message = t("organization:DeparmentSuccessfullyUpdated");
    try {
      if (values.parent === undefined) {
        await updateFirstDepartment(values._id, values);
        refreshDepartmentSeleectors(true);
      } else if (values._id) {
        await updateDepartment(values._id, values);
        refreshDepartmentSeleectors(true);
      } else {
        await saveDepartment(values);
        refreshDepartmentSeleectors(true);
        message = t("organization:DeparmentSuccessfullyCreated");
      }

      setOpen(false);
      setChange(!change);
      resetForm();

      if (setUpdateWizard) {
        setUpdateWizard(Math.random());
      }

      Toast.fire({
        icon: "success",
        title: message,
      });
    } catch (err) {
      setOpen(false);
      Toast.fire({
        icon: "error",
        title: err.message,
      });

      console.log("Error saving deparment: ", err);
    }
  };

  const deleteItem = async (department) => {
    try {
      await deleteDepartment(department._id);
      refreshDepartmentSeleectors(true);
      setOpen(false);
      setChange(!change);
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t("organization:Department")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik initialValues={values} onSubmit={handleSubmit}>
          {(formik) => (
            <Form id="d" autoComplete="off">
              <FormDepartment
                formik={formik}
                parents={parents}
                managers={managers}
              />
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                  {canDeleteDepartment && (
                    <UIButton
                      label={t("general:Eliminar")}
                      onClick={() => {
                        setOpen(false);
                        Swal.fire({
                          title: t("general:Are"),
                          text: t("general:Youwon"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: t("general:deleteit"),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteItem(formik.values);
                            Swal.fire({
                              title: t("general:Deleted"),
                              text: t("general:YourFileHasBeenDeleted"),
                              icon: "success",
                            });
                          }
                        });
                      }}
                      disabled={formik.isSubmitting}
                    />
                  )}
                  {!formik.isSubmitting && (
                    <UIButton
                      type="button"
                      label={t("general:Cancel")}
                      onClick={() => {
                        setOpen(false);
                      }}
                      loading={formik.isSubmitting}
                    />
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </BootstrapDialog>
  );
};
