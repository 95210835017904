import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Description = ({ job }) => {
  const { t } = useTranslation(["recruitment"]["general"]);

  return (
    <Stack spacing={0} mt={2}>
      <Typography>
        {t("recruitment:VacanciesRequestText1")}{" "}
        <Typography component="span" color="primary">
          {job?.quantity ?? 0}
        </Typography>
      </Typography>
      <Typography>
        {t("recruitment:VacanciesRequestText2")}{" "}
        <Typography component="span" color="primary">
          {job?.occupiedPositions ?? 0}
        </Typography>
      </Typography>
      <Typography>
        {t("recruitment:VacanciesRequestText3")}{" "}
        <Typography component="span" color="primary">
          {job?.available ?? 0}
        </Typography>
      </Typography>
      <Typography>
        {t("recruitment:VacanciesRequestText4")}{" "}
        <Typography component="span" color="primary">
          {job?.afterPassing ?? 0}
        </Typography>
      </Typography>
    </Stack>
  );
};
