export const EmployeeNavigation = [
  {
    name: "Inicio",
    path: "/Inicio",
    tree: [
      {
        name: "IndicadoresClave",
        path: "/home/indicators",
        permissions: [],
      },
      {
        name: "Directorio",
        path: "/home/directorio",
        permissions: [],
      },
      {
        name: "Documentos",
        path: "/home/documentos",
        permissions: [],
      },
      {
        name: "WorkTeam",
        path: "/home/work-team",
        permissions: [],
      },
    ],
  },
  {
    name: "Requests",
    path: "/requests",
    tree: [
      {
        name: "JobDescription",
        path: "/job-description",
        permissions: ["add_jobPositions", "change_jobPositions"],
      },
      {
        name: "StaffRequests",
        path: "/recruitment/staff-requests",
        permissions: [
          "add_manageJobPositions",
          "change_manageJobPositions",
          "delete_manageJobPositions",
          "view_manageJobPositions",
        ],
      },
    ],
  },
  // {
  //   name: "Cuenta",
  //   path: "/Cuenta",
  //   tree: [
  //     {
  //       name: "Ajustes",
  //       path: "/account/configurations",
  //       permissions: [],
  //     },
  //     {
  //       name: "GestiónDeUsuarios",
  //       path: "/account/management",
  //       permissions: [],
  //     },
  //     {
  //       name: "InformaciónDeEmpleado",
  //       path: "/account/employee",
  //       permissions: [],
  //     },
  //     {
  //       name: "Cuentas",
  //       path: "/account/accounts",
  //       permissions: [],
  //     },
  //     {
  //       name: "Notificaciones",
  //       path: "/account/notifications",
  //       permissions: [],
  //     },
  //     {
  //       name: "PlanesYFacturación",
  //       path: "/account/plans-billing",
  //       permissions: [],
  //     },
  //     {
  //       name: "HorariosLaborales",
  //       path: "/account/working-hours",
  //       permissions: [],
  //     },
  //   ],
  // },
  {
    name: "Perfil",
    path: "/Perfil",
    tree: [
      {
        name: "Perfil-Collaborator",
        path: "/profile/collaborator",
        tree: [
          {
            name: "Perfil-InformaciónPersonal",
            path: "/profile/collaborator/personal-information",
            permissions: ["change_employees", "view_employees"],
          },
          {
            name: "Perfil-DetallesDeContacto",
            path: "/profile/collaborator/contact-details",
            permissions: [
              "add_contactDetails",
              "change_contactDetails",
              "delete_contactDetails",
              "view_contactDetails",
            ],
          },
          {
            name: "Perfil-DocumentosDeIdentidad",
            path: "/profile/collaborator/identities-documents",
            permissions: [
              "add_identities",
              "change_identities",
              "delete_identities",
              "view_identities",
            ],
          },
          {
            name: "Perfil-HistorialAcadémico",
            path: "/profile/competencies/academy-history",
            permissions: [
              "add_academicBackground",
              "change_academicBackground",
              "delete_academicBackground",
              "view_academicBackground",
            ],
          },
          {
            name: "Perfil-Habilidades",
            fatherName: "Perfil-Habilidades",
            sonOf: "Perfiles",
            path: "/profile/competencies/skills-summary-tab",
            tree: [
              {
                name: "Perfil-Habilidades",
                sonOf: "Perfil-Habilidades",
                path: "/profile/competencies/skills-summary",
                permissions: [
                  "add_employeeSkills",
                  "change_employeeSkills",
                  "delete_employeeSkills",
                  "view_employeeSkills",
                ],
              },
              {
                name: "Perfil-Habilidades-Idiomas",
                sonOf: "Perfil-Habilidades",
                path: "/profile/competencies/skills-summary-languages",
                permissions: [
                  "add_employeeLanguages",
                  "change_employeeLanguages",
                  "delete_employeeLanguages",
                  "view_employeeLanguages",
                ],
              },
            ],
          },
          {
            name: "Perfil-EmpleoActual",
            path: "/profile/employment-information/current-employment",
            permissions: [],
          },
          {
            name: "Perfil-HistorialDeEmpleo",
            path: "/profile/employment-information/employment-summary",
            permissions: [
              "add_employmentHistory",
              "change_employmentHistory",
              "delete_employmentHistory",
              "view_employmentHistory",
            ],
          },
          {
            name: "Perfil-Compensación",
            path: "/profile/compensation",
            permissions: [],
          },
          {
            name: "Perfil-Documentos",
            path: "/profile/documents",
            permissions: ["add_files", "change_files", "view_files"],
          },
          {
            name: "Perfil-Assets",
            path: "/profile/assets",
            permissions: [],
          },
          {
            name: "Perfil-HistoralDeRegistro",
            path: "/profile/record-history",
            permissions: [],
          },
        ],
      },
      {
        name: "Estadísticas",
        path: "/profile/stats",
        permissions: [],
      },
      {
        name: "Referencias",
        path: "/profile/references",
        permissions: [],
      },
      {
        name: "DesarrolloProfesional",
        path: "/profile/desarrolloProfesional",
        permissions: [],
      },
      {
        name: "RendimientoMétricas",
        path: "/profile/metricsPerformance",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/profile/profile-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "TurnoDeAsistencia",
    path: "/TurnoDeAsistencia",
    tree: [
      {
        name: "AsistenciaAusencia",
        sonOf: "TurnoDeAsistencia",
        path: "/shift-attendance/attendance-absence",
        permissions: [],
      },
    ],
  },
  // {
  //   name: "CentroDeAprendizaje",
  //   path: "/CentroDeAprendizaje",
  //   tree: [
  //     {
  //       name: "AcademiaCereza",
  //       path: "/learning-center/cereza-academy",
  //       permissions: [],
  //     },
  //     {
  //       name: "LearningHub",
  //       path: "/learning-center/learning-hub",
  //       permissions: [],
  //     },
  //     {
  //       name: "EvaluaciónDeCompetencias",
  //       path: "/learning-center/competency-assessment",
  //       permissions: [],
  //     },
  //     {
  //       name: "Ajustes",
  //       path: "/learning-center/marketplace-settings",
  //       permissions: [],
  //     },
  //   ],
  // },
  // {
  //   name: "CentroDeRendimiento",
  //   path: "CentroDeRendimiento",
  //   tree: [
  //     {
  //       name: "MisIndicadores",
  //       path: "/performance-center/key-indicators",
  //       permissions: [],
  //     },
  //     {
  //       name: "Evaluaciones",
  //       path: "/performance-center/evaluaciones",
  //       permissions: [],
  //     },
  //   ],
  // },
  // {
  //   name: "Recursos",
  //   path: "/Recursos",
  //   tree: [
  //     {
  //       name: "Cronograma",
  //       path: "/resources/schedule",
  //       permissions: [],
  //     },
  //     {
  //       name: "Visitantes",
  //       path: "/resources/visitors",
  //       permissions: [],
  //     },
  //   ],
  // },
  // {
  //   name: "Médico",
  //   path: "/Médico",
  //   tree: [
  //     {
  //       name: "Incapacidades",
  //       path: "/medical/disabilities",
  //       permissions: [],
  //     },
  //     {
  //       name: "Registro",
  //       path: "/medical/record",
  //       permissions: [],
  //     },
  //     {
  //       name: "Historia",
  //       path: "/medical/history",
  //       permissions: [],
  //     },
  //     {
  //       name: "CertificadoMédico",
  //       path: "/medical/medicalCertificate",
  //       permissions: [],
  //     },
  //     {
  //       name: "Referrals",
  //       path: "/medical/medical-referrals",
  //       permissions: [],
  //     },
  //     {
  //       name: "Ajustes",
  //       path: "/medical/medical-settings",
  //       permissions: [],
  //     },
  //   ],
  // },
  // {
  //   name: "Herramientas",
  //   path: "/Herramientas",
  //   tree: [
  //     {
  //       name: "Junta",
  //       path: "/tools/board",
  //       permissions: [],
  //     },
  //     {
  //       name: "InsigniaYLogro",
  //       path: "/tools/badge-achievement",
  //       permissions: [],
  //     },
  //     {
  //       name: "Boleto",
  //       path: "/tools/tickets",
  //       permissions: [],
  //     },
  //   ],
  // },
  {
    name: "RutasEspeciales",
    path: "/RutasEspeciales",
    tree: [
      {
        name: "SearchAdvanced",
        path: "/search/advanced",
        permissions: [],
      },
    ],
  },
];
