import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BasicTable, UIButton, UIPaper } from "components";
import { useTranslation } from "react-i18next";
import { Box, Grid, TableCell, Typography } from "@mui/material";
import { setLocations, generalSelect } from "@redux/slices/general";
import { ModalSettings } from "./ModalSettings";
import { getTimeCheck, getLocations } from "api";

export const ShiftAttendanceSettings = () => {
  const { t } = useTranslation(["shiftAttendance"]);

  const dispatcher = useDispatch();

  const { locations } = useSelector(generalSelect);

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [newData, setNewData] = useState(false);
  const [locationsIndex, setLocationsIndex] = useState({});
  const [iValues, setIValues] = useState(undefined);

  useEffect(() => {
    const fetchLocations = async () => {
      if (locations?.length === 0 || !locations || locations === undefined) {
        try {
          const { data } = await getLocations("selectors");
          dispatcher(setLocations(data));
          setLocationsIndex(
            data.reduce((acc, row) => {
              acc[row.value] = row;
              return acc;
            }, {})
          );
        } catch (err) {
          console.error("Error getting locations:", err);
        }
      } else {
        setLocationsIndex(
          locations.reduce((acc, row) => {
            acc[row.value] = row;
            return acc;
          }, {})
        );
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    const fetchTimeCheck = async () => {
      try {
        const { data } = await getTimeCheck();

        const rows = data.map((row) => ({
          id: row._id,
          name: row.name,
          type: row.type,
          locations: locationsIndex[row.location]?.label ?? "",
          isActive:
            row.isActive === true
              ? t("shiftAttendance:Si")
              : t("shiftAttendance:No"),
        }));
        setRows(rows);

        setResponseData(
          data.reduce((acc, row) => {
            acc[row._id] = row;
            return acc;
          }, {})
        );
      } catch (err) {
        console.error("Error getting locations:", err);
      }
    };
    if (Object.keys(locationsIndex)?.length > 0) {
      fetchTimeCheck();
    }
  }, [newData, locationsIndex]);

  const handleClick = (row) => {
    setIValues(responseData[row?.id]);
    setOpen(true);
  };

  return (
    <>
      <UIPaper>
        <Box p={4}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item={true} xs={12} md={9}>
              <Typography variant="h5" mb={2}>
                {t("shiftAttendance:RelojesChecadores")}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <UIButton
                sx={{ marginRight: "8px", width: "100%" }}
                onClick={() => {
                  setIValues(undefined);
                  setOpen(true);
                }}
              >
                {t("shiftAttendance:Crear")}
              </UIButton>
            </Grid>
          </Grid>
          <br />
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("shiftAttendance:RelojChecador")}</TableCell>
            <TableCell>{t("shiftAttendance:Tipo")}</TableCell>
            <TableCell>{t("shiftAttendance:Ubicaciones")}</TableCell>
            <TableCell>{t("shiftAttendance:Activo")}</TableCell>
          </BasicTable>
        </Box>
      </UIPaper>
      <ModalSettings
        open={open}
        setOpen={setOpen}
        setNewData={setNewData}
        iValues={iValues}
      />
    </>
  );
};
