import { Box, Tabs, Tab } from "@mui/material";
import { useAccess } from "../../../../hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const MenuNav = ({ ubication = "organization", redirect = false }) => {
  const { t } = useTranslation(["organization"]);
  const navigate = useNavigate();
  const { access } = useAccess();

  return (
    <>
      <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
        <Tabs
          value={ubication}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          style={{ maxWidth: "100%" }}
          sx={
            redirect
              ? {
                  "& .MuiTab-root": {
                    color: "#fff",
                    backgroundColor: "primary.light",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "primary.light",
                      opacity: 0.7,
                    },
                  },
                }
              : {}
          }
        >
          {access(
            "/cereza-organization/organization/organization-settings"
          ) && (
            <Tab
              value="organization"
              label={t("organization:organization")}
              onClick={() => {
                navigate(
                  "/cereza-organization/organization/organization-settings"
                );
              }}
            />
          )}
          {access(
            "/cereza-organization/organization/organization-settings/ubications-org"
          ) && (
            <Tab
              value="locations"
              label={t("organization:locations")}
              onClick={() => {
                navigate(
                  "/cereza-organization/organization/organization-settings/ubications-org"
                );
              }}
            />
          )}
          {(access(
            "/cereza-organization/organization/organization-settings/catalogs"
          ) ||
            access(
              "/cereza-organization/organization/organization-settings/catalogs-currencies"
            ) ||
            access(
              "/cereza-organization/organization/organization-settings/catalogs-languages"
            )) && (
            <Tab
              value="catalogs"
              label={t("organization:catalogs")}
              onClick={() => {
                navigate(
                  "/cereza-organization/organization/organization-settings/catalogs"
                );
              }}
            />
          )}
          {access(
            "/cereza-organization/organization/organization-settings/plan"
          ) && (
            <Tab
              value="plan"
              label={t("organization:plan")}
              onClick={() => {
                navigate(
                  "/cereza-organization/organization/organization-settings/plan"
                );
              }}
            />
          )}
        </Tabs>
      </Box>
    </>
  );
};
