import { useRef } from "react";
import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
} from "@mui/material";
import { UIButton } from "components";
import { DeleteForever as DeleteForeverIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";
export const UIMultipleFileInput = ({
  formik,
  photos,
  setPhotos,
  label,
  disabled = false,
}) => {
  const inputFile = useRef(null);

  const { mode } = useSelector(parameterizationSelect);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const LoadPhotos = async (files) => {
    const arrayPhotos = Array.from(files).map((e) => e);
    const listPhotos = [...photos];

    for (let i = 0; i < arrayPhotos.length; i++) {
      let photob64 = await getBase64(arrayPhotos[i])
        .then((result) => {
          return result;
        })
        .catch((e) => {
          console.log(e);
        });

      listPhotos.push(photob64);
    }

    setPhotos(listPhotos);
  };

  const OpenModalFile = () => {
    inputFile.current.click();
  };

  return (
    <Stack
      sx={{
        borderRadius: "5px",
        padding: "1rem",
        backgroundColor: mode === "dark" ? "#272c36" : "#f7f7fa",
      }}
      spacing={2}
    >
      <Box>
        <UIButton
          label={label}
          onClick={OpenModalFile}
          disabled={formik.isSubmitting || disabled}
        />
        <input
          name="files"
          type="file"
          multiple
          accept="image/*"
          ref={inputFile}
          hidden
          onChange={(event) => {
            LoadPhotos(event.currentTarget.files);
          }}
        />
      </Box>
      <ImageList variant="quilted" cols={3} rowHeight={164}>
        {photos.map((photo, index) => (
          <ImageListItem key={`${index}-${photo}`}>
            <img
              srcSet={`${photo}`}
              src={`${photo}?w=164&h=164&fit=crop&auto=format`}
            />
            <ImageListItemBar
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.75)" }}
                  onClick={() => {
                    let listPhotos = [...photos];
                    listPhotos.splice(index, 1);
                    setPhotos(listPhotos);
                  }}
                  disabled={formik.isSubmitting || disabled}
                >
                  <DeleteForeverIcon />
                </IconButton>
              }
            ></ImageListItemBar>
          </ImageListItem>
        ))}
      </ImageList>
    </Stack>
  );
};
