import { getCountries } from "api";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { generalSelect, setCountries } from "../redux/slices/general";
import { useSelector } from "react-redux";

export function useCountries() {
  const dispatcher = useDispatch();
  const { countries } = useSelector(generalSelect);

  useEffect(() => {
    const fetchCountries = async () => {
      if (countries?.length === 0 || !countries || countries === undefined) {
        try {
          const { data } = await getCountries();
          dispatcher(setCountries(data));
        } catch (err) {
          console.error("Error getting countries:", err);
        }
      }
    };

    fetchCountries();
  }, []);
}
