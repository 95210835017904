import { deleteNotifications, markAsReadNotifications } from "api";
import { Toast } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function useActionsNotificatio() {
  const { t } = useTranslation(["general"]["settings"]);
  const navigate = useNavigate();

  const markAsRead = async (notification, fetchNotifications) => {
    try {
      await markAsReadNotifications(notification?._id);
      fetchNotifications();
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("settings:ErrorSendingData"),
      });
    }
  };

  const deleteNotification = async (
    notification,
    fetchNotifications,
    handleCloseNotification = null
  ) => {
    try {
      await deleteNotifications(notification?._id);
      if (handleCloseNotification) {
        handleCloseNotification();
      }
      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
      fetchNotifications();
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: t("settings:ErrorSendingData"),
      });
    }
  };

  const redirectNotification = (type, url = '/cereza-organization/') => {

    switch (type) {
      case "NEW_SIGN_ADDED":
        return navigate(`${url}requests`);

      case "DOCUMENT_TO_BE_REVIEW":
        return navigate(`${url}requests`);

      case "DOCUMENT_REJECTED":
        return navigate(`${url}requests/history`);

      case "DOCUMENT_APPROVED":
        return navigate(`${url}requests/history`);

      case "DOCUMENT_TO_BE_SIGN":
        return navigate(`${url}requests/signature`);

      default:
        break;
    }
  };

  return { markAsRead, deleteNotification, redirectNotification };
}
