import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Toast, UIButton, UIModal } from "components";
import SelectingTable from "components/Table/SelectingTable";
import { managerErrors } from "utils/functions";
import {
  getPermissionsGroup,
  postUserPermissionsGroups,
  putUserPermissionsGroups,
} from "api";

export const AssignGroup = ({
  employeeSelected,
  getGroups,
  selected,
  setSelected,
  openAssignGroup,
  setOpenAssignGroup,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const [groups, setGroups] = useState([]);

  const headCells = [
    {
      id: "collection",
      numeric: false,
      disablePadding: true,
      label: t("settings:GruposDePermisos"),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("settings:AsignarGrupo"),
    },
    {
      id: "codename",
      numeric: false,
      disablePadding: true,
      label: t("settings:NumeroDePermisos"),
    },
  ];

  useEffect(() => {
    const getGrupos = async () => {
      try {
        const { data } = await getPermissionsGroup();

        const rows = data.map((item) => ({
          id: item._id,
          name: item.name,
          description: item.description,
          permissions: item.permits.length.toString(),
        }));

        setGroups(rows);
      } catch (error) {
        console.error(error);
      }
    };
    getGrupos();
  }, []);

  const onSubmit = async () => {
    try {
      if (getGroups?._id) {
        getGroups.groups = selected;
        await putUserPermissionsGroups(getGroups, getGroups._id);
      } else {
        const body = {
          userId: employeeSelected?.id,
          groups: selected,
        };
        await postUserPermissionsGroups(body);
      }
      Toast.fire({
        icon: "success",
        title: t("settings:PermissionsOK"),
      });
      setSelected([]);
      setOpenAssignGroup(false);
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <UIModal open={openAssignGroup} setOpen={setOpenAssignGroup}>
        <Box p={4}>
          <Typography variant="h6">{t("settings:AsignarGrupo")}</Typography>
          <Typography variant="subtitle2">
            {`${employeeSelected?.fullName} - ${employeeSelected?.position}`}
          </Typography>
          <br />
          <SelectingTable
            headCells={headCells}
            rows={groups}
            selected={selected}
            setSelected={setSelected}
            name={t("settings:GruposDelSistema")}
            perPage={10}
          />
          <Box
            mt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" gap="1em">
              <UIButton onClick={onSubmit} label={t("general:Guardar")} />
              <UIButton
                label={t("general:Cancel")}
                onClick={() => {
                  setOpenAssignGroup(false);
                }}
              />
            </Box>
          </Box>
        </Box>
      </UIModal>
    </>
  );
};
