import * as React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
} from "@mui/material";
import { getComparator, stableSort } from "./helpers";
import { alpha } from "@mui/material/styles";
import EnhancedTableHead from "./EnhancedTableHead";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Avatar from "@mui/material/Avatar";

export default function SelectingTable({
  children,
  headCells,
  rows,
  selected,
  setSelected,
  name = "",
  actionOnClick = null,
  perPage = 25,
  deleteButton = false,
  sxTableTitle = { flex: "1 1 100%" },
  canPermissions = true,
  blocked = false,
  maxHeight = "60vh",
}) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].label);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(perPage);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, row) => {
    if (
      (event.target.attributes.hasOwnProperty("type") &&
        event.target.attributes.type.value === "checkbox") ||
      actionOnClick === null
    ) {
      const selectedIndex = selected.indexOf(row.id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row.id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    } else {
      actionOnClick(row);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%" }}>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(selected.length > 0 && {
              bgcolor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.activatedOpacity
                ),
            }),
          }}
        >
          {selected.length > 0 ? (
            <Typography
              sx={{ flex: "1 1 100%" }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {name} - {selected.length} seleccionados
            </Typography>
          ) : (
            <Typography
              sx={sxTableTitle}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {name}
            </Typography>
          )}
          {selected.length > 0 && deleteButton ? (
            <Tooltip title="Delete">
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            children
          )}
        </Toolbar>
        <TableContainer sx={{ maxHeight: maxHeight }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover={blocked === false}
                    onClick={(event) => {
                      if (canPermissions && blocked === false) {
                        handleClick(event, row);
                      }
                    }}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: blocked ? "progress" : "pointer" }}
                  >
                    {Object.keys(row).map((key, index) => {
                      let cell_props = {};
                      let cell_content = row[key];

                      if (key === "id") {
                        cell_props = {
                          padding: "checkbox",
                        };
                        cell_content = (
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        );
                      } else if (key === "thumbnail") {
                        cell_props = {
                          component: "th",
                          id: labelId,
                          padding: "none",
                          align: "left",
                        };
                        cell_content = (
                          <Avatar
                            src={cell_content}
                            sx={{
                              width: 24,
                              height: 24,
                            }}
                          />
                        );
                      } else {
                        cell_props = {
                          component: "th",
                          id: labelId,
                          padding: "none",
                          align: "left",
                        };
                      }

                      return (
                        <TableCell key={index} {...cell_props}>
                          {cell_content}
                        </TableCell>
                      );
                    })}
                    {headCells[headCells.length - 1]?.id === "actions" && (
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={() => actionOnClick(row)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={perPage !== 25 ? [perPage] : [5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
