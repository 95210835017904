import React from "react";
import { Box } from "@mui/material";
import working from "../../../assets/illustrations/en-progreso.png";

export const Bilding = () => {
  return (
    <>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <img width="40%" src={working} alt="Bilding" />
      </Box>
    </>
  );
};
