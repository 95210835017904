import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UIJoditEditor, UISelect, UITextField } from "components";
import { Description } from "./Description";

export const FormStaffRequest = ({
  formik,
  canAdd,
  canChange,
  jobsPosition,
}) => {
  const { t } = useTranslation(["recruitment"]["general"]);

  const { status, _id } = formik.values;
  const bool_id = Boolean(_id);

  const disabled =
    status === "PENDING" ||
    (!canAdd && status === "REVIEW_REQUIRED") ||
    (!canChange && bool_id) ||
    formik.isSubmitting;

  let job = jobsPosition.find((obj) => obj._id === formik.values?.jobPosition);

  if (job) {
    job.available = job?.quantity - job?.occupiedPositions;
    job.afterPassing = formik.values.quantity
      ? job.available + formik.values.quantity
      : job.available - formik.values.quantity;
  }

  return (
    <>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12} md={6}>
          <UISelect
            label={t("recruitment:JobPosition")}
            name="jobPosition"
            formik={formik}
            options={jobsPosition}
            disabled={disabled}
            isrequired={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UITextField
            label={t("recruitment:VacanciesPlus")}
            name="quantity"
            formik={formik}
            disabled={disabled || !job}
            isrequired={true}
            type="number"
            InputProps={{
              inputProps: {
                min: job ? -job.available : "",
                max: 99,
              },
            }}
          />
        </Grid>
        <Grid item={true} md={12}>
          <UIJoditEditor
            name="vacancyReason"
            label={t("recruitment:VacancyReasonPlus")}
            disabled={disabled || !job}
          />
        </Grid>
      </Grid>
      {["PENDING", "REVIEW_REQUIRED"].includes(status) && job && (
        <Description job={job} />
      )}
    </>
  );
};
