import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openFilterPage: false,
  employeeSelected: {},
  employees: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setOpenFilterPage: (state, action) => {
      state.openFilterPage = action.payload;
    },
    setEmployeeSelected: (state, action) => {
      state.employeeSelected = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
  },
});

export const { setOpenFilterPage, setEmployeeSelected, setEmployees } =
  profileSlice.actions;

export const profileSelect = (state) => state.profile;

export default profileSlice.reducer;
