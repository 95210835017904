import { useEffect, useState } from "react";
import { MenuRequests } from "../MenuRequests";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TableCell,
} from "@mui/material";
import { getDocumentsToSign } from "api";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";
import { useRequests } from "hooks";
import { convertUTCToLocal } from "helpers";
import _ from "lodash";
import { BasicTable, UIButton } from "components";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Filtro } from "../components/Filtro";
import { SignModal } from "./SingModal";
dayjs.extend(utc);

export const Signature = ({ application = "organization" }) => {
  const { t } = useTranslation(["request"]);

  const { user } = useSelector(authUserSelect);
  const { employees } = useRequests();
  const [documents, setDocuments] = useState([]);
  const [signAuths, setSignAuths] = useState(null);
  const [dataRequests, setDataRequests] = useState([]);
  const [finish, setFinish] = useState(false);
  const [dataFilter, setDataFilter] = useState(null);
  const [open, setOpen] = useState(false);
  const [typeDocument, setTypeDocument] = useState(null);
  const [request, setRequest] = useState(null);
  const [signAuth, setSignAuth] = useState(null);
  const [update, setUpdate] = useState(null);

  const [typesOfRequests, setTypesOfRequests] = useState({
    staff: {
      label: t("request:Staff"),
      checked: false,
    },
    vacation: {
      label: t("request:Vacations"),
      checked: false,
    },
    permissions: {
      label: t("request:Permissions"),
      checked: false,
    },
    justifications: {
      label: t("request:Justifications"),
      checked: false,
    },
    jobPosition: {
      label: t("request:JobsPositions"),
      checked: false,
    },
    quitting: {
      label: t("request:Quitting"),
      checked: false,
    },
    termination: {
      label: t("request:Dismissal"),
      checked: false,
    },
  });

  const reduceDocuments = ({ data, documents }) => {
    documents = _.reduce(
      data,
      function (result, value) {
        result[value._id] = value;
        return result;
      },
      documents,
    );

    return documents;
  };

  const mapRequest = ({ requests, data, type }) => {
    if (data) {
      const newRequests = data.map((i) => ({
        id: i._id,
        folio: i.folio,
        applicant: employees[i.applicant].fullName,
        createdAt: convertUTCToLocal(i.createdAt).format("YYYY-MM-DD HH:mm"),
        status: t(`recruitment:${i.status}`),
        type: t(`recruitment:${type}`),
      }));

      requests.all = _.unionBy(newRequests, requests.all, "id");

      requests.types = data.reduce(
        (previous, current) => ((previous[current._id] = type), previous),
        requests.types,
      );
    }

    return requests;
  };

  const orderRequests = ({ requests }) => {
    requests.all.sort((a, b) =>
      dayjs(a.createdAt).isBefore(dayjs(b.createdAt)),
    );
  };

  useEffect(() => {
    setFinish(false);

    const fetchGet = async () => {
      try {
        let requests = { all: [], types: {} };
        let documentsReduce = {};
        const { data } = await getDocumentsToSign(user._id);

        if (data?.documents) {
          const documents = data.documents;
          const attendancePermits = documents?.attendancePermits;
          const jobPosition = documents?.jobPositions;
          const justifications = documents?.justifications;
          const manageJobPositions = documents?.manageJobPositions;
          const vacations = documents?.vacations;
          const endContracts = documents?.endContracts;

          if (vacations) {
            requests = mapRequest({
              requests: requests,
              data: vacations,
              type: "vacation",
            });

            documentsReduce = reduceDocuments({
              data: vacations,
              documents: documentsReduce,
            });
          }

          if (attendancePermits) {
            const permissions = attendancePermits.filter(
              (o) => o.type !== "VACATION",
            );
            requests = mapRequest({
              requests: requests,
              data: permissions,
              type: "permissions",
            });

            documentsReduce = reduceDocuments({
              data: attendancePermits,
              documents: documentsReduce,
            });
          }

          if (jobPosition) {
            requests = mapRequest({
              requests: requests,
              data: jobPosition,
              type: "jobPosition",
            });

            documentsReduce = reduceDocuments({
              data: jobPosition,
              documents: documentsReduce,
            });
          }

          if (justifications) {
            requests = mapRequest({
              requests: requests,
              data: justifications,
              type: "justifications",
            });

            documentsReduce = reduceDocuments({
              data: justifications,
              documents: documentsReduce,
            });
          }

          if (manageJobPositions) {
            requests = mapRequest({
              requests: requests,
              data: manageJobPositions,
              type: "staff",
            });

            documentsReduce = reduceDocuments({
              data: manageJobPositions,
              documents: documentsReduce,
            });
          }

          if (endContracts) {
            requests = mapRequest({
              requests: requests,
              data: _.filter(endContracts, { type: "QUITTING" }),
              type: "quitting",
            });

            requests = mapRequest({
              requests: requests,
              data: _.filter(endContracts, { type: "TERMINATION" }),
              type: "termination",
            });

            documentsReduce = reduceDocuments({
              data: endContracts,
              documents: documentsReduce,
            });
          }
        }

        orderRequests({ requests });
        setDataRequests(requests);
        setDocuments(documentsReduce);
        setSignAuths(data.signAuths);
        setFinish(true);
      } catch (error) {
        console.error("getDocumentsToSign: ", error);
      }
    };

    if (employees) {
      fetchGet();
    }
  }, [employees, update]);

  useEffect(() => {
    if (finish) {
      let requests = _.cloneDeep(dataRequests);

      const requestsChecked = _.reduce(
        typesOfRequests,
        function (result, value, key) {
          if (value.checked) {
            result.push(key);
          }

          return result;
        },
        [],
      );

      if (requestsChecked.length) {
        requests.all = _.filter(requests.all, function (request) {
          return requestsChecked.includes(request.type);
        });
      }

      requests.all = requests.all.map(({ type, ...rest }) => ({
        ...rest,
        type: t(`request:t${type}`),
      }));
      setDataFilter(requests);
    }
  }, [finish, dataRequests, typesOfRequests]);

  const handleClick = async (row) => {
    const type = dataRequests.types[row.id];
    setTypeDocument(type);
    setRequest(documents[row.id]);
    setSignAuth(_.find(signAuths, (s) => s.document === row.id));
    setOpen(true);
  };

  const confirmSign = () => {
    let requests = _.cloneDeep(dataRequests);
    requests.all = requests.all.filter((item) => {
      return item.id !== request._id;
    });
    setDataRequests(requests);
  };

  return (
    <Paper>
      <MenuRequests ubication={"Signature"} application={application} />
      <Box p={2}>
        {finish ? (
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={9}>
              {dataFilter ? (
                <BasicTable
                  PerPage={25}
                  rows={dataFilter.all}
                  handleClick={handleClick}
                  customStyle={"StatusRequest"}
                >
                  <TableCell>{t("recruitment:Folio")}</TableCell>
                  <TableCell>{t("recruitment:Applicant")}</TableCell>
                  <TableCell>{t("request:CreationDateAndTime")}</TableCell>
                  <TableCell>{t("recruitment:status")}</TableCell>
                  <TableCell>{t("request:TypeOfRequest")}</TableCell>
                </BasicTable>
              ) : (
                <Alert severity="info">{t("request:NotRequestPending")}</Alert>
              )}
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <Filtro
                typesOfRequests={typesOfRequests}
                setTypesOfRequests={setTypesOfRequests}
                requests={dataRequests}
                showNewRequest={false}
              >
                <UIButton
                  label={t("request:Update")}
                  onClick={() => {
                    setUpdate(Math.random());
                  }}
                />
              </Filtro>
            </Grid>
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
          </Stack>
        )}
      </Box>
      <SignModal
        open={open}
        setOpen={setOpen}
        typeDocument={typeDocument}
        request={request}
        signAuth={signAuth}
        confirmSign={confirmSign}
      />
    </Paper>
  );
};
