import { Typography, Grid } from "@mui/material";
import { Toast, UIModal, UIPasswordField, UISubmit } from "components";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { resetPIN } from "api";
import { managerErrors } from "utils/functions";

export const PIN = ({ open, setOpen, id }) => {
  const { t } = useTranslation(["employee"]["general"]);

  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .min(4, t("general:validation15"))
      .max(4, t("general:validation16"))
      .notOneOf(["1234", "0123"], t("general:validation6"))
      .matches(/\S/, t("general:9"))
      .required(t("general:validation10")),

    pin2: Yup.string()
      .oneOf([Yup.ref("pin"), null], t("general:validation11"))
      .required(t("general:validation12")),
  });

  const handleSubmit = async (values) => {
    try {
      await resetPIN(id, values);
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
      setOpen(false);
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <UIModal open={open} setOpen={setOpen}>
        <Typography variant="h6" mb={2}>
          {t("employee:AsignarPIN")}
        </Typography>
        <Formik
          initialValues={{ pin: "", pin2: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="AsignarPIN" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={6}>
                  <UIPasswordField
                    formik={formik}
                    label={t("employee:pin")}
                    name={"pin"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={6}>
                  <UIPasswordField
                    formik={formik}
                    label={t("employee:pin2")}
                    name={"pin2"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
              </Grid>
              <UISubmit formik={formik} isFull={true} />
            </Form>
          )}
        </Formik>
      </UIModal>
    </>
  );
};
