import {
  DownhillSkiing as SkillIcon, MonetizationOn as MoneyIcon,
  PersonRemove as TerminationIcon
} from '@mui/icons-material';
import { Languages } from "./Languages";
import { Currencies } from "./Currencies";
import { Skills } from "./Skills";
import { Termination } from "./Termination";
import { Box, Paper } from "@mui/material";
import { MenuNav } from "./MenuNav";
import TranslateIcon from '@mui/icons-material/Translate';
import { useAccess } from "hooks";
import { Paneles } from "modules/Settings/Configurations/Paneles";
import { useTranslation } from "react-i18next";

export const PageCatalogs = () => {
  const { t } = useTranslation(["organization"]["general"]);
  const { access } = useAccess();

  const paneles = [
    {
      id: "skill",
      title: t("organization:Skills"),
      icon: <SkillIcon />,
      content: <Skills />,
      hasAccess: access("/cereza-organization/organization/organization-settings/catalogs"),
    },
    {
      id: "currencies",
      title: t("organization:Currencies"),
      icon: <MoneyIcon />,
      content: <Currencies />,
      hasAccess: access(
        "/cereza-organization/organization/organization-settings/catalogs-currencies"
      ),
    },
    {
      id: "lenguajes",
      title: t("organization:Lenguajes"),
      icon: <TranslateIcon />,
      content: <Languages />,
      hasAccess: access("/cereza-organization/organization/organization-settings/catalogs-languages"),
    },
    {
      id: "termination",
      title: t("organization:Termination"),
      icon: <TerminationIcon />,
      content: <Termination />,
      hasAccess: access("/cereza-organization/organization/organization-settings/termination-reason"),
    },
  ];
  return (
    <>
      <Paper>
        <MenuNav ubication={"catalogs"} />
        <Box p={2}>
          <Paneles paneles={paneles} />
        </Box>
      </Paper>
    </>
  );
};
