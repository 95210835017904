import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Box, Typography } from "@mui/material";
import { Field } from "formik";

export const UICheckBox = React.memo(
  ({ label = "", name = "", checked, onChange, width = "100%", style, disabled }) => {
    return (
      <Box
        component="label" // Permite modificar el checkbox al tocar el label
        sx={{
          width: width,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        style={style}
      >
        <Field
          type="checkbox"
          name={name}
          as={Checkbox}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
          {label}
        </Typography>
      </Box>
    );
  },
  // Comparador 
  (prevProps, nextProps) =>
    prevProps.checked === nextProps.checked &&
    prevProps.label === nextProps.label &&
    prevProps.name === nextProps.name &&
    prevProps.onChange === nextProps.onChange &&
    prevProps.width === nextProps.width &&
    prevProps.disabled === nextProps.disabled &&
    JSON.stringify(prevProps.style) === JSON.stringify(nextProps.style)
);

UICheckBox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  width: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};