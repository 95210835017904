import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid } from "@mui/material";
import {
  UIModal,
  UITextField,
  TagInput,
  FilePreview,
  UIDatePicker,
  UISelect,
  UINumericInput,
  UIButton,
} from "components";
import dayjs from "dayjs";
import { IMPACT_CHOICES, USAGE_TYPE_CHOICES } from "utils";
import { setAssetTypes, generalSelect } from "@redux/slices/general";
import { getAssetTypes, saveAssets, updateAssets } from "api";

export const ModalAsset = ({ open, setOpen, title }) => {
  const { t } = useTranslation(["employee"]["general"]);

  const dispatcher = useDispatch();
  const { assetTypes } = useSelector(generalSelect);

  const [src, setSrc] = useState();

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        if (
          assetTypes === undefined ||
          !assetTypes ||
          assetTypes?.length === 0
        ) {
          const { data } = await getAssetTypes();
          dispatcher(setAssetTypes(data));
        }
      } catch (err) {
        console.error("Error getting asset types:", err);
      }
    };

    fetchTypes();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    const updatedValues = {
      ...values,
      purchaseDate: dayjs(values.purchaseDate).format("YYYY-MM-DD"),
      warrantyDate: dayjs(values.warrantyDate).format("YYYY-MM-DD"),
    };
    try {
      if (values?._id) {
        const { data } = await updateAssets(updatedValues, values._id);
        console.log(data);
        setOpen(false);
        resetForm();
      } else {
        const { data } = await saveAssets(updatedValues);
        console.log(data);
        setOpen(false);
        resetForm();
      }
    } catch (err) {
      console.error("Error submitting form:", err);
    }
  };

  return (
    <UIModal open={open} setOpen={setOpen}>
      <Typography variant="h5">{t(title)}</Typography>
      <br />
      <Formik
        initialValues={{
          name: "",
          brand: "",
          model: "",
          invoice: "",
          description: "",
          serialNumber: "",
          purchaseDate: dayjs(),
          warrantyDate: dayjs(),
          assetTag: [],
          cost: "",
          impact: "low",
          usageType: "permanent",
          assetType: "",
        }}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form id="d" autoComplete="off">
            <Grid container spacing={2}>
              <Grid item={true} xs={12}>
                <FilePreview
                  src={src}
                  setSrc={setSrc}
                  lblBtnUpdate={t("employee:Cargarinvoice")}
                  isAvatar={true}
                  formik={formik}
                  name={"invoice"}
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item={true} xs={12}>
                    <UITextField
                      formik={formik}
                      label={t("employee:Name")}
                      name={"name"}
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <UITextField
                      formik={formik}
                      label={t("employee:SerialNumber")}
                      name={"serialNumber"}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UITextField
                      formik={formik}
                      label={t("employee:Brand")}
                      name={"brand"}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UITextField
                      formik={formik}
                      label={t("employee:Model")}
                      name={"model"}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UISelect
                      formik={formik}
                      label={t("employee:AssetType")}
                      name={"assetType"}
                      options={assetTypes}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item={true} xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item={true} xs={12}>
                    <UIDatePicker
                      formik={formik}
                      label={t("employee:PurchaseDate")}
                      name={"purchaseDate"}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UIDatePicker
                      formik={formik}
                      label={t("employee:WarrantyDate")}
                      name={"warrantyDate"}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UINumericInput
                      formik={formik}
                      label={t("employee:Cost")}
                      name={"cost"}
                      prefix={"$"} // TODO conseguir la moneda preferida del cliente para este prefijo
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <UISelect
                      formik={formik}
                      label={t("employee:impact")}
                      name={"impact"}
                      options={IMPACT_CHOICES}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UISelect
                      formik={formik}
                      label={t("employee:UsageType")}
                      name={"usageType"}
                      options={USAGE_TYPE_CHOICES}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12}>
                <UITextField
                  formik={formik}
                  label={t("employee:Description")}
                  name={"description"}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TagInput
                  formik={formik}
                  label={t("employee:AssetTag")}
                  name={"assetTag"}
                />
              </Grid>
            </Grid>

            <Box
              mt={2}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" gap="1em">
                <UIButton type="submit" label={t("general:Guardar")} />
                <UIButton
                  onClick={() => {
                    setOpen(false);
                  }}
                  label={t("general:Cancel")}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </UIModal>
  );
};
