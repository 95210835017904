import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataForTable: {},
  requestUpdated: null,
};

export const requests = createSlice({
  name: "requests",
  initialState: initialState,
  reducers: {
    setDataForTable: (state, action) => {
      state.dataForTable = action.payload;
    },
    setRequestUpdated: (state, action) => {
      state.requestUpdated = action.payload;
    },
  },
});

export const { setDataForTable, setRequestUpdated } = requests.actions;

export const requestsSelect = (state) => state.requests;

export default requests.reducer;
