import {
  Box,
} from "@mui/material";
import { UIBootstrapDialog, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { useRef, useCallback, useState } from "react";
import Webcam from "react-webcam";
import Swal from "sweetalert2";

export const CameraModal = (props) => {
  const { open, setOpen, setSrc, formik, name } = props;
  const { t } = useTranslation(["settings"]["general"]);
  const webcamref = useRef(null);
  const [webcamReady, setWebcamReady] = useState(false);
  const capture = useCallback(() => {
    const photoTaken = webcamref.current.getScreenshot();
    if (photoTaken !== null) {
      const imageSrc = photoTaken;
      setSrc(imageSrc);
      formik.setFieldValue(name, imageSrc);
      handleCloseCamera();
    } else {
      handleUserMediaError();
    }
  }, [webcamref]);

  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user",
  };
  const handleCloseCamera = () => {
    setOpen(false);
  };
  const handleUserMediaError = (error = "") => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: t("settings:ErrorCameraNotFound"),
    });
    handleCloseCamera();
  };
  return (
    <UIBootstrapDialog open={open} onClose={handleCloseCamera} maxWidth={"fit-content"} fullWidth={false}>
      <Box p={2} maxWidth={"fit-content"}>
        <Webcam
          audio={false}
          width={500}
          height={500}
          ref={webcamref}
          onUserMedia={() => {
            setWebcamReady(true); 
          }}
          onUserMediaError={handleUserMediaError}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <Box mt={2} display="flex" width={"fit-content"} maxWidth={"55%"} gap="1em">
          <UIButton
            disabled={!webcamReady}
            onClick={capture}
            label={t("settings:Accept")}
          />
        </Box>
      </Box>
    </UIBootstrapDialog>
  );
};
