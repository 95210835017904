import { cerezaClient } from "../adapters";

const PERMISSIONS = "/v1/auth/cereza/permissions";

export function getPermissions() {
  return cerezaClient.get(`${PERMISSIONS}/list`);
}

export function getPermissionsGroup() {
  return cerezaClient.get(`${PERMISSIONS}/group`);
}

export function postPermissionsGroup(data) {
  return cerezaClient.post(`${PERMISSIONS}/group`, data);
}

export function putPermissionsGroup(data, id) {
  return cerezaClient.put(`${PERMISSIONS}/group/${id}`, data);
}

export function deletePermissionsGroup(id) {
  return cerezaClient.delete(`${PERMISSIONS}/group/${id}`);
}
export function getUserPermissionsGroups(userId) {
  return cerezaClient.get(`${PERMISSIONS}/user-group?userId=${userId}`);
}

export function postUserPermissionsGroups(data) {
  return cerezaClient.post(`${PERMISSIONS}/user-group`, data);
}

export function putUserPermissionsGroups(data, id) {
  return cerezaClient.put(`${PERMISSIONS}/user-group/${id}`, data);
}

export function getUserPermissions(userId) {
  return cerezaClient.get(`${PERMISSIONS}/user-permissions/${userId}`);
}

export function postUserPermissions(data) {
  return cerezaClient.post(`${PERMISSIONS}/user-permissions`, data);
}

export function putUserPermissions(data, id) {
  return cerezaClient.put(`${PERMISSIONS}/user-permissions/${id}`, data);
}

export function getAccessApps() {
  return cerezaClient.get(`${PERMISSIONS}/apps`);
}

export function patchAccessApps(data) {
  return cerezaClient.patch(`${PERMISSIONS}/apps`, data);
}

export function getEmployeesOfGroup(id) {
  return cerezaClient.get(`${PERMISSIONS}/user-group?id=${id}`);
}
