import { Grid, Typography } from "@mui/material";
import {
  UIButton,
  UIDatePicker,
  UISwitch,
  UITextField,
} from "../../../components";
import { FieldArray } from "formik";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import dayjs from "dayjs";

export const FormHolidayDetails = ({ formik, nameFiledArray }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  return (
    <FieldArray name={nameFiledArray}>
      {({ push, remove }) => (
        <>
          <Grid container spacing={2} mb={2} mt={2}>
            <Grid item={true} xs={12} md={11}>
              <Typography variant="h5">
                {t("shiftAttendance:Holidays")}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={1}>
              <UIButton
                onClick={() =>
                  push({
                    date: dayjs.utc().format("YYYY-MM-DD"),
                    localName: "",
                    name: "",
                    isWorkday: false,
                  })}
                disabled={formik.isSubmitting}
              >
                <AddCircleOutlineIcon />
              </UIButton>
            </Grid>
          </Grid>
          {formik.values.holidays.map((valuesDetail, index) => (
            <Fragment key={index}>
              <Grid container={true} spacing={2} mb={2}>
                <Grid item={true} xs={12} md={9}>
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12} md={2}>
                      <UIDatePicker
                        name={"date"}
                        label={t("shiftAttendance:Date")}
                        formik={formik}
                        nameFileArray={nameFiledArray}
                        index={index}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                      <UITextField
                        name={"localName"}
                        label={t("shiftAttendance:LocalName")}
                        formik={formik}
                        nameFileArray={nameFiledArray}
                        index={index}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={5}>
                      <UITextField
                        name={"name"}
                        label={t("shiftAttendance:Name")}
                        formik={formik}
                        nameFileArray={nameFiledArray}
                        index={index}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12} md={3}>
                  <Grid container={true}>
                    <Grid item={true} xs={12} md={9}>
                      <UISwitch
                        name={"isWorkday"}
                        label={t("shiftAttendance:IsWorkday")}
                        formik={formik}
                        nameFileArray={nameFiledArray}
                        index={index}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={3}>
                      <UIButton
                        onClick={() =>
                          remove(index)}
                        disabled={formik.isSubmitting}
                      >
                        <DeleteIcon />
                      </UIButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          ))}
        </>
      )}
    </FieldArray>
  );
};
