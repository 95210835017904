import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenant: {
    _id: "",
    tenant: "",
    currentPlan: "",
    commercialName: "",
    legalName: "",
    companyLogo: "",
    companyDescription: "",
    companyPhone: "",
    socialNetwork: [],
    taxIdentificationNumber: "",
    customColors: {},
    acceptTermsAndConditions: true,
  },
  currentPlan: {
    _id: "",
    name: "",
    isActive: true,
    monthlyPrice: 0,
    annualPrice: 0,
    employees: 0,
    monthlyEmails: 0,
    monthlySMS: 0,
    smsPrice: 0,
    gbSpace: 0,
    gcpS3Price: 0,
    numberManagers: 1,
    departments: 3,
    emailSupport: true,
    chatSupport: false,
    phoneSupport: false,
    supportSchedule: "",
    emailTemplates: 0,
    smtpSupport: false,
    activeVacancies: 0,
    expiredVacancies: 0,
    interviewQuestionnaires: 0,
    interviewEvaluations: 0,
    createdAt: "",
    updatedAt: "",
  },
};

export const tenantSettings = createSlice({
  name: "tenantSettings",
  initialState: initialState,
  reducers: {
    setTenantSettings: (state, action) => {
      state.tenant = action.payload;
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
  },
});

export const { setTenantSettings, setCurrentPlan } = tenantSettings.actions;

export const tenantSettingsSelect = (state) => state.tenantSettings;

export default tenantSettings.reducer;
