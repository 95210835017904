import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { Toast, UIButton } from "components";
import { useTranslation } from "react-i18next";
import { FormAcademy } from "./FormAcademy";
import { saveCollages } from "api/globals";
import { useDispatch } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalAcademy = ({ open, setOpen, collages, setCollages }) => {
  const { t } = useTranslation(["employee"]["general"]);
  const dispatcher = useDispatch();

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { data } = await saveCollages(values);

      dispatcher(setCollages([...collages, data]));
      setOpen(false);

      resetForm();
      Toast.fire({
        icon: "success",
        title: t("employee:CollageSuccessfullyCreated"),
      });
    } catch (err) {
      console.log(err);
      setOpen(false);
      Toast.fire({
        icon: "error",
        title: err.messages,
      });
    }
  };

  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle>{t("employee:Institution")}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik
          initialValues={{
            institution: "",
            location: "",
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <FormAcademy formik={formik} />
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                  {!formik.isSubmitting && (
                    <UIButton
                      type="button"
                      label={t("general:Cancel")}
                      onClick={() => {
                        setOpen(false);
                      }}
                      loading={formik.isSubmitting}
                    />
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </BootstrapDialog>
  );
};
