import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  holidays: "",
};

const timeOffSlice = createSlice({
  name: "timeOff",
  initialState,
  reducers: {
    setHolidays: (state, action) => {
      state.holidays = action.payload;
    },
  },
});

export const { setHolidays } = timeOffSlice.actions;

export const timeOffSelect = (state) => state.timeOff;

export default timeOffSlice.reducer;
