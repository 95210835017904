import { PersonalInformation } from "modules/Employees/Profiles/Collaborator/PersonalInformation";
import { ContactDetails } from "modules/Employees/Profiles/Collaborator/ContactDetails";
import { IdentitiesDocuments } from "modules/Employees/Profiles/Collaborator/IdentitiesDocuments";
import { RecordHistory } from "modules/Employees/Profiles/RecordHistory";
import { AcademyHistory } from "modules/Employees/Profiles/Competencies/AcademicHistory";
import { SkillsSummary } from "modules/Employees/Profiles/Competencies/SkillsSummary";
import { CurrentEmployment } from "modules/Employees/Profiles/EmploymentInformation/CurrentEmployment";
import { EmploymentSummary } from "modules/Employees/Profiles/EmploymentInformation/EmploymentsSummary";
import { Assets } from "modules/Employees/Profiles/Assets";
import { Documents } from "modules/Employees/Profiles/Documents";
import { Compensation } from "modules/Employees/Profiles/Compensation";
import { TimeClock } from "modules/Employees/Profiles/TimeClock";
import { Bilding } from "components/Bilding";
import { OrganizationNavigationSource } from "modules/Settings/Security/CreateGroups/OrganizationNavigationSource";

function generateRoutesFromNavigation(source, isSpecial = false) {
  source = !Array.isArray(source) ? Array(source) : source;
  return source.reduce((routes, item) => {
    let accumulatedRoutes = routes;

    if (item.tree) {
      const childRoutes = generateRoutesFromNavigation(item.tree, isSpecial);
      accumulatedRoutes = accumulatedRoutes.concat(childRoutes);
    }
    let condicional = isSpecial ? item.is_special : item.is_nav;
    if (condicional) {
      const pathToNameMap = {
        "/cereza-organization/Planes": "Planes",
        "/cereza-organization/organization/organization-settings/plan": "Plan",
        "/cereza-organization/shift-attendance/time-off-holidays/holidays":
          "Holidays",
        "/cereza-organization/shift-attendance/policy": "Policy",
        "/cereza-organization/shift-attendance/work-schedule": "WorkSchedule",
        "/cereza-organization/organization/organization-settings/ubications-org":
          "UbicationsOrg",
        "/cereza-organization/organization/organization-settings/catalogs":
          "Catalogos",
        "/cereza-organization/settings/configurations": "Configuraciones",
        "/cereza-organization/recruitment/staff-requests": "StaffRequest",
      };

      item.name = pathToNameMap[item.path] || item.name;

      const routeConfig = {
        name: item.name,
        route: item.path,
        component: item.component || <Bilding />,
      };
      accumulatedRoutes.push(routeConfig);
    }
    return accumulatedRoutes;
  }, []);
}

export const HOME = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Home")
);

export const REQUESTS = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Requests")
);

export const ACCOUNT = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Cuenta").tree
);

export const ORGANIZATION = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Organización").tree
);

export const RECRUITMENT = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Reclutamiento")
    .tree
);

export const EMPLOYEES = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Empleados").tree
);

export const SHIFT_ATTENDANCE = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "TurnoDeAsistencia")
    .tree
);

export const LERNING_CENTER = generateRoutesFromNavigation(
  OrganizationNavigationSource.find(
    (item) => item.name === "CentroDeAprendizaje"
  ).tree
);

export const PERFORMANCE_CENTER = generateRoutesFromNavigation(
  OrganizationNavigationSource.find(
    (item) => item.name === "CentroDeRendimiento"
  ).tree
);

export const ASSETS = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Activos").tree
);

export const RESOURCES = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Recursos").tree
);

export const TOOLS = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Herramientas").tree
);

export const SETTINGS = generateRoutesFromNavigation(
  OrganizationNavigationSource.find((item) => item.name === "Configuraciones")
);

export const SPECIAL_ROUTES = generateRoutesFromNavigation(
  OrganizationNavigationSource,
  true
);

const PATH_PROFILE = "/cereza-organization/employees/profile";
const PATH_COLLABORATOR = `${PATH_PROFILE}/collaborator`;
const PATH_COMPETENCIES = `${PATH_PROFILE}/competencies`;
const PATH_EMPLOYMENT = `${PATH_PROFILE}/employment-information`;

export const PROFILE_MODULE = {
  Collaborator: {
    name: "Collaborator",
    sections: [
      {
        name: "PersonalInformation",
        route: `${PATH_COLLABORATOR}/personal-information`,
        component: <PersonalInformation />,
      },
      {
        name: "ContactDetails",
        route: `${PATH_COLLABORATOR}/contact-details`,
        component: <ContactDetails />,
      },
      {
        name: "IdentitiesDocuments",
        route: `${PATH_COLLABORATOR}/identities-documents`,
        component: <IdentitiesDocuments />,
      },
    ],
  },
  Competencies: {
    name: "Competencies",
    sections: [
      {
        name: "AcademyHistory",
        route: `${PATH_COMPETENCIES}/academy-history`,
        component: <AcademyHistory />,
      },
      {
        name: "SkillsSummary",
        route: `${PATH_COMPETENCIES}/skills-summary`,
        component: <SkillsSummary />,
      },
    ],
  },
  EmploymentInformation: {
    name: "EmploymentInformation",
    sections: [
      {
        name: "CurrentEmployment",
        route: `${PATH_EMPLOYMENT}/current-employment`,
        component: <CurrentEmployment />,
      },
      {
        name: "EmploymentSummary",
        route: `${PATH_EMPLOYMENT}/employment-summary`,
        component: <EmploymentSummary />,
      },
    ],
  },
  Compensation: {
    name: "Compensation",
    route: `${PATH_PROFILE}/compensation`,
    component: <Compensation />,
    sections: [],
  },
  TimeClock: {
    name: "TimeClock",
    route: `${PATH_PROFILE}/time-clocks`,
    component: <TimeClock />,
    sections: [],
  },
  Documents: {
    name: "Documents",
    route: `${PATH_PROFILE}/documents`,
    component: <Documents />,
    sections: [],
  },
  Assets: {
    name: "Assets",
    route: `${PATH_PROFILE}/assets`,
    component: <Assets />,
    sections: [],
  },
  RecordHistory: {
    name: "RecordHistory",
    route: `${PATH_PROFILE}/record-history`,
    component: <RecordHistory />,
    sections: [],
  },
};
