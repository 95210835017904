import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { ProfileTabs, Spinner, TitleBox, Toast, UIButton } from "components";
import { Form, Formik } from "formik";
import { generalSelect } from "@redux/slices/general";
import { profileSelect } from "@redux/slices/profile";
import {
  getDepartments,
  getEmploymentInfo,
  getFlowVacation,
  getJobPositions,
  saveEmploymentInfo,
  selectingEmployeeList,
  updateEmploymentInfo,
} from "api";
import { managerErrors } from "utils";
import { useAccess, useLocations } from "hooks";
import { useNavigate } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import _ from "lodash";
import { FormCurrentEmployment } from "./FormCurrentEmployment";
import { pathEmployee, isFormerEmployee, getProfileReturnUri } from "helpers";

export const CurrentEmployment = ({
  application = "organization",
  user = null,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);
  const [jobDescriptionOptions, setJobDescriptionOptions] = useState([]);
  const [jobPositionsData, setJobPositionsData] = useState([]);
  const [allDataDownload, setAllDataDownload] = useState(false);
  const [supervisors, setSupervisors] = useState([]);
  const [vacationFlowSettings, setVacationFlowSettings] = useState([]);

  const { refreshLocationsSeleectors } = useLocations();

  refreshLocationsSeleectors();

  const { locations } = useSelector(generalSelect);
  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [initialValues, setInitialValues] = useState({});

  const { HasPermissions } = useAccess();
  const { canAccess, canChange } = 
  isFormerEmployee(HasPermissions(
    pathEmployee(application, "/profile/employment-information/current-employment"),
      application,
    ),
    employeeSelected
  );
  const returnUri = getProfileReturnUri(employeeSelected);

  const mapSupervisors = (job) => {
    let supervisors = [
      {
        value: "",
        label: t("employee:SelectSupervisor"),
      },
    ];

    if (
      jobPositionsData[job.position] &&
      jobPositionsData[job.position].reportAt.length
    ) {
      supervisors = _.concat(
        supervisors,
        _.map(jobPositionsData[job.position].reportAt, (i) => ({
          value: i._id,
          label: i.fullName,
        })),
      );
    }

    setSupervisors(supervisors);
  };

  useEffect(() => {
    const getDataBase = async () => {
      try {
        let { data: departments } = await getDepartments(null);
        let { data: employees } = await selectingEmployeeList();
        let { data: jobPositions } = await getJobPositions(null);
        let { data: dataVacationFlowSettings } = await getFlowVacation();

        jobPositions = jobPositions.reduce((acc, j) => {
          j.department = _.find(departments, (o) => o._id === j.department);
          j.reportAt = _.filter(employees, (o) => o.positionId === j.reportAt);
          j.occupiedPositions = _.filter(
            employees,
            (o) => o.positionId === j._id,
          ).length;
          j.available = j.occupiedPositions < j.quantity;

          acc[j._id] = j;

          return acc;
        }, {});

        setJobPositionsData(jobPositions);

        setVacationFlowSettings(
          dataVacationFlowSettings.map(({ _id: value, title: label }) => ({
            value,
            label,
          })),
        );

        setAllDataDownload(true);
      } catch (err) {
        console.log("Error get data: ", err);
      }
    };

    getDataBase();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let { data } = await getEmploymentInfo(employeeSelected._id);

        mapSupervisors(data);

        setJobDescriptionOptions(
          _.map(
            _.filter(jobPositionsData, (job) => {
              return job.available || job._id === data.position;
            }),
            (job) => ({
              value: job._id,
              label: job.title,
            }),
          ),
        );

        setInitialValues(
          _.merge(
            {
              employee: employeeSelected._id,
              position: "",
              startDateCurrentPosition: "",
              workLocation: "",
              supervisor: "",
              dateJoiningCompany: "",
              cellphoneNumber: "",
              telephoneNumber: "",
              extensionNumber: "",
              vacationFlowSetting: "",
            },
            data,
          ),
        );
      } catch (err) {
        console.error("Error getting current employment:", err);
      }
    };

    if (allDataDownload) {
      fetchData();
    }
  }, [allDataDownload, employeeSelected]);

  const handleSubmit = async (values) => {
    const updatedValues = { ...values };

    try {
      if (values?._id) {
        const { data } = await updateEmploymentInfo(updatedValues, values._id);
        setInitialValues(data);
      } else {
        const { data } = await saveEmploymentInfo(updatedValues);
        setInitialValues(data);
      }
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <ProfileTabs
        tab_initial="employment-information"
        application={application}
      />
      <br />
      <Paper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
            isRed={!!employeeSelected?.formerEmployee}
          />
          <Typography variant="h5" mb={2}>
            {t("employee:EmploymentInformation")}
          </Typography>
          {initialValues.position === undefined ? (
            <Spinner />
          ) : (
            <>
              {canAccess && (
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form id="language" autoComplete="off">
                      <FormCurrentEmployment
                        formik={formik}
                        locations={locations}
                        jobDescriptionOptions={jobDescriptionOptions}
                        supervisorsOptions={supervisors}
                        canChange={canChange}
                        mapSupervisors={mapSupervisors}
                        jobDescriptionData={jobPositionsData}
                        vacationFlowSettings={vacationFlowSettings}
                      />
                      <Stack
                        mt={2}
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        {application === "organization" && (
                          <UIButton
                            variant="contained"
                            startIcon={<ChevronLeftIcon />}
                            onClick={() =>
                              navigate(
                                returnUri,
                              )
                            }
                            disabled={formik.isSubmitting}
                            fullWidth={false}
                          />
                        )}
                        {canChange && (
                          <UIButton
                            type="submit"
                            label={
                              formik?.values?._id
                                ? t("general:Actualizar")
                                : t("general:Guardar")
                            }
                            loading={formik.isSubmitting}
                            fullWidth={false}
                          />
                        )}
                      </Stack>
                    </Form>
                  )}
                </Formik>
              )}
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};
