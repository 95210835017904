import { Dialog, DialogTitle, IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const UIBootstrapDialog = (props) => {
  const { title, children, setOpen, ...modalProps } = props;

  return (
    <BootstrapDialog maxWidth="lg" fullWidth={true} {...modalProps}>
      <DialogTitle sx={{ m: 0, p: 2 }}>{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => modalProps?.onClose ? modalProps.onClose() : setOpen(false)}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      {children}
    </BootstrapDialog>
  );
};
