import axios from "axios";
import store from "@redux/store";
import { CLIENT_ID } from "utils/constants";

/**
 * Clase HttpAdapter para manejar las solicitudes HTTP.
 */
class HttpAdapter {
  constructor(baseURL) {
    this.httpClient = axios.create({
      baseURL: baseURL,
    });
    this.setupInterceptors();
  }

  /**
   * Configura los interceptores para manejar la autenticación y el manejo de respuestas.
   */
  setupInterceptors() {
    this.httpClient.interceptors.request.use(this.authInterceptor.bind(this));
    this.httpClient.interceptors.response.use(
      this.successInterceptor.bind(this),
      this.errorInterceptor.bind(this)
    );
  }

  /**
   * Interceptor para añadir los headers de autorización y otros headers necesarios.
   * @param {Object} config Configuración de la solicitud.
   * @returns {Object} Configuración modificada.
   */
  authInterceptor(config) {
    const token = this.getAuthToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json";
      config.headers["Client"] = CLIENT_ID;
      config.headers["Realm"] = this.getTenant();
      config.headers["Accept-Language"] = this.getLanguage();
      config.headers["Time-Zone"] = this.getTimeZone();
    }
    return config;
  }

  /**
   * Interceptor para manejar las respuestas exitosas.
   * @param {Object} response Respuesta de la solicitud.
   * @returns {Object} Respuesta original.
   */
  successInterceptor(response) {
    return response;
  }

  /**
   * Interceptor para manejar los errores.
   * @param {Object} error Error de la solicitud.
   * @returns {Promise} Rechazada con el error.
   */
  errorInterceptor(error) {
    // Manejo de errores
    if (error?.response?.status) {
      switch (error.response.status) {
        case 401:
          const { url } = error.config;
          if (url === "/auth/sign-in/keycloak") return;
          else {
            console.log("Su sesión ha terminado");
            setTimeout(() => {
              localStorage.clear();
              document.location.reload();
            }, 2000);
          }
      }
    }
    return Promise.reject(error);
  }

  /**
   * Obtiene el token de autenticación del estado de Redux.
   * @returns {string|null} Token de autenticación o null si no existe.
   */
  getAuthToken() {
    const state = store.getState();
    const { accessToken } = state.authUser;
    return accessToken || null;
  }

  getLanguage() {
    const state = store.getState();
    const { language } = state?.ui?.preferences;
    return language?.abb || "es";
  }

  getTimeZone() {
    const state = store.getState();
    const { timeZone } = state?.ui;
    return timeZone || null;
  }

  /**
   * Determina el dominio del tenant basado en la URL actual.
   * @returns {string} Dominio del tenant.
   */
  getTenant() {
    const url = window.location.href;
    if (url.includes("localhost")) return process.env.REACT_APP_TENANT;
    const tenant = url.split(".")[0].split("//")[1];
    return tenant;
  }

  /**
   * Realiza una solicitud GET.
   * @param {string} url URL de la solicitud.
   * @param {Object} [params] Parámetros de la solicitud.
   * @returns {Promise} Respuesta de la solicitud.
   */
  get(url, params = {}) {
    return this.request({ method: "GET", url, params });
  }

  /**
   * Realiza una solicitud POST.
   * @param {string} url URL de la solicitud.
   * @param {Object} data Datos de la solicitud.
   * @returns {Promise} Respuesta de la solicitud.
   */
  post(url, data) {
    return this.request({ method: "POST", url, data });
  }

  /**
   * Realiza una solicitud PUT.
   * @param {string} url URL de la solicitud.
   * @param {Object} data Datos de la solicitud.
   * @returns {Promise} Respuesta de la solicitud.
   */
  put(url, data) {
    return this.request({ method: "PUT", url, data });
  }

  /**
   * Realiza una solicitud PATCH.
   * @param {string} url URL de la solicitud.
   * @param {Object} data Datos de la solicitud.
   * @returns {Promise} Respuesta de la solicitud.
   */
  patch(url, data) {
    return this.request({ method: "PATCH", url, data });
  }

  /**
   * Realiza una solicitud DELETE.
   * @param {string} url URL de la solicitud.
   * @returns {Promise} Respuesta de la solicitud.
   */
  delete(url) {
    return this.request({ method: "DELETE", url });
  }

  /**
   * Realiza una solicitud HTTP con la configuración dada.
   * @param {Object} config Configuración de la solicitud.
   * @returns {Promise} Respuesta de la solicitud.
   */
  request(config) {
    return this.httpClient.request(config);
  }
}

export default HttpAdapter;
