import { useEffect } from "react";
import PropTypes from "prop-types";
import { IconButton, Modal, Box, Typography, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyle } from "helpers";
import { useTranslation } from "react-i18next";
import {
  Toast,
  UINumericInput,
  UISwitch,
  UITextField,
  UIButton,
  UISelect,
} from "components";
import { Form, Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setCurrencies, generalSelect } from "@redux/slices/general";
import { getCurrencies, postBenefits, putBenefits } from "api";
import { setReRender } from "@redux/slices/ui";
import { managerErrors } from "utils";

export const ModalCompensation = ({
  open,
  setOpen,
  benefitSelected = null,
}) => {
  const { t } = useTranslation(["employee"]["general"]);

  const dispatcher = useDispatch();

  const { currencies } = useSelector(generalSelect);

  useEffect(() => {
    const fetchCurrencies = async () => {
      if (currencies?.length === 0 || !currencies || currencies === undefined) {
        try {
          const { data } = await getCurrencies(true);
          dispatcher(setCurrencies(data));
        } catch (err) {
          console.error("Error getting currencies:", err);
        }
      }
    };

    fetchCurrencies();
  }, []);

  const getPrefix = (currencyId) => {
    const currency = currencies.find((obj) => obj.value === currencyId);
    return currency?.prefix;
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (benefitSelected === null) {
      try {
        await postBenefits(values);
        dispatcher(setReRender(Math.random()));
        Toast.fire({
          icon: "success",
          title: t("organization:BeneficioCreadoConExito"),
        });
        setOpen(false);
      } catch (err) {
        managerErrors(err?.response?.data?.message);
        console.error("Error submitting form:", err);
      }
    } else {
      try {
        const { id, ...res } = values;
        await putBenefits(id, res);
        dispatcher(setReRender(Math.random()));
        Toast.fire({
          icon: "success",
          title: t("organization:BeneficioActualizadoConExito"),
        });
        setOpen(false);
      } catch (err) {
        managerErrors(err?.response?.data?.message);
        console.error("Error submitting form:", err);
      }
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={{ width: "auto" }} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h5">{t("organization:Beneficio")}</Typography>
        <br />
        <Formik
          initialValues={
            benefitSelected !== null
              ? benefitSelected
              : {
                  isActive: true,
                  title: "",
                  periodicity: "",
                  amount: "",
                  currency: "",
                }
          }
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={12}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("employee:Nombre")}
                        name={"title"}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UINumericInput
                        formik={formik}
                        label={t("employee:Montoeconómico")}
                        name={"amount"}
                        prefix={
                          getPrefix(formik?.values?.currency, currencies) ?? "$"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("employee:Periodicidadendías")}
                        name={"periodicity"}
                        type="number"
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UISelect
                        formik={formik}
                        label={t("organization:Divisa")}
                        name={"currency"}
                        options={currencies}
                      />
                    </Grid>
                    {benefitSelected !== null && (
                      <Grid item={true} xs={12} md={6}>
                        <UISwitch
                          formik={formik}
                          label={t("employee:isActive")}
                          name={"isActive"}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton type="submit" label={t("general:Guardar")} />
                  <UIButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    label={t("general:Cancel")}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

ModalCompensation.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
