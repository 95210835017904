import {
  Cached as CachedIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  ButtonGroup,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAccess } from "hooks";
import { getAutorizationFlowHelper, getEmployeeInTimeClock } from "api";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { setJobDescription } from "@redux/slices/jobDescription";
import { setStaffRequest } from "@redux/slices/recruitment";

export const MenuRequest = ({
  setOpen,
  application = "organization",
  setUpdate,
  setTypeDocument,
  supervises,
}) => {
  const { t } = useTranslation(["request"]);
  const dispatch = useDispatch();
  const { user } = useSelector(authUserSelect);
  const [requests, setRequests] = useState({});
  const [finish, setFinish] = useState(false);
  const [canCreateRequests, setCanCreateRequests] = useState(false);
  const { HasPermissions } = useAccess();

  const pathStaff =
    application === "organization" ? "/cereza-organization" : "";
  const { canAdd: canAddStaff } = HasPermissions(
    `${pathStaff}/recruitment/staff-requests`,
    application,
  );
  const pathJobPosition =
    application === "organization"
      ? "/cereza-organization/organization/job-description"
      : "/job-description";
  const { canAdd: canAddJobPosition } = HasPermissions(
    pathJobPosition,
    application,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = (typeDocument) => {
    if (typeDocument === "jobPosition") {
      dispatch(
        setJobDescription({
          title: "",
          workMode: "",
          quantity: 1,
          department: "",
          reportAt: null,
          profile: {
            gender: "",
            maritalStatus: "",
            minAge: "",
            maxAge: "",
            experience: {
              haveExperience: true,
              howManyYearsOfExperience: 1,
            },
            trainingIn: [],
            specializedKnowledge: [],
            languages: [],
            education: [],
            skills: [],
          },
          description: {
            objective: "<p><br></p>",
            functions: "<p><br></p>",
            responsibilities: "<p><br></p>",
            salaryRange: {
              minSalary: "",
              maxSalary: "",
              currency: "",
            },
          },
          externalRelations: "",
          positionType: "",
          publicationLanguage: "",
          staffRequests: [],
          status: "REVIEW_REQUIRED",
        }),
      );
    } else if (typeDocument === "staff") {
      dispatch(
        setStaffRequest({
          vacancyReason: "",
          quantity: 1,
          jobPosition: "",
          status: "REVIEW_REQUIRED",
        }),
      );
    }
    setTypeDocument(typeDocument);
    setAnchorEl(null);
    setOpen(true);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data: timeClock } = await getEmployeeInTimeClock(user._id);
        let {
          data: { justifications, ...validRequests },
        } = await getAutorizationFlowHelper(user._id);
        validRequests.jobPositions =
          validRequests.jobPositions && canAddJobPosition;
        validRequests.manageJobPositions =
          validRequests.manageJobPositions && canAddStaff;
        validRequests.vacations =
          validRequests.vacations && timeClock.policy && timeClock.schedule;

        setRequests(validRequests);
        setCanCreateRequests(_.some(_.values(validRequests), Boolean));
        setFinish(true);
      } catch (e) {
        console.log(e);
      }
    };

    getData();
  }, []);

  if (canCreateRequests && finish) {
    return (
      <ButtonGroup variant="contained" fullWidth={true}>
        <Button
          id="new-request-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={onClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          {t("request:New")}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{ "aria-labelledby": "new-request-button" }}
        >
          {requests?.vacations && (
            <MenuItem
              onClick={() => {
                onClose("vacation");
              }}
            >
              {t("request:Solicituddevacaciones")}
            </MenuItem>
          )}
          {requests?.attendancePermits && (
            <MenuItem
              onClick={() => {
                onClose("permissions");
              }}
            >
              {t("request:Solicituddepermisos")}
            </MenuItem>
          )}
          {requests?.jobPositions && (
            <MenuItem
              onClick={() => {
                onClose("jobPosition");
              }}
            >
              {t("request:SolicituddeNuevoPuesto")}
            </MenuItem>
          )}
          {requests?.manageJobPositions && (
            <MenuItem
              onClick={() => {
                onClose("staff");
              }}
            >
              {t("request:SolicitudAumentoPuestos")}
            </MenuItem>
          )}
          {requests?.employee_quitting && (
            <MenuItem
              onClick={() => {
                onClose("quitting");
              }}
            >
              {t("request:RequestForResignation")}
            </MenuItem>
          )}
          {requests?.employee_termination && supervises.length > 0 && (
            <MenuItem
              onClick={() => {
                onClose("termination");
              }}
            >
              {t("request:RequestDismissal")}
            </MenuItem>
          )}
        </Menu>
        <Button
          onClick={() => {
            setUpdate(Math.random());
          }}
          endIcon={<CachedIcon />}
        ></Button>
      </ButtonGroup>
    );
  } else if (finish) {
    return <Alert severity="warning">{t("request:cantCreateRequest")}</Alert>;
  } else {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <CircularProgress />
      </Stack>
    );
  }
};
