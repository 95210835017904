import { Grid, Box, Typography } from "@mui/material";
import { Toast, UIButton, UIModal, UISwitch } from "components";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { patchAccessApps } from "api";
import { managerErrors } from "utils/functions";

export const AccessApps = ({
  open,
  setOpen,
  employeeSelected,
  dataEmployeeSelected,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const body = {
        apps: values,
        userId: employeeSelected?.id,
      };

      await patchAccessApps(body);
      setOpen(false);

      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <UIModal open={open} setOpen={setOpen}>
        <Box p={4}>
          <Typography variant="h6">{t("settings:Aplicaciones")}</Typography>
          <Typography variant="subtitle2">
            {`${employeeSelected?.fullName} - ${employeeSelected?.position}`}
          </Typography>
          <br />
          <Formik
            initialValues={
              dataEmployeeSelected?.apps
                ? dataEmployeeSelected?.apps
                : { organization: false, employees: false }
            }
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item={true} xs={12}>
                    <UISwitch
                      formik={formik}
                      label={t("settings:CerezaOrganization")}
                      name={"organization"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <UISwitch
                      formik={formik}
                      label={t("settings:CerezaEmployee")}
                      name={"employees"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                </Grid>
                <br />
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap="1em">
                    <UIButton
                      type="submit"
                      label={t("Guardar")}
                      loading={formik.isSubmitting}
                    />
                    <UIButton
                      onClick={() => {
                        setOpen(false);
                      }}
                      label={t("Cancel")}
                      disabled={formik.isSubmitting}
                    />
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </UIModal>
    </>
  );
};
