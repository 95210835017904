import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  refreshToken: null,
  keycloakProfile: null,
  myKeycloakId: "",
  myProfile: null,
  user: {},
  permissions: [],
};

const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setAuthUser: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    setKeycloakProfile: (state, action) => {
      state.keycloakProfile = action.payload;
    },
    setMyKeycloakId: (state, action) => {
      state.myKeycloakId = action.payload;
    },
    setMyProfile: (state, action) => {
      state.myProfile = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});

export const {
  setAuthUser,
  setRefreshToken,
  setKeycloakProfile,
  setMyKeycloakId,
  setMyProfile,
  setUser,
  setPermissions,
} = authUserSlice.actions;

export const authUserSelect = (state) => state.authUser;

export default authUserSlice.reducer;
