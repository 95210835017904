import { Divider, Grid } from "@mui/material";
import {
  UITextFast,
  UISelectFast,
  UISwitchFast,
  UISelectMultipleFast,
} from "components";
import { useTranslation } from "react-i18next";

export const FormAuthorizationGeneric = ({ formik, employees, disabled }) => {
  const { t } = useTranslation();

  const NOTIFICATIONS_METHODS = [
    { value: "BROADCAST", label: t("options:BROADCAST") },
    { value: "GROUP", label: t("options:GROUP") },
    { value: "CHAIN", label: t("options:CHAIN") },
  ];

  const AUTHORIZATION_METHOD_CHOICES = [
    { value: "ONE", label: t("options:ONE") },
    { value: "ALL", label: t("options:ALL") },
    { value: "MAJORITY", label: t("options:MAJORITY") },
  ];

  const AUTOMATIC_METHOD_CHOICES = [
    { value: "APPROVE", label: t("options:APPROVE") },
    { value: "REJECT", label: t("options:REJECT") },
  ];

  return (
    <>
      <Divider>{t("organization:Nivel1")}</Divider>
      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:authorizationMethod")}
            name="flowSettings.authorizationMethod"
            options={AUTHORIZATION_METHOD_CHOICES}
            required={true}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:hierarchicalOrder")}
            name={"flowSettings.hierarchicalOrder"}
            onChange={(e) => {
              if (e.target.checked) {
                formik.setFieldValue("flowSettings.approvers", []);
              } else {
                formik.setFieldValue("flowSettings.maxHierarchyDepth", "");
              }
              formik.setFieldValue(
                "flowSettings.hierarchicalOrder",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={12}>
          <UISelectMultipleFast
            label={t("organization:approvers")}
            name="flowSettings.approvers"
            options={employees}
            required={!formik.values.flowSettings.hierarchicalOrder}
            disabled={disabled || formik.values.flowSettings.hierarchicalOrder}
          />
        </Grid>
      </Grid>
      <Divider>{t("organization:Nivel2")}</Divider>

      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={4}>
          <UITextFast
            type="number"
            label={t("organization:maxHierarchyDepth")}
            name="flowSettings.maxHierarchyDepth"
            required={formik.values.flowSettings.hierarchicalOrder}
            disabled={disabled || !formik.values.flowSettings.hierarchicalOrder}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISwitchFast
            label={t("organization:finalAuthorizer")}
            name={"flowSettings.finalAuthorizer"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue("flowSettings.authorizers", []);
              }
              formik.setFieldValue(
                "flowSettings.finalAuthorizer",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <UISelectMultipleFast
            label={t("organization:authorizers")}
            name={"flowSettings.authorizers"}
            options={employees}
            required={formik.values.flowSettings.finalAuthorizer}
            disabled={disabled || !formik.values.flowSettings.finalAuthorizer}
          />
        </Grid>
      </Grid>

      <Divider>{t("organization:configuracionesAdicionales")}</Divider>
      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:sendNotificationMethod")}
            name="flowSettings.deliveryMethod"
            options={NOTIFICATIONS_METHODS}
            required={true}
            disabled={disabled}
          />
        </Grid>
      </Grid>
      <Grid container={true} spacing={2} mb={2} mt={1}>
        <Grid item={true} xs={12} md={3}>
          <UISwitchFast
            label={t("organization:automaticAuthorized")}
            name={"flowSettings.automaticAuthorized"}
            onChange={(e) => {
              if (!e.target.checked) {
                formik.setFieldValue("flowSettings.automaticMethod", null);
                formik.setFieldValue("flowSettings.waitingTime", "");
              }
              formik.setFieldValue(
                "flowSettings.automaticAuthorized",
                e.target.checked,
              );
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UISelectFast
            label={t("organization:automaticMethod")}
            name="flowSettings.automaticMethod"
            options={AUTOMATIC_METHOD_CHOICES}
            required={formik.values.flowSettings.automaticAuthorized}
            disabled={
              disabled || !formik.values.flowSettings.automaticAuthorized
            }
          />
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <UITextFast
            type="number"
            label={t("organization:waitingTime")}
            name="flowSettings.waitingTime"
            required={formik.values.flowSettings.automaticAuthorized}
            disabled={
              disabled || !formik.values.flowSettings.automaticAuthorized
            }
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
