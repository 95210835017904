import { useSelector } from "react-redux";
import { Toast, UIButton, UIPaper } from "components";
import {
  setWorkSchedule,
  workSchedulesSelect,
} from "@redux/slices/work-schedules";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { FormWorkSchedule } from "./FormWorkSchedule";
import { FormWeeksSchedule } from "./FormWeeksSchedule";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { deleteSchedule, postSchedule, putSchedule } from "api";
import * as Yup from "yup";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

export const WorkSchedule = ({ setUpdateWizard = null }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const dispatch = useDispatch();
  const { workSchedule } = useSelector(workSchedulesSelect);
  const [schedule, setSchedule] = useState(null);
  const [deleting, setDeleting] = useState(false);
  dayjs.extend(customParseFormat);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);

  const DAYS_CHOICES = [
    { value: "MONDAY", label: t("shiftAttendance:Monday") },
    { value: "TUESDAY", label: t("shiftAttendance:Tuesday") },
    { value: "WEDNESDAY", label: t("shiftAttendance:Wednesday") },
    { value: "THURSDAY", label: t("shiftAttendance:Thursday") },
    { value: "FRIDAY", label: t("shiftAttendance:Friday") },
    { value: "SATURDAY", label: t("shiftAttendance:Saturday") },
    { value: "SUNDAY", label: t("shiftAttendance:Sunday") },
  ];

  const DAYS = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  useEffect(() => {
    let v = JSON.parse(JSON.stringify(workSchedule));

    v.weeks = workSchedule.weeks.map((week) => {
      let days = {};

      DAYS.forEach((day) => {
        if (week.hasOwnProperty(day)) {
          days[day] = { ...week[day], day: true };
        } else {
          days[day] = {
            workStart: "",
            workEnd: "",
            dayWorkEnd: "",
            hasLunch: false,
            lunchStart: "",
            dayLunchStart: "",
            lunchEnd: "",
            dayLunchEnd: "",
            day: false,
          };
        }
      });

      return days;
    });

    setSchedule(v);
  }, [workSchedule]);

  const generateMessages = ({
    dayName = "",
    week = "",
    context = null,
    message = "",
  }) => {
    dayName = dayName.toLowerCase();
    dayName = t(`shiftAttendance:${dayName}`);
    let messageDay = dayName ? `${t("shiftAttendance:day")} ${dayName}` : "";
    let messageWeek =
      week && context?.type === "ROTATING"
        ? ` ${t("shiftAttendance:week")} ${week}`
        : "";
    let separator = dayName || context?.type === "ROTATING" ? "," : "";

    message = `${messageDay}${messageWeek}${separator} ${message}`.trim();
    return message[0].toUpperCase() + message.slice(1);
  };

  let daysSchema = Yup.object().shape({
    workStart: Yup.string().test({
      name: "workStart",
      test(value, context) {
        let {
          parent: { week, day, dayName },
        } = this;

        if (day && !value) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:requiredEntryTime"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
    dayWorkEnd: Yup.string().test({
      name: "dayWorkEnd",
      test(value, context) {
        let {
          parent: { day, week, dayName },
        } = this;

        if (day && !value) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:requiredDeparturDay"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
    workEnd: Yup.string().test({
      name: "workEnd",
      test(value, context) {
        let {
          parent: { day, week, dayName, dayWorkEnd, workStart },
        } = this;

        if (
          value &&
          dayName === dayWorkEnd &&
          dayjs(value, "HH:mm").isSame(dayjs(workStart, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:departureEualArrival"),
              context: context.options.context,
            }),
          });
        }

        if (
          value &&
          dayName === dayWorkEnd &&
          dayjs(value, "HH:mm").isBefore(dayjs(workStart, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:departureBeforeArrival"),
              context: context.options.context,
            }),
          });
        }

        if (day && !value) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:departureTimeRequired"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
    lunchStart: Yup.string().test({
      name: "lunchStart",
      test(value, context) {
        let {
          parent: {
            week,
            hasLunch,
            dayName,
            dayLunchStart,
            workStart,
            workEnd,
            dayWorkEnd,
          },
        } = this;

        if (
          value &&
          dayName === dayLunchStart &&
          dayjs(value, "HH:mm").isSameOrBefore(dayjs(workStart, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:mealStartTimeBeforeEntry"),
              context: context.options.context,
            }),
          });
        }

        if (
          value &&
          (dayName === dayLunchStart || dayWorkEnd === dayLunchStart) &&
          dayjs(value, "HH:mm").isAfter(dayjs(workEnd, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:mealAfterDepartura"),
              context: context.options.context,
            }),
          });
        }

        if (hasLunch && !value) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:requiredMealStartTime"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
    dayLunchStart: Yup.string().test({
      name: "dayLunchStart",
      test(value, context) {
        let {
          parent: { week, hasLunch, dayName, dayWorkEnd },
        } = this;

        if (hasLunch && dayName === dayWorkEnd && value !== dayWorkEnd) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:dayStartOfLunchIncorrect"),
              context: context.options.context,
            }),
          });
        }

        if (hasLunch && !value) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:dayStartOfLunchRequired"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
    lunchEnd: Yup.string().test({
      name: "lunchEnd",
      test(value, context) {
        let {
          parent: {
            week,
            hasLunch,
            dayName,
            dayLunchEnd,
            workStart,
            workEnd,
            lunchStart,
            dayWorkEnd,
          },
        } = this;

        if (
          value &&
          dayName === dayLunchEnd &&
          dayjs(value, "HH:mm").isBefore(dayjs(workStart, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:endtimeBeforeEntry"),
              context: context.options.context,
            }),
          });
        }

        if (
          value &&
          (dayName === dayLunchEnd || dayWorkEnd === dayLunchEnd) &&
          dayjs(value, "HH:mm").isSameOrBefore(dayjs(lunchStart, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:beforeStartMeal"),
              context: context.options.context,
            }),
          });
        }

        if (
          value &&
          (dayName === dayLunchEnd || dayWorkEnd === dayLunchEnd) &&
          dayjs(value, "HH:mm").isSameOrAfter(dayjs(workEnd, "HH:mm"))
        ) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:mealTermAfterDeparture"),
              context: context.options.context,
            }),
          });
        }

        if (hasLunch && !value) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:requiredMealEndTime"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
    dayLunchEnd: Yup.string().test({
      name: "dayLunchEnd",
      test(value, context) {
        let {
          parent: { week, hasLunch, dayName, dayWorkEnd },
        } = this;

        if (hasLunch && dayName === dayWorkEnd && value !== dayWorkEnd) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:dayEndOfLunchIncorrect"),
              context: context.options.context,
            }),
          });
        }

        if (!value && hasLunch) {
          return context.createError({
            message: generateMessages({
              dayName,
              week,
              message: t("shiftAttendance:dayFinishOfLunchRequired"),
              context: context.options.context,
            }),
          });
        }

        return true;
      },
    }),
  });

  const weekSchema = Yup.array()
    .of(
      Yup.object().shape({
        days: Yup.array()
          .of(daysSchema)
          .test({
            name: "days",
            test(value, context) {
              if (value.length === 0) {
                let {
                  parent: { week },
                } = this;

                return context.createError({
                  message: generateMessages({
                    week,
                    context: context.options.context,
                    message: t("shiftAttendance:scheduleOneDaySelected"),
                  }),
                });
              }
              return true;
            },
          }),
      }),
    )
    .test({
      name: "week",
      test(value, context) {
        if (this.parent.type === "ROTATING" && value.length < 2) {
          return context.createError({
            message: t("shiftAttendance:rotatingSchedulesMinimumWeeks"),
          });
        }

        return true;
      },
    });

  const workScheduleSchema = Yup.object().shape({
    name: Yup.string().required("required"),
    description: Yup.string().required("required"),
    isActive: Yup.boolean().required("required"),
    type: Yup.string().required("required"),
    lunch: Yup.string().required("required"),
    rotations: Yup.number().required("required"),
    weeks: weekSchema,
  });

  const onSubmit = async (values) => {
    let message = "";

    let newValues = JSON.parse(JSON.stringify(values));
    delete newValues.minDate;
    newValues.weeks = newValues.weeks.map((week) => {
      let days = {};

      DAYS.forEach((day) => {
        let dayOfWeek = week[day];

        if (dayOfWeek.day) {
          delete dayOfWeek.day;
          days[day] = dayOfWeek;
        }
      });

      return days;
    });

    let validateValues = JSON.parse(JSON.stringify(values));
    validateValues.weeks = validateValues.weeks.map((week, i) => {
      let days = [];

      DAYS.forEach((day) => {
        let dayOfWeek = week[day];

        if (dayOfWeek.day) {
          dayOfWeek["week"] = i + 1;
          dayOfWeek["dayName"] = day.toUpperCase();
          days.push(dayOfWeek);
        }
      });

      return { week: i + 1, days };
    });

    try {
      await workScheduleSchema.validate(validateValues, {
        abortEarly: false,
        context: validateValues,
      });

      try {
        if (newValues._id) {
          const { data } = await putSchedule(newValues._id, newValues);
          dispatch(setWorkSchedule({ ...data, minDate: values.minDate }));
          message = t("shiftAttendance:WorkScheduleSuccessfullyUpdate");
        } else {
          const { data } = await postSchedule(newValues);
          dispatch(setWorkSchedule({ ...data, minDate: values.minDate }));
          message = t("shiftAttendance:WorkScheduleSuccessfullyCreated");
        }

        if (setUpdateWizard) {
          setUpdateWizard(Math.random());
        }

        Toast.fire({
          icon: "success",
          title: message,
        });
      } catch (e) {
        const msgError = e?.response?.data?.error
          ? e?.response?.data?.error
          : t("shiftAttendance:WorkScheduleErrorSubmit");
        Toast.fire({
          icon: "error",
          title: msgError,
        });
        console.log("Error to submit schedule: ", e);
      }
    } catch (e) {
      Toast.fire({
        icon: "error",
        title: e.errors.join("; "),
      });
    }
  };

  const deleteItem = async (item) => {
    try {
      setDeleting(true);
      await deleteSchedule(item._id);
      Toast.fire({
        icon: "success",
        title: t("shiftAttendance:WorkScheduleSuccessfullyDelete"),
      });
      setDeleting(false);
      dispatch(
        setWorkSchedule({
          name: "",
          description: "",
          isActive: true,
          type: "",
          lunch: "",
          rotations: 0,
          weeks: [{}],
          startOfTheSchedule: null,
        }),
      );
    } catch (err) {
      console.log(err);
      const msgError = err?.response?.data?.error
        ? err?.response?.data?.error
        : t("shiftAttendance:WorkScheduleErrorDelete");
      Toast.fire({
        icon: "error",
        title: msgError,
      });
      setDeleting(false);
    }
  };

  return (
    <UIPaper>
      <Box p={2}>
        <Typography variant="h5" mb={2}>
          {t("shiftAttendance:WorkSchedule")}
        </Typography>
        {schedule ? (
          <Formik
            initialValues={schedule}
            onSubmit={onSubmit}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormWorkSchedule formik={formik} DAYS={DAYS} />
                <FormWeeksSchedule
                  formik={formik}
                  DAYS={DAYS}
                  DAYS_CHOICES={DAYS_CHOICES}
                />
                <Box
                  mt={2}
                  width="100%"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box display="flex" gap="1em">
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting || deleting}
                      disabled={formik.isSubmitting || deleting}
                    />
                    {formik?.values?._id && (
                      <UIButton
                        label={t("general:Eliminar")}
                        onClick={() => {
                          Swal.fire({
                            title: t("general:Are"),
                            text: t("general:Youwon"),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t("general:deleteit"),
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteItem(formik.values);
                            }
                          });
                        }}
                        loading={formik.isSubmitting || deleting}
                        disabled={formik.isSubmitting || deleting}
                      />
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          ""
        )}
      </Box>
    </UIPaper>
  );
};
