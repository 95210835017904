import { timeClockClient } from "../adapters";

const HOLIDAYS = "/v1/holidays";

export function getHolidays(holidaysId) {
  if (holidaysId) {
    return timeClockClient.get(`${HOLIDAYS}/${holidaysId}`);
  } else {
    return timeClockClient.get(`${HOLIDAYS}`);
  }
}

export function postHolidays(data) {
  return timeClockClient.post(`${HOLIDAYS}`, data);
}

export function putHolidays(holidaysId, data) {
  return timeClockClient.put(`${HOLIDAYS}/${holidaysId}`, data);
}

export function deleteHolidays(holidaysId) {
  return timeClockClient.delete(`${HOLIDAYS}/${holidaysId}`);
}
