import { Box, DialogContent, Grid, Typography, Stack, CircularProgress } from "@mui/material";
import { UIBootstrapDialog } from "components";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { CheckBoxMenuOrganization } from "../components/CheckBoxMenuOrganization";
import { CheckBoxMenuEmployees } from "../components/CheckBoxMenuEmployees";

export const MixPermissions = ({
  open,
  setOpen,
  initialsValues,
  employeeSelected,
}) => {
  const { t } = useTranslation(["settings"]["general"]);
  return (
    <UIBootstrapDialog open={open} setOpen={setOpen} title={t("settings:MixPermisos")}>
      {employeeSelected !== null ?
        (
          <DialogContent>
            <Typography variant="subtitle2">
              {`${employeeSelected?.fullName} - ${employeeSelected?.position}`}
            </Typography>
            <br />

            <Formik
              initialValues={initialsValues}
              onSubmit={() => { }}
              enableReinitialize={true}
            >
              {(formik) => (
                <Form id="" autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" gutterBottom>
                        {t("settings:NavegaciónOrganization")}
                      </Typography>
                      <Box p={2} borderTop={1} borderColor="divider" sx={{ pointerEvents: "none" }}>
                        <CheckBoxMenuOrganization formik={formik} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" gutterBottom>
                        {t("settings:NavegaciónEmployees")}
                      </Typography>
                      <Box p={2} borderTop={1} borderColor="divider" sx={{ pointerEvents: "none" }}>
                        <CheckBoxMenuEmployees formik={formik} />
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </DialogContent>
        )
        :
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ height: '70vh' }}
        >
          <CircularProgress />
        </Stack>
      }
    </UIBootstrapDialog >
  );
};