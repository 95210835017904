import { Alert, Box } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";

export const TitleBox = (props) => {
  const { mode } = useSelector(parameterizationSelect);
  const {text = ""} = props;
  const {isRed = false} = props;
  const lightGreen = "rgb(237, 247, 237)";
  const darkGreen = "rgb(12, 19, 13)";
  const lightRed = "rgb(247, 237, 237)";
  const darkRed = "rgb(19, 12, 13)";
  const backgroundColor = mode === "light" ? (isRed ? lightRed : lightGreen) : (isRed ? darkRed : darkGreen);
  const severity = (backgroundColor === darkRed || backgroundColor === lightRed) ? "danger" : "success"
  return (
    <>
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          backgroundColor:backgroundColor,
        }}
      >
        <Alert icon={<PersonPinIcon fontSize="inherit" />} severity={severity}>
          {text}
        </Alert>
      </Box>
      <br />
    </>
  );
};
