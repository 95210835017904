import { useState } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stack, Typography, Paper } from "@mui/material";
import { ProfileTabs, TitleBox, Toast, UIButton } from "components";
import { FormIdentitesDocumentos } from "./FormIdentitesDocument";
import { profileSelect } from "@redux/slices/profile";
import { deleteReferences, saveReferences, updateReferences } from "api";
import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAccess } from "hooks";
import dayjs from "dayjs";
import { pathEmployee } from "helpers";
import { isFormerEmployee, getProfileReturnUri } from "helpers";

export const IdentitiesDocuments = ({
  application = "organization",
  user = null,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [newData, setNewData] = useState(false);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = 
  isFormerEmployee(HasPermissions(
    pathEmployee(application, "/profile/collaborator/personal-information"),
      application,
    ),
    employeeSelected
  );
  const returnUri = getProfileReturnUri(employeeSelected);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (values?.isDelete) {
        return await deleteItem(values,resetForm);
      }
      if (values?._id) {
        const updatedValues = { ...values };
        await updateReferences(
          updatedValues,
          employeeSelected._id,
          "identities",
          values._id
        );
        setNewData(!newData);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      } else {
        const updatedValues = { ...values };
        await saveReferences(updatedValues, employeeSelected._id, "identities");
        setNewData(!newData);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  const deleteItem = async ( values ,resetForm) => {
    try {
      await deleteReferences(
        employeeSelected._id,
        "identities",
        values._id
      );
      setNewData(!newData);
      resetForm();
      Swal.fire({
        title: t("general:Deleted"),
        text: t("general:YourFileHasBeenDeleted"),
        icon: "success",
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="collaborator" application={application} />
      <br />
      <Paper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
            isRed={!!employeeSelected?.formerEmployee}
          />
          <Typography variant="h5">
            {t("employee:Documentosdeidentidad")}
          </Typography>
          <br />
          <br />
          <Formik
            initialValues={{
              type: "",
              number: "",
              country: "",
              expirationDate: dayjs().format("YYYY-MM-DD"),
              file: "",
            }}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormIdentitesDocumentos
                  formik={formik}
                  newData={newData}
                  canAdd={canAdd}
                  canChange={canChange}
                  canDelete={canDelete}
                  user={user}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UIButton
                      variant="contained"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() =>
                        navigate(returnUri)
                      }
                      disabled={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {((canAdd && !formik?.values?._id) ||
                    (canChange && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      fullWidth={false}
                    />
                  )}
                  {!isEqual(formik?.values, formik?.initialValues) && (
                    <>
                      {canDelete && formik?.values?._id && (
                        <UIButton
                          label={t("general:Eliminar")}
                          onClick={() => {
                            Swal.fire({
                              title: t("general:Are"),
                              text: t("general:Youwon"),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: t("general:deleteit"),
                            }).then( (result) => {
                              if (result.isConfirmed) {
                                formik.setFieldValue("isDelete", true);
                                formik.submitForm();
                              }
                            });
                          }}
                          disabled={formik.isSubmitting}
                          fullWidth={false}
                        />
                      )}
                      {canAdd && (
                        <UIButton
                          label={t("general:Nuevo")}
                          onClick={() => formik.setValues(formik.initialValues)}
                          disabled={formik.isSubmitting}
                          fullWidth={false}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </>
  );
};
