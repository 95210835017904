import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Toast, UIButton, UIPaper } from "components";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { policesSelect, setPolicy } from "@redux/slices/attendance-absence";
import { FormPolicy } from "./FormPolicy";
import { PropTypes } from "prop-types";
import { useState } from "react";
import { FormAttendance } from "./FormAttendance";
import { FormCheckIn } from "./FormCheckIn";
import { FormLunchTime } from "./FormLunchTime";
import { FormCheckOut } from "./FormCheckOut";
import { deletePolicy, postPolicy, putPolicy } from "api";
import { useDispatch } from "react-redux";
import {
  CreditCard as CreditCardIcon,
  Login as LoginIcon,
  Logout as LogoutIcon,
  Restaurant as RestaurantIcon,
} from "@mui/icons-material";
import * as Yup from "yup";
import Swal from "sweetalert2";

export const Policy = () => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);
  const { policy } = useSelector(policesSelect);
  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  let policySchema = Yup.object().shape({
    attendance: Yup.object().shape({
      notAttendOnAWorkday: Yup.string().required(
        `${t("shiftAttendance:notAttendOnAWorkday")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      attendOnAWorkday: Yup.string().required(
        `${t("shiftAttendance:attendOnAWorkday")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
    }),
    checkIn: Yup.object().shape({
      preEntryTime: Yup.string().required(
        `${t("shiftAttendance:preEntryTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      workingTimeBeforeSchedule: Yup.string().required(
        `${t("shiftAttendance:workingTimeBeforeSchedule")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      arrivesAfterTheStartingTime: Yup.string().required(
        `${t("shiftAttendance:arrivesAfterTheStartingTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      toleranceTime: Yup.string().required(
        `${t("shiftAttendance:DelayToleranceTimeToleranceTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ).test({
        name: "DelayToleranceTimeToleranceTimeGreaterThan",
        test(value, context) {
          if (
            context.parent.arrivesAfterTheStartingTime ===
              "DELAY_TOLERANCE_TIME" &&
            value === "0"
          ) {
            return context.createError({
              message: t("shiftAttendance:CheckInToleranceTimeGreaterThan"),
            });
          }

          return true;
        },
      }),
    }),
    lunchTime: Yup.object().shape({
      notUseLuchTime: Yup.string().required(
        `${t("shiftAttendance:notUseLuchTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      toleranceTime: Yup.string().required(
        `${t("shiftAttendance:toleranceTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
    }),
    checkOut: Yup.object().shape({
      afterCheckOutTime: Yup.string().required(
        `${t("shiftAttendance:afterCheckOutTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      timeWorkedAfterHours: Yup.string().required(
        `${t("shiftAttendance:timeWorkedAfterHours")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      leavesBeforeCheckOutTime: Yup.string().required(
        `${t("shiftAttendance:leavesBeforeCheckOutTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ),
      toleranceTime: Yup.string().required(
        `${t("shiftAttendance:EarlyDepartureToleranceTime")}, ${
          t(
            "shiftAttendance:isRequired",
          )
        }`,
      ).test({
        name: "CheckOutToleranceTimeGreaterThan",
        test(value, context) {
          if (
            context.parent.leavesBeforeCheckOutTime === "EARLY_DEPARTURE" &&
            value === "0"
          ) {
            return context.createError({
              message: t("shiftAttendance:CheckOutToleranceTimeGreaterThan"),
            });
          }

          return true;
        },
      }),
    }),
  });

  const onSubmit = async (values) => {
    let message = "";

    try {
      await policySchema.validate(values, { abortEarly: false });

      try {
        if (values._id) {
          const { data } = await putPolicy(values._id, values);
          dispatch(setPolicy(data));
          message = t("shiftAttendance:PolicySuccessfullyUpdate");
        } else {
          const { data } = await postPolicy(values);
          dispatch(setPolicy(data));
          message = t("shiftAttendance:PolicySuccessfullyCreated");
        }
        Toast.fire({
          icon: "success",
          title: message,
        });
      } catch (e) {
        if (e?.response?.data?.error) {
          Toast.fire({
            icon: "error",
            title: e?.response?.data?.error,
          });
        }
        console.log("Error to submit policy: ", e);
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err.errors.join("; "),
      });
    }
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={1}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function allyProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const deleteItem = async (policy) => {
    try {
      await deletePolicy(policy._id);
      dispatch(
        setPolicy({
          name: "",
          description: "",
          isActive: true,
          attendance: {
            notAttendOnAWorkday: "",
            attendOnAWorkday: "",
          },
          checkIn: {
            preEntryTime: "",
            workingTimeBeforeSchedule: "",
            arrivesAfterTheStartingTime: "",
            toleranceTime: 0,
          },
          lunchTime: {
            notUseLuchTime: "",
            toleranceTime: 0,
          },
          checkOut: {
            afterCheckOutTime: "",
            timeWorkedAfterHours: "",
            leavesBeforeCheckOutTime: "",
            autoRegister: false,
            toleranceTime: 0,
          },
        }),
      );
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <UIPaper>
      <Box p={2}>
        <Typography variant="h5" mb={2}>
          {t("shiftAttendance:Policy")}
        </Typography>
        <Formik
          initialValues={policy}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <FormPolicy formik={formik} />
              <Box mt={2}>
                <Typography variant="h5">
                  {t("shiftAttendance:Setting")}
                </Typography>
              </Box>
              <Box
                mt={2}
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  value={tab}
                  onChange={(event, newTab) => setTab(newTab)}
                >
                  <Tab
                    label={
                      <Typography
                        sx={{
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        {t("shiftAttendance:Attendance")}
                      </Typography>
                    }
                    icon={<CreditCardIcon />}
                    iconPosition="start"
                    sx={{
                      alignItems: "self-start",
                      justifyContent: "flex-start",
                    }}
                    {...allyProps(0)}
                  />
                  <Tab
                    label={
                      <Typography
                        sx={{
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        {t("shiftAttendance:CheckIn")}
                      </Typography>
                    }
                    icon={<LoginIcon />}
                    iconPosition="start"
                    sx={{
                      alignItems: "self-start",
                      justifyContent: "flex-start",
                    }}
                    {...allyProps(1)}
                  />
                  <Tab
                    label={
                      <Typography
                        sx={{
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        {t("shiftAttendance:LunchTime")}
                      </Typography>
                    }
                    icon={<RestaurantIcon />}
                    iconPosition="start"
                    sx={{
                      alignItems: "self-start",
                      justifyContent: "flex-start",
                    }}
                    {...allyProps(2)}
                  />
                  <Tab
                    label={
                      <Typography
                        sx={{
                          display: { xs: "none", sm: "none", md: "block" },
                        }}
                      >
                        {t("shiftAttendance:CheckOut")}
                      </Typography>
                    }
                    icon={<LogoutIcon />}
                    iconPosition="start"
                    sx={{
                      alignItems: "self-start",
                      justifyContent: "flex-start",
                    }}
                    {...allyProps(3)}
                  />
                </Tabs>
                <TabPanel value={tab} index={0}>
                  <FormAttendance formik={formik} />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <FormCheckIn formik={formik} />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <FormLunchTime formik={formik} />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  <FormCheckOut formik={formik} />
                </TabPanel>
              </Box>
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={formik?.values?._id
                      ? t("general:Actualizar")
                      : t("general:Guardar")}
                    loading={formik.isSubmitting}
                    disabled={formik.isSubmitting}
                  />
                  {formik?.values?._id && (
                    <UIButton
                      label={t("general:Eliminar")}
                      onClick={() => {
                        Swal.fire({
                          title: t("general:Are"),
                          text: t("general:Youwon"),
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: t("general:deleteit"),
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteItem(formik.values);
                            Swal.fire({
                              title: t("general:Deleted"),
                              text: t("general:YourFileHasBeenDeleted"),
                              icon: "success",
                            });
                          }
                        });
                      }}
                      disabled={formik.isSubmitting}
                    />
                  )}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </UIPaper>
  );
};
