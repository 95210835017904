import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
};

export const socket = createSlice({
  name: "socket",
  initialState: initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { setNotifications } = socket.actions;

export const socketSelect = (state) => state.socket;

export default socket.reducer;
