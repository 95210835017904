import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Modal, Box, Typography, ButtonGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { modalStyle } from "helpers";
import { UIButton, Toast } from "components";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { importEmployees } from "api";

export const ModalImportEmployees = ({ open, setOpen, setUpdateRows }) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  const { t } = useTranslation(["employee"]);

  const handleSubmit = async (values) => {
    console.log("values", values);
    try {
      await importEmployees(values, tenant._id);

      setOpen(false);

      if (setUpdateRows) {
        setUpdateRows((prev) => !prev);
      }

      Toast.fire({
        icon: "success",
        title: "Empleados importados con éxito",
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message,
      });
      console.error("Error submitting form:", err);
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={{ width: "auto" }} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h5">{t("employee:ImportEmployees")}</Typography>
        <br />
        <Formik initialValues={{ file: "" }} onSubmit={handleSubmit}>
          {(formik) => (
            <Form id="importEmployees" autoComplete="off">
              <ButtonGroup variant="text">
                <UIButton
                  onClick={() => setOpen(false)}
                  label={t("Cancel")}
                  disabled={formik.isSubmitting}
                />
                <UIButton
                  component="label"
                  label={t("Upload")}
                  disabled={formik.isSubmitting}
                  startIcon={<CloudUploadIcon />}
                  loading={formik.isSubmitting}
                >
                  <input
                    type="file"
                    accept=".csv,.xls,.xlsx"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          formik.setFieldValue("file", reader.result);
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                  />
                </UIButton>
                <UIButton
                  type="submit"
                  label={t("Guardar")}
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !formik.values.file}
                />
              </ButtonGroup>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
