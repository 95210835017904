import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography, TableCell, Grid } from "@mui/material";
import { UIButton, BasicTable } from "components";
import { ModalPackageShortcut } from "./ModalPackageShortcut";
import { ModalCompensation } from "./ModalCompensation";
import { getBenefitPackage, getBenefits, getCurrencies } from "api";
import { generalSelect, setCurrencies } from "@redux/slices/general";
import { getLabel } from "utils";
import { uiSelect } from "@redux/slices/ui";

export const CompensationAndBenefits = () => {
  const { t } = useTranslation(["employee"]["organization"]);

  const { currencies } = useSelector(generalSelect);
  const { reRender } = useSelector(uiSelect);

  const [rowsCompensation, setRowsCompensation] = useState([]);
  const [rowsPackage, setRowsPackage] = useState([]);
  const [packages, setPackages] = useState(null);
  const [open, setOpen] = useState(false);
  const [openCompensation, setOpenCompensation] = useState(false);
  const [benefitSelected, setBenefitSelected] = useState(null);
  const [packageSelected, setPackageSelected] = useState(null);

  useEffect(() => {
    const fetchCurrencies = async () => {
      if (currencies?.length === 0 || !currencies || currencies === undefined) {
        try {
          const { data } = await getCurrencies(true);
          dispatcher(setCurrencies(data));
        } catch (err) {
          console.error("Error getting currencies:", err);
        }
      }
    };

    fetchCurrencies();
  }, []);

  useEffect(() => {
    const getBenefitsFetch = async () => {
      try {
        const { data } = await getBenefits();
        const cleanData = data.map((item) => ({
          id: item._id,
          title: item?.title,
          amount: item?.amount,
          periodicity: item?.periodicity,
          currency: getLabel(currencies, item?.currency),
          isActive:
            item?.isActive === true ? t("employee:Si") : t("employee:No"),
        }));
        setRowsCompensation(cleanData);
      } catch (error) {
        console.log(error);
      }
    };

    const getBenefitsPackageFetch = async () => {
      try {
        const { data } = await getBenefitPackage();
        const cleanData = data.map((item) => ({
          id: item._id,
          title: item?.title,
          benefits: item?.benefits.length,
          isActive:
            item?.isActive === true ? t("employee:Si") : t("employee:No"),
        }));
        setRowsPackage(cleanData);
        setPackages(data);
      } catch (error) {
        console.log(error);
      }
    };

    getBenefitsFetch();
    getBenefitsPackageFetch();
  }, [reRender]);

  const handleClickCompensation = (row) => {
    const modifiedRow = { ...row };

    modifiedRow.isActive = modifiedRow?.isActive === "Si" ? true : false;
    modifiedRow.currency = currencies?.find(
      (obj) => obj?.label === modifiedRow?.currency
    )?.value;

    setBenefitSelected(modifiedRow);
    setOpenCompensation(true);
  };

  const handleClickPackages = (row) => {
    const myPackage = packages.find((obj) => obj?._id === row?.id);
    const { createdAt, updatedAt, ...res } = myPackage;
    setPackageSelected(res);
    setOpen(true);
  };
  return (
    <>
      <Paper>
        <Box p={4}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item={true} xs={12} md={6}>
              <Typography variant="h5" mb={2}>
                {t("organization:CompensacionesYbeneficios")}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UIButton
                label={t("organization:CrearCompensacionesOBeneficios")}
                onClick={() => {
                  setBenefitSelected(null);
                  setOpenCompensation(true);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <BasicTable
            rows={rowsCompensation}
            handleClick={handleClickCompensation}
          >
            <TableCell>{t("organization:Beneficio")}</TableCell>
            <TableCell>{t("organization:MontoEconómico")}</TableCell>
            <TableCell>{t("organization:PeriodicidadEnDías")}</TableCell>
            <TableCell>{t("organization:Divisa")}</TableCell>
            <TableCell>{t("organization:EstáActivo")}</TableCell>
          </BasicTable>
        </Box>
      </Paper>

      <br />

      <Paper>
        <Box p={4}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item={true} xs={12} md={6}>
              <Typography variant="h5" mb={2}>
                {t("organization:PaquetesDeCompensaciones")}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={2}>
              <UIButton
                label={t("organization:CrearPaquete")}
                onClick={() => {
                  setPackageSelected(null);
                  setOpen(true);
                }}
              />
            </Grid>
          </Grid>
          <br />
          <BasicTable rows={rowsPackage} handleClick={handleClickPackages}>
            <TableCell>{t("organization:NombreDelPaquete")}</TableCell>
            <TableCell>{t("organization:CantidadDeCompensaciones")}</TableCell>
            <TableCell>{t("organization:EstáActivo")}</TableCell>
          </BasicTable>
        </Box>
      </Paper>
      <ModalCompensation
        open={openCompensation}
        setOpen={setOpenCompensation}
        benefitSelected={benefitSelected}
      />
      <ModalPackageShortcut
        open={open}
        setOpen={setOpen}
        packageSelected={packageSelected}
      />
    </>
  );
};
