import { GeneralData } from "./GeneralData";
import { MenuNav } from "./MenuNav";

export const OrganizationSettings = (
  { showTabs = true, setUpdateWizard = null },
) => {
  return (
    <>
      {showTabs &&
        <MenuNav ubication={"organization"} />}
      <GeneralData setUpdateWizard={setUpdateWizard} />
    </>
  );
};
