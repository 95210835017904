import { useTranslation } from "react-i18next";
import { nodos } from "helpers";
import { OrganizationNavigation } from "../CreateGroups/OrganizationNavigation";

export const CheckBoxMenuOrganization = ({ formik, ...rest }) => {
  const { t } = useTranslation(["sidenav"]);
  const { disabled } = rest;

  return OrganizationNavigation?.map((obj, i) => (
    nodos(t, obj, i, formik, disabled)
  ));
};
