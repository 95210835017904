import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { UIButton, UISubmit, UITextField, Toast, UISwitch } from "components";
import { Form, Formik } from "formik";
import {
  generateInitialValues,
  replaceCodenames,
  reverseTransformObject,
  transformObjectForPermission,
} from "helpers";
import {
  deletePermissionsGroup,
  postPermissionsGroup,
  putPermissionsGroup,
} from "api";
import { managerErrors } from "utils/functions";
import Swal from "sweetalert2";
import { CheckBoxMenuEmployees } from "../components/CheckBoxMenuEmployees";
import { EmployeeNavigation } from "./EmployeeNavigation";

export const Employee = ({
  groupSelected,
  setGroupSelected,

  permisos,
  setExecuteOnce,

  canAdd,
  canChange,
  canDelete,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const data = transformObjectForPermission(values, "employees");
      const payload = await replaceCodenames(data, permisos);

      if (groupSelected === null && canAdd) {
        await postPermissionsGroup(payload);

        Toast.fire({
          icon: "success",
          title: t("settings:PermissionsOK"),
        });
        resetForm();
        setExecuteOnce(true);
      }
      else {
        if (values?.isDelete && canDelete) {
          await deletePermissionsGroup(values?._id);
          Swal.fire({
            title: t("general:Deleted"),
            text: t("general:YourFileHasBeenDeleted"),
            icon: "success",
          });
          resetForm();
          setGroupSelected(null);
          setExecuteOnce(true);
        } else if (values._id && canChange) {
          await putPermissionsGroup(payload, values._id);
          Toast.fire({
            icon: "success",
            title: t("settings:PermissionsOK"),
          });
          resetForm();
          setGroupSelected(null);
          setExecuteOnce(true);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("general:NoAccess"),
          });
        }
      }
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  const Values = {
    name: "",
    description: "",
    default: false,
    permits: generateInitialValues(EmployeeNavigation),
  };
  return (
    <>
      <Formik
        initialValues={
          groupSelected !== null
            ? reverseTransformObject(groupSelected, {
              permits: generateInitialValues(EmployeeNavigation)
            },
              permisos)
            : Values
        }
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form id="" autoComplete="off">
            <Grid container spacing={3} justifyContent="center">
              <Grid item={true} xs={12} md={8}>
                <br />
                <Grid container spacing={3}>
                  <Grid item={true} xs={12} md={4}>
                    <UITextField
                      formik={formik}
                      label={t("settings:name")}
                      name={"name"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={8}>
                    <UITextField
                      formik={formik}
                      label={t("settings:description")}
                      name={"description"}
                      disabled={formik.isSubmitting}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item={true} xs={12} md={4}>
                        <UISwitch
                          formik={formik}
                          label={t("settings:default")}
                          name={"default"}
                          disabled={formik.isSubmitting}
                        />
                      </Grid>
                      <Grid item={true} xs={12} md={8}>
                        <Typography variant="caption" color={"primary"}>
                          {t("settings:defaultText")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />

                <CheckBoxMenuEmployees formik={formik} disabled={formik.isSubmitting} />

                {(canAdd || canChange) && (
                  <UISubmit formik={formik}>
                    {formik?.values?._id && (
                      <>
                        {canDelete && (
                          <UIButton
                            label={t("general:Eliminar")}
                            onClick={() => {
                              Swal.fire({
                                title: t("general:Are"),
                                text: t("general:Youwon"),
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: t("general:deleteit"),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  formik.setFieldValue("isDelete", true);
                                  formik.submitForm();
                                }
                              });
                            }}
                            disabled={formik.isSubmitting}
                          />
                        )}
                      </>
                    )}
                  </UISubmit>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
