import { useEffect, useState } from "react";
import {
  BasicTable,
  UIButton,
} from "components";
import { Box, Grid, CircularProgress, Stack, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTerminations } from "api";
import { useSelector } from "react-redux";
import { ModalTermination } from "./ModalTermination";
import { uiSelect } from "@redux/slices/ui";
import { useAccess } from "hooks";

export const Termination = () => {
  const { t } = useTranslation(["organization"]["general"]);
  const { reRender } = useSelector(uiSelect);
  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/organization/organization-settings/termination-reason"
  );
  const [terminationSelected, setTerminationSelected] = useState(null);
  const [openTermination, setOpenTermination] = useState(false);
  const [terminationData, setTerminationData] = useState([]);

  const [rows, setRows] = useState([]);

  const handleClick = (row) => {
    const select = terminationData.find((item) => item._id === row._id);
    setTerminationSelected(select);
    
    setOpenTermination(true);
  };


  useEffect(() => {
    const getTerminationsFetch = async () => {
      try {
        const { data } = await getTerminations();
        setTerminationData(data);
        const rows = data.map((item) => ({
          _id: item._id,
          reason: item?.reason,
          description: item?.description,
        }));
        setRows(rows);
      } catch (error) {
        console.log(error);
      }
    };
    getTerminationsFetch();
  }, [reRender]);
  return (
    <>
      <Box p={2}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item={true} xs={12} md={6}>
          </Grid>
          <Grid item={true} xs={12} md={2}>
            <UIButton
              label={t("shiftAttendance:Crear")}
              onClick={() => {
                setTerminationSelected(null);
                setOpenTermination(true);
              }}
            />
          </Grid>
        </Grid>
        <br />
        {(rows.length !== 0 &&
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("organization:TerminationReason")}</TableCell>
            <TableCell>{t("organization:TerminationDescription")}</TableCell>
          </BasicTable>
        ) ||
          (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <CircularProgress />
            </Stack>
          )
        }
      </Box>
      <ModalTermination
        open={openTermination}
        setOpen={setOpenTermination}
        terminationSelected={terminationSelected}
        canAdd={canAdd}
        canChange={canChange}
      />
    </>
  );
};
