import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
} from "@mui/material";
import { UIModal, UIButton } from "components";
import { setPolicy } from "@redux/slices/attendance-absence";
import { setWorkSchedule } from "@redux/slices/work-schedules";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { JustifyForm } from "./JustifyForm";

import ReportIcon from "@mui/icons-material/Report";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ModalJustify = ({
  open,
  setOpen,
  report,
  allPolices,
  allWorkSchedules,
  countries,
}) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formJustify, setFormJustify] = useState(false);

  const MyListItemText = (title, text, icon) => {
    return (
      <ListItemText
        primary={
          <>
            {title}
            {icon && (
              <IconButton aria-label="delete" size="small">
                <FileOpenIcon fontSize="inherit" />
              </IconButton>
            )}
          </>
        }
        secondary={<>{text}</>}
      />
    );
  };

  const Mycountry = countries.find(
    (country) => country?.value === report?.country,
  );

  const MyPolicy = allPolices.find((policy) => policy?._id === report?.policy);

  const toPolicy = (policy) => {
    if (policy) {
      dispatch(setPolicy(policy));
      navigate("/cereza-organization/shift-attendance/policy");
    }
  };

  const MiHorario = allWorkSchedules.find(
    (horario) => horario?._id === report?.schedule,
  );

  const toWorkSchedules = (workSchedule) => {
    if (workSchedule) {
      const minDate = workSchedule.startOfTheSchedule
        ? workSchedule.startOfTheSchedule
        : "";
      dispatch(setWorkSchedule({ ...workSchedule, minDate }));
      navigate("/cereza-organization/shift-attendance/work-schedule");
    }
  };

  const warningsMessages = (report) => {
    let messages = {
      msgRealCheckIn: null,
      msgLunchStart: null,
      msgLunchEnd: null,
      msgRealCheckOut: null,
      msgWorkedHours: null,
    };

    if (!report?.realCheckIn) {
      messages.msgRealCheckIn = t("shiftAttendance:msgRealCheckIn");
    }
    if (!report?.lunchStart) {
      messages.msgLunchStart = t("shiftAttendance:msgLunchStart");
    }
    if (!report?.lunchEnd) {
      messages.msgLunchEnd = t("shiftAttendance:msgLunchEnd");
    }
    if (!report?.realCheckOut) {
      messages.msgRealCheckOut = t("shiftAttendance:msgLunchEnd");
    }
    if (!report?.realCheckOut) {
      messages.msgRealCheckOut = t("shiftAttendance:msgRealCheckOut");
    }
    if (!report?.workedHours) {
      messages.msgWorkedHours = t("shiftAttendance:msgWorkedHours");
    }

    return messages;
  };

  const messages = warningsMessages(report);

  return (
    <UIModal open={open} setOpen={setOpen} width={"70%"}>
      {formJustify ? (
        <>
          <JustifyForm dailyCheck={report?.id} setOpen={setOpen} />
        </>
      ) : (
        <>
          <Typography variant="h5">
            {t("shiftAttendance:DetalleDelReporte")}
          </Typography>
          <br />

          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={report?.employee} src={report?.thumbnail} />
              </ListItemAvatar>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={9.6}>
                  {MyListItemText(
                    t("shiftAttendance:Empleado"),
                    report?.fullName,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:Fecha"),
                    `${report?.date}`,
                  )}
                </Grid>
              </Grid>
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {messages.msgRealCheckIn && (
                  <ReportIcon style={{ color: "red" }} />
                )}
              </ListItemAvatar>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:Entrada"),
                    report?.checkIn,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:EntradaReal"),
                    `${report?.realCheckIn}`,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:diferenciaEntrada(Min)"),
                    report?.differenceCheckIn,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:Retardo"),
                    report?.checkInDelay,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4} sx={{ color: "red" }}>
                  {messages.msgRealCheckIn &&
                    MyListItemText(
                      t("shiftAttendance:warning"),
                      messages.msgRealCheckIn,
                    )}
                </Grid>
              </Grid>
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {(messages.msgLunchStart || messages.msgLunchEnd) && (
                  <ReportIcon style={{ color: "red" }} />
                )}
              </ListItemAvatar>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:InicioComida"),
                    report?.lunchStart,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:finComida"),
                    report?.lunchEnd,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:lunchTime"),
                    report?.lunchTime,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}></Grid>
                <Grid item={true} xs={12} md={2.4} sx={{ color: "red" }}>
                  {(messages.msgLunchStart || messages.msgLunchEnd) &&
                    MyListItemText(
                      t("shiftAttendance:warning"),
                      `${messages.msgLunchStart === null ? "" : messages.msgLunchStart} ${messages.msgLunchEnd === null ? "" : messages.msgLunchEnd}`,
                    )}
                </Grid>
              </Grid>
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {messages.msgRealCheckOut && (
                  <ReportIcon style={{ color: "red" }} />
                )}
              </ListItemAvatar>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:HoraSalida"),
                    `${report?.checkOut}`,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:SalidaReal"),
                    report?.realCheckOut,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:diferenciaSalida(Min)"),
                    report?.differenceCheckOut,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}></Grid>
                <Grid item={true} xs={12} md={2.4} sx={{ color: "red" }}>
                  {messages.msgRealCheckOut &&
                    MyListItemText(
                      t("shiftAttendance:warning"),
                      messages.msgRealCheckOut,
                    )}
                </Grid>
              </Grid>
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                {messages.msgWorkedHours && (
                  <ReportIcon style={{ color: "red" }} />
                )}
              </ListItemAvatar>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:Laborado"),
                    report?.workedHours,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(t("shiftAttendance:T.IN"), report?.timeIn)}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(t("shiftAttendance:T.OUT"), report?.timeOut)}
                </Grid>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:Asistencia"),
                    report?.assistance,
                  )}
                </Grid>
                <Grid item={true} xs={12} md={2} sx={{ color: "red" }}>
                  {messages.msgWorkedHours &&
                    MyListItemText(
                      t("shiftAttendance:warning"),
                      messages.msgWorkedHours,
                    )}
                </Grid>
              </Grid>
            </ListItem>

            <Divider variant="inset" component="li" />

            <ListItem alignItems="flex-start">
              <ListItemAvatar></ListItemAvatar>
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={2.4}>
                  {MyListItemText(
                    t("shiftAttendance:country"),
                    Mycountry?.label,
                  )}
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={2.4}
                  style={{ cursor: MyPolicy ? "pointer" : "" }}
                  onClick={() => toPolicy(MyPolicy)}
                >
                  {MyListItemText(
                    t("shiftAttendance:policy"),
                    MyPolicy?.name,
                    MyPolicy ? true : false,
                  )}
                </Grid>
                <Grid
                  item={true}
                  xs={12}
                  md={2.4}
                  style={{ cursor: MiHorario ? "pointer" : "" }}
                  onClick={() => toWorkSchedules(MiHorario)}
                >
                  {MyListItemText(
                    t("shiftAttendance:schedule"),
                    MiHorario?.name,
                    MiHorario ? true : false,
                  )}
                </Grid>
              </Grid>
            </ListItem>
          </List>
        </>
      )}
      <br />
      <Divider />
      <br />
      <Box mt={2} width="100%" display="flex" justifyContent="space-between">
        <Box display="flex" gap="1em">
          {(report?.assistanceColor === "INCOMPLETE_SCHEDULE" ||
            report?.assistanceColor === "ABSENCE" ||
            report?.assistanceColor === "DELAY") &&
            !formJustify && (
              <UIButton
                label={t("shiftAttendance:justify")}
                onClick={() => {
                  setFormJustify(true);
                }}
              />
            )}
          {formJustify && (
            <UIButton
              onClick={() => {
                setFormJustify(false);
              }}
            >
              <ArrowBackIcon />
            </UIButton>
          )}
          <UIButton
            label={t("general:Cancel")}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Box>
      </Box>
    </UIModal>
  );
};
