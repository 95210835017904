import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const today = dayjs().startOf("day");

const shouldDisableDate = (date) => {
  return date.isBefore(today, "day");
};

const shouldDisableDateAndToday = (date) => {
  return date.isBefore(today, "day") || date.isSame(today, "day");
};

const shouldDisableFutureDates = (date) => {
  return date.isAfter(today, "day") || date.isSame(today, "day");
};

const shouldDisable = (customDisabled) => {
  if (customDisabled === "Before") {
    return shouldDisableDate;
  }

  if (customDisabled === "After") {
    return shouldDisableFutureDates;
  }

  if (customDisabled === "BeforeAndToday") {
    return shouldDisableDateAndToday;
  }

  return null;
};

export const UIDatePicker = ({
  formik,
  label = "",
  name = "",
  nameFileArray = "",
  index = null,
  isrequired = true,
  readOnly = false,
  disabled = false,
  width = "100%",
  customDisabled = "",
  props = {},
}) => {
  let value =
    nameFileArray === ""
      ? formik.values[name]
      : formik.values[nameFileArray][index][name];

  useEffect(() => {
    let newValue = value ? dayjs.utc(value) : dayjs.utc();
    newValue = newValue.format("YYYY-MM-DD");

    index !== null
      ? formik.setFieldValue(`${nameFileArray}.${index}.${name}`, newValue)
      : formik.setFieldValue(name, newValue);
  }, []);

  const onChange = (date) => {
    let newValue = date;

    newValue = date.format("YYYY-MM-DD");

    index !== null
      ? formik.setFieldValue(`${nameFileArray}.${index}.${name}`, newValue)
      : formik.setFieldValue(name, newValue);
  };

  return (
    <DatePicker
      label={label}
      name={name}
      value={value ? dayjs.utc(value) : dayjs.utc()}
      onChange={onChange}
      required={isrequired}
      readOnly={readOnly}
      disabled={disabled}
      shouldDisableDate={shouldDisable(customDisabled)}
      format="YYYY/MM/DD"
      sx={{
        width: width,
      }}
      {...props}
    />
  );
};

UIDatePicker.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isrequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  isString: PropTypes.bool,
};
