import { cerezaClient } from "../adapters";

const FILES = "/v1/int/azure/storage/files";

export function getFiles(tenantId, fileName) {
  if (tenantId && fileName) {
    return cerezaClient.get(
      `${FILES}?container=${tenantId}&fileName=${fileName}`
    );
  }
}
