import MuiPhoneNumber from "material-ui-phone-number";
import PropTypes from "prop-types";

export const UIPhoneNumber = ({
  formik,
  label = "",
  name = "",
  nameFileArray = "",
  index = null,
  isrequired = true,
  disabled = false,
  width = "100%",
  style,
}) => {
  return (
    <MuiPhoneNumber
      fullWidth
      defaultCountry={"us"}
      variant="outlined"
      label={label}
      name={name}
      value={
        nameFileArray === ""
          ? formik.values[name]
          : formik.values[nameFileArray][index][name]
      }
      onChange={(phone) => {
        index !== null
          ? formik.setFieldValue(`${nameFileArray}.${index}.${name}`, phone)
          : formik.setFieldValue(name, phone);
      }}
      required={isrequired}
      disabled={disabled}
      sx={{
        width: width,
      }}
      style={style}
      onBlur={formik.handleBlur}
    />
  );
};

UIPhoneNumber.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  nameFileArray: PropTypes.string,
  index: PropTypes.number,
  isrequired: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  style: PropTypes.object,
};
