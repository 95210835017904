import { TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, getIn, useField } from "formik";

export const UITextFast = (props) => {
  const { name, helperText, ...rest } = props;
  const [field] = useField(name);

  return (
    <Field {...field} {...rest}>
      {({ field }) => (
        <>
          <TextField
            sx={{
              width: "100%",
            }}
            {...field}
            {...rest}
          />
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <Typography color="error" variant="caption">
                {msg}
              </Typography>
            )}
          />
        </>
      )}
    </Field>
  );
};
