import { ErrorMessage, Field, useField } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { useEffect } from "react";

export const UIDatePickerFast = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <Field {...field} {...rest}>
      {({ field, form: { touched, errors, setFieldValue } }) => {
        useEffect(() => {
          if (!field.value) {
            setFieldValue(name, dayjs.tz().format("YYYY-MM-DD"));
          }
        }, []);

        return (
          <>
            <DatePicker
              {...field}
              sx={{
                width: "100%",
              }}
              format="YYYY/MM/DD"
              value={field.value ? dayjs.tz(field.value) : dayjs.tz()}
              {...rest}
              onChange={(date) => {
                setFieldValue(name, date ? date.format("YYYY-MM-DD") : date);
              }}
            />
            <ErrorMessage
              name={field.name}
              render={(msg) => (
                <Typography color="error" variant="caption">
                  {msg}
                </Typography>
              )}
            />
          </>
        );
      }}
    </Field>
  );
};
