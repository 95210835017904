import { useTranslation } from "react-i18next";
import {
  Toast,
  UIButton,
  UITextFast,
  UIJoditEditor,
  UISwitchFast
} from "components";
import { postTermination, patchTermination } from "api";
import { setReRender } from "@redux/slices/ui";
import { managerErrors } from "utils";
import { Formik, Form } from "formik";
import { Box, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";

export const FormTermination = ({
  setOpen,
  terminationSelected = null,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["employee"]["general"]);
  const dispatcher = useDispatch();

  const [iValues,] = useState({
    reason: "",
    description: "",
    isActive: true,
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (terminationSelected === null) {
      try {
        await postTermination(values);
        dispatcher(setReRender(Math.random()));
        Toast.fire({
          icon: "success",
          title: t("general:AcciónExitosa"),
        });
        resetForm();
        setOpen(false);
      } catch (err) {
        managerErrors(err?.response?.data?.message);
        console.error("Error submitting form:", err);
      }
    } else {
      try {
        await patchTermination(values);
        dispatcher(setReRender(Math.random()));
        Toast.fire({
          icon: "success",
          title: t("general:AcciónExitosa"),
        });
        setOpen(false);
      } catch (err) {
        managerErrors(err?.response?.data?.message);
        console.error("Error submitting form:", err);
      }
    }
  };

  return (
    <Formik
      initialValues={
        terminationSelected !== null
          ? terminationSelected
          : iValues
      }
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form id="d" autoComplete="off">
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={6}>
              <UITextFast
                label={t("organization:TerminationReason")}
                name={"reason"}
                disabled={terminationSelected || formik.isSubmitting ? true : false}
              />
              <UISwitchFast
                label={t("home:On")}
                name={"isActive"}
                disabled={formik.isSubmitting || terminationSelected === null}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <UIJoditEditor
                label={t("organization:SkillDescription")}
                name={"description"}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
          <br />
          <Box
            mt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" width={{ xs: '100%', sm: 'initial' }} flexDirection={{ xs: 'column', sm: 'row' }} gap="1em">
              {(canAdd || canChange) && (
                <>
                  <UIButton

                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                  {(terminationSelected?.description != formik?.values?.description &&
                    (formik.values.description || formik.values.reason)) &&
                    <UIButton
                      label={formik?.values?._id
                        ?
                        t("organization:descartarCambios")
                        :
                        t("general:restart")
                      }
                      onClick={() =>

                        formik.setValues({
                          reason: (!terminationSelected) ? "" : formik?.values?.reason,
                          description: (!terminationSelected) ? "" : terminationSelected.description,
                          isActive: (!terminationSelected) ? true : terminationSelected.isActive,
                          _id: (!terminationSelected) ? "" : terminationSelected._id,
                        })
                      }
                      disabled={formik.isSubmitting}
                    />
                  }
                </>
              )}
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
