import { useTranslation } from "react-i18next";
import { CreationForm } from "./CreationForm";
import { useState } from "react";
import { UIModal } from "../../../components";
import { Typography } from "@mui/material";
import { PasswordForm } from "./PasswordForm";

export const ModalSettings = ({ open, setOpen, setNewData, iValues }) => {
  const { t } = useTranslation(["shiftAttendance"]["general"]);

  const [password, setpassword] = useState(false);

  return (
    <>
      <UIModal open={open} setOpen={setOpen} width={"75vw"}>
        <Typography variant="h5">
          {t("shiftAttendance:RelojChecador")}
        </Typography>
        <br />
        {password ? (
          <PasswordForm
            setpassword={setpassword}
            setOpen={setOpen}
            iValues={iValues}
          />
        ) : (
          <CreationForm
            iValues={iValues}
            setpassword={setpassword}
            setOpen={setOpen}
            setNewData={setNewData}
          />
        )}
      </UIModal>
    </>
  );
};
