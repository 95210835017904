import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  TableCell,
  Tabs,
  Typography,
} from "@mui/material";
import {
  BasicTable,
  SearchBar,
  UIModal,
  UIDatePicker,
  UIButton,
  Toast,
  UITextField,
} from "components";
import { getVacancies, getJobPositionsOpenings, postVacancies } from "api";
import { useAccess } from "hooks";
import { Form, Formik } from "formik";

import dayjs from "dayjs";
import Swal from "sweetalert2";

export const JobPostings = () => {
  const { t } = useTranslation(["recruitment", "general"]);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange } = HasPermissions(
    "/cereza-organization/recruitment/postings"
  );

  const [ubication, setUbication] = useState("uno");
  const [vacancies, setVacancies] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [open, setOpen] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await getVacancies();
        const dataTable = data?.map((obj, index) => ({
          id: obj?.id,
          index: index + 1,
          name: obj?.position?.title,
          quantity: obj?.quantity,
          validFrom: obj?.validFrom.split("T")[0],
          validTo: obj?.validTo.split("T")[0],
          status: t(`recruitment:${obj?.status}`),
        }));
        setVacancies(dataTable);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchGetApprovedStaffs = async () => {
      try {
        const { data } = await getJobPositionsOpenings();
        const valuesArray = Object.values(data).map((e) => ({
          id: e._id,
          folio: e.folio,
          title: e.title,
          workMode: t(`recruitment:${e.workMode}`),
          positionType: t(`recruitment:${e.positionType}`),
          vacancies: e.openings,
          status: t(`recruitment:${e.status}`),
        }));

        setDataTable(valuesArray);
      } catch (error) {
        console.log(error);
      }
    };

    fetch();
    fetchGetApprovedStaffs();
  }, [update]);

  const StatusRequestAction = (row) => {
    if (canAdd) {
      setOpen(true);
      console.log("rowSelected: ", row);
      setRowSelected(row);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("general:NoAccess"),
      });
    }
  };

  const handleSubmit = (values) => {
    setOpen(false);
    Swal.fire({
      title: t("recruitment:PublicarVacante"),
      text: t("recruitment:confirmaAcción"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("recruitment:sipublicala"),
      cancelButtonText: t("general:Cancel"),
    }).then(async (result) => {
      if (result?.isConfirmed) {
        try {
          const data = {
            validFrom: new Date(`${values?.validFrom}T01:00:00`)
              .toISOString()
              .slice(0, 19),
            validTo: new Date(`${values?.validTo}T01:00:00`)
              .toISOString()
              .slice(0, 19),
            jobPosition: rowSelected?.id,
            quantity: 1,
          };
          await postVacancies(data);
          Toast.fire({ icon: "success", title: t("general:AcciónExitosa") });
          setUpdate(!update);
        } catch (error) {
          Toast.fire({
            icon: "error",
            title: t("recruitment:ErrorAlIntentarPublicarLaVacante"),
          });
          console.log("error: ", error);
        }
      }
    });
  };

  const VacanciesAction = (row) => {
    if (canChange) {
      Swal.fire({
        title: t("recruitment:ArchivarPublicación"),
        text: t("recruitment:confirmaAcción"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("general:Yes"),
        cancelButtonText: t("general:Cancel"),
      }).then(async (result) => {
        if (result?.isConfirmed) {
          try {
            Toast.fire({ icon: "success", title: t("general:AcciónExitosa") });
            setUpdate(!update);
          } catch (error) {
            Toast.fire({
              icon: "error",
              title: t("recruitment:ErrorAlIntentarLaAccion"),
            });
            console.log("error: ", error);
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("general:NoAccess"),
      });
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", display: "grid", placeItems: "center" }}>
        <Tabs
          value={ubication}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          style={{ maxWidth: "100%" }}
        >
          <Tab
            value="uno"
            label={t("recruitment:VacantesPublicadas")}
            onClick={() => setUbication("uno")}
          />
          <Tab
            value="dos"
            label={t("recruitment:PuestosDeTrabajoDisponibles")}
            onClick={() => setUbication("dos")}
          />
        </Tabs>
        <br />
        {ubication === "uno" && (
          <>
            <SearchBar
              data={vacancies}
              searchKeys={["name"]}
              setFilteredData={setFilteredData}
            />
            <br />
            <BasicTable
              rows={filteredData.length > 0 ? filteredData : vacancies}
              handleClick={VacanciesAction}
              customStyle={"Vacancies"}
              PerPage={25}
            >
              <TableCell>#</TableCell>
              <TableCell>{t("recruitment:Vacante")}</TableCell>
              <TableCell>{t("recruitment:Númerodepuestos")}</TableCell>
              <TableCell>{t("recruitment:Fechadepublicación")}</TableCell>
              <TableCell>{t("recruitment:Validohasta")}</TableCell>
              <TableCell>{t("recruitment:status")}</TableCell>
            </BasicTable>
          </>
        )}
        {ubication === "dos" && (
          <>
            {dataTable ? (
              <>
                <BasicTable
                  rows={dataTable}
                  handleClick={StatusRequestAction}
                  customStyle={"StatusRequest"}
                  PerPage={25}
                >
                  <TableCell>{t("recruitment:Folio")}</TableCell>
                  <TableCell>{t("recruitment:title")}</TableCell>
                  <TableCell>{t("recruitment:workMode")}</TableCell>
                  <TableCell>{t("recruitment:PositionType")}</TableCell>
                  <TableCell>{t("recruitment:Vacancies")}</TableCell>
                  <TableCell>{t("recruitment:status")}</TableCell>
                </BasicTable>
              </>
            ) : (
              <>
                <Box
                  p={2}
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "grid",
                    placeItems: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      <UIModal open={open} setOpen={setOpen}>
        <Typography variant="h5">
          {t("recruitment:AjustesPublicación")}
        </Typography>
        <br />
        <Formik
          initialValues={{
            validFrom: dayjs().format("YYYY-MM-DD"),
            validTo: dayjs().format("YYYY-MM-DD"),
            quantity: 1,
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={6}>
                  <UIDatePicker
                    formik={formik}
                    label={t("recruitment:ValidoDesde")}
                    name="validFrom"
                    customDisabled={"Before"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <UIDatePicker
                    formik={formik}
                    label={t("recruitment:Validohasta")}
                    name="validTo"
                    customDisabled={"Before"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <UITextField
                    label={t("organization:numeroDeVacantesApublicar")}
                    name="quantity"
                    formik={formik}
                    isrequired={true}
                    type="number"
                    disabled={rowSelected?.vacancies === 1}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: rowSelected?.vacancies,
                      },
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <UIButton type="submit" label={t("Guardar")} width={"auto"} />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </UIModal>
    </>
  );
};
