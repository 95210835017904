import React from "react";
import { Box } from "@mui/material";

export const MainBox = ({ children, open }) => {
  return (
    <Box
      style={{
        width: open ? `calc(100vw - 465px)` : "calc(100vw - 65px)",
      }}
    >
      {children}
    </Box>
  );
};
