import { useTranslation } from "react-i18next";
import { UIButton, UISelectFast } from "components";
import { Box, Grid, Typography } from "@mui/material";
import { FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LEVELS } from "utils/constants";
import { translateSelectors } from "helpers";
import { useSelector } from "react-redux";
import { generalSelect } from "@redux/slices/general";
import {
  JobDescriptionDisabled,
  JobDescriptionEnabledButtons,
} from "./JobDescriptionDisabled";

export const FormJobSkills = ({
  formik,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["employee"]["options"]);
  const { skills } = useSelector(generalSelect);
  const fieldArrayName = "profile.skills";

  const disabled = JobDescriptionDisabled({ canAdd, canChange, formik });
  const buttons = JobDescriptionEnabledButtons({ canAdd, canChange, formik });

  return (
    <Box mt={2}>
      {skills?.length !== 0 && (
        <FieldArray name={fieldArrayName}>
          {({ push, remove }) => (
            <Box>
              <Grid container spacing={2} mb={2}>
                <Grid item={true} xs={12} md={11}>
                  <Typography variant="h5">{t("employee:Skills")}</Typography>
                </Grid>
                {buttons && (
                  <Grid item={true} xs={12} md={1}>
                    <UIButton
                      onClick={() =>
                        push({
                          skill: "",
                          level: "",
                        })}
                      disabled={formik.isSubmitting}
                    >
                      <AddCircleOutlineIcon />
                    </UIButton>
                  </Grid>
                )}
              </Grid>
              {formik.values?.profile.skills.map((skill, index) => (
                <Grid container={true} spacing={2} mb={2} key={index}>
                  <Grid item={true} xs={12} md={4}>
                    <UISelectFast
                      label={t("employee:Skill")}
                      name={`${fieldArrayName}.${index}.skill`}
                      options={translateSelectors(t, skills)}
                      disabled={disabled}
                      required={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12} md={4}>
                    <UISelectFast
                      label={t("employee:Level")}
                      name={`${fieldArrayName}.${index}.level`}
                      options={translateSelectors(t, LEVELS)}
                      disabled={disabled}
                      required={true}
                    />
                  </Grid>
                  {buttons && (
                    <Grid item={true} xs={12} md={1}>
                      <UIButton
                        onClick={() => remove(index)}
                        disabled={formik.isSubmitting}
                      >
                        <DeleteIcon />
                      </UIButton>
                    </Grid>
                  )}
                </Grid>
              ))}
            </Box>
          )}
        </FieldArray>
      )}
    </Box>
  );
};
