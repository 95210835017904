import { useTranslation } from "react-i18next";
import { nodos } from "helpers";
import { EmployeeNavigation } from "../CreateGroups/EmployeeNavigation";

export const CheckBoxMenuEmployees = ({ formik , ...rest}) => {
  const { t } = useTranslation(["sidenav"]);
  const {disabled} = rest;

  return EmployeeNavigation?.map((obj, i) => (
    nodos(t, obj, i, formik, disabled)
  ));
};