import PropTypes from "prop-types";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { forwardRef } from "react";

export const UIButton = forwardRef(({ label, children, ...props }, ref) => {
  return (
    <LoadingButton
      variant="contained"
      type="button"
      fullWidth={true}
      {...props}
      ref={ref}
    >
      {label}
      {children}
    </LoadingButton>
  );
});

UIButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};
