import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainColor: "#DE008B",
  logo: null,
  mode: "light",
  darkBackgroundColor: "#121212",
};

const parameterizationSlice = createSlice({
  name: "parameterization",
  initialState,
  reducers: {
    setMainColor: (state, action) => {
      state.mainColor = action.payload;
    },

    setLogo: (state, action) => {
      state.logo = action.payload;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setMainColor, setLogo, setMode } = parameterizationSlice.actions;

export const parameterizationSelect = (state) => state.parameterization;

export default parameterizationSlice.reducer;
