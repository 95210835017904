import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobProfile: {},
  staffRequest: {},
  staffs: [],
  updateStaffs: true,
  vacancyReasons: [],
};

export const recruitment = createSlice({
  name: "recruitment",
  initialState: initialState,
  reducers: {
    setJobProfile: (state, action) => {
      state.jobProfile = action.payload;
    },
    setStaffRequest: (state, action) => {
      state.staffRequest = action.payload;
    },
    setStaffs: (state, action) => {
      state.staffs = action.payload;
    },
    setUpdateStaffs: (state, action) => {
      state.updateStaffs = action.payload;
    },
    setVacancyReasons: (state, action) => {
      state.vacancyReasons = action.payload;
    },
  },
});

export const {
  setJobProfile,
  setStaffRequest,
  setStaffs,
  setUpdateStaffs,
  setVacancyReasons,
} = recruitment.actions;

export const recruitmentSelect = (state) => state.recruitment;

export default recruitment.reducer;
