import { OrganizationNavigationSource } from "./OrganizationNavigationSource";

function filterNavigation(data, esNodoHijo = false) {
  return data.reduce((acc, item) => {
    if (item.is_permission || esNodoHijo) {
      const newItem = { ...item };
      if (item.tree) {
        newItem.tree = filterNavigation(item.tree, true);
      }
      acc.push(newItem);
    }
    return acc;
  }, []);
}

// Filtrar las secciones con is_permission en true
export const OrganizationNavigation = filterNavigation(
  OrganizationNavigationSource
);