import { getEmails } from "api";
import { useEffect, useState } from "react";

export function useEmails() {
  const [emails, setEmails] = useState(undefined);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const { data } = await getEmails();
        const aux = data.reduce((acc, curr) => {
          acc[curr._id] = curr;
          return acc;
        }, {});

        setEmails(aux);
      } catch (err) {
        console.error("Error getting emails:", err);
      }
    };

    fetchEmails();
  }, []);

  return emails;
}
