import { Grid } from "@mui/material";
import { UITextField, UIJoditEditor } from "../../../components";
import { useTranslation } from "react-i18next";

export const FormMailVariables = ({ formik }) => {
  const { t } = useTranslation(["settings"]["general"]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={12}>
        <UITextField
          formik={formik}
          label={t("settings:TagName")}
          name={"title"}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={true} xs={12} md={12}>
        <UIJoditEditor
          label={t("settings:htmlContent")}
          name={"html"}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
