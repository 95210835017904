import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workSchedule: {},
  allWorkSchedules: [],
};

const workSchedulesSlice = createSlice({
  name: "workSchedules",
  initialState,
  reducers: {
    setWorkSchedule: (state, action) => {
      state.workSchedule = action.payload;
    },
    setAllWorkSchedules: (state, action) => {
      state.allWorkSchedules = action.payload;
    },
  },
});

export const { setWorkSchedule, setAllWorkSchedules } =
  workSchedulesSlice.actions;

export const workSchedulesSelect = (state) => state.workSchedules;

export default workSchedulesSlice.reducer;
