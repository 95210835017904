import PropTypes from "prop-types";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Box, Button } from "@mui/material";
import "./styles.css";

export const PlacesAutocomplete = ({
  setSelected,
  setSelectedManual,
  isMarkerBeingDragged,
  setQuest,
  setZoom,
}) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setZoom(15);
    setSelectedManual(null);
    setValue(address, false);
    clearSuggestions();
    if (!isMarkerBeingDragged) {
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);
      setSelected({ lat, lng });

      const country = results[0]?.address_components?.find((address) =>
        address.types.includes("country")
      )?.short_name;

      setQuest({
        country: country,
        address: address,
        lat: lat,
        lng: lng,
      });
    }
  };

  return (
    <Box className="combobox">
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="combobox-input"
          value={value}
          placeholder={"Buscar..."}
          onChange={(e) => setValue(e.target.value)}
          // disabled={!ready}
        />
        <ComboboxPopover className="combobox-popover">
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption
                  className="combobox-option"
                  key={place_id}
                  value={description}
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      <Box className="clear-box">
        <Button
          onClick={() => {
            setValue("");
            clearSuggestions();
          }}
          variant="gradient"
          color="info"
          className="clear-button"
        >
          <BackspaceIcon />
        </Button>
      </Box>
    </Box>
  );
};

PlacesAutocomplete.propTypes = {
  setSelected: PropTypes.func,
  setSelectedManual: PropTypes.func,
  isMarkerBeingDragged: PropTypes.bool,
  setQuest: PropTypes.func,
  setZoom: PropTypes.func,
};
