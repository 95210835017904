import { useEffect, useState } from "react";
import { UIButton, UISelect, UITextField } from "components";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies, getLanguages, getTimeZones } from "api";
import {
  generalSelect,
  setCurrencies,
  setTimeZones,
} from "@redux/slices/general";
import { setLanguages } from "@redux/slices/general";
import { ModalMap } from "components/Map/ModalMap";

export const LocationsForm = ({ formik }) => {
  const { t } = useTranslation(["organization"]);

  const dispatcher = useDispatch();
  const { timeZones, languages, currencies, countriesCompleteData } =
    useSelector(generalSelect);

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(null);

  const countrySelect = countriesCompleteData?.find(
    (country) => country?.alpha2 === address?.country
  )?._id;

  const handleClickMap = () => {
    formik.setFieldValue("country", countrySelect);
    formik.setFieldValue("address", address?.address);
    formik.setFieldValue("latitude", address?.lat);
    formik.setFieldValue("longitude", address?.lng);
  };

  useEffect(() => {
    const fetchDataLanguages = async () => {
      if (languages?.length === 0 || !languages || languages === undefined) {
        try {
          const { data } = await getLanguages(true);
          dispatcher(setLanguages(data));
        } catch (err) {
          console.error("Error getting languages:", err);
        }
      }
    };

    const fetchCurrencies = async () => {
      if (currencies?.length === 0 || !currencies || currencies === undefined) {
        try {
          const { data } = await getCurrencies(true);
          dispatcher(setCurrencies(data));
        } catch (err) {
          console.error("Error getting currencies:", err);
        }
      }
    };

    const fetchTimeZones = async () => {
      if (timeZones?.length === 0 || !timeZones || timeZones === undefined) {
        try {
          const { data } = await getTimeZones("selectors");
          dispatcher(setTimeZones(data));
        } catch (err) {
          console.error("Error getting time zones:", err);
        }
      }
    };

    fetchDataLanguages();
    fetchCurrencies();
    fetchTimeZones();
  }, []);

  return (
    <>
      {timeZones?.length && currencies?.length && languages?.length ? (
        <>
          <Grid container spacing={2}>
            <Grid item={true} xs={12} md={8}>
              <Box
                onClick={() => {
                  setOpen(true);
                }}
              >
                <UITextField
                  formik={formik}
                  label={t("employee:Dirección")}
                  name={"address"}
                  InputProps={{ readOnly: true }}
                  disabled={formik.isSubmitting}
                />
              </Box>
              <UITextField
                formik={formik}
                label={t("employee:Latitud")}
                name={"latitude"}
                style={{ display: "none" }}
                disabled={formik.isSubmitting}
              />
              <UITextField
                formik={formik}
                label={t("employee:Longitud")}
                name={"longitude"}
                style={{ display: "none" }}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UITextField
                formik={formik}
                label={t("organization:LocationName")}
                name={"name"}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={8}>
              <UISelect
                formik={formik}
                label={t("organization:timeZone")}
                name={"timeZone"}
                options={timeZones}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UITextField
                formik={formik}
                label={t("organization:zipCode")}
                name={"zipCode"}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelect
                formik={formik}
                label={t("organization:language")}
                name={"language"}
                options={languages}
                disabled={formik.isSubmitting}
              />
            </Grid>
            <Grid item={true} xs={12} md={4}>
              <UISelect
                formik={formik}
                label={t("organization:currency")}
                name={"currency"}
                options={currencies}
                disabled={formik.isSubmitting}
              />
            </Grid>
          </Grid>
          <Box
            mt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
          >
            <Box display="flex" gap="1em">
              <UIButton
                type="submit"
                label={
                  formik?.values?._id
                    ? t("general:Actualizar")
                    : t("general:Guardar")
                }
                loading={formik.isSubmitting}
              />
              <UIButton
                label={t("general:restart")}
                onClick={() =>
                  formik.setValues({
                    address: "",
                    latitude: "",
                    longitude: "",
                    name: "",
                    zipCode: "",
                    timeZone: "",
                    language: "",
                    currency: "",
                  })
                }
                disabled={formik.isSubmitting}
              />
            </Box>
          </Box>
          <ModalMap
            open={open}
            setOpen={setOpen}
            setAddress={setAddress}
            address={address}
            handleClick={handleClickMap}
          />
        </>
      ) : (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <CircularProgress />
        </Stack>
      )}
    </>
  );
};
