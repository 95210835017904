import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import {
  FilePreview,
  Toast,
  UIButton,
  UIColorPicker,
  UIPhoneNumber,
  UIRichText,
  UISelect,
  UITextField,
} from "components";
import { Box, Checkbox, Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { setTenantSettings, tenantSettingsSelect } from "@redux/slices/tenant";
import { useDispatch, useSelector } from "react-redux";
import { SocialNetworks } from "../../Employees/Profiles/Collaborator/ContactDetails/SocialNetworks";
import { managerErrors } from "utils";
import { getTenantSettings, patchTenantSettings } from "api";
import { generalSelect } from "@redux/slices/general";
import { setMainColor } from "@redux/slices/parameterization";
import { useAccess } from "hooks";

export const GeneralData = ({ setUpdateWizard = null }) => {
  const dispatch = useDispatch();

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    "/cereza-organization/organization/organization-settings"
  );

  const { t } = useTranslation(["organization"]["general"]);
  const { t: translate } = useTranslation(["employee"]);
  const { tenant, currentPlan } = useSelector(tenantSettingsSelect);
  const { locations } = useSelector(generalSelect);

  const [srcAvatar, setSrcAvatar] = useState(null);
  const [srcPath, setSrcPath] = useState(tenant?.companyLogo);

  const [srcAvatarLogoLogin, setSrcAvatarLogoLogin] = useState(null);
  const [srcPathLogoLogin, setSrcPathLogoLogin] = useState(tenant?.logoLogin);

  useEffect(() => {
    const getTenant = async () => {
      try {
        const { data } = await getTenantSettings();
        dispatch(setMainColor(data?.customColors?.mainColor));
        dispatch(setTenantSettings(data));
      } catch (err) {
        console.error("Error getting locations:", err);
      }
    };
    getTenant();
  }, []);

  const handleSubmit = async (values) => {
    const {
      acceptTermsAndConditions,
      createdAt,
      updatedAt,
      currentPlan,
      tenant,
      _id,
      ...rest
    } = values;
    try {
      await patchTenantSettings(_id, rest);
      dispatch(setTenantSettings(values));
      dispatch(setMainColor(rest?.customColors?.mainColor));

      if (setUpdateWizard) {
        setUpdateWizard(Math.random());
      }

      Toast.fire({
        icon: "success",
        title: t("general:AcciónExitosa"),
      });
    } catch (error) {
      managerErrors(error?.response?.data?.message);
      console.error(error);
    }
  };

  return (
    <Paper>
      <Box p={4}>
        <Typography variant="h5">{t("organization:GeneralData")}</Typography>
        <br />
        <Formik initialValues={tenant} onSubmit={handleSubmit}>
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={8}>
                  <Grid container spacing={2}>
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("organization:tenant")}
                        name={"tenant"}
                        disabled={true}
                        isrequired={false}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("organization:commercialName")}
                        name={"commercialName"}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("organization:legalName")}
                        name={"legalName"}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("organization:taxIdentificationNumber")}
                        name={"taxIdentificationNumber"}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UIColorPicker
                        label={t("organization:customColors")}
                        value={formik.values["customColors"]?.mainColor}
                        onChange={(event) => {
                          const formattedColor =
                            event.target.value.toUpperCase();
                          formik.setFieldValue("customColors", {
                            ...formik.values["customColors"],
                            mainColor: formattedColor,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UIPhoneNumber
                        formik={formik}
                        label={t("organization:companyPhone")}
                        name={"companyPhone"}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UITextField
                        formik={formik}
                        label={t("organization:companyUrl")}
                        name={"companyUrl"}
                        disabled={formik.isSubmitting}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UISelect
                        formik={formik}
                        label={t("organization:mainLocation")}
                        name={"mainLocation"}
                        options={locations}
                        disabled={formik.isSubmitting}
                        isrequired={true}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography variant="h6">
                        {t("organization:ConfiguraciónLogin")}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UIColorPicker
                        label={t("organization:colorDegradadoInicial")}
                        value={
                          formik.values["customColors"]?.loginGradient
                            ?.initialColor
                        }
                        onChange={(event) => {
                          const formattedColor =
                            event.target.value.toUpperCase();
                          formik.setFieldValue("customColors", {
                            ...formik.values["customColors"],
                            loginGradient: {
                              ...formik.values["customColors"]?.loginGradient,
                              initialColor: formattedColor,
                            },
                          });
                        }}
                      />
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <UIColorPicker
                        label={t("organization:colorDegradadoFinal")}
                        value={
                          formik.values["customColors"]?.loginGradient
                            ?.finalColor
                        }
                        onChange={(event) => {
                          const formattedColor =
                            event.target.value.toUpperCase();
                          formik.setFieldValue("customColors", {
                            ...formik.values["customColors"],
                            loginGradient: {
                              ...formik.values["customColors"]?.loginGradient,
                              finalColor: formattedColor,
                            },
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12} md={4}>
                  <Grid container spacing={3}>
                    <Grid item={true} xs={12}>
                      <FilePreview
                        formik={formik}
                        name={"companyLogo"}
                        src={srcAvatar}
                        setSrc={setSrcAvatar}
                        srcPath={srcPath}
                        lblBtnUpdate={translate("employee:UploadFile")}
                        disabled={
                          currentPlan?.name === "Free" || formik.isSubmitting
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <hr />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <FilePreview
                        formik={formik}
                        name={"logoLogin"}
                        src={srcAvatarLogoLogin}
                        setSrc={setSrcAvatarLogoLogin}
                        srcPath={srcPathLogoLogin}
                        lblBtnUpdate={translate("employee:UploadFile")}
                        disabled={
                          currentPlan?.name === "Free" || formik.isSubmitting
                            ? true
                            : false
                        }
                      />
                      <Box sx={{ display: "grid", placeItems: "center" }}>
                        <Typography variant="overline">
                          {t("organization:LogoForLogin")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                  <UIRichText
                    formik={formik}
                    label={t("organization:companyDescription")}
                    name={"companyDescription"}
                    disabled={formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <br />
                  <SocialNetworks
                    formik={formik}
                    nameFileArray={"socialNetworks"}
                    values={formik.values.socialNetworks}
                    canAdd={canAdd}
                    canChange={canChange}
                    canDelete={canDelete}
                    md={7}
                  />
                </Grid>
              </Grid>
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                </Box>
                <Box
                  width="100%"
                  display="grid"
                  sx={{
                    gridTemplateColumns: "auto 1fr",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Checkbox disabled checked />
                  <a
                    href="https://cereza.io/legal/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate("employee:Terminos")}
                  </a>
                </Box>
              </Box>
              <br />
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};
