import {
  Stack,
} from "@mui/material";
import { UIBootstrapDialog } from "components";


const FileView = ({ src }) => {
  var data_array = src.split(";");
  var data_type = data_array[0];

  switch (data_type) {
    case "data:application/pdf":
      const binStr = atob(data_array[1].replace("base64,", ""));
      const len = binStr.length;
      const arr = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      const blob = new Blob([arr], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      return (
        <embed
          src={url}
          style={{
            width: "50vw",
            height: "70vh",
          }}
        />
      );
    default:
      return (
        <img
          src={src}
          style={{
            width: "500px",
            maxWidth: "500px",
          }}
        />
      );
  }
};

export const PreviewModal = (props) => {
  const {open, setOpen , src} = props;
  return (
    <UIBootstrapDialog open={open} setOpen={setOpen} fullWidth={false}>
      <Stack direction="row" alignItems="center" justifyContent="center" p={2}>
        <FileView src={src} />
      </Stack>
    </UIBootstrapDialog>
  );
};
