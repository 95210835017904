import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areas: [],
  skills: [],
  timeZones: [],
  collages: [],
  countries: [],
  countriesCompleteData: [],
  languages: [],
  locations: [],
  currencies: [],
  assetTypes: [],
  departments: [],
  openDialogSearch: false,
  expandedSearch: false,
  textSearchDialog: "",
};

export const general = createSlice({
  name: "general",
  initialState: initialState,
  reducers: {
    setSkills: (state, action) => {
      state.skills = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setCountriesCompleteData: (state, action) => {
      state.countriesCompleteData = action.payload;
    },
    setTimeZones: (state, action) => {
      state.timeZones = action.payload;
    },
    setCollages: (state, action) => {
      state.collages = action.payload;
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setAreas: (state, action) => {
      state.areas = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setOpenDialogSearch: (state, action) => {
      state.openDialogSearch = action.payload;
    },
    setExpandedSearch: (state, action) => {
      state.expandedSearch = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setAssetTypes: (state, action) => {
      state.assetTypes = action.payload;
    },
    setTextSearchDialog: (state, action) => {
      state.textSearchDialog = action.payload;
    },
  },
});

export const {
  setAreas,
  setSkills,
  setTimeZones,
  setCollages,
  setCountries,
  setCountriesCompleteData,
  setLanguages,
  setLocations,
  setCurrencies,
  setAssetTypes,
  setDepartments,
  setExpandedSearch,
  setOpenDialogSearch,
  setTextSearchDialog,
} = general.actions;

export const generalSelect = (state) => state.general;

export default general.reducer;
