import axios from "axios";
import store from "@redux/store";

export const getTenant = () => {
  const url = window.location.href;
  if (url.includes("localhost")) return process.env.REACT_APP_TENANT;
  const tenant = url.split(".")[0].split("//")[1];
  return tenant;
};

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const getAuthToken = () => {
  const state = store.getState();
  const { accessToken } = state.authUser;
  if (accessToken) {
    return accessToken;
  }
  return null;
};

const authInterceptor = (config) => {
  const token = getAuthToken();
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";
  config.headers["Client"] = "web-app-cli";
  config.headers["Realm"] = getTenant();
  return config;
};

const successInterceptor = (response) => {
  return response;
};

const errorInterceptor = (error) => {
  if (error?.response?.status) {
    // eslint-disable-next-line default-case
    switch (error.response.status) {
      case 401:
        const { url } = error.config;
        if (url === "/auth/sign-in/keycloak") return;
        else {
          console.log("Su sesión ha terminado");
          setTimeout(() => {
            localStorage.clear();
            // document.location.reload();
          }, 2000);
        }
    }
  }
  return Promise.reject(error);
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.response.use(successInterceptor, errorInterceptor);
