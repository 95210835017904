import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const UIRichText = ({
  formik,
  label = "",
  name = "",
  nameFileArray = "",
  index = null,
}) => {
  const { t } = useTranslation();

  let value = nameFileArray === ""
    ? formik.values[name]
    : formik.values[nameFileArray][index][name];

  if (!value || value === undefined) {
    value = "<p><br></p>";
  }

  const onChange = (value) => {
    nameFileArray === ""
      ? formik.setFieldValue(name, value)
      : formik.setFieldValue(`${nameFileArray}.${index}.${name}`, value);
  };

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ direction: "rtl" }],

      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ["clean"],
    ],
  };

  return (
    <>
      <Box>
        <Typography variant="subtitle2">{t(label)}</Typography>
      </Box>
      <Paper>
        <ReactQuill
          theme="snow"
          value={value}
          onChange={onChange}
          modules={modules}
        />
      </Paper>
    </>
  );
};

UIRichText.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nameFileArray: PropTypes.string,
  index: PropTypes.number,
};
