import { Box, Grid, Typography, TextField } from "@mui/material";

export const UIColorPicker = ({
  label = "",
  value,
  onChange,
  isrequired = true,
  disabled = false,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item={true} xs={12} md={6}>
        <TextField
          fullWidth
          type="color"
          label={label}
          value={value}
          onChange={onChange}
          required={isrequired}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={6}>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            gap: "1rem",
            width: "100%",
            border: 1,
            borderRadius: 1,
            borderColor: "rgba(0, 0, 0, 0.23)",
            padding: 2,
            "&:hover": {
              borderColor: "rgba(0, 0, 0, 0.87)",
            },
          }}
        >
          <Typography variant="subtitle2">
            <b>HEX:</b> {value}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
