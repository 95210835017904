import React, { useMemo, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Box, Skeleton } from "@mui/material";
import { PlacesAutocomplete } from "./PlacesAutocomplete";
import { getAddressFromCoordinates } from "./helper";
import { ShowForm } from "./ShowForm";
import PropTypes from "prop-types";

import { uiSelect } from "@redux/slices/ui";
import { useSelector } from "react-redux";
import "./styles.css";

const libraries = ["places"];

const Map = ({ setAddress, onlyMap = false }) => {
  const { currentGeolocation } = useSelector(uiSelect);

  const [selected, setSelected] = useState(null);
  const [isMarkerBeingDragged, setIsMarkerBeingDragged] = useState(false);
  const [zoom, setZoom] = useState(17);
  const [selectedManual, setSelectedManual] = useState(null);
  const [quest, setQuest] = useState(null);

  const center = useMemo(() => currentGeolocation, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
    libraries: libraries,
  });

  if (loadError) {
    console.log("loadError", loadError);
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return (
      <Box
        sx={{
          bgcolor: "#121212",
          height: "50vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          width={"100%"}
          height={"50vh"}
        />
      </Box>
    );
  }

  setAddress(selectedManual !== null ? selectedManual : quest);
  return (
    <>
      {!onlyMap && (
        <PlacesAutocomplete
          setSelected={setSelected}
          setSelectedManual={setSelectedManual}
          isMarkerBeingDragged={isMarkerBeingDragged}
          setQuest={setQuest}
          setZoom={setZoom}
        />
      )}

      <GoogleMap
        mapContainerClassName="map-container"
        center={selected === null ? center : selected}
        zoom={zoom}
      >
        <Marker
          position={selected === null ? center : selected}
          animation={window.google.maps.Animation.BOUNCE}
          draggable={true}
          onDragStart={() => setIsMarkerBeingDragged(true)}
          onDragEnd={async (e) => {
            setIsMarkerBeingDragged(false);
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();
            setSelected({ lat, lng });
            getAddressFromCoordinates(lat, lng)
              .then((addressInfo) => {
                setSelectedManual(addressInfo);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }}
        />
      </GoogleMap>

      {!onlyMap && (
        <ShowForm
          selectedManual={selectedManual !== null ? selectedManual : quest}
        />
      )}
    </>
  );
};

Map.propTypes = {
  setAddress: PropTypes.func.isRequired,
};

export default Map;
