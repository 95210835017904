import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { UIBootstrapDialog, UIButton } from "components";
import {
  Box,
  DialogContent,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
} from "@mui/material";
import {
  DeleteForever as DeleteForeverIcon,
  ZoomOutMap as ZoomOutMapIcon,
} from "@mui/icons-material";
import MimePdfIcon from "assets/illustrations/application-pdf.svg";

export const MultipleFiles = ({ formik, name, disabled, files, setFiles }) => {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);

  const openModalFile = () => {
    inputFile.current.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const loadFiles = async (arrayFiles) => {
    const filesFormik = [...formik.values[name]];
    const filesData = [...files];

    for (let i = 0; i < arrayFiles.length; i++) {
      let fileb64 = await getBase64(arrayFiles[i])
        .then((result) => {
          return result;
        })
        .catch((e) => {
          console.log(e);
        });

      filesFormik.push(fileb64);
      filesData.push(fileb64);
    }

    formik.setFieldValue(name, filesFormik);
    setFiles(filesData);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <UIButton
          label={t("request:SelectFiles")}
          fullWidth={false}
          onClick={openModalFile}
          disabled={disabled}
        />
        <input
          name="files"
          type="file"
          multiple
          ref={inputFile}
          hidden
          onChange={(event) => {
            const arrayFiles = Array.from(event.currentTarget.files).map(
              (e) => e,
            );
            loadFiles(arrayFiles);
          }}
        />
      </Box>
      <UIBootstrapDialog open={open} setOpen={setOpen} fullWidth={false}>
        <DialogContent>{file}</DialogContent>
      </UIBootstrapDialog>
      <ImageList variant="quilted" cols={3} rowHeight={164}>
        {files.map((file, index) => {
          let filePreview = null;
          let fileModal = null;

          if (/^data:image/.test(file)) {
            filePreview = (
              <img
                srcSet={`${file}`}
                src={`${file}?fit=contain&auto=format`}
                style={{ objectFit: "contain" }}
              />
            );
            fileModal = filePreview;
          }

          if (/^data:application\/pdf/.test(file)) {
            filePreview = (
              <img
                srcSet={`${MimePdfIcon}`}
                src={`${MimePdfIcon}?fit=contain&auto=format`}
                style={{ objectFit: "contain" }}
              />
            );

            const data_array = file.split(";");
            const binStr = atob(data_array[1].replace("base64,", ""));
            const len = binStr.length;
            const arr = new Uint8Array(len);

            for (let i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }

            const blob = new Blob([arr], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);

            fileModal = (
              <embed
                src={url}
                style={{
                  width: "50vw",
                  height: "70vh",
                }}
              />
            );
          }

          return (
            <div key={`${index}-${Math.random()}`}>
              <ImageListItem>
                {filePreview}
                <ImageListItemBar
                  actionIcon={
                    <>
                      <IconButton
                        sx={{ color: "rgba(255, 255, 255, 0.75)" }}
                        onClick={() => {
                          setFile(fileModal);
                          setOpen(true);
                        }}
                        disabled={formik.isSubmitting}
                      >
                        <ZoomOutMapIcon />
                      </IconButton>
                      {!disabled && (
                        <IconButton
                          sx={{ color: "rgba(255, 255, 255, 0.75)" }}
                          onClick={() => {
                            const filesFormik = [...formik.values[name]];
                            filesFormik.splice(index, 1);
                            formik.setFieldValue(name, filesFormik);

                            const filesData = [...files];
                            filesData.splice(index, 1);
                            setFiles(filesData);
                          }}
                          disabled={formik.isSubmitting}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      )}
                    </>
                  }
                ></ImageListItemBar>
              </ImageListItem>
            </div>
          );
        })}
      </ImageList>
    </Stack>
  );
};
