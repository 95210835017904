import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, TableCell } from "@mui/material";
import {
  BasicTable,
  FilePreview,
  UIDatePicker,
  UISelect,
  UITextField,
} from "components";
import { DOCUMENT_OPTIONS, getLabel } from "utils";
import { generalSelect } from "@redux/slices/general";
import { profileSelect } from "@redux/slices/profile";
import { getReferences } from "api";
import { useCountries } from "hooks";
import { translateSelectors } from "helpers";

export const FormIdentitesDocumentos = ({
  formik,
  newData,
  canAdd,
  canChange,
  user = null,
}) => {
  const { t } = useTranslation(["employee"]);

  useCountries();
  const { countries } = useSelector(generalSelect);
  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const [srcFile, setSrcFile] = useState(null);
  const [srcPath, setSrcPath] = useState("");
  const [rows, setRows] = useState([]);
  const [responseData, setResponseData] = useState({});
  const disabled = (!canAdd && formik.values?._id === undefined) ||
    (!canChange && formik.values?._id !== undefined) ||
    formik.isSubmitting;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getReferences(
          employeeSelected._id,
          "identities",
        );
        const cleanData = data.map((item) => ({
          id: item._id,
          type: item.type,
          number: item.number,
          country: getLabel(countries, item.country),
        }));
        setRows(cleanData);
        setResponseData(data);
      } catch (err) {
        console.error("Error getting identities:", err);
      }
    };
    setSrcPath("");
    fetchData();
  }, [employeeSelected, newData]);

  const handleClick = (row) => {
    const identitySelected = responseData.find((obj) => obj._id === row.id);
    const {createdAt, updatedAt, filePath, ...rest} = identitySelected;
    setSrcPath(filePath);
    formik.setValues(rest);
  };

  return (
    <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
      <Grid item={true} xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item={true} xs={12}>
            <UISelect
              formik={formik}
              label={t("employee:Documenttype")}
              name="type"
              options={translateSelectors(t, DOCUMENT_OPTIONS)}
              disabled={disabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <UITextField
              formik={formik}
              label={t("employee:Identificationnumber")}
              name="number"
              disabled={disabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <UISelect
              formik={formik}
              label={t("employee:Documentcountry")}
              name="country"
              options={countries}
              disabled={disabled}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <UIDatePicker
              formik={formik}
              label={t("employee:Expirationdate")}
              name="expirationDate"
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} md={3} sx={{minWidth: "193px"}}>
        <FilePreview 
          formik={formik}
          name={"file"}
          src={srcFile}
          setSrc={setSrcFile}
          srcPath={srcPath}
          lblBtnUpdate={t("employee:UploadFile")}
          disabled={disabled}
        />
      </Grid>
      <Grid item={true} xs={12} md={5}>
        <BasicTable rows={rows} handleClick={handleClick}>
          <TableCell>{t("employee:Tipodedocumento")}</TableCell>
          <TableCell>{t("employee:Númerodeldocumento")}</TableCell>
          <TableCell>{t("employee:País")}</TableCell>
        </BasicTable>
      </Grid>
    </Grid>
  );
};
