import { useSelector } from "react-redux";
import { parameterizationSelect } from "@redux/slices/parameterization";
import {
  Calendar,
  Whisper,
  Popover as PopoverRS,
  Badge,
  CustomProvider,
} from "rsuite";
import { getCalendar } from "api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Paper,
  Popover,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import "rsuite/dist/rsuite.min.css";
import "../../../styles.css";

function getListOfDatesPerDay(date, fechas) {
  const calendarDate = date.toISOString().split("T")[0];
  const fechaSinAnio = calendarDate.slice(5);

  const items = fechas.filter((obj) => obj.date.includes(fechaSinAnio));

  return items.length > 0 ? items : [];
}

const TransformDates = (Filtro, dates, t) => {
  const format = [];

  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];

    if (Filtro.includes("Cumpleaños")) {
      if (date.dateOfBirth && date.dateOfBirth !== "") {
        const fechaCorta = date.dateOfBirth.split("T")[0];
        format.push({
          _id: date?._id,
          fullName: date?.fullName,
          date: fechaCorta,
          title: `🎂 ${t("home:dateOfBirth")} ${date?.fullName}`,
          type: "dateOfBirth",
        });
      }
    }

    if (Filtro.includes("AniversariosLaborales")) {
      if (date.dateJoiningCompany && date.dateJoiningCompany !== "") {
        const fechaCorta = date.dateJoiningCompany.split("T")[0];
        format.push({
          _id: date?._id,
          fullName: date?.fullName,
          date: fechaCorta,
          title: `🎉 ${t("home:dateJoiningCompany")} ${date?.fullName}`,
          type: "dateJoiningCompany",
        });
      }
    }
  }

  return format;
};

export const CalendarWidget = () => {
  const { t } = useTranslation(["home"]);

  const { mode } = useSelector(parameterizationSelect);

  const [fechas, setFechas] = useState([]);
  const [checked, setChecked] = useState([
    "Cumpleaños",
    "AniversariosLaborales",
  ]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await getCalendar();
        setFechas(TransformDates(checked, data, t));
      } catch (error) {
        console.log(error);
      }
    };
    fetch();
  }, [checked]);

  function renderCell(date) {
    const list = getListOfDatesPerDay(date, fechas);

    if (list?.length) {
      const moreCount = list.length;
      const moreItem = (
        <li>
          <Whisper
            placement="top"
            trigger="click"
            speaker={
              <PopoverRS>
                {list.map((item, index) => (
                  <p key={index}>
                    <b>{item.title}</b>
                  </p>
                ))}
              </PopoverRS>
            }
          >
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {moreCount > 1 ? (
                <>
                  <a>
                    <Badge /> {t("home:view")} {moreCount}
                  </a>
                </>
              ) : (
                <>
                  <Badge /> <a>{t("home:view")}</a>
                </>
              )}
            </Box>
          </Whisper>
        </li>
      );

      return <ul className="calendar-todo-list">{moreItem}</ul>;
    }

    return null;
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Paper
      elevation={3}
      sx={{
        width: "100% !important",
        height: "100% !important",
        display: "grid",
        alignItems: "center",
        position: "relative",
      }}
    >
      <CustomProvider theme={mode}>
        <Button
          style={{
            width: "100px",
            height: "30px",
            position: "absolute",
            top: "20px",
            right: "80px",
            zIndex: 1,
          }}
          startIcon={<FilterAltIcon />}
          onClick={handleClick}
          aria-describedby={id}
        >
          {t("home:filtrar")}
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            mt: 1,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
            subheader={<ListSubheader>{t("home:filtro")}</ListSubheader>}
          >
            <ListItem>
              <ListItemIcon>🎂</ListItemIcon>
              <ListItemText
                id="switch-list-label-Cumpleaños"
                primary={t("home:Cumpleaños")}
              />
              <Switch
                edge="end"
                onChange={handleToggle("Cumpleaños")}
                checked={checked.indexOf("Cumpleaños") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-Cumpleaños",
                }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>🎉</ListItemIcon>
              <ListItemText
                id="switch-list-label-AniversariosLaborales"
                primary={t("home:AniversariosLaborales")}
              />
              <Switch
                edge="end"
                onChange={handleToggle("AniversariosLaborales")}
                checked={checked.indexOf("AniversariosLaborales") !== -1}
                inputProps={{
                  "aria-labelledby": "switch-list-label-AniversariosLaborales",
                }}
              />
            </ListItem>
          </List>
        </Popover>

        <Calendar compact bordered renderCell={renderCell} />
      </CustomProvider>
    </Paper>
  );
};
