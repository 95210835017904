import React, { useState } from "react";
import { PropTypes } from "prop-types";
import {
  Box,
} from "@mui/material";
import { UIBootstrapDialog, UIButton } from "components";
import Avatar from "react-avatar-edit";
import { useTranslation } from "react-i18next";

export const AvatarCropper = ({ src, open, setOpen, setSrcPhoto, formik, name }) => {
  const [preview, setPreview] = useState(null);
  const { t } = useTranslation(["employee"]);

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (view) => {
    setPreview(view);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    formik.setFieldValue(name, preview);
    setSrcPhoto(preview);
  };

  return (
    <UIBootstrapDialog
      open={open}
      onClose={handleClose}
      title={t("employee:CropPhoto")}
      aria-labelledby="avatar-cropper"
      aria-describedby="avatar-cropper"
      maxWidth={"fit-content"}
      fullWidth={false}
      PaperProps={{sx: {overflow: "hidden",},}}
    >
      <Box p={2} maxWidth={"fit-content"}>
        <Avatar
          width={500}
          height={250}
          src={src}
          onCrop={onCrop}
          onClose={onClose}
          exportAsSquare={true}
        />
        <Box mt={2} display="flex" width={"fit-content"} maxWidth={"55%"} gap="1em">
          <UIButton
            onClick={handleSave}
            label={t("settings:Accept")}
            disabled={!preview}
          />
        </Box>
      </Box>
    </UIBootstrapDialog>
  );
};

AvatarCropper.propTypes = {
  src: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSrcPhoto: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.any.isRequired,
};
