import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import authUserReducer from "./slices/authUser";
import uiReducers from "./slices/ui";
import generalReducers from "./slices/general";
import parameterizationReducers from "./slices/parameterization";
import profileReducers from "./slices/profile";
import tenantSettingsReducers from "./slices/tenant";
import timeOffReducers from "./slices/time-off";
import policesReducers from "./slices/attendance-absence";
import workSchedulesReducers from "./slices/work-schedules";
import jobDescriptionReducers from "./slices/jobDescription";
import recruitmentReducers from "./slices/recruitment";
import requestsReducers from "./slices/requests";
import socketReducers from "./slices/socket";
import publicReducers from "./slices/public";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "authUser",
    "ui",
    "general",
    "parameterization",
    "profile",
    "tenantSettings",
    "timeOff",
    "polices",
    "workSchedules",
    "jobDescription",
    "recruitment",
    "requests",
    "socket",
    "public",
  ],
};

const reducers = combineReducers({
  authUser: authUserReducer,
  ui: uiReducers,
  general: generalReducers,
  parameterization: parameterizationReducers,
  profile: profileReducers,
  tenantSettings: tenantSettingsReducers,
  timeOff: timeOffReducers,
  polices: policesReducers,
  workSchedules: workSchedulesReducers,
  jobDescription: jobDescriptionReducers,
  recruitment: recruitmentReducers,
  requests: requestsReducers,
  socket: socketReducers,
  public: publicReducers,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.env === "development" ? true : false,
});

export const RootState = store.getState();
export const AppDispatch = store.dispatch;

export default store;
