import { useTranslation } from "react-i18next";
import { ProfileTabs } from "components/ProfileTabs";
import {
  Box,
  Grid,
  TableCell,
  Typography,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { ModalAsset } from "./ModalAsset";
import { TitleBox, UIButton, BasicTable } from "components";
import { profileSelect } from "@redux/slices/profile";
import { useSelector } from "react-redux";
import { useAccess } from "hooks";
import { isFormerEmployee, pathEmployee } from "helpers";

export const Assets = ({ application = "organization", user = null }) => {
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);

  const [rows, setRows] = useState([
    {
      name: "Iphone",
      usageType: "continuo",
      brand: "Apple",
      impact: "low",
    },
  ]);
  const [open, setOpen] = useState(false);

  const { HasPermissions } =  useAccess();

  const { canChange, canAdd } = 
    isFormerEmployee(HasPermissions(
      pathEmployee(application, "/profile/assets"),
        application,
      ),
      employeeSelected
    );

  const handleClick = (row) => {
    if(canChange || canAdd)
    {
      setOpen(true);
    }
  };


  return (
    <>
      <ProfileTabs tab_initial="assets" />
      <br />
      <Paper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
            isRed={!!employeeSelected?.formerEmployee}
          />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item={true} xs={12} md={9}>
              <Typography variant="h5" mb={2}>
                {t("employee:Activosasignados")}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} md={3}>
              <UIButton 
                sx={{ marginRight: "8px", width: "100%" }}
                disabled={!canAdd}  
              >
                {t("employee:Asignaractivo")}
              </UIButton>
            </Grid>
          </Grid>
          <br />
          <BasicTable rows={rows} disabled={!canChange} handleClick={handleClick}>
            <TableCell>{t("employee:Nombredelactivo")}</TableCell>
            <TableCell>{t("employee:Usodelactivo")}</TableCell>
            <TableCell>{t("employee:Marca")}</TableCell>
            <TableCell>{t("employee:Impactodelactivo")}</TableCell>
          </BasicTable>
        </Box>
      </Paper>
      <ModalAsset
        open={open}
        setOpen={setOpen}
        title={t("employee:Detalledelactivo")}
      />
    </>
  );
};
