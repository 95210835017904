import { Box, Grid, Typography, Divider, DialogContent, Stack, CircularProgress }  from "@mui/material";
import { Toast, UIButton, UIBootstrapDialog } from "components";
import { useTranslation } from "react-i18next";
import { putUserPermissions } from "api";
import { managerErrors } from "utils/functions";
import { Form, Formik } from "formik";
import { CheckBoxMenuOrganization } from "../components/CheckBoxMenuOrganization";
import { CheckBoxMenuEmployees } from "../components/CheckBoxMenuEmployees";
import {
  generateInitialValues,
  replaceCodenames,
  transformObjectForPermission,
} from "helpers";
import { OrganizationNavigation } from "../CreateGroups/OrganizationNavigation";
import { EmployeeNavigation } from "../CreateGroups/EmployeeNavigation";

export const AssignPermission = ({
  permisos,
  initialsValues,
  setInitialsValues,
  userPermissions,
  setUserPermissions,
  employeeSelected,
  openAsignarPermiso,
  setOpenAsignarPermiso,
}) => {
  const { t } = useTranslation(["settings"]["general"]);

  const initial = {
    permits: {
      ...generateInitialValues(EmployeeNavigation),
      ...generateInitialValues(OrganizationNavigation),
    },
  };

  const handleSubmit = async (values, { resetForm }) => {
    const data = transformObjectForPermission(values);
    delete data?.name;
    delete data?.description;
    delete data?.default;
    const payload = await replaceCodenames(data, permisos);

    try {
      await putUserPermissions(payload, userPermissions?._id);
      resetForm();
      setUserPermissions(null);
      setInitialsValues(initial);
      setOpenAsignarPermiso(false);

      Toast.fire({
        icon: "success",
        title: t("settings:PermissionsOK"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <UIBootstrapDialog open={openAsignarPermiso} setOpen={setOpenAsignarPermiso} width="70%" title={t("settings:AsignarPermiso")}>
      {employeeSelected !== null ?
        (
          <DialogContent>
            <Box flex={1}>
              <Typography variant="subtitle1">
                {`${employeeSelected?.fullName} - ${employeeSelected?.position}`}
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Formik
                initialValues={initialsValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form id="assign-permission-form" autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                          {t("settings:NavegaciónOrganization")}
                        </Typography>
                        <Box p={2} borderTop={1} borderColor="divider">
                          <CheckBoxMenuOrganization formik={formik} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                          {t("settings:NavegaciónEmployees")}
                        </Typography>
                        <Box p={2} borderTop={1} borderColor="divider">
                          <CheckBoxMenuEmployees formik={formik} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>

            <Box
              mt={2}
              display="flex"
            >
              <Box display="flex" gap="1em">
                <UIButton
                  type="submit"
                  label={t("general:Guardar")}
                  form="assign-permission-form"
                />
                <UIButton
                  label={t("general:Cancel")}
                  onClick={() => {
                    setOpenAsignarPermiso(false);
                    setInitialsValues(initial);
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
        )
        :
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ height: '70vh' }}
        >
          <CircularProgress />
        </Stack>
      }
    </UIBootstrapDialog>
  );
};