import {
  Box,
  Grid,
  Stack,
  Typography,
  Avatar,
  ListItem,
  Divider,
  useMediaQuery,
  useTheme,
  Chip,
  IconButton
} from "@mui/material";
import { useSelector } from "react-redux";
import { profileSelect } from "@redux/slices/profile";
import { useTranslation } from "react-i18next";
import { generalSelect } from "@redux/slices/general";
import { getLabel } from "utils";
import { UIButton } from "components";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { parameterizationSelect } from "@redux/slices/parameterization";

import avatar from "assets/illustrations/avatar.jpeg";

export const PresentationLetter = () => {
  const { t } = useTranslation(["employee"]["options"]);

  const { countries } = useSelector(generalSelect);
  const { employeeSelected } = useSelector(profileSelect);
  const { mainColor } = useSelector(parameterizationSelect);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            borderRadius: '16px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            overflow: 'hidden',
            width: isSmallScreen ? '90vw' : '60vw',
            height: 'auto',
            minHeight: "300px",
            margin: '0 auto',
          }}
        >
          <Box
            sx={{
              backgroundColor: `${mainColor}`,
              width: isSmallScreen ? '100%' : '30%',
              height: isSmallScreen ? '150px' : 'auto',
              position: 'relative',
              clipPath: isSmallScreen ? 'ellipse(150% 100% at 50% 0%)' : 'ellipse(90% 70% at 10% 50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: isSmallScreen && 'row-reverse'
            }}
          >
            <Stack style={{
              width: isSmallScreen && '67%',
              alignItems: isSmallScreen && 'flex-start'
            }}>
              <Avatar
                alt={employeeSelected?.name}
                src={employeeSelected?.thumbnail ?? avatar}
                sx={{ width: isSmallScreen ? '124px' : '150px', height: isSmallScreen ? '124px' : '150px', border: '4px solid white' }}
              />
            </Stack>
            {isSmallScreen && (
                <Stack style={{
                  alignItems: 'center',
                  width: '33%'
                }}>
                  <IconButton onClick={() => window.history.back()}>
                    <ArrowBackIcon style={{ fontSize: '1.5em' }}/>
                  </IconButton>
                </Stack>
              )}
          </Box>
          <Box sx={{ padding: '20px', width: isSmallScreen ? '100%' : '70%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <Stack>
                <Typography variant="h5" fontWeight="bold">
                  {employeeSelected?.fullName}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {employeeSelected?.email}
                </Typography>
              </Stack>
              {!isSmallScreen && (
                <Stack>
                  <IconButton onClick={() => window.history.back()}>
                    <ArrowBackIcon />
                  </IconButton>
                </Stack>
              )}
            </Box>

            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
              <Grid item={true} xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  {t("employee:CurrentPosition")}
                </Typography>
                <Typography variant="body1">
                  {employeeSelected?.position}
                </Typography>
              </Grid>

              <Grid item={true} xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  {t("employee:department")}
                </Typography>
                <Typography variant="body1">
                  {employeeSelected?.department}
                </Typography>
              </Grid>

              <Grid item={true} xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  {t("employee:Lugardetrabajo")}
                </Typography>
                <Typography variant="body1">
                  {employeeSelected?.workLocation}
                </Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <Divider />
              </Grid>

              {employeeSelected?.cellphoneNumber && (
                <Grid item={true} xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("employee:cellphoneNumber")}
                  </Typography>
                  <Typography variant="body1">
                    {employeeSelected?.cellphoneNumber}
                  </Typography>
                </Grid>
              )}

              {employeeSelected?.telephoneNumber && (
                <Grid item={true} xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("employee:telephoneNumber")}
                  </Typography>
                  <Typography variant="body1">
                    {employeeSelected?.telephoneNumber}
                  </Typography>
                </Grid>
              )}

              {employeeSelected?.extensionNumber && (
                <Grid item={true} xs={12} md={4}>
                  <Typography variant="body2" color="text.secondary">
                    {t("employee:extensionNumber")}
                  </Typography>
                  <Typography variant="body1">
                    {employeeSelected?.extensionNumber}
                  </Typography>
                </Grid>
              )}

              {(employeeSelected?.extensionNumber || employeeSelected?.telephoneNumber || employeeSelected?.cellphoneNumber) && (
                <Grid item={true} xs={12}>
                  <Divider />
                </Grid>
              )}

              <Grid item={true} xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  {t("employee:InicioRelaciónLaboral")}
                </Typography>
                <Typography variant="body1">
                  {employeeSelected?.dateJoiningCompany}
                </Typography>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  {t("employee:FechaDeNacimiento")}
                </Typography>
                <Typography variant="body1">
                  {employeeSelected?.dateOfBirth.split("T")[0]}
                </Typography>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <Typography variant="body2" color="text.secondary">
                  {t("employee:PaísDeOrigen")}
                </Typography>
                <Typography variant="body1">
                  {getLabel(countries, employeeSelected?.nationality)}
                </Typography>
              </Grid>

              {employeeSelected?.hobbies.length > 0 && (
                <>
                <Grid item={true} xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {t("employee:Pasatiempos")}
                  </Typography>
                  {employeeSelected?.hobbies?.map((option, index) => (
                    <Chip
                      sx={{ margin: '5px' }}
                      key={index}
                      label={option}
                    />
                  ))}
                </Grid>
                </>
              )}

              {employeeSelected?.languages.length > 0 && (
                <>
                <Grid item={true} xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {t("employee:Language")}
                  </Typography>
                  {employeeSelected?.languages?.map((option, index) => (
                    <Chip
                      sx={{ margin: '5px' }}
                      key={index}
                      label={option}
                    />
                  ))}
                </Grid>
                </>
              )}

              {employeeSelected?.skills.length > 0 && (
                <>
                <Grid item={true} xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" color="text.secondary">
                    {t("employee:Skill")}
                  </Typography>
                  {employeeSelected?.skills?.map((option, index) => (
                    <Chip
                      sx={{ margin: '5px' }}
                      key={index}
                      label={option}
                    />
                  ))}
                </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Grid>
    </>
  );
};
