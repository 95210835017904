import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { UIRichText, UISelect, UISwitch, UITextField } from "components";
import { useTranslation } from "react-i18next";

export const FormDepartment = ({ formik, parents }) => {
  const { t } = useTranslation(["organization"]["general"]);
  const parentDisabled = formik.values?.parent === undefined;
  const onlyParents = parents.filter((p) => p.value !== formik.values._id);

  return (
    <Grid mt={2} container spacing={2}>
      <Grid item="true" xs={12} md={3}>
        <UITextField
          formik={formik}
          label={t("organization:name")}
          name={"name"}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item="true" xs={12} md={5}>
        <UISelect
          formik={formik}
          label={t("organization:parent")}
          name={"parent"}
          options={onlyParents}
          disabled={formik.isSubmitting || parentDisabled}
        />
      </Grid>
      <Grid item="true" xs={12} md={3}>
        <UISwitch
          formik={formik}
          label={t("organization:isInternal")}
          name={"isInternal"}
          disabled={formik.isSubmitting || parentDisabled}
        />
      </Grid>
      <Grid item="true" xs={12} md={12}>
        <UIRichText
          formik={formik}
          label={t("organization:description")}
          name={"description"}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
