import { Menu, MenuItem, Tab, Tabs } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PROFILE_MODULE } from "routes";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useAccess } from "hooks/useAccess";
import { pathEmployee } from "helpers";

export const ProfileMenu = ({ anchor, open, close, menu }) => {
  const navigate = useNavigate(["employee"]);
  const { access } = useAccess();

  return (
    <Menu
      id={`${menu.name}Menu`}
      anchorEl={anchor}
      open={open}
      onClose={close}
      MenuListProps={{
        onMouseLeave: close,
      }}
    >
      {menu.sections.map((option, index) => {
        if (access(option?.route)) {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                navigate(option.route);
                close();
              }}
            >
              {t(`employee:${option.name}`)}
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
};

export const ProfileTabs = ({ tab_initial, application = "organization" }) => {
  const { t } = useTranslation(["employee"]);

  const { access } = useAccess();

  const [tab, setTab] = React.useState(tab_initial);
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };
  const navigate = useNavigate();

  const [anchorCollaborator, setAnchorCollaborator] = React.useState(null);

  const handleCollaboratorOpen = (event) => {
    if (anchorCollaborator !== event.currentTarget) {
      setAnchorCollaborator(event.currentTarget);
    }
  };

  const handleCollaboratorClose = () => {
    setAnchorCollaborator(null);
  };

  const openCollaborator = Boolean(anchorCollaborator);

  const [anchorCompetencies, setAnchorCompetencies] = React.useState(null);

  const handleCompetenciesOpen = (event) => {
    if (anchorCompetencies !== event.currentTarget) {
      setAnchorCompetencies(event.currentTarget);
    }
  };

  const handleCompetenciesClose = () => {
    setAnchorCompetencies(null);
  };

  const openCompetencies = Boolean(anchorCompetencies);

  const [anchorEmploymentInformation, setAnchorEmploymentInformation] = React
    .useState(null);

  const handleEmploymentInformationOpen = (event) => {
    if (anchorEmploymentInformation !== event.currentTarget) {
      setAnchorEmploymentInformation(event.currentTarget);
    }
  };

  const handleEmploymentInformationClose = () => {
    setAnchorEmploymentInformation(null);
  };

  const openEmploymentInformation = Boolean(anchorEmploymentInformation);

  return (
    <>
      <Tabs
        id="cereza-profile-tabs"
        value={tab}
        variant="scrollable"
        onChange={handleChange}
        scrollButtons
        allowScrollButtonsMobile
      >
        {access(
          pathEmployee(
            application,
            "/profile/collaborator/personal-information",
          ),
          pathEmployee(application, "/profile/collaborator/contact-details"),
          pathEmployee(
            application,
            "/profile/collaborator/identities-documents",
          ),
        ) && (
          <Tab
            label={t("employee:Colaborador")}
            value="collaborator"
            aria-owns={openCollaborator ? "CollaboratorMenu" : undefined}
            aria-haspopup="true"
            onClick={handleCollaboratorOpen}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(
          pathEmployee(application, "/profile/competencies/academy-history"),
          pathEmployee(application, "/profile/competencies/skills-summary"),
          pathEmployee(
            application,
            "/profile/competencies/skills-summary-languages",
          ),
        ) && (
          <Tab
            label={t("employee:Competencias")}
            value="competencies"
            aria-owns={openCompetencies ? "CompetenciesMenu" : undefined}
            aria-haspopup="true"
            onClick={handleCompetenciesOpen}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(
          pathEmployee(
            application,
            "/profile/employment-information/current-employment",
          ),
          pathEmployee(
            application,
            "/profile/employment-information/employment-summary",
          ),
        ) && (
          <Tab
            label={t("employee:EmploymentInformation")}
            value="employment-information"
            aria-owns={openEmploymentInformation
              ? "EmploymentInformationMenu"
              : undefined}
            aria-haspopup="true"
            onClick={handleEmploymentInformationOpen}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(pathEmployee(application, "/profile/compensation")) && (
          <Tab
            label={t("employee:Compensación")}
            value="compensation"
            onClick={() => {
              navigate(PROFILE_MODULE.Compensation.route);
            }}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(pathEmployee(application, "/profile/time-clocks")) && (
          <Tab
            label={t("employee:TimeClock")}
            value="time-clock"
            onClick={() => {
              navigate(PROFILE_MODULE.TimeClock.route);
            }}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(pathEmployee(application, "/profile/documents")) && (
          <Tab
            label={t("employee:Documentos")}
            value="documents"
            onClick={() => {
              navigate(PROFILE_MODULE.Documents.route);
            }}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(pathEmployee(application, "/profile/assets")) && (
          <Tab
            label={t("employee:Activos")}
            value="assets"
            onClick={() => {
              navigate(PROFILE_MODULE.Assets.route);
            }}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
        {access(pathEmployee(application, "/profile/record-history")) && (
          <Tab
            label={t("employee:Historialderegistros")}
            value="record history"
            onClick={() => {
              navigate(PROFILE_MODULE.RecordHistory.route);
            }}
            sx={{
              flexGrow: {
                md: 1,
              },
            }}
          />
        )}
      </Tabs>

      <ProfileMenu
        anchor={anchorCollaborator}
        open={openCollaborator}
        close={handleCollaboratorClose}
        menu={PROFILE_MODULE.Collaborator}
      />

      <ProfileMenu
        anchor={anchorCompetencies}
        open={openCompetencies}
        close={handleCompetenciesClose}
        menu={PROFILE_MODULE.Competencies}
      />

      <ProfileMenu
        anchor={anchorEmploymentInformation}
        open={openEmploymentInformation}
        close={handleEmploymentInformationClose}
        menu={PROFILE_MODULE.EmploymentInformation}
      />
    </>
  );
};
