import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";

export const Filtro = ({
  typesOfRequests = {},
  setTypesOfRequests,
  requests,
  children,
}) => {
  return (
    <Paper
      sx={{
        height: "100%",
      }}
    >
      <Box
        p={2}
        sx={{
          overflow: "hidden",
        }}
      >
        {children}
        <Box mt={2}>
          <FormGroup mt={2}>
            {Object.keys(typesOfRequests).map((key) => {
              const typeOfRequest = typesOfRequests[key];
              const total = _.filter(requests.all, function (i) {
                return i.type === key;
              }).length;

              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={typeOfRequest.checked}
                      onChange={(e) => {
                        let newTypesOfRequests = _.cloneDeep(typesOfRequests);
                        newTypesOfRequests[key].checked = e.target.checked;
                        setTypesOfRequests(newTypesOfRequests);
                      }}
                    />
                  }
                  label={
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{typeOfRequest.label}</Typography>
                      <Typography color="primary">{total}</Typography>
                    </Stack>
                  }
                />
              );
            })}
          </FormGroup>
        </Box>
      </Box>
    </Paper>
  );
};
