import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const GridButtons = ({ options }) => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center" gap={1}>
      {options.map((option, index) => (option.hasAccess && (
        <Grid
          key={index}
          item={true}
          md={2}
          xs={12}
          component={Button}
          height={100}
          variant="contained"
          onClick={() => {
            if (option?.action) {
              option.action();
            } else {
              navigate(option.navigate);
            }
          }}
        >
          {option.label}
        </Grid>
      )))}
    </Grid>
  );
};
