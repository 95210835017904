import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { UISelect, UITextField } from "components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { generalSelect } from "@redux/slices/general";

export const FormAcademy = ({ formik }) => {
  const { t } = useTranslation(["employee"]["general"]);

  const { countries } = useSelector(generalSelect);

  return (
    <Grid container spacing={2}>
      <Grid item={"true"} xs={12} md={6}>
        <UITextField
          formik={formik}
          name={"institution"}
          label={t("employee:NameInstitution")}
          disabled={formik.isSubmitting}
        />
      </Grid>
      <Grid item={"true"} xs={12} md={6}>
        <UISelect
          formik={formik}
          label={t("employee:PaísDeOrigen")}
          name={"location"}
          options={countries}
          isrequired={true}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
