import { getDepartments } from "api";
import { useDispatch } from "react-redux";
import { generalSelect, setDepartments } from "../redux/slices/general";
import { useSelector } from "react-redux";

export function useDepartments() {
  const dispatcher = useDispatch();
  const { departments } = useSelector(generalSelect);

  const refreshDepartmentSeleectors = async (refresh = false) => {
    if (
      departments?.length === 0 ||
      !departments ||
      departments === undefined ||
      refresh
    ) {
      try {
        const { data } = await getDepartments("selectors");
        dispatcher(setDepartments(data));
      } catch (err) {
        console.error("Error getting departments:", err);
      }
    }
  };

  return { refreshDepartmentSeleectors };
}
