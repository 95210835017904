import { UIPaper } from "components";
import { useEffect } from "react";
import { useAccess } from "hooks";
import { useNavigate } from "react-router-dom";

export const RedirectAccessOrganization = () => {
  const navigate = useNavigate();
  const { access } = useAccess();

  useEffect(() => {
    if (access("/cereza-organization/organization/organization-settings")) {
      navigate("/cereza-organization/organization/organization-settings");
    } else if (
      access(
        "/cereza-organization/organization/organization-settings/ubications-org"
      )
    ) {
      navigate(
        "/cereza-organization/organization/organization-settings/ubications-org"
      );
    } else if (
      access("/cereza-organization/organization/organization-settings/catalogs")
    ) {
      navigate(
        "/cereza-organization/organization/organization-settings/catalogs"
      );
    } else if (
      access("/cereza-organization/organization/organization-settings/plan")
    ) {
      navigate("/cereza-organization/organization/organization-settings/plan");
    }
  }, []);

  return <UIPaper></UIPaper>;
};
