import { CircularProgress, IconButton } from "@mui/material";
import { CloudDownload as CloudDownloadIcon } from "@mui/icons-material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx-js-style";
import { useState } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

export const ExportTrackingData = ({ allData, filterData }) => {
  const { t } = useTranslation(["shiftAttendance"]);

  const [generateExcel, setGenerateExcel] = useState(false);

  const exportToExcel = () => {
    setGenerateExcel(true);

    const ids = _.map(filterData, "id");
    const filter = _.filter(allData.data, (i) => _.includes(ids, i._id));
    const dataToExcel = _.map(
      filter,
      ({ employee, geoLocation, registerAt, schedule, timeClock, type }) => {
        const row = {
          CTemployeeNumber: employee.number,
          CTemployee: employee.fullName,
          CTemail: employee.email,
          CTdepartment: employee.department,
          CTposition: employee.position,
          CTsupervisor: employee.supervisor,
          CTactive:
            employee.isActive === true ? t("employee:Si") : t("employee:No"),
          CTschedule: schedule.name,
          CTtimeClock: timeClock.name,
          CTtype: t(`shiftAttendance:${type}`),
          CTregisterAt: registerAt,
          CTgeoLocation:
            geoLocation && geoLocation.length ? geoLocation.join(",") : "",
        };

        return row;
      },
    );
    const arrayOfArray = _.map(dataToExcel, (e) => _.values(e));
    let wscols = [];
    if (arrayOfArray.length) {
      wscols = arrayOfArray[0].map((a, i) => ({
        wch: Math.max(
          ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0)),
        ),
      }));
    }
    let head = dataToExcel.length ? Object.keys(dataToExcel[0]) : [];
    head = _.map(head, (h) => t(`shiftAttendance:${h}`));
    arrayOfArray.unshift(head);

    const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArray);

    worksheet["!cols"] = wscols;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      t("shiftAttendance:Tracking"),
    );
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.ms-excel",
    });
    saveAs(
      blob,
      `${t("shiftAttendance:Tracking")} - ${dayjs().format("LLLL")}.xlsx`,
    );
    setGenerateExcel(false);
  };

  return generateExcel ? (
    <IconButton size="small">
      <CircularProgress />
    </IconButton>
  ) : (
    <IconButton size="large" onClick={exportToExcel}>
      <CloudDownloadIcon />
    </IconButton>
  );
};
