import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { socketSelect, setNotifications } from "../redux/slices/socket";
import { getNotifications, getConnectionStr } from "api";
import { WebPubSubClient } from "@azure/web-pubsub-client";

let socket;

export function useSocket() {
  const dispatcher = useDispatch();

  const { user } = useSelector(authUserSelect);
  const { notifications } = useSelector(socketSelect);

  const [isConnected, setIsConnected] = useState(false);

  const fetchConnString = async () => {
    try {
      const { data } = await getConnectionStr(user._id);
      return data?.url;
    } catch (err) {
      console.error("Error getting connection string: ", err);
      return "";
    }
  };

  const fetchNotifications = async () => {
    if (user?._id) {
      try {
        const { data } = await getNotifications(user._id);
        dispatcher(setNotifications(data));
      } catch (err) {
        console.error("Error getting notifications: ", err);
      }
    }
  };

  const startConnection = useCallback(async () => {
    if (isConnected || !user._id) return;

    const connString = await fetchConnString();
    if (connString) {
      socket = new WebPubSubClient(connString);
      socket.on("server-message", (e) => {
        console.log("message", e.message);
        dispatcher(setNotifications([e.message.data, ...notifications]));
      });

      socket.on("group-message", (e) => {
        console.log(
          `Received message from ${e.message.group}: ${e.message.data}`
        );
      });

      try {
        await socket.start();
        setIsConnected(true);
      } catch (err) {
        console.error("Error starting socket connection: ", err);
      }
    }
  }, [isConnected, user._id]);

  useEffect(() => {
    if (!socket) {
      fetchNotifications();
      startConnection();
    }
  }, [startConnection]);

  return { notifications, startConnection, isConnected, fetchNotifications };
}
