import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GroupsIcon from "@mui/icons-material/Groups";
import { useTranslation } from "react-i18next";
import { getEmployeesOfGroup, selectingEmployeeList } from "api";
import { useEffect, useState } from "react";
import { useAccess } from "hooks";
import { MembersGroup } from "../MembersGroup";
import { EditGroup } from "../CreateGroups/EditGroup";

export const GroupsActions = ({
  permisos,

  groups,

  setExecuteOnce,
  registerFunction,
  
  row,
}) => {
  const { t } = useTranslation(["settings"]["general"]);
  const { access } = useAccess();

  const [valueForm, setValueForm] = useState("one");
  const [miembrosGrupo, setMiembrosGrupo] = useState(null);
  const [openMiembrosGrupo, setOpenMiembrosGrupo] = useState(false);
  const [groupSelected, setGroupSelected] = useState(null);
  const [openEditGroup, setOpenEditGroup] = useState(false);

  // Registrar la referencia de functionX en el padre
  useEffect(() => {
    if(registerFunction != null )
    {
      registerFunction(actionEdit);
    }
  }, []);

  const actionEdit = (row = null) => {
    const data = groups?.find((group) => group?._id === row?.id);
    if (data?.name.includes("Organization")) {
      setValueForm("one");
    }
    if (data?.name.includes("Employees")) {
      setValueForm("two");
    }
    setGroupSelected(data || null);
    setOpenEditGroup(true);
  };

  const actionMembers = async (row) => {
    try {
      setGroupSelected(row);
      setOpenMiembrosGrupo(true);
      const { data: members } = await getEmployeesOfGroup(row?.id);
      const { data: employees } = await selectingEmployeeList();

      const data = [];

      for (const item of employees) {
        if (members.includes(item.userId)) {
          data.push({
            id: item.userId,
            fullName: item.fullName,
            department: item.department === "" ? "NA" : item.department,
            position: item.position,
          });
        }
      }

      setMiembrosGrupo(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        {access(
          "/cereza-organization/settings/security-user-permissions-members"
        ) && (
          <Tooltip title={t("settings:Miembros")}>
            <IconButton onClick={() => actionMembers(row)}>
              <GroupsIcon />
            </IconButton>
          </Tooltip>
        )}
        {access("/cereza-organization/settings/security-groups") && (
          // - aqui es donde se tiene que abrir el modal
          <Tooltip  title={t("settings:Editar")}>
              <IconButton  onClick={() => actionEdit(row)}>
                <EditIcon />
              </IconButton>
          </Tooltip>
        )}
      </Stack>

      {access(
        "/cereza-organization/settings/security-user-permissions-members"
      ) && (
        <MembersGroup
          groupSelected={groupSelected}
          miembrosGrupo={miembrosGrupo}
          setOpenMiembrosGrupo={setOpenMiembrosGrupo}
          openMiembrosGrupo={openMiembrosGrupo}
        />
      )}

      {access("/cereza-organization/settings/security-groups") && (
        <EditGroup
        permisos={permisos}
        
        setExecuteOnce={setExecuteOnce}

        openEditGroup={openEditGroup}
        setOpenEditGroup={setOpenEditGroup}

        valueForm={valueForm}
        setValueForm={setValueForm}
      
        groupSelected={groupSelected}
        setGroupSelected={setGroupSelected}
        />
      )}
    </>
  );
};