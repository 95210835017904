import { Typography } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const ItemPlan = ({ include, text, value }) => {
  return (
    <>
      <br />
      <Typography
        variant="h6"
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {include ? (
          <TaskAltIcon
            sx={{
              color: "#238636",
            }}
          />
        ) : (
          <HighlightOffIcon
            sx={{
              color: "#E00000",
            }}
          />
        )}
        {text}
        {<div>{value}</div>}
      </Typography>
    </>
  );
};
