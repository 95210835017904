import { Paper, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ItemPlan, UIButton } from "../../../components";
import { useNavigate } from "react-router-dom";

export const Plan = ({ plan, label }) => {
  const { t } = useTranslation(["organization"]["general"]);
  const navigate = useNavigate();

  return (
    <>
      <Paper>
        <Box p={4}>
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="button"
              sx={{
                fontSize: "3rem",
              }}
            >
              {plan?.name}
            </Typography>
          </Box>
          <hr />
          <Box
            sx={{
              width: "fit-content",
              margin: "0 auto",
            }}
          >
            <ItemPlan
              include={true}
              text={t("organization:gbSpace")}
              value={plan?.gbSpace}
            />
            <ItemPlan
              include={true}
              text={t("organization:monthlyPrice")}
              value={plan?.monthlyPrice}
            />
            <ItemPlan
              include={true}
              text={t("organization:annualPrice")}
              value={plan?.annualPrice}
            />
            <ItemPlan
              include={true}
              text={t("organization:employees")}
              value={plan?.employees}
            />
            <ItemPlan
              include={plan?.monthlyEmails === 0 ? false : true}
              text={t("organization:monthlyEmails")}
              value={plan?.monthlyEmails}
            />
            <ItemPlan
              include={plan?.monthlySMS === 0 ? false : true}
              text={t("organization:monthlySMS")}
              value={plan?.monthlySMS}
            />
            <ItemPlan
              include={plan?.smsPrice !== 0 ? false : true}
              text={t("organization:smsPrice")}
              value={plan?.smsPrice}
            />
            <ItemPlan
              include={plan?.gcpS3Price !== 0 ? false : true}
              text={t("organization:gcpS3Price")}
              value={plan?.gcpS3Price}
            />
            <ItemPlan
              include={true}
              text={t("organization:numberManagers")}
              value={plan?.numberManagers}
            />
            <ItemPlan
              include={plan?.emailSupport}
              text={t("organization:emailSupport")}
              value={plan?.emailSupport ? t("general:Yes") : t("general:No")}
            />
            <ItemPlan
              include={plan?.chatSupport}
              text={t("organization:chatSupport")}
              value={plan?.chatSupport ? t("general:Yes") : t("general:No")}
            />
            <ItemPlan
              include={plan?.phoneSupport}
              text={t("organization:phoneSupport")}
              value={plan?.phoneSupport ? t("general:Yes") : t("general:No")}
            />
            <ItemPlan
              include={plan?.smtpSupport}
              text={t("organization:smtpSupport")}
              value={plan?.smtpSupport ? t("general:Yes") : t("general:No")}
            />
            <ItemPlan
              include={plan?.activeVacancies === 0 ? false : true}
              text={t("organization:activeVacancies")}
              value={plan?.activeVacancies}
            />
            <ItemPlan
              include={plan?.interviewQuestionnaires === 0 ? false : true}
              text={t("organization:interviewQuestionnaires")}
              value={plan?.interviewQuestionnaires}
            />
            <ItemPlan
              include={plan?.interviewEvaluations === 0 ? false : true}
              text={t("organization:interviewEvaluations")}
              value={plan?.interviewEvaluations}
            />
            <br />
            <UIButton
              label={label}
              onClick={() => navigate("/cereza-organization/Planes")}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};
