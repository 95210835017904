import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const FormAttendance = ({ formik }) => {
  const { t } = useTranslation(["general"]["shiftAttendance"]);
  const [notAttendOnAWorkday, setNotAttendOnAWorkdat] = useState(
    formik.values.attendance.notAttendOnAWorkday,
  );
  const [attendOnAWorkday, setAttendOnAWorkday] = useState(
    formik.values.attendance.attendOnAWorkday,
  );

  return (
    <Stack spacing={2}>
      <FormControl>
        <FormLabel id="not-attend-on-a-workday-dadio-group">
          {t("shiftAttendance:notAttendOnAWorkday")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="not-attend-on-a-workday-dadio-group"
          name="attendance.notAttendOnAWorkday"
          value={notAttendOnAWorkday}
          onChange={(event) => {
            setNotAttendOnAWorkdat(event.target.value);
            formik.setFieldValue(
              "attendance.notAttendOnAWorkday",
              event.target.value,
            );
          }}
        >
          <FormControlLabel
            value="MARK_AS_ABSENT"
            control={<Radio />}
            label={t("shiftAttendance:MarkAsAbsent")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="TIME_AGAINST"
            control={<Radio />}
            label={t("shiftAttendance:TimeAgainst")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="AUTO_RECORD_ATTENDANCE"
            control={<Radio />}
            label={t("shiftAttendance:AutoRecordAttendance")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel id="attend-on-a-workday">
          {t("shiftAttendance:attendOnAWorkday")}
        </FormLabel>
        <RadioGroup
          aria-labelledby="attend-on-a-workday"
          name="attendance.attendOnAWorkday"
          value={attendOnAWorkday}
          onChange={(event) => {
            setAttendOnAWorkday(event.target.value);
            formik.setFieldValue(
              "attendance.attendOnAWorkday",
              event.target.value,
            );
          }}
        >
          <FormControlLabel
            value="TIME_IN_FAVOR"
            control={<Radio />}
            label={t("shiftAttendance:TimeInFavor")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="OVERTIME"
            control={<Radio />}
            label={t("shiftAttendance:Overtime")}
            disabled={formik.isSubmitting}
          />
          <FormControlLabel
            value="DO_NOTHING"
            control={<Radio />}
            label={t("shiftAttendance:DoNothing")}
            disabled={formik.isSubmitting}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};
