import { useEffect, useState } from "react";
import { Avatar, Box, Paper, Stack, Typography, MobileStepper, Button } from "@mui/material";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { setEmployeeSelected } from "@redux/slices/profile";
import { getCoworker } from "api";

import avatar from "assets/illustrations/avatar.jpeg";
import { useNavigate } from "react-router-dom";

function obtenerElementosAleatorios(array) {
  array.sort(() => Math.random() - 0.5);
  return array.slice(0, 10);
}

export const Coworkers = ({ app = "/cereza-organization/" }) => {
  const { t } = useTranslation(["home"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(authUserSelect);
  const { mode, mainColor } = useSelector(parameterizationSelect);

  const [coworkers, setCoworkers] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = coworkers.length > 0 ? coworkers.length : 1;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  useEffect(() => {
    const lista = async () => {
      try {
        let { data } = await getCoworker();
        data = Object.keys(data).map((key) => data[key]);

        let colegas = data.filter((obj) => obj.email !== user.email);

        colegas = obtenerElementosAleatorios(colegas);
        setCoworkers(colegas);
      } catch (error) {
        console.error(error);
      }
    };

    lista();
  }, []);

  useEffect(() => {
    const timeout =  setTimeout(() => {
      handleNext();
    }, 3000);
    return () => clearTimeout(timeout);
  }, [activeStep, maxSteps])

  const LetMeSee = async (worker) => {
    dispatch(setEmployeeSelected(worker));
    navigate(`${app}presentation-letter`);
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "350px",
          height: "100% !important",
          display: "flex",
          justifyContent: "center",
          "@media (max-width: 600px)": {
            width: "100%",
          },
        }}
      >
        <Box
          p={2}
          sx={{
            textAlign: "left",
            width: "100% !important"
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            {t("home:ConoceColega")}
          </Typography>
          <br />
            {coworkers.length > 0 && (
              <>
                <Stack
                  marginTop={3}
                  marginBottom={6}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: mode === "light" ? "#fff" : "#252525",
                    cursor: "pointer",
                  }}
                  onClick={() => LetMeSee(coworkers[activeStep])}
                >
                  <Avatar
                    alt="avatar"
                    src={coworkers[activeStep]?.thumbnail ?? avatar}
                    sx={{ width: 124, height: 124, border: `2px solid ${mainColor}` }}
                  />
                  <br />
                  <Typography variant="h6" gutterBottom>
                    {coworkers[activeStep]?.fullName}
                  </Typography>
                  <Typography variant="body2" display="block" gutterBottom>
                    {coworkers[activeStep]?.email}
                  </Typography>
                </Stack>
                <MobileStepper
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  sx={{
                    backgroundColor: mode === "light" ? "#fff" : "#252525",
                  }}
                  nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                      <KeyboardArrowRight />
                    </Button>
                  }
                  backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                      <KeyboardArrowLeft />
                    </Button>
                  }
                />
              </>
            )}
        </Box>
      </Paper>
    </>
  );
};
