import {
  ChevronLeft as ChevronLeftIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Toast, UIButton } from "components";
import { FormPersonalInformation } from "modules/Employees/Profiles/Collaborator/PersonalInformation/FormPersonalInformation";
import { saveEmployee, updateEmployee } from "api";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const ModalEmployeeWizard = (
  { open, setOpen, values, canAdd, canChange, setUpdateWizard, setUpdateRows },
) => {
  const { t } = useTranslation(["general"]);
  const { tenant } = useSelector(tenantSettingsSelect);

  const handleSubmit = async (values) => {
    try {
      let message = "";

      if (values._id) {
        await updateEmployee(values, values._id);
        message = t("employee:SuccessfullyUpdatedEmployee");
      } else {
        await saveEmployee(values, tenant._id);
        message = t("employee:Empleadocreadoconéxito");
      }

      setOpen(false);

      Toast.fire({
        icon: "success",
        title: message,
      });

      setUpdateWizard(Math.random());
      setUpdateRows(Math.random());
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={() => setOpen(false)}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {t("employee:Employee")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik initialValues={values} onSubmit={handleSubmit}>
          {(formik) => (
            <Form id="d" autoComplete="off">
              <FormPersonalInformation
                formik={formik}
                canAdd={canAdd}
                canChange={canChange}
              />
              <Stack
                mt={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <UIButton
                  variant="contained"
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => setOpen(false)}
                  disabled={formik.isSubmitting}
                  sx={{}}
                />
                {((canAdd && !formik?.values?._id) ||
                  (canChange && formik?.values?._id)) && (
                  <UIButton
                    type="submit"
                    label={formik?.values?._id
                      ? t("general:Actualizar")
                      : t("general:Guardar")}
                    loading={formik.isSubmitting}
                    sx={{}}
                  />
                )}
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </BootstrapDialog>
  );
};
