import { Box, IconButton, Modal, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyle } from "helpers";
import { UIButton } from "components/UIButton";
import { useTranslation } from "react-i18next";
import { FormPersonalInformation } from "./Collaborator/PersonalInformation/FormPersonalInformation";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { saveEmployee } from "api";
import { Toast } from "components";

export const ModalCreateEmployees = ({
  open,
  setOpen,
  setUpdateRows,
  canAdd,
  canChange,
}) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  const { t } = useTranslation(["employee"]);

  const handleSubmit = async (values) => {
    try {
      const updatedValues = { ...values };
      updatedValues.dateOfBirth = dayjs(updatedValues.dateOfBirth).format(
        "YYYY-MM-DD"
      );
      const { data } = await saveEmployee(updatedValues, tenant._id);
      setUpdateRows((prev) => !prev);
      setOpen(false);

      Toast.fire({
        icon: "success",
        title: t("employee:Empleadocreadoconéxito"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message,
      });
      console.error("Error submitting form:", err);
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={{ width: "90%" }} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h5">{t("employee:NuevoEmpleado")}</Typography>
        <br />
        <Formik
          initialValues={{
            names: "",
            surnames: "",
            email: "",
            gender: "",
            dateOfBirth: dayjs(),
            bloodType: "",
            nationality: "",
            maritalStatus: "",
            hobbies: [],
            socialSecurityNumber: "",
            taxIdentificationNumber: "",
            photo: "",
          }}
          //   validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <FormPersonalInformation
                formik={formik}
                canAdd={canAdd}
                canChange={canChange}
              />
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={t("Guardar")}
                    loading={formik.isSubmitting}
                  />
                  <UIButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    label={t("Cancel")}
                    disabled={formik.isSubmitting}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
