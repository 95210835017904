import { useEffect, useState } from "react";
import { Grid, TableCell } from "@mui/material";
import { BasicTable, FilePreview, UISelect } from "components";
import { useDispatch, useSelector } from "react-redux";
import { LEVELS } from "utils";
import { generalSelect, setSkills } from "@redux/slices/general";
import { getReferences, getSkills } from "api";
import { profileSelect } from "@redux/slices/profile";
import { getLabel } from "utils";
import { useTranslation } from "react-i18next";
import { translateSelectors } from "helpers";

export const FormSkillAndCompetencies = ({
  formik,
  newData,
  canAdd,
  canChange,
  user = null,
}) => {
  const { t } = useTranslation(["employee"]);

  const dispatcher = useDispatch();
  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const { skills } = useSelector(generalSelect);

  const [srcAvatar, setSrcAvatar] = useState();
  const [srcPath, setSrcPath] = useState("");
  const [rows, setRows] = useState([]);
  const [responseData, setResponseData] = useState({});
  const disabled = (!canAdd && formik.values?._id === undefined) ||
    (!canChange && formik.values?._id !== undefined) ||
    formik.isSubmitting;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getReferences(employeeSelected._id, "skills");
        const cleanData = data.map((item) => ({
          id: item._id,
          skill: getLabel(skills, item.skill),
          level: item.level,
        }));
        setRows(cleanData);
        setResponseData(data);
      } catch (err) {
        console.error("Error getting skills:", err);
      }
    };

    fetchData();
  }, [employeeSelected, newData]);

  useEffect(() => {
    if (skills?.length === 0 || !skills || skills === undefined) {
      try {
        getSkills("selectors").then((res) => {
          dispatcher(setSkills(res.data));
        });
      } catch (err) {
        console.error("Error getting skills:", err);
      }
    }
  }, []);

  const handleClick = (row) => {
    const skillSelected = { ...responseData.find((obj) => obj._id === row.id) };
    delete skillSelected.createdAt;
    delete skillSelected.updatedAt;
    setSrcPath(skillSelected.filePath);
    delete skillSelected.filePath;
    formik.setValues(skillSelected);
  };

  return (
    <>
      <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
        <Grid item={true} xs={12} md={3}>
          <Grid
            container
            flexDirection={{ xs: "column", md: "column" }}
            spacing={2}
          >
            <Grid item={true}>
              <UISelect
                formik={formik}
                label={t("employee:Skill")}
                name="skill"
                options={translateSelectors(t, skills)}
                disabled={disabled}
              />
            </Grid>
            <Grid item={true}>
              <UISelect
                formik={formik}
                label={t("employee:Levelofcompetency")}
                name="level"
                options={translateSelectors(t, LEVELS)}
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <FilePreview
            formik={formik}
            name={"file"}
            src={srcAvatar}
            setSrc={setSrcAvatar}
            srcPath={srcPath}
            lblBtnUpdate={t("employee:UploadFile")}
            disabled={disabled}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <BasicTable rows={rows} handleClick={handleClick}>
            <TableCell>{t("employee:Habilidad")}</TableCell>
            <TableCell>{t("employee:Niveldecompetencia")}</TableCell>
          </BasicTable>
        </Grid>
      </Grid>
    </>
  );
};
