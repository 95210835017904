import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { Toast, UIButton, UISelectCheckmarks } from "components";
import { Paper, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getCurrencies,
  saveCurrencies,
  updateCurrencies,
  getBaseCurrencies,
} from "api";
import { managerErrors } from "utils";
import { setCurrencies } from "@redux/slices/general";

export const Currencies = () => {
  const dispatcher = useDispatch();

  const [baseCurrencies, setBaseCurrencies] = useState([]);
  const [favoriteCurrencies, setFavoriteCurrencies] = useState({});

  const { t } = useTranslation(["organization"]);

  useEffect(() => {
    const fetchBaseCurrencies = async () => {
      try {
        const { data } = await getBaseCurrencies();
        setBaseCurrencies(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCurrencies = async () => {
      try {
        const { data } = await getCurrencies();
        setFavoriteCurrencies(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBaseCurrencies();
    fetchCurrencies();
  }, []);

  const handleSubmit = async (values, { setValues }) => {
    delete values.createdAt;
    delete values.updatedAt;
    try {
      if (values?._id) {
        const { data } = await updateCurrencies(values, values._id);
        setFavoriteCurrencies(data);
        setValues(data);
      } else {
        const { data } = await saveCurrencies(values);
        setFavoriteCurrencies(data);
        setValues(data);
      }
      const updateData = values?.currencies?.map((id) => {
        const aux = baseCurrencies.find((currency) => currency._id === id);
        return {
          value: aux._id,
          label: aux.name,
          prefix: aux.symbol,
        };
      });
      dispatcher(setCurrencies(updateData));
      Toast.fire({
        icon: "success",
        title: "Favorite currencies updated successfully",
      });
    } catch (error) {
      managerErrors(error?.response?.data?.message);
      console.error(error);
    }
  };

  return (
    <Box p={2}>
      <Typography variant="body2">
        {t("organization:CurrenciesDescription")}
      </Typography>
      <br />
      {Object.keys(favoriteCurrencies).length > 0 && (
        <Formik initialValues={favoriteCurrencies} onSubmit={handleSubmit}>
          {(formik) => (
            <Form id="d" autoComplete="off">
              <UISelectCheckmarks
                options={baseCurrencies}
                formik={formik}
                label={t("organization:Currencies")}
                name={"currencies"}
                disabled={formik.isSubmitting}
                fieldRender={"name"}
              />
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton
                    type="submit"
                    label={
                      formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")
                    }
                    loading={formik.isSubmitting}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
