import PropTypes from "prop-types";
import { Switch, Box, Typography } from "@mui/material";

export const UISwitch = ({
  formik,
  label = "",
  name = "",
  nameFileArray = "",
  index = null,
  isrequired = false,
  disabled = false,
  width = "100%",
  style,
}) => {
  return (
    <Box
      sx={{
        width: width,
        display: "flex",
        flexDirection: "row",
      }}
      style={style}
    >
      <Switch
        checked={
          nameFileArray === ""
            ? formik.values[name]
            : formik.values[nameFileArray][index][name]
        }
        onChange={
          nameFileArray !== ""
            ? () => {
                formik.setFieldValue(
                  `${nameFileArray}.${index}.${name}`,
                  !formik.values[nameFileArray][index][name]
                );
              }
            : () => formik.setFieldValue(name, !formik.values[name])
        }
        name={name}
        required={isrequired}
        disabled={disabled}
      />
      <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
        {label}
      </Typography>
    </Box>
  );
};

UISwitch.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  nameFileArray: PropTypes.string,
  index: PropTypes.number,
  isrequired: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  style: PropTypes.object,
};
