import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export const UITagFast = ({
  field,
  form: { errors, touched, setTouched, setFieldValue },
  ...props
}) => {
  const name = field.name;
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (event, values) => {
    setTouched({ ...touched, [name]: true });
    event.persist();
    setFieldValue(name, values);
    setInputValue("");
  };

  const handleInputChange = (
    event,
    newInputValue,
  ) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Tab": {
        if (inputValue.trim().length > 0) {
          handleChange(event, field.value.concat([inputValue]));
        }
        break;
      }
      default:
    }
  };

  return (
    <Autocomplete
      multiple
      disableClearable={true}
      options={[]}
      freeSolo
      value={field.value}
      inputValue={inputValue}
      onChange={handleChange}
      onBlur={(e) => {
        if (inputValue.trim()) {
          handleChange(e, field.value.concat([inputValue]));
        }

        setInputValue("");
      }}
      onInputChange={handleInputChange}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;

        return (
          <TextField
            name={name}
            error={touched[name] && Boolean(errors.emails)}
            helperText={touched[name] && errors.emails}
            variant="outlined"
            {...params}
            {...props}
          />
        );
      }}
      {...props}
    />
  );
};
