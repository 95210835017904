import { Autocomplete, TextField, Typography } from "@mui/material";
import { ErrorMessage, Field, useField } from "formik";

export const UISelectMultipleFast = (props) => {
  const { name, options, ...rest } = props;
  const [field] = useField(name);

  let option_selected = field.value
    ? field.value.map((v) => props.options.find((o) => o.value === v))
    : [];

  return (
    <Field {...field} {...rest}>
      {({ field, form: { setFieldValue } }) => (
        <>
          <Autocomplete
            fullWidth
            multiple
            value={option_selected}
            getOptionLabel={(o) => o.label}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            onChange={(event, newValue) => {
              return setFieldValue(
                name,
                newValue.map((o) => o.value),
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                required={rest.required ? !field.value.length : false}
              />
            )}
            {...rest}
            options={options}
          />
          <ErrorMessage
            name={field.name}
            render={(msg) => (
              <Typography color="error" variant="caption">
                {msg}
              </Typography>
            )}
          />
        </>
      )}
    </Field>
  );
};
