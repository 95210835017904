import { useTranslation } from "react-i18next";
import {
  UIBootstrapDialog,
} from "components";
import { Box } from "@mui/material";
import { FormTermination } from "./FormTermination";

export const ModalTermination = ({
  open,
  setOpen,
  terminationSelected = null,
  canAdd,
  canChange,
}) => {
  const { t } = useTranslation(["employee"]["general"]);

  return (
    <>
      <UIBootstrapDialog open={open} setOpen={setOpen} title={t("organization:NewTermination")}>
        <Box p={2}>
          <FormTermination 
            canAdd={canAdd}
            canChange={canChange} 
            setOpen={setOpen}
            terminationSelected={terminationSelected}
          />
        </Box>
      </UIBootstrapDialog>
    </>
  );
};
