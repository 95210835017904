import { UIButton, UISelect, UISwitch, UITextField } from "../../../components";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

export const FormHolidays = (
  {
    formik,
    countries,
    getPublicHolidays,
    downloadHolidays,
    setDownloadHolidays,
  },
) => {
  const { t } = useTranslation(["general"]["shiftAttendance"]);

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={6}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={12}>
            <UITextField
              name={"name"}
              isrequired={true}
              formik={formik}
              label={t("shiftAttendance:Name")}
              disabled={formik.isSubmitting}
            />
          </Grid>
          <Grid item={true} xs={12} md={12}>
            <UITextField
              name={"description"}
              isrequired={true}
              formik={formik}
              label={t("shiftAttendance:Description")}
              disabled={formik.isSubmitting}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={8}>
            <UISelect
              name={"country"}
              isrequired={true}
              formik={formik}
              label={t("shiftAttendance:Country")}
              options={countries}
              disabled={formik.isSubmitting}
            />
          </Grid>
          <Grid item={true} xs={4}>
            <UIButton
              disabled={formik.values.country === "" || downloadHolidays}
              label={t("shiftAttendance:Import")}
              loading={downloadHolidays}
              onClick={() => {
                setDownloadHolidays(true);
                getPublicHolidays(formik.values.country, formik);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12} md={3}>
        <UISwitch
          formik={formik}
          label={t("shiftAttendance:ShowInCalendar")}
          name={"isVisible"}
          disabled={formik.isSubmitting}
        />
      </Grid>
    </Grid>
  );
};
