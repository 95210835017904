import Box from "@mui/material/Box";
import {
  Autocomplete,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { Toast } from "..";
import { useTranslation } from "react-i18next";

export const CheckFilter = (
  { index, option, group_key, updateFilters, checked },
) => {
  return (
    <FormControlLabel
      key={index}
      control={
        <Checkbox
          key={index}
          checked={checked}
          onChange={(e) => updateFilters(e.target.checked, option, group_key)}
        />
      }
      label={option}
    />
  );
};

export const GroupsFilter = ({ filters, updateFilters, title }) => {
  const groups_keys = Object.keys(filters.groupsConfiguration);

  return (
    <>
      <Box p={2} m={2}>
        <Stack spacing={2}>
          <Typography variant="h6">{title}</Typography>
          {groups_keys.map((group_key) => {
            return (
              <Box key={group_key}>
                <Typography variant="subtitle1" key={`typo-${group_key}`}>
                  {filters.groupsConfiguration[group_key].title}
                </Typography>
                {filters.groupsConfiguration[group_key].type === "select"
                  ? (
                    <Autocomplete
                      options={filters.groupsConfiguration[group_key].options}
                      value={[...filters.groups[group_key]]}
                      multiple={true}
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(e, value) => {
                        updateFilters("", value, group_key);
                      }}
                    />
                  )
                  : ""}
                {filters.groupsConfiguration[group_key].type === "checkbox"
                  ? (
                    <FormGroup key={`fg-${group_key}`}>
                      {filters.groupsConfiguration[group_key].options.map(
                        (option, index) => {
                          return (
                            <CheckFilter
                              key={index}
                              index={index}
                              option={option}
                              group_key={group_key}
                              checked={filters?.groups[group_key]?.has(option)}
                              updateFilters={updateFilters}
                            />
                          );
                        },
                      )}
                    </FormGroup>
                  )
                  : ""}
              </Box>
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export const Filter = ({
  title,
  renderData,
  setRenderData,
  filters,
  setFilters,
}) => {
  const { t } = useTranslation(["general"]);

  function updateFilters(checked, filter, group_key) {
    let cpFilters = { ...filters };

    let group = cpFilters.groups[group_key];

    if (cpFilters.groupsConfiguration[group_key].type === "checkbox") {
      if (checked) {
        group = new Set(group).add(filter);
      } else {
        const next = new Set(group);
        next.delete(filter);
        group = next;
      }
    } else if (cpFilters.groupsConfiguration[group_key].type === "select") {
      group = new Set(filter);
    }

    cpFilters.groups[group_key] = group;
    cpFilters.hasFilter = false;

    for (const [key, val] of Object.entries(cpFilters.groups)) {
      cpFilters.hasFilter = val.size ? true : cpFilters.hasFilter;
    }

    setFilters({ ...cpFilters });
  }

  useEffect(() => {
    let filtered = renderData;

    if (filters.hasFilter) {
      for (const [key, g] of Object.entries(filters.groups)) {
        if (g.size) {
          filtered = filtered.filter((i) => {
            return g.has(i[key]);
          });
        }
      }

      if (!filtered.length) {
        Toast.fire({
          icon: "error",
          title: t("general:NoMatchesFound"),
        });
      }
    }

    setRenderData(filtered);
  }, [filters]);

  return (
    <GroupsFilter
      title={title}
      filters={filters}
      updateFilters={updateFilters}
    />
  );
};
