import { TextField, FormControl } from "@mui/material/";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

export const UINumericInput = ({
  formik,
  label = "",
  name = "",
  placeholder = "",
  nameFileArray = "",
  index = null,
  isrequired = true,
  disabled = false,
  width = "100%",
  style,
  InputProps,
  readOnly = false,
  prefix = "$",
  thousandSeparator = ",",
  decimalSeparator = ".",
}) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      readOnly={readOnly}
      disabled={disabled}
      style={style}
      inputprops={InputProps}
      placeholder={placeholder}
      required={isrequired}
    >
      <NumericFormat
        allowNegative={false}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        customInput={TextField}
        label={label}
        name={name}
        value={
          nameFileArray === ""
            ? formik.values[name]
            : formik.values[nameFileArray][index][name]
        }
        onValueChange={(values, sourceInfo) => {
          nameFileArray === ""
            ? formik.setFieldValue(name, values.floatValue)
            : formik.setFieldValue(
                `${nameFileArray}.${index}.${name}`,
                values.floatValue
              );
        }}
        autoComplete="off"
        prefix={`${prefix} `}
        disabled={disabled}
      />
    </FormControl>
  );
};

UINumericInput.propTypes = {
  formik: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};
