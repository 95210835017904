export const getAddressFromCoordinates = async (lat, lng) => {
  const apiKey = process.env.REACT_APP_GMAPS_API_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data?.results[0]?.formatted_address;
      const country = data?.results[0]?.address_components?.find((address) =>
        address.types.includes("country")
      )?.short_name;

      return {
        country: country,
        address: formattedAddress,
        lat: lat,
        lng: lng,
      };
    } else {
      return {
        address: "Address not found",
        lat: lat,
        lng: lng,
      };
    }
  } catch (error) {
    console.error("Error fetching address:", error);
    return {
      address: "Error fetching address",
      lat: lat,
      lng: lng,
    };
  }
};
