import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { IconButton } from "@mui/material";

export const SocialNetworksBtn = ({ red = null, url = null }) => {
  const redes = [
    {
      name: "FACEBOOK",
      icon: <FacebookIcon />,
      backgroundColor: "#3b5998",
    },
    {
      name: "X",
      icon: <XIcon />,
      backgroundColor: "#212121",
    },
    {
      name: "INSTAGRAM",
      icon: <InstagramIcon />,
      backgroundColor: "#3f729b",
    },
    {
      name: "LINKEDIN",
      icon: <LinkedInIcon />,
      backgroundColor: "#0e76a8",
    },
    {
      name: "PINTEREST",
      icon: <PinterestIcon />,
      backgroundColor: "#c8232c",
    },
    {
      name: "YOUTUBE",
      icon: <YouTubeIcon />,
      backgroundColor: "#c4302b",
    },
    {
      name: "TIKTOK",
      icon: "T",
      backgroundColor: "#212121",
    },
    {
      name: "SNAPCHAT",
      icon: "S",
      backgroundColor: "#000000",
    },
  ];

  return (
    <>
      {redes.map((item) => {
        if (item.name === red) {
          return (
            <IconButton
              key={item.name}
              sx={{
                width: "50px",
                height: "50px",
                backgroundColor: `${item.backgroundColor}`,
                color: "white",
              }}
              onClick={() => {
                if (url !== null && url !== "") {
                  window.open(url, "_blank");
                }
              }}
            >
              {item.icon}
            </IconButton>
          );
        }
      })}
    </>
  );
};
