import { Box } from "@mui/material";
import { UIButton } from "../UIButton";
import { useTranslation } from "react-i18next";

export const UISubmit = ({ formik, isFull = false, children }) => {
  const { t } = useTranslation(["general"]);

  return (
    <>
      {isFull ? (
        <Box mt={2}>
          <UIButton
            type="submit"
            label={
              formik?.values?._id
                ? t("general:Actualizar")
                : t("general:Guardar")
            }
            loading={formik.isSubmitting}
          />
        </Box>
      ) : (
        <Box mt={2} width="100%" display="flex" justifyContent="space-between">
          <Box display="flex" gap="1em">
            <UIButton
              type="submit"
              label={
                formik?.values?._id
                  ? t("general:Actualizar")
                  : t("general:Guardar")
              }
              loading={formik.isSubmitting}
            />
            {children}
          </Box>
        </Box>
      )}
    </>
  );
};
