import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MailVariables } from "./MailVariables";
import { MailSettingsSend } from "./MailSettingsSend";
import { useAccess } from "../../../../hooks";

export const MailSettings = () => {
  const { t } = useTranslation(["settings"]["general"]);
  const { access } = useAccess();
  const pathSendMail =
    "/cereza-organization/settings/configurations/EnvíoCorreo";
  const pathTags = "/cereza-organization/settings/configurations/Tags";
  const [tabSelected, setTabSelected] = useState(
    access(pathSendMail) ? pathSendMail : pathTags,
  );

  return (access(pathTags, pathSendMail) &&
    (
      <TabContext value={tabSelected}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, newTab) => setTabSelected(newTab)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {access(pathSendMail) && (
              <Tab label={t("settings:SendEmail")} value={pathSendMail} />
            )}
            {access(pathTags) && (
              <Tab label={t("settings:CustomTags")} value={pathTags} />
            )}
          </TabList>
        </Box>
        {access(pathSendMail) && (
          <TabPanel value={pathSendMail}>
            <MailSettingsSend />
          </TabPanel>
        )}
        {access(pathTags) && (
          <TabPanel value={pathTags}>
            <MailVariables />
          </TabPanel>
        )}
      </TabContext>
    ));
};
