import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  Avatar,
  TableHead,
} from "@mui/material";
import TablePaginationActions from "../TablePaginationActions";
import { useTranslation } from "react-i18next";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useSelector } from "react-redux";

export function BasicTable({
  rows,
  handleClick,
  children,
  PerPage = 5,
  hover = true,
  customStyle = null,
  color = true,
  dense = false,
  dontShow = [],
  maxHeight = "60vh",
}) {
  const { t } = useTranslation(["request"]);

  const { mainColor, mode } = useSelector(parameterizationSelect);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(PerPage);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getBackgroundColor = (row) => {
    if (customStyle === "StatusRequest") {
      switch (row?.status) {
        case t("recruitment:REVIEW_REQUIRED"):
          return "rgb(255, 244, 229)";

        case t("recruitment:REJECTED"):
          return "rgb(253, 237, 237)";

        case t("recruitment:PUBLISHED"):
          return "rgb(237, 247, 237)";

        case t("recruitment:CANCELED"):
          return "rgb(253, 237, 237)";

        default:
          return null;
      }
    } else if (customStyle === "AttendanceReports") {
      switch (row?.assistanceColor) {
        case "ASSISTANCE":
          return "rgb(195, 225, 197)";

        case "DELAY":
          return "rgb(254, 246, 189)";

        case "INCOMPLETE_SCHEDULE":
          return "rgb(253, 237, 237)";

        case "ABSENCE":
          return "rgb(250, 205, 208)";

        case "VACATIONS":
          return "rgb(237, 247, 237)";

        case "FULL_TIME_PERMISSION":
          return "rgb(170, 225, 242)";

        case "HOLIDAY":
          return "rgb(208, 194, 229)";

        default:
          return null;
      }
    } else if (customStyle === "Vacancies") {
      switch (row?.status) {
        case t("recruitment:OPEN"):
          return "rgb(237, 247, 237)";

        case t("recruitment:CLOSED"):
          return "rgb(253, 237, 237)";

        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <TableContainer sx={{ maxHeight: maxHeight }}>
        <Table size={dense ? "small" : "medium"} stickyHeader>
          <TableHead
            sx={color ? { backgroundColor: `${mainColor}40 !important` } : {}}
          >
            <TableRow
              sx={color ? { backgroundColor: `${mainColor}40 !important` } : {}}
            >
              {children}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <TableRow
                hover={hover}
                key={index}
                sx={hover ? { cursor: "pointer" } : {}}
              >
                {Object.keys(row).map((key, index) => {
                  if (key === "thumbnail") {
                    return (
                      <TableCell
                        component="th"
                        key={index}
                        align="left"
                        onClick={() => handleClick(row)}
                        style={
                          mode === "dark"
                            ? { color: getBackgroundColor(row) }
                            : {
                                backgroundColor: getBackgroundColor(row),
                              }
                        }
                      >
                        <Avatar
                          src={row[key]}
                          sx={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      </TableCell>
                    );
                  } else if (![...dontShow, "id", "_id"].includes(key)) {
                    return (
                      <TableCell
                        component="th"
                        key={index}
                        align="left"
                        onClick={() => handleClick(row)}
                        style={
                          mode === "dark"
                            ? { color: getBackgroundColor(row) }
                            : {
                                backgroundColor: getBackgroundColor(row),
                              }
                        }
                      >
                        {row[key]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={9}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
