import { cerezaClient } from "../adapters";

const NOTIFY = "/v1/notify";
const INTEGRATIONS = "/v1/int";

export function getConnectionStr(employeeId) {
  return cerezaClient.get(
    `${INTEGRATIONS}/azure/socket/connection/${employeeId}`
  );
}

export function getNotifications(employeeId) {
  return cerezaClient.get(`${NOTIFY}/user/notifications/${employeeId}`);
}

export function deleteNotifications(notifyId) {
  return cerezaClient.delete(`${NOTIFY}/user/notifications/${notifyId}`);
}

export function markAsReadNotifications(notifyId) {
  return cerezaClient.patch(`${NOTIFY}/user/notifications/${notifyId}`, {});
}
