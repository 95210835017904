import { useTranslation } from "react-i18next";
import {
  Toast,
  UIModal,
  UIButton,
  UISwitch,
  UITextField,
  UISelectCheckmarks,
} from "components";
import { Box, Grid, Stack, Alert, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { managerErrors } from "utils";
import { getBenefits, postBenefitPackage, putBenefitPackage } from "api";
import { useEffect, useState } from "react";
import { setReRender, uiSelect } from "@redux/slices/ui";
import { generalSelect } from "@redux/slices/general";

export const ModalPackageShortcut = ({
  open,
  setOpen,
  packageSelected = null,
}) => {
  const { t } = useTranslation(["employee"]["general"]);
  const dispatcher = useDispatch();

  const { currencies } = useSelector(generalSelect);
  const { reRender } = useSelector(uiSelect);

  const [benefits, setBenefits] = useState([]);

  useEffect(() => {
    const fetchBenefits = async () => {
      try {
        const { data } = await getBenefits();
        setBenefits(data);
      } catch (err) {
        console.error("Error fetching benefits:", err);
      }
    };
    fetchBenefits();
  }, [reRender]);

  const handleSubmit = async (values, { resetForm }) => {
    if (packageSelected === null) {
      try {
        await postBenefitPackage(values);
        dispatcher(setReRender(Math.random()));
        Toast.fire({
          icon: "success",
          title: t("employee:PaqueteCreadoConExito"),
        });
        setOpen(false);
      } catch (err) {
        managerErrors(err?.response?.data?.message);
        console.error("Error submitting form:", err);
      }
    } else {
      try {
        const { tenant, _id, ...res } = values;
        await putBenefitPackage(_id, res);
        dispatcher(setReRender(Math.random()));
        Toast.fire({
          icon: "success",
          title: t("employee:PaqueteActualizadoConExito"),
        });
        setOpen(false);
      } catch (err) {
        managerErrors(err?.response?.data?.message);
        console.error("Error submitting form:", err);
      }
    }
  };

  const moneyFormat = (amount, id) => {
    const item = currencies.find((currency) => currency.value === id);
    return `${item?.prefix} ${amount} ${item?.label}`;
  };

  const makeBenefitDetails = (id) => {
    const item = benefits.find((benefit) => benefit?._id === id);

    return `${item?.title} paid every ${
      item?.periodicity
    } days for an amount of ${moneyFormat(item?.amount, item?.currency)}.`;
  };

  return (
    <>
      <UIModal open={open} setOpen={setOpen}>
        <Typography variant="h5">
          {t("employee:CrearAtajoDeAsignación")}
        </Typography>
        <br />
        <Formik
          initialValues={
            packageSelected !== null
              ? packageSelected
              : {
                  isActive: true,
                  title: "",
                  benefits: [],
                }
          }
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <br />
              <Grid container spacing={2}>
                <Grid
                  item={true}
                  xs={12}
                  md={packageSelected !== null ? 8 : 12}
                >
                  <UITextField
                    formik={formik}
                    label={t("employee:Nombredelpaquete")}
                    name={"title"}
                  />
                </Grid>
                {packageSelected !== null && (
                  <Grid item={true} xs={12} md={4}>
                    <UISwitch
                      formik={formik}
                      label={t("employee:isActive")}
                      name={"isActive"}
                    />
                  </Grid>
                )}
                <Grid item={true} xs={12}>
                  <UISelectCheckmarks
                    options={benefits.filter((item) => {
                      return (
                        (item.isActive && item.isActive === true) ||
                        formik.values?.benefits?.includes(item._id)
                      );
                    })}
                    formik={formik}
                    label={"benefits"}
                    name={"benefits"}
                  />
                </Grid>
              </Grid>
              <br />

              <Stack sx={{ width: "100%" }} spacing={2}>
                {formik.values.benefits.map((item, index) => (
                  <Alert severity="info" key={index}>
                    {makeBenefitDetails(item)}
                  </Alert>
                ))}
              </Stack>
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  <UIButton type="submit" label={t("general:Guardar")} />
                  <UIButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    label={t("general:Cancel")}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </UIModal>
    </>
  );
};
