import { useState } from "react";
import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";

export const SearchBar = ({ data, searchKeys, setFilteredData }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = data.filter((item) =>
      searchKeys.some((key) => item[key].toLowerCase().includes(term))
    );
    setFilteredData(filtered);
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: ["auto", "auto", "50%"],
        "@media screen and (max-width: 768px)": {
          width: "auto",
        },
      }}
    >
      <InputBase
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        sx={{ ml: 1, flex: 1 }}
      />
      <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};
