import { timeClockClient } from "../adapters";

const POLICY = "/v1/policy";

export function getPolicy(policyId) {
  if (policyId) {
    return timeClockClient.get(`${POLICY}/${policyId}`);
  } else {
    return timeClockClient.get(`${POLICY}`);
  }
}

export function postPolicy(data) {
  return timeClockClient.post(`${POLICY}`, data);
}

export function putPolicy(policyId, data) {
  return timeClockClient.put(`${POLICY}/${policyId}`, data);
}

export function deletePolicy(policyId) {
  return timeClockClient.delete(`${POLICY}/${policyId}`);
}
