import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const AddButton = ({
  flowModalOpen,
  canAddVacations,
  canAddDismissals,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickMenuItem = (typeFlow) => {
    switch (typeFlow) {
      case "vacations":
        flowModalOpen({
          title: "",
          country: "",
          flowSettings: {
            authorizationMethod: "ONE",
            hierarchicalOrder: false,
            approvers: [],
            maxHierarchyDepth: "",
            finalAuthorizer: false,
            authorizers: [],
            waitingTime: "",
            automaticAuthorized: false,
            deliveryMethod: "BROADCAST",
          },
          extraRules: {
            allowVacationAccrual: false,
            maximumAccrualPeriodsDays: "",
            allowExceedingBalance: false,
            allowVacationPeriods: false,
            numberPeriodsInYear: "",
            numberConsecutiveDays: "",
            accountForNonWorkingDays: false,
            holidayCalendars: [],
            accountForHolidays: false,
            allowVacationBeforeTheEndYear: false,
            allowVacationStartingFromWhichMonth: "",
          },
          _type: typeFlow,
        });
        break;

      case "dismissals":
        flowModalOpen({
          type: "",
          title: "",
          country: "",
          flowSettings: {
            authorizationMethod: "ONE",
            hierarchicalOrder: false,
            approvers: [],
            maxHierarchyDepth: "",
            finalAuthorizer: false,
            authorizers: [],
            waitingTime: "",
            automaticAuthorized: false,
            deliveryMethod: "BROADCAST",
          },
          alerts: [],
          requiresSupervisorComments: false,
          _type: typeFlow,
        });
        break;
    }

    setAnchorEl(null);
  };
  if (canAddVacations || canAddDismissals) {
    return (
      <>
        <Button
          id="new-flow-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={onClick}
          endIcon={<KeyboardArrowDownIcon />}
          variant="contained"
          fullWidth={true}
        >
          {t("geneneral:Nuevo")}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
          MenuListProps={{ "aria-labelledby": "new-request-button" }}
        >
          {canAddVacations && (
            <MenuItem
              onClick={() => {
                onClickMenuItem("vacations");
              }}
            >
              {t("settings:VacationsFlow")}
            </MenuItem>
          )}
          {canAddDismissals && (
            <MenuItem
              onClick={() => {
                onClickMenuItem("dismissals");
              }}
            >
              {t("settings:DismissalsFlow")}
            </MenuItem>
          )}
        </Menu>
      </>
    );
  } else {
    return "";
  }
};
