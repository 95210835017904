import { Toast } from "components";

export function getLabel(array, value) {
  const element = array.find((obj) => obj.value === value);
  return element ? element.label : null;
}

export const getPrefix = (currencyId, currencies) => {
  const currency = currencies.find((currency) => currency.value === currencyId);
  return currency?.prefix;
};

export function managerErrors(input) {
  if (typeof input === "string") {
    return Toast.fire({
      icon: "error",
      title: input,
    });
  }

  if (typeof input?.errors === "object" && input?.errors !== null) {
    const concatenatedValues = Object.values(input?.errors).join(", ");
    return Toast.fire({
      icon: "error",
      title: concatenatedValues,
    });
  }

  return null;
}
